import styled from "@emotion/styled";
import { Box, Card, Container } from "@mui/material";
import rgba from "assets/theme/functions/rgba";

export const StyledShopWrapper = styled(Box)(({ theme, darkTheme }) => ({
  backgroundImage: darkTheme && `url(${"https://d3a0rs3velfrz.cloudfront.net/bg-gradient.svg"})`,
  backgroundSize: darkTheme && "cover",
  transform: "rotate(180deg)",
}));

export const StyledShopProductContainer = styled(Container)(({ theme, darkTheme }) => ({
  transform: "rotate(-180deg)",
  paddingBlock: "4rem",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));
export const StyledProductCards = styled(Card)(({ theme }) => ({
  padding: ".8rem",
  minHeight: "107px",
  transition: "all .2s ease-in-out",
  background: `${rgba("#fff", 0.9)}`,
  backdropFilter: "blur(15px)",
  borderRadius: 0,
  borderLeft: `10px solid ${theme.palette.accent.main}`,
  "&:hover": {
    cursor: "pointer",
    transition: "all .3s ease-in-out",
    transform: "translateY(-4px)",
    boxShadow: `${rgba(theme.palette.accent.main, 0.6)} 0px 6px 24px`,
  },
}));
