import styled from "@emotion/styled";
import { Container } from "@mui/material";
import MKBox from "components/MKBox";

export const StyledMusicAndTherapyPageHeaderSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));

export const StyledMusicAndTherapyTextBox = styled(MKBox)(({ theme }) => ({
  //! ended up not needing at the moment delete if so.
}));
