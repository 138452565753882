import styled from "@emotion/styled";
import { Container } from "@mui/material";
import MKBox from "components/MKBox";

export const StyledMusicAndTherapyPageHeaderSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",

  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));

export const StyledMusicAndTherapyCardsContainer = styled(MKBox)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
}));

export const StyledMusicImage = styled("img")(({ theme }) => ({
  width: "100px",
  height: "100px",
  objectFit: "cover",
  margin: "0 auto 1rem auto",
  display: "flex",
  [theme.breakpoints.up("md")]: {
    margin: "0 0 1rem 0",
  },
}));
