import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import MKTypography from "components/MKTypography";

export const StyledOurProgramSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));
export const StyledHowItWorksTitle = styled(MKTypography)(({ theme }) => ({
  paddingBottom: "1.5rem",
  color: `${theme.palette.white.main} !important`,
  // textAlign: "center",
  marginBottom: "0",
}));

export const StyledOurTherapistOuterGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  // flexDirection: "center",
  // justifyContent: "center",
  // textAlign: "center",
}));

export const StyledOurTherapistListText = styled(MKTypography)(({ theme }) => ({
  fontWeight: 500,
  position: "relative",
  color: `${theme.palette.white.main} !important`,
  borderBottom: `3px solid ${theme.palette.accent.main}`,
  // textDecoration: "underline",
  // textDecorationColor: theme.palette.accent.main,
  // border: "1px solid red",
  paddingBottom: ".5rem",
  [theme.breakpoints.up("lg")]: {
    textAlign: "center",
  },
}));
