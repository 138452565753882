// Major
import React, {useState, useRef} from "react";
import { Formik, Form, Field, useFormikContext } from "formik";

// Components
import { TextField, Select, FormControl, MenuItem, OutlinedInput, InputAdornment, Stack, Container } from "@mui/material";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TAImageCrop from "components/TAImageCrop";
import getCroppedImg from "utils/helpers/getCroppedImg";

// Styles
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { convertCamelCaseToWords } from "utils/helpers/convertCamelCase";
import theme from "assets/theme";
import { StyledFormContainer, StyledInputContainer } from "./index.styles";

const EditForm = ({questions, handleSubmit}) => {
    

    const FormBody = () => {
        const [selectedFile, setSelectedFile] = useState(null);
        const [fileName, setFileName] = useState("");
        const fileInputRef = useRef(null);
        const { setFieldValue } = useFormikContext();

        /////////////////////////////////////
        const [src, setSrc] = useState(null); // To store the original image
        const [crop, setCrop] = useState({ aspect: 1 }); // Crop settings
        const [croppedImageUrl, setCroppedImageUrl] = useState(null); // URL of the cropped image
        const [croppedFile, setCroppedFile] = useState(null); // File of the cropped image
        const imgRef = useRef(null); // Reference to the image element

        const onImageLoaded = (event) => {
            const image = event.target;
            imgRef.current = image;
            setCroppedImageUrl(null);
        };

        const onCropComplete = (crop) => {
            if (imgRef.current && crop.width && crop.height) {
            const {croppedUrl, blob} = getCroppedImg(imgRef.current, crop);
            if(croppedUrl){
                setSrc(null)
                setCroppedImageUrl(croppedUrl);
            }
            if(blob){
                setCroppedFile(blob);
            }
            setSelectedFile(blob);
            setFileName("Cropped_Image");
            setFieldValue("profile cropped image", blob);
            }
        };


        /////////////////////////////////////

        
        const handleFileChange = (e, name) => {
            const file = e.target.files[0];

            // setSelectedFile(file);
            // setFileName(file.name);
            // setFieldValue(name, file);

            const reader = new FileReader();
            reader.onload = () => setSrc(reader.result);
            reader.readAsDataURL(file);
        };
        
        const handleButtonClick = () => {
            fileInputRef.current.click();
        };
        
        
        const menuProps = {
            PaperProps: {
            style: {
                maxHeight: 200,
                marginTop: "1rem",
            },
            },
        };
        const commonInputStyles = {
            "& .MuiInputBase-input": {
            color: "black !important",
            },
            "& .MuiFormLabel-root": {
            color: "black !important",
            },
        };
        const safeJSONParse = (str) => {
            try {
            return JSON.parse(str);
            } catch (e) {
            return str;
            }
        };

        
        const questionOptionOutline = (question, index) => {
            return(
                <StyledInputContainer key={index}>
                    <MKTypography component="label" for={`${question.name}`} variant="h6" color="text.primary" sx={{ fontWeight: "500" }} gutterBottom>
                    {question.name || ""}
                    </MKTypography>
                    <Field
                        as={TextField}
                        name={question.name}
                        variant={question.variant}
                        multiline={question.rows > 1}
                        rows={question.rows}
                        
                        fullWidth
                        inputProps={{
                            style: { color: 'black', backgroundColor: theme.palette.white.main }
                        }}
                    />
                </StyledInputContainer>
            )
    }

    const questionOptionSelect = (question, index) => {
        return(
            <StyledInputContainer key={index}>
            <MKTypography component="label" for={`${question.name}`} variant="h6" color="text.primary" sx={{ fontWeight: "500" }} gutterBottom>
                {question.name || ""}
            </MKTypography>
            <Field name={question.name} inputProps={{
                style: { color: 'black', }
            }}>
                {({ field, form }) => (
                    <FormControl variant="outlined" fullWidth sx={{backgroundColor: theme.palette.white.main,}}>
                    <Select
                        {...field}
                        multiple={question.multi}
                        value={safeJSONParse(field.value) || []}
                        onChange={(event) => {
                            const value = event.target.value;
                            const formattedValue = question.multi ? JSON.stringify(value) : value;
                            form.setFieldValue(question.name, formattedValue);
                        }}
                        input={
                            <OutlinedInput
                                endAdornment={
                                    <InputAdornment  position="end">
                                        <ExpandMoreIcon alt={"Expand more Icon"} sx={{zIndex: "-1"}} />
                                    </InputAdornment>
                                }
                            />
                        }
                        label={convertCamelCaseToWords(question.name)}
                        displayEmpty
                        renderValue={(selected) => {
                            if (!selected.length) {
                                return (
                                    <MKTypography component="p" sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.55)" }}>
                                        Select your {convertCamelCaseToWords(question.name)}
                                    </MKTypography>
                                );
                            }
                            return Array.isArray(selected) ? selected.join(", ") : selected;
                        }}
                        MenuProps={menuProps} 
                        sx={{
                            "& .MuiInputBase-input": {
                              color: "black !important",
                              height: "44px",
                              display: "flex",
                              alignItems: "center",
                              padding: "10px 14px",
                              marginRight: "-40px",
                            },
                            "& .MuiFormLabel-root": {
                              color: "black !important",
                            },
                            height: "44px",
                            width: "100%",
                          }} 
                    >
                        {question.options.map((option, optionIndex) => (
                        <MenuItem key={optionIndex} value={option.value}>
                            {option.label}
                        </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                )}
            </Field>
            </StyledInputContainer>
        )
    }

    const questionOptionNumber = (question, index) => {
        return(
            <StyledInputContainer key={index}>
            <MKTypography component="label" for={`${question.name}`} variant="h6" color="text.primary" sx={{ fontWeight: "500" }} gutterBottom>
                {question.name || ""}
            </MKTypography>
            <Field
                id={question.name}
                name={question.name}
                as={TextField}
                type="number"
                variant={"outlined"}
                placeholder={`Enter your ${convertCamelCaseToWords(question.name)}`}
                InputProps={{
                    style: {
                    color: "black !important",
                    backgroundColor: theme.palette.white.main,
                    },
                }}
                sx={commonInputStyles}
                fullWidth
            />
            </StyledInputContainer>
        )
    }

    const questionOptionTel = (question, index) => {
       return(
            <StyledInputContainer key={index}>
            <MKTypography component="label" for={`${question.name}`} variant="h6" color="text.primary" sx={{ fontWeight: "500" }} gutterBottom>
                {question.name || ""}
            </MKTypography>
            <Field
                id={question.name}
                name={question.name}
                as={TextField}
                type="tel"
                variant={"outlined"}
                placeholder={`Enter your ${convertCamelCaseToWords(question.name)}`}
                InputProps={{
                    style: {
                    color: "black !important",
                    backgroundColor: theme.palette.white.main,
                    },
                }}
                sx={commonInputStyles}
                fullWidth
            />
            </StyledInputContainer>
        ) 
    }

    const questionOptionFile = (question, index) => {
        
        return(
            <>
                <StyledInputContainer key={index}>
                <MKTypography component="label" for={`${question.name}`} variant="h6" color="text.primary" sx={{ fontWeight: "500" }} gutterBottom>
                    {question.name || ""}
                </MKTypography>
                <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={(e) => handleFileChange(e, question.name)} />
                <TextField
                    variant="outlined"
                    name={question.name}
                    value={fileName}
                    placeholder="Choose a file"
                    InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <MKButton variant="contained" onClick={handleButtonClick}>
                        Browse
                        </MKButton>
                    ),
                    style: {
                        color: "black !important",
                        backgroundColor: theme.palette.white.main,
                    },
                    }}
                    sx={commonInputStyles}
                    fullWidth
                />
                </StyledInputContainer>
                {src ? (
                        
                        <Stack sx={{width: "80% !important", padding: "1rem", margin: "auto !important", backgroundColor: theme.palette.grey[100]}} >
                            <TAImageCrop 
                                src={src} 
                                crop={crop}
                                setCrop={setCrop}
                                onCropComplete={onCropComplete}
                                locked={false} 
                                circularCrop={false} 
                                aspect={1} o
                                onImageLoaded={onImageLoaded} 
                            /> 
                            <MKButton sx={{width: "50px", margin: "1rem", color: theme.palette.white.main ,backgroundColor: theme.palette.secondary.main}} onClick={() => {onCropComplete(crop)}}>Crop</MKButton>
                        </Stack>
                        ) : null}
                        {croppedImageUrl && (
                            <Container sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                                <img src={croppedImageUrl} alt="Cropped_Image" />
                            </Container>
                    )}
            </>
        )
    }
        return(
            <Form>
                {questions.map((question, index) => {
                    switch (question.type) {
                        case "outline":
                            return questionOptionOutline(question, index);
                        case "select":
                            return questionOptionSelect(question, index);
                        case "number":
                            return questionOptionNumber(question, index);
                        case "tel":
                            return questionOptionTel(question, index);
                        case "file":
                            return questionOptionFile(question, index);
                        default:
                            return null;
                    }
            })}
                <MKButton type="submit" disabled={src} variant="contained" color="primary" sx={{marginTop: "0.5rem"}}>Submit</MKButton>
            </Form>
            
        )
    }
    



    return(
        <StyledFormContainer>
            <Formik
                initialValues={
                    questions.reduce((acc, question) => {
                        acc[question.name] = question.answer;
                        return acc;
                    }, {})
                }
                onSubmit={(values) => {
                    console.log("values", values);
                    handleSubmit(values);
                }}
            >
                {() => (
                    <FormBody />
                )}
            </Formik>
        </StyledFormContainer>
    )
}

export default EditForm;