import React from "react";
import { StyledMainPageContainer } from "index.styles";
import Slider from "./components/Slider";
import ProductSection from "./components/productSection";

const Shop = () => {
  return (
    <StyledMainPageContainer id="shop-page">
      <Slider />
      <ProductSection productId="prod_QhjbTCZqe6jIXZ" />
      <ProductSection productId="prod_Qhjdsp4kFmoJc6" reverse={true} darkTheme={true} />
    </StyledMainPageContainer>
  );
};

export default Shop;
