import React from "react";
import Hero from "./components/Hero";
import AboutKetamine from "./components/AboutKetamine";
import OurServices from "./components/OurServices/";
import Testimonials from "./components/Testimonials";
import CBDivider from "components/CBDivider";
import { StyledMainPageContainer } from "index.styles";
import SchemaComponent from "components/CBSchema";
import { constructSchemaData } from "utils/helpers/constructSchemaData";
import homeSchemaData from "./schema.data.json";
import SEO from "components/CBSEO";
import seoData from "./seo.data.json";
const Home = () => {
  const schemaData = constructSchemaData(homeSchemaData.url, homeSchemaData.breadcrumbs);

  return (
    <StyledMainPageContainer id="home-page-container">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />

      <Hero />
      <AboutKetamine />
      <OurServices />
      <CBDivider />
      <Testimonials />
    </StyledMainPageContainer>
  );
};

export default Home;
