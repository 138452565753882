import styled from "@emotion/styled";

import { Container } from "@mui/material";
import MKTypography from "components/MKTypography";

export const StyledResearchSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",

  [theme.breakpoints.up("lg")]: {
    // minHeight: "525px",
    paddingBlock: "6rem",
    // display: "flex",
    // alignItems: "center",
  },
}));

export const EllipsisHeaderTypography = styled(MKTypography)`
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
`;
export const EllipsisHeader6Typography = styled(MKTypography)`
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
`;
export const EllipsisBodyTextTypography = styled(MKTypography)`
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
`;
