import styled from "@emotion/styled";

import { Container } from "@mui/material";
import MKBox from "components/MKBox";

export const StyledHowItWorksSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));

export const StyledHowItWorksFlexBox = styled(MKBox)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "2rem",
  [theme.breakpoints.up("md")]: {
    gap: "4rem",
  },
  [theme.breakpoints.up("lg")]: {
    flexDirection: "row",
  },
}));

export const StyledHowItWorksVideoBox = styled(MKBox)(({ theme }) => ({
  height: "300px",
  minWidth: "100%",
  minHeight: "300px",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  backgroundColor: "#3330304e",
  [theme.breakpoints.up("md")]: {
    minWidth: "500px",
    minHeight: "400px",
  },
}));
