import theme from "assets/theme";
import MKTypography from "components/MKTypography";
import React from "react";
import { StyledSectionBackgroundWrapper } from "index.styles";
import { StyledTextContainer } from "./index.styles";
const PageHeader = () => {
  return (
    <StyledSectionBackgroundWrapper id="insurance-coverage-wrapper">
      <StyledTextContainer component="section" id="insurance-page-header-section">
        <MKTypography variant={"h1"} sx={{ color: `${theme.palette.white.main} !important` }} gutterBottom>
          Insurance Coverage for <br />
          <span style={{ color: theme.palette.accent.main }}> Ketamine Psychedelic Therapy</span>
        </MKTypography>
      </StyledTextContainer>
    </StyledSectionBackgroundWrapper>
  );
};

export default PageHeader;
