import theme from "assets/theme";

const aboutKetamineData = [
  {
    title: (
      <div>
        Exceptional Care,
        <span style={{ color: theme.palette.accent.main }}> Your home, </span>Our Guidance.
      </div>
    ),
    subtitle: "Discover the benefits of sustained well-being with professional care from the comfort of your home.",
  },
  {
    title: (
      <div>
        Your Safety,
        <span style={{ color: theme.palette.accent.main }}> Our Priority, </span>Expert Care Awaits.
      </div>
    ),

    subtitle: "Our team of experienced, medically licensed professionals are dedicated to improving the mental health of their patients.  ",
  },
  {
    title: (
      <div>
        Real
        <span style={{ color: theme.palette.accent.main }}> Relief Now </span>
      </div>
    ),

    subtitle:
      "Ketamine Psychedelic Therapy  offers potential alternative treatments for depression, anxiety, PTSD and other mental health challenges.  ",
  },
];
export default aboutKetamineData;
