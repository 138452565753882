import styled from "@emotion/styled";
import { Box, Container, Divider } from "@mui/material";
import MKBox from "components/MKBox";

export const StyledCourseCardBox = styled(Box)(({ theme }) => ({
  borderRadius: "12px",
  width: "100%",
  minHeight: "400px",
  maxWidth: "525px",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  padding: "1.5rem",
  marginBottom: { xs: "2rem", lg: 0 },
  background: "white",

  [theme.breakpoints.up("lg")]: {
    minHeight: "450px",
    maxWidth: "450px",
  },
  [theme.breakpoints.up("xl")]: {
    maxWidth: "500px",
    minHeight: "435px",
  },
  [theme.breakpoints.up("xxl")]: {
    maxWidth: "550px",
    minHeight: "425px",
  },
}));
