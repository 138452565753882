import React from "react";
import { Link } from "react-router-dom";
// Components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
// Styled Components
import { StyledCareersAboutSectionContainer } from "./index.style";
import { StyledImage } from "index.styles";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";

const About = () => {
  const { isMd, isLg } = useMediaQueries();

  return (
    <StyledCareersAboutSectionContainer component={"section"} id="careers-about-section">
      <Grid container spacing={4} direction={isMd ? "row" : "column"}>
        <Grid item container justifyContent="center" alignItems="center" xs={12} lg={6}>
          <StyledImage
            height="530"
            width="416"
            src={"https://d3a0rs3velfrz.cloudfront.net/careers/careersImage.webp"}
            alt="A person interviewing another individual."
          />
        </Grid>
        <Grid item container alignItems={"center"} xs={12} lg={6}>
          <Box>
            <Box marginBottom={2}>
              <MKTypography color={"accent"} variant={isLg ? "body1" : "body2"}>
                Want to work with us?
              </MKTypography>
              <MKTypography variant="h1" component={"h1"}>
                Help Change Lives, <br />
                <MKTypography color={"accent"} component={"span"} variant={"inherit"}>
                  {" "}
                  One Treatment
                </MKTypography>{" "}
                At A Time
              </MKTypography>
            </Box>
            <Box marginBottom={3}>
              <MKTypography variant={isLg ? "body1" : "body2"} component="p">
                Almadelic medical and clinical advisors take a holistic and client-centered approach to mental health. We believe in the potential of
                psychedelic therapy to unlock new perspectives, heal old wounds, and ignite personal growth.
              </MKTypography>
            </Box>
            <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} alignItems={{ xs: "stretched", sm: "flex-start" }}>
              <MKButton aria-label="Speak with our team" component={Link} variant="contained" color="secondary" size="large" to={"/contact-us"}>
                Speak with our team
              </MKButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </StyledCareersAboutSectionContainer>
  );
};

export default About;
