import React from "react";
import { StyledMainPageContainer } from "index.styles";
import ProductDetails from "./components/product-details";
import Addons from "../../components/CBAddOns";
import CBDivider from "components/CBDivider";
import useFetchProducts from "pages/Shop/hooks/useFetchProducts";
import { Container } from "@mui/material";
import CBLoading from "components/CBLoading";

const ProductPage = () => {
  const { products, addons, loading, error } = useFetchProducts();

  return (
    <StyledMainPageContainer id="product-page">
      {error ? (
        <Container sx={{ marginBlock: "4rem" }}></Container>
      ) : (
        <>
          {loading ? (
            <Container sx={{ marginBlock: "4rem" }}>
              <CBLoading loadingItem={"products"} />
            </Container>
          ) : (
            <>
              <ProductDetails products={products} />
              <CBDivider sections={"product-details-and-addons"} />
              <Addons addons={addons} header="Suggested" headerAccent="Add-ons" />
            </>
          )}
        </>
      )}
    </StyledMainPageContainer>
  );
};

export default ProductPage;
