import { Link } from "react-router-dom";
// Components
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
// Styled components
import {
  StyledKetamineAssistedTherapySectionContainer,
  StyledKeyMechanismsOfKetamineAssistedTherapyBox,
  StyledKeyMechanismsOfKetamineAssistedTherapyButtonBox,
  StyledKeyMechanismsOfKetamineAssistedTherapyDivider,
  StyledKeyMechanismsOfKetamineAssistedTherapyInnerBox,
} from "./index.style";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Data
import infoSlider from "../../infoSlider.data.json";
// Theme
import theme from "assets/theme";
const KetamineAssistedTherapy = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledKetamineAssistedTherapySectionContainer component="section" id="ketamine-assisted-therapy-section">
      <StyledKeyMechanismsOfKetamineAssistedTherapyBox>
        <MKTypography component={"h3"} variant={"h3"}>
          <span style={{ color: `${theme.palette.accent.main} !important` }}>Key Mechanisms </span>
          Of Ketamine Assisted Psychedelic Therapy
        </MKTypography>
      </StyledKeyMechanismsOfKetamineAssistedTherapyBox>
      {infoSlider.map(({ title, content }, index) => {
        return (
          <StyledKeyMechanismsOfKetamineAssistedTherapyInnerBox key={index}>
            <StyledKeyMechanismsOfKetamineAssistedTherapyDivider />
            <MKTypography component={"h4"} variant={"h6"}>
              {title}
            </MKTypography>
            <MKTypography component={"p"} variant={isLg ? "body1" : "body2"}>
              {content}
            </MKTypography>
          </StyledKeyMechanismsOfKetamineAssistedTherapyInnerBox>
        );
      })}
      <StyledKeyMechanismsOfKetamineAssistedTherapyButtonBox>
        <MKButton
          component={Link}
          to="/science-and-research"
          variant="contained"
          color="secondary"
          size="large"
          sx={{ maxWidth: "500px" }}
          aria-label="View our science and research"
        >
          See Science and Research
        </MKButton>
      </StyledKeyMechanismsOfKetamineAssistedTherapyButtonBox>
    </StyledKetamineAssistedTherapySectionContainer>
  );
};

export default KetamineAssistedTherapy;
