import React from "react";
// Components
import WhatToExpect from "./components/WhatToExpect";
import OurPrograms from "./components/OurProgram";
import CBDivider from "components/CBDivider";
import OurServices from "./components/OurServices/index.component";
import SEO from "components/CBSEO";
// Styled components
import { StyledMainPageContainer } from "index.styles";
// Schema
import SchemaComponent from "components/CBSchema";
import ourProgramSchemaData from "./schema.organization.json";
// SEO
import seoData from "./seo.data.json";
// Utils
import { constructSchemaData } from "utils/helpers/constructSchemaData";

const OurProgram = () => {
  const schemaData = constructSchemaData(ourProgramSchemaData.url, ourProgramSchemaData.breadcrumbs);
  return (
    <StyledMainPageContainer id="our-program-container">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <OurPrograms />
      <WhatToExpect />
      <CBDivider />
      <OurServices />
    </StyledMainPageContainer>
  );
};

export default OurProgram;
