import React from "react";
import PersonalizedJourney from "./components/personalizedJourney";
import Addons from "../../components/CBAddOns";
import { StyledMainPageContainer } from "index.styles";
import ShopSlider from "pages/Shop/components/Slider";
import CBDivider from "components/CBDivider";
import useFetchProducts from "pages/Shop/hooks/useFetchProducts";

const CustomizeYourPackage = () => {
  const { addons } = useFetchProducts();

  return (
    <StyledMainPageContainer id="customize-your-package-container">
      <ShopSlider />
      <PersonalizedJourney />
      <CBDivider sections={"personalized-journey-and-addons"} />
      <Addons addons={addons} />
    </StyledMainPageContainer>
  );
};

export default CustomizeYourPackage;
