import React from "react";
// Components
import { Contact, Hero } from "./components";
import CBDivider from "components/CBDivider";
import SchemaComponent from "components/CBSchema";
import SEO from "components/CBSEO";
// Styled Components
import { StyledMainPageContainer } from "index.styles";
// Data
import { constructSchemaData } from "utils/helpers/constructSchemaData";
import contactUsSchemaData from "./schema.organization.json";
import seoData from "./seo.data.json";

const ContactPage = () => {
  const schemaData = constructSchemaData(contactUsSchemaData.url, contactUsSchemaData.breadcrumbs);
  return (
    <StyledMainPageContainer>
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <Hero />
      <CBDivider sections={"hero-contact"} />
      <Contact />
    </StyledMainPageContainer>
  );
};

export default ContactPage;
