import { useState } from "react";
import axios from "axios";
import { createAuthUserWithEmailAndPassword } from "utils/firebase/authentication.firebase";
import { createUserDocumentFromAuth } from "utils/firebase/authentication.firebase";

const filterEmptyValues = (payload) => {
  const filteredPayload = {};

  for (const key in payload) {
    if (payload.hasOwnProperty(key) && payload[key] !== "") {
      filteredPayload[key] = payload[key];
    }
  }

  return filteredPayload;
};

const parseArrayFields = (data) => {
  const arrayFields = [
    "licensedStates",
    "languagesSpoken",
    "licenses",
    "practiceSettings",
    "specialties",
    "therapyTypes",
    "therapyTypesOffered",
    "treatmentMethods",
    "treatmentModalities",
    "visitTypes",
  ];

  const parsedData = { ...data };
  if (!data) return parsedData;
  arrayFields.forEach((field) => {
    if (typeof data[field] === "string") {
      if (data[field].trim() === "") {
        parsedData[field] = [];
      } else {
        try {
          parsedData[field] = JSON.parse(data[field]);
        } catch (error) {
          console.error(`Error parsing field ${field}:`, error);
          console.error(`Data causing error: ${data[field]}`);
          parsedData[field] = [];
        }
      }
    }
  });
  return parsedData;
};

const useUpdateGHLContacts = () => {
  const [data, setData] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);

  const updateGHLContacts = async (postData, contactId) => {
    setIsUpdating(true);
    setIsError(false);
    setError(null);

    const filteredPayload = filterEmptyValues(postData);
    const parsedData = parseArrayFields(filteredPayload);

    const payload = {
      ...parsedData,
      contactId: contactId,
    };

    try {
      const response = await axios.put("https://1br067lqe8.execute-api.us-east-1.amazonaws.com/corsEnabled/", payload);
      if (response.status === 200) {
        const { user } = await createAuthUserWithEmailAndPassword(postData.email, postData.password);
        await createUserDocumentFromAuth(user, { crmContactId: response.data.body.contact.id });
        setData(response.data);
        return response.data;
      } else {
        setData(response.data);
        return response.data;
      }
    } catch (err) {
      setIsError(true);
      setError(err);
      switch (err.code) {
        case "auth/email-already-in-use":
          alert("Email already in use, please contact support@almadelic.com");
          setError(err);
          break;
        default:
          alert(error);
      }
    } finally {
      setIsUpdating(false);
    }
  };

  return { data, isUpdating, isError, error, updateGHLContacts };
};

export default useUpdateGHLContacts;
