import React from "react";
// Styled Components
import { StyledHowToCardsContainer, StyledHowToSectionContainer } from "./index.styles";
import { StyledHowToCard } from "index.styles";
import { StyledImage } from "index.styles";
// Components
import MKTypography from "components/MKTypography";
// Data
import howToData from "./howTo.data.json";
// Theme
import theme from "assets/theme";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
const HowTo = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledHowToSectionContainer component="section" id="how-to-section">
      <StyledImage
        howto={true}
        height="300"
        width="400"
        src={"https://d3a0rs3velfrz.cloudfront.net/preparation/howTo-section/howTo1.webp"}
        alt="Orange Ketamine Troche In Its Packaging"
      ></StyledImage>
      <MKTypography component="h2" variant="h2" sx={{ marginBottom: "2rem" }}>
        How To Self Administer A<span style={{ color: `${theme.palette.accent.main}` }}> Ketamine Troche</span>
      </MKTypography>
      <StyledHowToCardsContainer>
        {howToData.map(({ instruction }, index) => {
          return (
            <StyledHowToCard key={index}>
              <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
                {instruction}
              </MKTypography>
            </StyledHowToCard>
          );
        })}
      </StyledHowToCardsContainer>
    </StyledHowToSectionContainer>
  );
};

export default HowTo;
