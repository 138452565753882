import React from "react";
import { Link } from "react-router-dom";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import useMediaQueries from "utils/mediaQueries.utils";
import theme from "assets/theme";
import { StyledButtonContainer } from "index.styles";
import { StyledOurServicesTextBox } from "../../index.styles";
import { ghlLinkFreeConsultation } from "utils/helpers/ghlFunnelLinks";

const ServicesText = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledOurServicesTextBox>
      <MKTypography variant="h2" component="h2" gutterBottom>
        Our <span style={{ color: `${theme.palette.accent.main}` }}>Services</span>
      </MKTypography>
      <MKTypography component="p" variant={isLg ? "body1" : "body2"} gutterBottom>
        We recognize that every patient is on a distinct path to healing. Our customizable packages offer a flexible and cost-effective approach to
        crafting the transformative and supportive experience each person deserves. Treatment programs can incorporate optional add-on services,
        allowing plans that cater specifically to their needs and the recommendations of their treating provider for Ketamine Psychedelic Therapy.
      </MKTypography>
      <StyledButtonContainer>
        <MKButton
          component={Link}
          to={ghlLinkFreeConsultation}
          target="_blank"
          rel="noopener noreferrer"
          variant="contained"
          color="secondary"
          size="large"
          sx={{ maxWidth: "500px" }}
          aria-label="Schedule A Free Consultation Today"
        >
          Schedule A Free Consultation Today
        </MKButton>
      </StyledButtonContainer>
    </StyledOurServicesTextBox>
  );
};

export default ServicesText;
