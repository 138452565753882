import { Grid } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React from "react";
import useMediaQueries from "utils/mediaQueries.utils";
import { StyledImpactOfEnvironmentSectionContainer } from "./index.style";
import { StyledImage } from "index.styles";
import theme from "assets/theme";
import { StyledSectionBackgroundWrapper } from "index.styles";

const ImpactOfEnvironment = () => {
  const { isLg } = useMediaQueries();

  return (
    <StyledSectionBackgroundWrapper id="ideal-space-wrapper">
      <StyledImpactOfEnvironmentSectionContainer component="section" id="impact-of-environment-section">
        <Grid container spacing={isLg ? 8 : 4}>
          <Grid item xs={12} lg={6} order={isLg ? 1 : 0}>
            <StyledImage
              height="471"
              width="604"
              src={"https://d3a0rs3velfrz.cloudfront.net/creatingYourSpace/creating-your-space-3.webp"}
              alt="comfortable couch in cozy living room."
            />
          </Grid>
          <Grid item xs={12} lg={6} order={isLg ? 0 : 1}>
            <MKBox>
              <MKTypography component={"h2"} variant={"h2"} gutterBottom sx={{ color: `${theme.palette.white.main} !important` }}>
                Understanding the Impact of <span style={{ color: `${theme.palette.accent.main}` }}> Environment</span> In Ketamine Treatments
              </MKTypography>
              <MKTypography component={"p"} variant={isLg ? "body1" : "body2"} gutterBottom sx={{ color: `${theme.palette.white.main} !important` }}>
                Ketamine induces a sensory experience, making the surroundings crucial to the overall session experience. Both physical elements and
                the general atmosphere play a significant role, as evidenced by studies highlighting their impact on ketamine psychostimulant
                sensitization.
              </MKTypography>
              <MKTypography component={"p"} variant={isLg ? "body1" : "body2"} gutterBottom sx={{ color: `${theme.palette.white.main} !important` }}>
                Recognizing the importance of the environment, preparing your living space becomes essential for a productive ketamine therapy
                session. A tranquil and calm setting is generally recommended. Here are steps to create an ideal space for your at-home ketamine
                therapy.
              </MKTypography>
            </MKBox>
          </Grid>
        </Grid>
      </StyledImpactOfEnvironmentSectionContainer>
    </StyledSectionBackgroundWrapper>
  );
};

export default ImpactOfEnvironment;
