import React from "react";
// Styled Components
import { StyledMainPageContainer } from "index.styles";
// Components
import PageHeader from "./components/pageHeader";
import OurTherapistProgram from "./components/ourTherapistProgram";
import PatientBenefits from "./components/patientBenefits";
import SchemaComponent from "components/CBSchema";
import SEO from "components/CBSEO";
// Data
import therapistProgramSchemaData from "./schema.organization.json";
import seoData from "./seo.data.json";
// Utils
import { constructSchemaData } from "utils/helpers/constructSchemaData";

const TherapistProgram = () => {
  const schemaData = constructSchemaData(therapistProgramSchemaData.url, therapistProgramSchemaData.breadcrumbs);
  return (
    <StyledMainPageContainer id="therapist-program-page">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <PageHeader />
      <OurTherapistProgram />
      <PatientBenefits />
    </StyledMainPageContainer>
  );
};

export default TherapistProgram;
