// import React, { useContext, useRef, useState } from "react";
// import MKInput from "components/MKInput";
// import MKButton from "components/MKButton";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import MKBox from "components/MKBox";
// // import SearchFilterMenu from "../searchFiltersMenu";
// import { StyledSearchBarSectionContainer } from "./index.styles";
// import SearchIcon from "@mui/icons-material/Search";
// import theme from "assets/theme";
// import SearchFilterMenu from "pages/FindATherapist/components/searchFiltersMenu";
// import { SearchFor } from "utils/context/searchFilter.context";
// import SuggestionsList from "./components";
// const SearchBar = ({ placeholder, onChangeHandler, pathName, minusPadding }) => {
//   // console.log(pathName);
//   const [showFilters, setShowFilters] = useState(false);
//   const { searchTerms, filteredData, resetSearchTerms, setSearchBarTerms } = useContext(SearchFor);
//   const [isSuggestionsVisible, setSuggestionsVisible] = useState(false); // State to manage suggestions visibility
//   const inputRef = useRef(null);
//   let results = [];

//   const handleShowFilters = () => {
//     setShowFilters(!showFilters);
//   };
//   // console.log(searchTerms);
//   searchTerms?.forEach((value) => {
//     results.push(value);
//   });

//   const handleBlur = (e) => {
//     // Check if the focus is moving to an element within the suggestions list
//     if (e.relatedTarget && e.relatedTarget.closest(".suggestions-list")) {
//       return;
//     }
//     setSuggestionsVisible(false);
//   };
//   // console.log(filteredData);

//   return (
//     <StyledSearchBarSectionContainer minusPadding={minusPadding}>
//       <MKBox sx={{ position: "relative", margin: "auto", height: "100%", width: "100%" }}>
//         <MKBox
//           sx={{
//             position: "absolute",
//             left: 0,
//             top: "50%",
//             transform: "translate(0, -50%)",
//             minHeight: "44px",
//             zIndex: 1,
//             width: "50px",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             backgroundColor: `${theme.palette.secondary.main}`,
//           }}
//         >
//           <SearchIcon sx={{ color: "#fff" }} />
//         </MKBox>
//         <MKInput
//           onFocus={() => setSuggestionsVisible(true)} // Show suggestions on focus
//           onBlur={handleBlur} // Hide suggestions on blur
//           type="search"
//           placeholder={placeholder}
//           onChange={onChangeHandler}
//           ref={inputRef}
//           sx={{
//             display: "flex",
//             margin: "0 auto",
//             "& .MuiOutlinedInput-input": {
//               color: "black !important",
//               paddingLeft: { xs: "65px", lg: "85px" },
//             },
//             backgroundColor: "#fff !important",
//           }}
//         />
//         {/* {pathName === "/find-a-therapist" && (
//           <MKButton
//             sx={{ position: "absolute", right: 0, top: "50%", transform: "translate(0, -50%)", minHeight: "44px" }}
//             startIcon={showFilters ? <KeyboardArrowUpIcon></KeyboardArrowUpIcon> : <KeyboardArrowDownIcon></KeyboardArrowDownIcon>}
//             variant="contained"
//             color="secondary"
//             onClick={handleShowFilters}
//           >
//             Filter By
//           </MKButton>
//         )} */}
//       </MKBox>
//       {/* {results.length > 0 && <SuggestionsList suggestions={filteredData} />} */}
//       {isSuggestionsVisible && results.length > 0 && <SuggestionsList suggestions={filteredData} />}

//       {/* <Stack direction={"row"} spacing={".5rem"} sx={{ border: "1px solid red" }}>
//         {results.map((term, index) => {
//           return (
//             <></>
//           );
//         })}
//       </Stack> */}
//       {/* {pathName === "/find-a-therapist" && (
//         <MKButton
//           component={Link}
//           to="/partner-prospect-survey"
//           sx={{
//             px: 2,
//             mt: "1rem",
//             opacity: 1,
//             width: "100%",
//             maxWidth: "400px",
//             marginInline: "auto !important",
//             // color: "# !important",
//             letterSpacing: ".05rem !important",
//           }}
//           color="secondary"
//         >
//           Are You a Therapist? <br />
//           Showcase Your Expertise Here!
//         </MKButton>
//       )} */}
//       {pathName === "/find-a-therapist" && <SearchFilterMenu showFiltersMenu={showFilters} setShowFilters={setShowFilters} />}
//       {/* {searchTerms.length < 1 && (
//         <Stack direction={"row"} spacing="1rem" marginTop="2rem">
//           {searchTerms?.map((term, index) => {
//             return (
//               <MKTypography sx={{ backgroundColor: "#cac8c8", padding: ".5rem", fontSize: "80%", borderRadius: "12px" }} key={index}>
//                 {term.charAt(0).toUpperCase() + term.slice(1)}
//               </MKTypography>
//             );
//           })}
//         </Stack>
//       )} */}
//     </StyledSearchBarSectionContainer>
//   );
// };

// export default SearchBar;

import React, { useContext, useRef, useState } from "react";
import MKInput from "components/MKInput";
import MKBox from "components/MKBox";
import { StyledSearchBarSectionContainer } from "./index.styles";
import SearchIcon from "@mui/icons-material/Search";
import theme from "assets/theme";
import SearchFilterMenu from "pages/FindATherapist/components/searchFiltersMenu";
import { SearchFor } from "utils/context/searchFilter.context";
import SuggestionsList from "./components";
const SearchBar = ({ placeholder, onChangeHandler, pathName, minusPadding }) => {
  const [showFilters, setShowFilters] = useState(false);
  const { searchTerms, filteredData, resetSearchTerms, setSearchBarTerms } = useContext(SearchFor);
  const [isSuggestionsVisible, setSuggestionsVisible] = useState(false);
  const inputRef = useRef(null);
  let results = [];

  searchTerms?.forEach((value) => {
    results.push(value);
  });

  const handleBlur = (e) => {
    if (e.relatedTarget && e.relatedTarget.closest(".suggestions-list")) {
      return;
    }
    setSuggestionsVisible(false);
  };

  return (
    <StyledSearchBarSectionContainer minusPadding={minusPadding}>
      <MKBox sx={{ position: "relative", margin: "auto", height: "100%", width: "100%" }}>
        <MKBox
          sx={{
            position: "absolute",
            left: 0,
            top: "50%",
            transform: "translate(0, -50%)",
            minHeight: "44px",
            zIndex: 1,
            width: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: `${theme.palette.secondary.main}`,
          }}
        >
          <SearchIcon sx={{ color: "#fff" }} />
        </MKBox>
        <MKInput
          onFocus={() => setSuggestionsVisible(true)}
          onBlur={handleBlur}
          type="search"
          placeholder={placeholder}
          onChange={onChangeHandler}
          ref={inputRef}
          sx={{
            display: "flex",
            margin: "0 auto",
            "& .MuiOutlinedInput-input": {
              color: "black !important",
              paddingLeft: { xs: "65px", lg: "85px" },
            },
            backgroundColor: "#fff !important",
          }}
        />
      </MKBox>
      {/* {isSuggestionsVisible && results.length > 0 && <SuggestionsList results={results} suggestions={filteredData} />} */}
      {/* {pathName === "/find-a-therapist" && <SearchFilterMenu showFiltersMenu={showFilters} setShowFilters={setShowFilters} />} */}
    </StyledSearchBarSectionContainer>
  );
};

export default SearchBar;
