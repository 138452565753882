import { useEffect, useRef } from "react";

const useResetSearchTerms = (resetSearchTerms) => {
  const resetCalled = useRef(false);

  useEffect(() => {
    if (!resetCalled.current) {
      resetSearchTerms();
      resetCalled.current = true;
    }
  }, [resetSearchTerms]);
};

export default useResetSearchTerms;
