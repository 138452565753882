import { List, ListItem, Stack } from "@mui/material";
import theme from "assets/theme";
import rgba from "assets/theme/functions/rgba";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React from "react";

const ProductList = ({ cartItems, marginBottom, textColor }) => {
  return (
    <List sx={{ marginBottom: marginBottom ? "2rem" : "0" }}>
      {cartItems?.map(({ name, quantity, image }, index) => (
        <ListItem key={index}>
          {image ? (
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing="1rem"
              sx={{ border: `1px solid ${rgba(theme.palette.grey[400], 0.5)}`, width: "100%", padding: ".5rem" }}
            >
              <MKTypography
                component="p"
                variant="body2"
                gutterBottom
                sx={{ color: textColor === "light" && `${theme.palette.white.main} !important` }}
              >
                {quantity}x
              </MKTypography>
              <MKBox component="img" src={image} sx={{ height: "50px", width: "50px" }}></MKBox>
              <MKTypography
                component="p"
                variant="body2"
                gutterBottom
                sx={{ color: textColor === "light" && `${theme.palette.white.main} !important` }}
              >
                {" "}
                {name}
              </MKTypography>
            </Stack>
          ) : (
            <MKTypography component="p" variant="body2" gutterBottom>
              {quantity}x {name}
            </MKTypography>
          )}
        </ListItem>
      ))}
    </List>
  );
};

export default ProductList;
