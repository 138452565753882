// Major
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

//  Components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SendIcon from "@mui/icons-material/Send";
import ErrorIcon from "@mui/icons-material/Error";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";

// Styles
import { Stack, TextField } from "@mui/material";
import theme from "assets/theme";
import { StyledResetFormContainer } from "./index.styles";
import usePasswordReset from "../hooks";
import { StyledButtonContainer } from "index.styles";

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const { sendPasswordReset, isLoading, error, status } = usePasswordReset();

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .trim()
      .email("Please enter a valid email address")
      .matches(
        /^[^\s@]+@[^\s@]+\.(com|org|net|edu|gov|mil|info|biz|co|me|io|tech|online|us|uk|ca|au|ac|health|med|doctor|academy|institute|college|law|consulting|clinic|usp\.br)$/,
        "Email must end with valid domain suffixes."
      )
      .required("Email is required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    try {
      await sendPasswordReset(values.email);
      resetForm();
      setTimeout(() => {
        navigate("/therapist-auth/sign-in");
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const { isLg } = useMediaQueries();
  return (
    <StyledResetFormContainer>
      <MKTypography component="h1" variant="h1" mb="2rem">
        Password <span style={{ color: theme.palette.accent.main }}> Reset</span>
      </MKTypography>
      <MKBox
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{
          "& .MuiOutlinedInput-input": {
            color: "black !important",
            background: theme.palette.background.paper,
            padding: 2,
          },
          background: "rgba(255 ,255, 255, .3)",
          boxShadow: `rgba(0,0,0, .1) 0px 6px 12px`,
          backdropFilter: "blur(8px)",
          borderRadius: "8px",
          padding: "32px 16px",
        }}
      >
        {status && (
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={2}
            sx={{
              backgroundColor:
                status === "success" ? theme.palette.success.main : status === "sending" ? theme.palette.info.focus : theme.palette.error.main,
              color: "#fff",
              padding: "1rem",
              marginBottom: "1rem",
            }}
          >
            {status === "sending" ? <SendIcon></SendIcon> : status === "success" ? <CheckCircleIcon></CheckCircleIcon> : <ErrorIcon></ErrorIcon>}
            <MKTypography sx={{ color: "#fff" }}>{status}</MKTypography>
          </Stack>
        )}
        <MKTypography component="label" for="email" variant="h6" color="text.primary" fontWeight={700} gutterBottom>
          E-mail
        </MKTypography>
        <TextField
          id="email"
          placeholder="Enter Your email address..."
          variant="outlined"
          size="medium"
          name="email"
          fullWidth
          type="email"
          aria-label="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          sx={{ marginBottom: "1rem" }}
        />
        <Stack direction={isLg ? "row" : "column"} alignItems={"center"} justifyContent={"space-between"} mt="3rem">
          <MKTypography component="p" variant={"body2"}>
            Sign In Here?{" "}
            <Link style={{ color: theme.palette.accent.main, textDecoration: "none" }} to="/therapist-auth/sign-in" aria-label="Sign up link">
              Log In
            </Link>
          </MKTypography>
          <StyledButtonContainer>
            <MKButton variant="contained" type="submit" color="secondary" size="large" aria-label="Log in button">
              {isLoading ? "Sending..." : "Send Password Reset"}
            </MKButton>
          </StyledButtonContainer>
        </Stack>
      </MKBox>
    </StyledResetFormContainer>
  );
};

export default ResetPasswordForm;
