import styled from "@emotion/styled";
import { Drawer } from "@mui/material";

export const StyledSearchFilterDrawerMenu = styled(Drawer)(({ theme }) => ({
  marginTop: "2rem",
  borderRadius: "12px",
  boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
  "& .MuiDrawer-paper": {
    padding: "2rem",
    width: "80%",
    [theme.breakpoints.up("lg")]: {
      width: "50% ",
    },
  },
}));
