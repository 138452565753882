// Major
import React from "react";

// Components
import MKButton from "components/MKButton";
// Styles
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import useMediaQueries from "utils/mediaQueries.utils";


const EditButton = ({ showForm, setShowForm }) => {

    const { isLg, isMd, isSm } = useMediaQueries();

    const styleButton = {
        marginLeft: "0.5rem"
    }
    return (
        <MKButton color="secondary" size="small" sx={styleButton} onClick={() => setShowForm(!showForm)}>
                {showForm ? <EditOffIcon sx={{height: isLg ? "24px" : "20px", width:isLg ? "24px" : "20px"}}/> : <EditIcon sx={{height: isLg ? "24px" : "20px", width: isLg ? "24px" : "20px"}}/>}
        </MKButton>
    )
};

export default EditButton;