import { useState, useEffect } from "react";
import axios from "axios";

const useFetchStripeTransaction = (paymentIntentId) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchPaymentIntentDetails = async () => {
      try {
        const response = await axios.post("https://lbealjhg80.execute-api.us-east-1.amazonaws.com/dev2/transactions/fetch-transaction", {
          paymentIntentId,
        });

        setData(response.data.paymentIntent);
      } catch (error) {
        setError(error.response?.data?.error || error.message);
      } finally {
        setLoading(false);
      }
    };

    if (paymentIntentId) {
      fetchPaymentIntentDetails();
    }
  }, []);

  return { loading, error, data };
};

export default useFetchStripeTransaction;
