import React from "react";
// Components
import MKTypography from "components/MKTypography";
// Styled Components
import { StyledImage } from "index.styles";
import { StyledProfileCards } from "./index.style";

const MeetTheTeamCard = ({ image, name, title }) => {
  return (
    <StyledProfileCards>
      <StyledImage height="150" width="150" headshot={"true"} src={image} alt={`${name}'s Member Partner Profile Image`} />

      <MKTypography component="h2" variant="h6">
        {name}
      </MKTypography>
      <MKTypography component="p" variant={"body2"}>
        {title}
      </MKTypography>
    </StyledProfileCards>
  );
};

export default MeetTheTeamCard;
