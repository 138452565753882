import { useState } from "react";
import axios from "axios";
import { createAuthUserWithEmailAndPassword } from "utils/firebase/authentication.firebase";
import { createUserDocumentFromAuth } from "utils/firebase/authentication.firebase";

const usePostGHLContacts = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);

  const excludeKeys = (obj, keys) => {
    return Object.keys(obj).reduce((acc, key) => {
      if (!keys.includes(key)) {
        acc[key] = obj[key];
      }
      return acc;
    }, {});
  };

  const parseArrayFields = (data) => {
    const arrayFields = [
      "licensedStates",
      "languagesSpoken",
      "licenses",
      "practiceSettings",
      "specialties",
      "therapyTypes",
      "therapyTypesOffered",
      "treatmentMethods",
      "treatmentModalities",
      "visitTypes",
    ];

    const parsedData = { ...data };
    if (!data) return parsedData;
    arrayFields.forEach((field) => {
      if (typeof data[field] === "string") {
        if (data[field].trim() === "") {
          parsedData[field] = [];
        } else {
          try {
            parsedData[field] = JSON.parse(data[field]);
          } catch (error) {
            console.error(`Error parsing field ${field}:`, error);
            console.error(`Data causing error: ${data[field]}`);
            parsedData[field] = [];
          }
        }
      }
    });
    return parsedData;
  };

  const postGHLContacts = async (postData) => {
    setIsLoading(true);
    setIsError(false);
    setError(null);

    const sanitizedData = excludeKeys(postData, ["password", "confirmPassword"]);

    const parsedData = parseArrayFields(sanitizedData);

    const payload = {
      ...parsedData,
      scenario: "prospect therapist",
      locationId: process.env.REACT_APP_GHL_LOCATION_ID,
    };
    try {
      const response = await axios.post(process.env.REACT_APP_AWS_CREATE_CONTACT_GHL_ENDPOINT, payload);
      if (response.data.statusCode === 400 && response.data.message === "This location does not allow duplicated contacts.") {
        setData(response.data);
        return response.data;
      } else {
        const { user } = await createAuthUserWithEmailAndPassword(postData.email, postData.password);
        await createUserDocumentFromAuth(user, { crmContactId: response.data.contact.id });
        setData(response.data);
        return response.data;
      }
    } catch (err) {
      // FirebaseError: Firebase: Error (auth/email-already-in-use)
      setIsError(true);
      setError(err);
      switch (err.code) {
        case "auth/email-already-in-use":
          alert("Email already in use, please contact support@almadelic.com");
          setError(err);
          break;
        default:
          alert(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return { data, isLoading, isError, error, postGHLContacts };
};

export default usePostGHLContacts;
