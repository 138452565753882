import styled from "@emotion/styled";
import { Container } from "@mui/material";

export const StyledInsuranceCoverageContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  // [theme.breakpoints.up("md")]: {
  //   minHeight: "625px",
  // },
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
  // [theme.breakpoints.up("xl")]: {
  //   minHeight: "650px",
  // },
  // [theme.breakpoints.up("xxl")]: {
  //   minHeight: "850px",
  // },
}));
