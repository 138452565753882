import { useState } from "react";
import axios from "axios";

const usePurchaseMade = () => {
  const [loading, setLoading] = useState(false);
  const [automationError, setAutomationError] = useState(null);
  const [automationResponse, setAutomationResponse] = useState(null);

  const purchaseMade = async (purchaseData) => {
    setLoading(true);
    setAutomationError(null);

    try {
      const response = await axios.post(
        "https://lbealjhg80.execute-api.us-east-1.amazonaws.com/dev2/transactions/purchase-automation",
        {
          data: purchaseData,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("automation response", response);
      setAutomationResponse(response.data);
      return response.data;
    } catch (err) {
      setAutomationError(err.message);
      console.error("Zapier Error:", err);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { purchaseMade, loading, automationError, automationResponse };
};

export default usePurchaseMade;
