/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import CBLogo from "components/CBLogo";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import FooterForm from "../FooterForm";
import { Stack } from "@mui/material";
import theme from "assets/theme";
import useMediaQueries from "utils/mediaQueries.utils";
import { StyledSectionBackgroundWrapper } from "index.styles";

function DetailedFooter({ content }) {
  const { socials, menus, copyright, disclaimer } = content;

  const { isXl } = useMediaQueries();
  return (
    <MKBox component="footer" sx={{ position: "relative", overflow: "hidden" }}>
      <StyledSectionBackgroundWrapper gradientfooterbg="true">
        <Container sx={{ padding: { xs: "2rem 1rem", md: "2rem !important", lg: "0" }, maxWidth: "1500px !important" }}>
          {/* Footer Grid Container */}
          <Grid spacing={4} container sx={{ justifyContent: "space-between" }}>
            {/* Footer Brand/Logo */}
            <Grid
              item
              xs={12}
              xl={2}
              sx={{ display: "flex", flexDirection: isXl ? "column" : "row", justifyContent: { xs: "space-between", xl: "start" } }}
            >
              <Stack mb={"1rem"}>
                <Link aria-label="Almadelic homepage" style={{ display: "flex", justifyContent: "center" }} to="/">
                  {/* <CBLogo /> */}
                  {/* Uncomment when we have the proper footer logo */}
                  <CBLogo footerLogo={true} />
                </Link>
              </Stack>
              {/* Socials */}
              <MKBox sx={{ display: "flex", justifyContent: "space-evenly" }} display="flex" alignItems="start">
                {socials.map(({ icon, link, key }, index) => (
                  <MKTypography
                    sx={{
                      color: theme.palette.white.main,
                      fontSize: "1.125rem",
                      display: "flex",
                      mr: {
                        xs: key === socials.length - 1 ? 0 : 2,
                        xl: 0,
                      },
                    }}
                    key={index}
                    component="a"
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    variant="body2"
                  >
                    {icon}
                  </MKTypography>
                ))}
              </MKBox>
            </Grid>
            {/* Footer Menus */}
            <Grid
              item
              xs={12}
              xl={5}
              sx={{
                minHeight: "100%",
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                justifyContent: { xs: "space-between", md: "space-around", lg: "space-evenly" },
                gap: { xs: "1rem" },
              }}
            >
              {menus.map(({ name: title, items }, index) => (
                <MKBox key={index} sx={{ minWidth: { lg: "124px" }, maxWidth: { lg: "155px" } }}>
                  <MKTypography
                    sx={{ color: theme.palette.white.main }}
                    component="h6"
                    variant="button"
                    fontWeight="bold"
                    textTransform="capitalize"
                    mb={1}
                  >
                    {title}
                  </MKTypography>
                  <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                    {items.map(({ name, route, href }) => (
                      <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25} sx={{ minWidth: { md: "200px" } }}>
                        {href ? (
                          <MKTypography
                            sx={{ color: theme.palette.white.main }}
                            component="a"
                            href={href}
                            target="_blank"
                            rel="noreferrer"
                            variant="button"
                            fontWeight="regular"
                            textTransform="capitalize"
                            pb={0.5}
                          >
                            {name}
                          </MKTypography>
                        ) : (
                          <MKTypography
                            sx={{ color: theme.palette.white.main }}
                            component={Link}
                            to={route}
                            variant="button"
                            fontWeight="regular"
                            textTransform="capitalize"
                            pb={0.5}
                          >
                            {name}
                          </MKTypography>
                        )}
                      </MKBox>
                    ))}
                  </MKBox>
                </MKBox>
              ))}
            </Grid>
            {/* Footer Form */}
            <FooterForm />
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Divider />
              <MKTypography component="p" variant="body2" sx={{ margin: "auto", color: theme.palette.white.main }}>
                {disclaimer}
              </MKTypography>
            </Grid>
            {/* Footer Copy Right */}
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <MKTypography component="p" variant="body2" sx={{ color: theme.palette.white.main }}>
                {copyright}
              </MKTypography>
            </Grid>
          </Grid>
        </Container>
      </StyledSectionBackgroundWrapper>
    </MKBox>
  );
}

// Typechecking props for the DetailedFooter
DetailedFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DetailedFooter;
