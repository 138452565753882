import { styled } from "@mui/system";
import rgba from "assets/theme/functions/rgba";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
const { Box, Typography, Collapse } = require("@mui/material");

export const NavBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  cursor: "pointer",
}));

export const NavLink = styled(Typography)({
  textDecoration: "none !important",
});

export const DropdownBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.borders.borderRadius.md,
  background: "rgba(255, 255, 255, 0)",
  color: "black",
  padding: theme.spacing(1),
  zIndex: 1,
  width: "100%",
}));

export const CustomCollapse = styled(Collapse)(({ theme }) => ({
  position: "absolute",
  top: "80px",
  left: 0,
  width: "100%",
}));

export const StyledMKTypography = styled(MKTypography)(({ theme }) => ({
  width: "100%",
  display: "block",
  borderRadius: theme.borders.borderRadius.md,
  "&:hover": {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.dark.main,
    "& *": {
      color: theme.palette.dark.main,
    },
  },
}));

export const StyledMenuBox = styled(MKBox)(({ theme }) => ({
  padding: "2rem 0",
  backgroundColor: rgba("#fff", 0.8),
  backdropFilter: "blur(30px) !important",
  boxShadow: "rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;",
}));
