import React from "react";
import ServicesData from "./services.data.json";
import ServiceCard from "../serviceCard";
import { Box } from "@mui/material";
const ServicesCards = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: { sm: "column", md: "row" }, flexWrap: "wrap", gap: "1rem", justifyContent: "center" }}>
      {ServicesData.map(({ title, description, iconUrl }, i) => {
        return <ServiceCard key={i} title={title} description={description} iconUrl={iconUrl} index={i} />;
      })}
    </Box>
  );
};

export default ServicesCards;
