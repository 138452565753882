/* eslint-disable no-param-reassign */
/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 PRO React examples

// Routes
import routes from "routes";
// Context
import { useContext } from "react";
import { AuthContext } from "utils/context/authentication.context";
import { UserContext } from "utils/context/user.context";
// Components
import CBNavBar from "./components/Navbar";

function NavbarTransparent() {
  const { isAuthorized } = useContext(AuthContext);
  const { currentUserProfile } = useContext(UserContext);

  const filterRoutes = (allRoutes) => {
    return allRoutes
      .map((route) => {
        if (route.collapse) {
          // Recursively filter collapse routes
          const filteredCollapse = filterRoutes(route.collapse);
          return { ...route, collapse: filteredCollapse };
        }

        if ((route.protected && !isAuthorized) || (route.requiredRole && currentUserProfile?.role !== route.requiredRole)) {
          return null;
        }

        return route;
      })
      .filter((route) => route !== null && (!route.collapse || route.collapse.length > 0));
  };

  const filteredRoutes = filterRoutes(routes);
  return <CBNavBar routes={filteredRoutes} />;
}

export default NavbarTransparent;
