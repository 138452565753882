import { Link } from "react-router-dom";
import React from "react";
// Components
import { Grid, List, ListItem, Stack } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import CBBulletPoint from "components/CBBulletPoint";
import MKButton from "components/MKButton";
// Styled Components
import { StyledAreYouATherapistSectionContainer } from "./index.styles";
import { StyledImage } from "index.styles";
import { StyledButtonContainer } from "index.styles";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Theme
import theme from "assets/theme";

const mockData = [
  "Increase visibility with patients looking for ketamine providers",
  "Share your background, specialties, and treatment approaches",
  "Control your availability for virtual appointments",
  "Receive inquiries directly from prospective patients",
];

const AreYouATherapist = () => {
  const { isLg, isXl } = useMediaQueries();

  return (
    <StyledAreYouATherapistSectionContainer id="find-a-therapist-header-section" component="section">
      <Grid container columnSpacing={isLg ? 6 : 3} rowSpacing={isXl ? 0 : 6}>
        <Grid item xs={12} xl={5} order={isXl ? 1 : 0}>
          <StyledImage
            findatherapist={"true"}
            width="502"
            height="487"
            src={"https://d3a0rs3velfrz.cloudfront.net/findATherapist/areYouATherapist1.webp"}
            alt="Patient meeting and shaking the hand of their professional therapist."
          />
        </Grid>
        <Grid item xs={12} xl={7} order={isXl ? 0 : 1}>
          <MKTypography gutterBottom component={"h2"} variant={"h2"}>
            Are you a therapist? <br />
            <span style={{ color: theme.palette.accent.main, fontSize: "inherit", fontWeight: "inherit" }}>Expand Your Practice At Almadelic</span>
          </MKTypography>

          <MKTypography component="p" variant={isLg ? "body1" : "body2"} gutterBottom>
            Are you a licensed mental health provider certified to administer ketamine therapy? Create your free profile on Almadelic to reach more
            patients seeking ketamine treatments.
          </MKTypography>
          <MKTypography component="p" variant={isLg ? "body1" : "body2"} gutterBottom>
            By listing your practice on our platform, you can:
          </MKTypography>
          <List>
            {mockData.map((item, index) => {
              return (
                <ListItem key={index} sx={{ alignItems: "center" }}>
                  <MKBox sx={{ marginTop: ".1rem" }}>
                    <CBBulletPoint />
                  </MKBox>
                  <Stack ml={1} sx={{ flexDirection: "row" }}>
                    <MKBox>
                      <MKTypography variant={isLg ? "body1" : "body2"} component="p">
                        {item}
                      </MKTypography>
                    </MKBox>
                  </Stack>
                </ListItem>
              );
            })}
          </List>
          <StyledButtonContainer>
            <MKButton aria-label="Learn more" component={Link} to={"/therapist-program"} color="secondary">
              Learn More
            </MKButton>
          </StyledButtonContainer>
        </Grid>
      </Grid>
    </StyledAreYouATherapistSectionContainer>
  );
};

export default AreYouATherapist;
