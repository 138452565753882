/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { StyledAboutKetamineImageBox, StyledAboutKetamineSectionContainer } from "./index.styles";
import useMediaQueries from "utils/mediaQueries.utils";
import theme from "assets/theme";
// import scienceBehindKetamineGif from "../../../../assets/images/CB/scienceAndResearch/scienceBehindKetamine.gif";
const AboutKetamine = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledAboutKetamineSectionContainer component="section" id="about-ketamine-section">
      <Grid container columnSpacing={isLg ? 8 : 0} rowSpacing={isLg ? 0 : 4} alignItems={"center"}>
        <Grid item xs={12} md={12} lg={6} order={isLg ? 1 : 0}>
          <StyledAboutKetamineImageBox>
            <img
              scrolling="no"
              title="Science behind ketamine giphy - Creative giphy with floating serotonin molecules."
              src={"https://d3a0rs3velfrz.cloudfront.net/scienceAndResearch/0124.gif"}
              frameBorder="0"
              className="giphy-embed"
              allowFullScreen
              alt="Science behind ketamine giphy - Creative giphy with floating serotonin molecules."
            ></img>
          </StyledAboutKetamineImageBox>
        </Grid>
        <Grid item xs={12} md={12} lg={6} order={isLg ? 0 : 1}>
          <MKBox>
            <MKTypography gutterBottom variant="h1" component="h1">
              The Science Behind
              <span style={{ color: theme.palette.accent.main }}> Ketamine Psychedelic Therapy</span>
            </MKTypography>
            <MKTypography variant={isLg ? "body1" : "body2"} component="p">
              Ketamine works quickly and effectively in improving your mood. Its unique way of influencing the brain's connections and chemicals makes
              it a promising option for those seeking relief from anxiety, depression, and additional mental health conditions.
            </MKTypography>
          </MKBox>
        </Grid>
      </Grid>
    </StyledAboutKetamineSectionContainer>
  );
};

export default AboutKetamine;
