import React from "react";
import { Link } from "react-router-dom";
// Components
import MKTypography from "components/MKTypography";
// Styled Components
import { StyledOurGoalOuterBox, StyledOurGoalInnerBox, StyledGetStartedButton } from "./index.style";
// Utils
import { useMediaQuery } from "@mui/material";
// Theme
import theme from "assets/theme";
const OurGoal = () => {
  const isLg = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });
  return (
    <StyledOurGoalOuterBox component="section" id="our-goal-section">
      <StyledOurGoalInnerBox
        height="125"
        width="125"
        alt={"Image of a Jagged Circle with a Check Mark in The Center"}
        src={"https://d3a0rs3velfrz.cloudfront.net/check.svg"}
      />
      <StyledGetStartedButton component={Link} content={"Get Started Today!"} />
      <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
        Ketamine-assisted therapy combines the strengths of ketamine treatment and traditional psychotherapy, accelerating the healing process while
        ensuring ongoing support. As these modalities synergize, individuals can experience profound transformations, paving the way for a future
        where innovative healing methods continue to flourish.
      </MKTypography>
    </StyledOurGoalOuterBox>
  );
};

export default OurGoal;
