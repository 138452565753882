import styled from "@emotion/styled";
import { Container } from "@mui/material";
export const StyledHeadlineSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
  minHeight: "285px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));
