export const constructPaymentData = (values, cartTotal, stripeCouponObj, discount, originalSubtotal, cartItems, customerId, paymentId) => {
  const {
    email,
    shippingAddress,
    shippingFirstName,
    shippingLastName,
    shippingCity,
    shippingCountry,
    shippingZipCode,
    shippingState,
    shippingPhone,
    billingAddress,
    billingFirstName,
    billingLastName,
    billingCity,
    billingCountry,
    billingZipCode,
    billingState,
    billingPhone,
  } = values;

  const cartItemsData = cartItems.map((item) => {
    const { default_price, id, name, price, quantity, images, metadata } = item;
    return {
      default_price,
      id,
      name,
      price,
      quantity,
      image: images[0],
      type: metadata.type,
    };
  });

  const finalBillingAddress = billingAddress || shippingAddress;
  const finalBillingFirstName = billingFirstName || shippingFirstName;
  const finalBillingLastName = billingLastName || shippingLastName;
  const finalBillingCity = billingCity || shippingCity;
  const finalBillingCountry = billingCountry || shippingCountry;
  const finalBillingZipCode = billingZipCode || shippingZipCode;
  const finalBillingState = billingState || shippingState;
  const finalBillingPhone = billingPhone || shippingPhone;

  return {
    amount: cartTotal,
    email: email,
    firstName: shippingFirstName,
    lastName: shippingLastName,
    shippingAddress: JSON.stringify({
      addressLine: shippingAddress,
      firstName: shippingFirstName,
      lastName: shippingLastName,
      city: shippingCity,
      country: shippingCountry,
      postalCode: shippingZipCode,
      state: shippingState,
      phone: shippingPhone,
    }),
    billingAddress: JSON.stringify({
      addressLine: finalBillingAddress,
      firstName: finalBillingFirstName,
      lastName: finalBillingLastName,
      city: finalBillingCity,
      country: finalBillingCountry,
      postalCode: finalBillingZipCode,
      state: finalBillingState,
      phone: finalBillingPhone,
    }),
    couponId: stripeCouponObj ? stripeCouponObj.id : null,
    couponAmount: discount,
    subtotal: originalSubtotal,
    cartItems: JSON.stringify(cartItemsData),
    customerId: customerId,
    paymentId: paymentId,
  };
};

export const constructBillingDetails = (values) => {
  return {
    name: `${values.billingFirstName} ${values.billingLastName}`,
    email: values.email,
    phone: values.billingPhone,
    address: {
      city: values.billingCity,
      country: values.billingCountry,
      line1: values.billingAddress,
      postal_code: values.billingZipCode,
      state: values.billingState,
    },
  };
};
