import theme from "assets/theme";

export const BenefitsOfKetamineTherapyData = [
  {
    title: (
      <div>
        How Does Ketamine Assist in
        <span
          style={{
            color: theme.palette.accent.main,
            fontSize: "inherit",
            fontWeight: "inherit",
          }}
        >
          {" "}
          Mental Health
        </span>
      </div>
    ),
    cardContent: [
      {
        subtitle: (
          <div>
            <span
              style={{
                // color: theme.palette.accent.main,
                fontSize: "inherit",
                fontWeight: "inherit",
              }}
            >
              Unearthing Unconscious Experiences
            </span>
          </div>
        ),
        text: "Ketamine's dissociative properties bring buried emotions and memories to the surface, allowing therapists and clients to work directly with the root causes of mental health challenges. This accelerated approach fosters new perspectives and insights, laying a solid foundation for further therapy.",
      },
      {
        subtitle: (
          <div>
            <span
              style={{
                // color: theme.palette.accent.main,
                fontSize: "inherit",
                fontWeight: "inherit",
              }}
            >
              Fostering Positive Mood for Exploration
            </span>
          </div>
        ),
        text: "Ketamine-induced emotional openness enhances clients' resilience, enabling them to confront emotionally charged material effectively. This positivity creates a conducive environment for exploring challenging experiences, often shortening the time required for healing.",
      },
      {
        subtitle: (
          <div>
            <span
              style={{
                // color: theme.palette.accent.main,
                fontSize: "inherit",
                fontWeight: "inherit",
              }}
            >
              Facilitating Sustainable Lifestyle Changes
            </span>
          </div>
        ),
        text: "The post-ketamine period offers heightened neuroplasticity, aiding the adoption of new, healthier habits. This enhanced mental flexibility facilitates significant lifestyle changes, promoting long-term healing beyond therapy sessions.",
      },
    ],
  },
  {
    title: (
      <div>
        The Mutual Benefits of
        <span
          style={{
            color: theme.palette.accent.main,
            fontSize: "inherit",
            fontWeight: "inherit",
          }}
        >
          {" "}
          Therapy & Ketamine
        </span>
      </div>
    ),
    cardContent: [
      {
        subtitle: (
          <div>
            <span
              style={{
                // color: theme.palette.accent.main,
                fontSize: "inherit",
                fontWeight: "inherit",
              }}
            >
              Navigating Complex Emotions
            </span>
          </div>
        ),
        text: "Therapists help clients comprehend the emotions surfaced during ketamine sessions, providing guidance and interpretation. Together, they explore the meaning behind these experiences, aiding clients' self-discovery and healing.",
      },
      {
        subtitle: (
          <div>
            <span
              style={{
                // color: theme.palette.accent.main,
                fontSize: "inherit",
                fontWeight: "inherit",
              }}
            >
              Decoding Ketamine Experiences
            </span>
          </div>
        ),
        text: "Ketamine's profound experiences often demand interpretation. Therapists offer questions, encouragement, and support, assisting clients in unraveling the complexities of their journeys, transforming confusing moments into valuable insights.",
      },
      {
        subtitle: (
          <div>
            <span
              style={{
                // color: theme.palette.accent.main,
                fontSize: "inherit",
                fontWeight: "inherit",
              }}
            >
              Providing Support & Encouragement
            </span>
          </div>
        ),
        text: "Embarking on transformative journeys requires ongoing support. Therapists serve as pillars of encouragement, offering motivation, accountability, and a structured approach to navigate challenges and integrate profound experiences into daily life.",
      },
    ],
  },
];
