export const parseIncludes = (metadata) => {
  if (!metadata) return [];

  return Object.keys(metadata)
    .filter((key) => key.startsWith("includes_"))
    .flatMap((key) => {
      try {
        return JSON.parse(`[${metadata[key]}]`.replace(/,\s*]$/, "]"));
      } catch (error) {
        console.error(`Error parsing ${key}:`, error);
        return [];
      }
    });
};
