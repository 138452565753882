import React from "react";
import { Container, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import useFetchStripeTransaction from "./hook/useFetchStripeTransaction";
import OrderDetails from "./components/orderDetails";
import PaymentSummary from "./components/paymentSummary";
import NextSteps from "./components/nextSteps";
import { unixToDateConversion } from "utils/helpers/unixToDateConversion";
import { StyledMainPageContainer, StyledSectionBackgroundWrapper } from "index.styles";
import CBLoading from "components/CBLoading";
import useCombinedPaymentIntent from "./hook/useCombineMultiplePaymentIntent";

const OrderConfirmation = () => {
  const location = useLocation();
  const { subscriptionPaymentIntentId, addonPaymentIntentId } = location.state;

  const { data: subscriptionPaymentIntent, error: subscriptionError } = useFetchStripeTransaction(subscriptionPaymentIntentId);
  const { data: addonPaymentIntent, error: addonError } = useFetchStripeTransaction(addonPaymentIntentId);

  const { loading, error, paymentIntent } = useCombinedPaymentIntent(subscriptionPaymentIntent, addonPaymentIntent, subscriptionError, addonError);

  if (loading) {
    return (
      <Container sx={{ marginBlock: "4rem" }}>
        <CBLoading loadingItem={"order confirmation"} />
      </Container>
    );
  }

  if (error) {
    return <Container sx={{ marginBlock: "4rem" }}>{error}</Container>;
  }

  return (
    <StyledMainPageContainer id="order-confirmation-container">
      <StyledSectionBackgroundWrapper>
        <Grid container spacing={0} sx={{ maxWidth: "1320px", margin: "auto" }}>
          <OrderDetails
            firstName={paymentIntent?.metadata.firstName}
            lastName={paymentIntent?.metadata.lastName}
            billingAddress={paymentIntent?.metadata.billingAddress}
            shippingAddress={paymentIntent?.metadata.shippingAddress}
            phone={paymentIntent?.metadata.phone}
            email={paymentIntent?.metadata.email}
            orderId={paymentIntent?.id}
          />
          <PaymentSummary
            discount={paymentIntent?.metadata.couponAmount}
            originalSubtotal={paymentIntent?.metadata.subtotal}
            cartTotal={paymentIntent?.amount}
            createdAt={unixToDateConversion(paymentIntent?.createdAt || paymentIntent.created)}
            paymentId={paymentIntent?.id}
            paymentMethod={paymentIntent?.payment_method_types}
            cartItems={paymentIntent?.metadata.combinedCartItems || paymentIntent?.metadata.cartItems}
          />
        </Grid>
      </StyledSectionBackgroundWrapper>
      <NextSteps shippingAddress={paymentIntent?.metadata.billingAddress} />
    </StyledMainPageContainer>
  );
};

export default OrderConfirmation;
