import React from "react";
import { Link } from "react-router-dom";
// Theme
import theme from "assets/theme";
// Components
import MKTypography from "components/MKTypography";
import { Grid } from "@mui/material";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import CBDivider from "components/CBDivider";
// Styled Components
import { StyledDividerContainer, StyledImage } from "../../../../index.styles";
import { StyledGridImageItem, StyledGridTextItem, StyledWhyUsSectionContainer } from "./index.styles";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";

const WhyUs = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledWhyUsSectionContainer component="section" id="why-us-section">
      <Grid container columnSpacing={isLg ? 6 : 3} rowSpacing={isLg ? 0 : 6}>
        <StyledGridImageItem item xs={12} lg={6} order={isLg ? 1 : 0}>
          <StyledImage
            height="398"
            width="522"
            src={"https://d3a0rs3velfrz.cloudfront.net/whyChooseUs/whyChooseUsImage1.webp"}
            alt="Lady smiling and waving to her friends on a virtual call"
          />
        </StyledGridImageItem>
        <StyledGridTextItem item xs={12} lg={6} order={isLg ? 0 : 1}>
          <MKTypography gutterBottom component={"h1"} variant={"h1"}>
            Why
            <span style={{ color: theme.palette.accent.main, fontSize: "inherit", fontWeight: "inherit" }}> Choose Us </span>
            for Your
            <br />
            <span style={{ color: theme.palette.accent.main, fontSize: "inherit", fontWeight: "inherit" }}> Mental Health Journey </span>
          </MKTypography>
          <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
            Almadelic pioneers mental health transformation through the use of safe, legal psychedelic therapies. Tailored to your uniqueness, our
            approach recognizes that traditional treatments may not always suffice. Experience breakthroughs beyond conventional limits with a team
            committed to your well-being. Choose Almadelic for a transformative path to mental health.
          </MKTypography>

          <MKBox marginTop={2}>
            <MKButton
              component={Link}
              to={"https://shop.almadelic.com/"}
              variant="contained"
              color="secondary"
              size="large"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Get Started Today"
            >
              Get Started Today!
            </MKButton>
          </MKBox>
        </StyledGridTextItem>
        <Grid item xs={12} order={2}>
          <StyledDividerContainer>
            <CBDivider />
          </StyledDividerContainer>
        </Grid>
        <StyledGridImageItem item xs={12} lg={5} order={3}>
          <StyledImage
            height="327"
            width="427"
            src={"https://d3a0rs3velfrz.cloudfront.net/whyChooseUs/whyChooseUsImage2.webp"}
            alt="Lady lifting here hands up to her forehead to form a mediation pose.
          "
          />
        </StyledGridImageItem>
        <StyledGridTextItem item xs={12} lg={7} order={4}>
          <MKTypography gutterBottom component={"h2"} variant={"h2"}>
            Our
            <span style={{ color: theme.palette.accent.main, fontSize: "inherit", fontWeight: "inherit" }}> Approach</span>
          </MKTypography>
          <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
            Almadelic takes a holistic and client-centered approach to mental health. We believe in the potential of psychedelic therapy to unlock new
            perspectives, heal old wounds, and ignite personal growth. Our team of experienced therapists and medical professionals are dedicated to
            creating a safe, supportive, and judgment-free environment for your journey.
          </MKTypography>
        </StyledGridTextItem>
      </Grid>
    </StyledWhyUsSectionContainer>
  );
};

export default WhyUs;
