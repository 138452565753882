import { Grid, Box } from "@mui/material";
import { Formik, Form } from "formik";
import MKTypography from "components/MKTypography";
import { StyledMainPageContainer } from "index.styles";
import { StyledCheckoutContainer } from "./index.styles";
import ContactInformation from "./components/contactInformation";
import BillingAddress from "./components/billingAddress";
import PaymentOptions from "./components/paymentOptions";
import CartSidebar from "../Cart/components/cart-sidebar";
import React, { useContext } from "react";
import { CartContext } from "utils/context/cart.context";
import { CheckoutContext } from "utils/context/checkout.context";
import useStripeTransaction from "pages/Checkout/hooks/useStripeTransaction";
import theme from "assets/theme";
import MKButton from "components/MKButton";
import useGoogleAddressValidation from "./hooks/useGoogleAddressValidation";
import { useNavigate } from "react-router-dom";
import MKBox from "components/MKBox";
import usePurchaseMade from "./hooks/usePurchaseMade";
import { constructPurchaseMadeData } from "utils/helpers/constructPurchaseMadeData";
import { initialValues, validationSchema, validationSchema2 } from "./formSchema";
import useCreateStripeAccount from "./hooks/useCreateStripeAccount";
import CBDivider from "components/CBDivider";
import { StyledDividerContainer } from "index.styles";

const Checkout = () => {
  const navigate = useNavigate();
  const { validateAddress } = useGoogleAddressValidation();
  const { processPayment, error: stripeError } = useStripeTransaction();
  const { purchaseMade, automationError } = usePurchaseMade();
  const { stripeAccountLoading, stripeAccountError, stripeCustomerId, createStripeAccount } = useCreateStripeAccount();

  const { cartItems, cartTotal, stripeCouponObj, discount, originalSubtotal } = useContext(CartContext);
  const { formData, updateFormData, showBillingAddressFormFields } = useContext(CheckoutContext);

  const handleSubmit = async (values) => {
    try {
      //* Google Address Validation
      const { isValid, error } = await validateAddress(values);
      if (!isValid) {
        alert(error);
        return; // stop form submission if address is not valid
      }
      updateFormData(values, showBillingAddressFormFields);
      //* Create stripe account
      const purchaseMadeDetails = constructPurchaseMadeData(values, cartItems, cartTotal, originalSubtotal);
      const stripeAccountResponse = await createStripeAccount(purchaseMadeDetails);

      //! Add error handling here
      //* Stripe paymentIntent
      const paymentResponse = await processPayment(values, cartTotal, stripeCouponObj, discount, originalSubtotal, cartItems, stripeAccountResponse);

      const { paymentResults, subscription } = paymentResponse;
      if (!paymentResults && !subscription) {
        alert(stripeError);
        return; // stop form submission if payment fails
      }
      // else if (paymentResults || subscription) {
      //   console.log("payment results - line 56", paymentResults);
      //   console.log("subscription - line 57", subscription);
      // }

      //* Transaction Automation
      const purchaseMadeAutomationDetails = constructPurchaseMadeData(values, cartItems, cartTotal, originalSubtotal, paymentResults, subscription);
      const automationResponse = await purchaseMade(purchaseMadeAutomationDetails);
      if (!automationResponse) {
        console.error("Automation Error: ", automationError);
        alert("An error occurred while notifying the server about the purchase.");
      } else {
        alert("Payment successful");
        navigate("/order-confirmation", {
          state: {
            subscriptionPaymentIntentId: subscription?.latest_invoice?.payment_intent?.id,
            addonPaymentIntentId: paymentResults?.id,
          },
        });
      }
    } catch (error) {
      console.log(error);
      alert("Error: " + error.message);
    }
  };

  const handleNavigate = () => {
    navigate("/cart");
  };

  return (
    <StyledMainPageContainer id="checkout-page">
      <Box
        sx={{
          "& .css-p1mc1-MuiInputBase-input-MuiOutlinedInput-input": { color: "black !important" },
          "& .css-1t1jbqy-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": { color: "black !important" },
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={showBillingAddressFormFields ? validationSchema2 : validationSchema}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
        >
          {({ isSubmitting, errors, touched, validateForm }) => (
            <Form>
              <StyledCheckoutContainer>
                <MKTypography component="h1" variant="h1" sx={{ mb: 2 }}>
                  Checkout
                </MKTypography>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column-reverse", md: "row" },
                    justifyContent: "center",
                  }}
                >
                  <Grid item xs={12} lg={8}>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        border: `1px solid ${theme.palette.grey[400]}`,
                        padding: "1rem",
                      }}
                    >
                      <Grid item>
                        <ContactInformation />
                      </Grid>
                      <Grid item>
                        <BillingAddress />
                      </Grid>
                      <Grid item>
                        <PaymentOptions />
                      </Grid>
                      <Grid item>
                        <MKBox
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            border: "1p solid red",
                            marginTop: "2rem",
                            maxWidth: "717px",
                            // paddingLeft: "32px",
                          }}
                        >
                          <MKButton onClick={handleNavigate} variant="contained" color="secondary">
                            Back To Cart
                          </MKButton>
                          <MKButton disabled={isSubmitting} type="submit" variant="contained" color="secondary">
                            {isSubmitting ? "Processing..." : "Place Order"}

                            {/* Place Order */}
                          </MKButton>
                        </MKBox>
                      </Grid>
                    </Grid>
                  </Grid>
                  <CartSidebar cartTotal={cartTotal} cartItems={cartItems} />
                </Grid>
              </StyledCheckoutContainer>
            </Form>
          )}
        </Formik>
      </Box>
    </StyledMainPageContainer>
  );
};

export default Checkout;
