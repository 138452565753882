import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";

export const StyledPageHeaderSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));

export const StyledPageHeaderTextGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  // justifyContent: "space-around",
}));
