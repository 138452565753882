import React, { useState, useEffect } from 'react';
import 'react-image-crop/dist/ReactCrop.css'

const getCroppedImg = (image, crop) => {
    if (!image || !crop.width || !crop.height) {
      console.error("Invalid image or crop parameters");
      return;
    }
    const canvas = document.createElement('canvas');
    
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
  
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    
    const croppedUrl = canvas.toDataURL('image/jpeg');

    const base64ToBlob = (base64) => {
      const byteString = atob(base64.split(',')[1]);
      const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    };

    const blob = base64ToBlob(croppedUrl);
    return {croppedUrl, blob};
  };

export default getCroppedImg;