import React from "react";
// Components
import AboutKetamine from "./components/AboutKetamine/";
import HowItWorks from "./components/HowItWorks";
import ClinicalResearch from "./components/ClinicalResearch";
import Benefits from "./components/Benefits";
import CBDivider from "components/CBDivider";
import SchemaComponent from "components/CBSchema";
import SEO from "components/CBSEO";
// Styled components
import { StyledMainPageContainer } from "index.styles";
// Utils
import { constructSchemaData } from "utils/helpers/constructSchemaData";
// Data
import scienceAndResearchSchemaData from "./schema.organization.json";
import scienceAndResearchArticleSchemaData from "./schema.article.json";
import seoData from "./seo.data.json";

const ScienceAndResearch = () => {
  const schemaData = constructSchemaData(
    scienceAndResearchSchemaData.url,
    scienceAndResearchSchemaData.breadcrumbs,
    scienceAndResearchArticleSchemaData
  );
  return (
    <StyledMainPageContainer id="science-and-research-container">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <AboutKetamine />
      <Benefits />
      <HowItWorks />
      <CBDivider section={"how-ketamine-and-clinical-research"} />
      <ClinicalResearch />
    </StyledMainPageContainer>
  );
};

export default ScienceAndResearch;
