import React, { useContext } from "react";
import { Link } from "react-router-dom";

// Icons
import { LogoutOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
// Utils
import { signOutUser } from "utils/firebase/authentication.firebase";
// Context
import { AuthContext } from "utils/context/authentication.context";

const LoginAndLogOut = ({ setMobileNavToggle, mobileNavToggle }) => {
  const { isAuthorized } = useContext(AuthContext);

  const handleUserSignOut = () => {
    signOutUser();
  };

  return (
    <Link to={"/therapist-auth/sign-in"} style={{ display: "flex", marginInline: { md: ".5rem" } }}>
      {isAuthorized ? (
        <IconButton aria-label="Therapist sign-in" onClick={handleUserSignOut}>
          <LogoutOutlined sx={{ color: "#F77C3A", height: "26px", width: "26px" }} />
        </IconButton>
      ) : (
        <IconButton aria-label="Therapist sign-out" onClick={setMobileNavToggle ? () => setMobileNavToggle(!mobileNavToggle) : undefined}>
          <PermIdentityOutlinedIcon sx={{ color: "#F77C3A", height: "26px", width: "26px" }} />
        </IconButton>
      )}
    </Link>
  );
};

export default LoginAndLogOut;
