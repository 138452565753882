import styled from "@emotion/styled";

export const StyledLogo = styled("img")(({ theme, footerLogo }) => ({
  width: footerLogo ? "70px" : "120px",
  maxHeight: footerLogo ? "70px" : "60px",
  transform: "scale(1)",
  [theme.breakpoints.up("sm")]: {
    transform: footerLogo ? "scale(1)" : "scale(1.3)",
  },
  [theme.breakpoints.up("md")]: {
    transform: footerLogo ? "scale(1)" : "scale(1.3)",
  },
  [theme.breakpoints.up("lg")]: {
    transform: footerLogo ? "scale(1)" : "scale(1.5)",
  },
}));
