import { Box } from "@mui/material";
import React from "react";
import ServicesText from "./components/servicesText";
import ServicesCards from "./components/servicesCards";
import { StyledOurServicesSectionContainer } from "./index.styles";
const OurServices = () => {
  return (
    <StyledOurServicesSectionContainer component={"section"} id="our-services-section">
      <Box style={{ minHeight: "678px", display: "flex", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
        <ServicesText />
        <ServicesCards />
      </Box>
    </StyledOurServicesSectionContainer>
  );
};

export default OurServices;
