import React from "react";
import PropTypes from "prop-types";
import { StyledYTVideoContainer } from "./index.styles";


const TAEmbeddedYT = ({youtubeURL}) => {
    const embedId = youtubeURL.split("v=")[1];
    return(
        <StyledYTVideoContainer id="vyoutube-container">
            <iframe
                src={`https://www.youtube.com/embed/${embedId}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </StyledYTVideoContainer>
    )
};

TAEmbeddedYT.propTypes = {
    embedId: PropTypes.string.isRequired
};

export default TAEmbeddedYT;