import React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
// Components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
// Styled Components
import { useTheme } from "@mui/material/styles";
// Hooks
import useGhlContactUsWebhook from "./hook/useGhlContactUsWebhook";

const inquiryType = ["New Patient", "Existing Patient", "Partner Therapist", "Career", "Other"];

const validationSchema = yup.object({
  fullName: yup
    .string()
    .trim()
    .min(2, "Please enter a valid full name")
    .max(50, "Please enter a valid full name")
    .required("Please specify your full name"),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .matches(
      /^[^\s@]+@[^\s@]+\.(com|org|net|edu|gov|mil|info|biz|co|me|io|tech|online|us|uk|ca|au|ac|health|med|doctor|academy|institute|college|law|consulting|clinic|usp\.br)$/,
      "Email must end with valid domain suffixes."
    )
    .required("Email is required"),
  message: yup.string().trim().required("Please specify your message"),
  inquiryType: yup.string().required("Please select an inquiry type"),
});

const Form = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const { loading, error, status, sendToWebhook } = useGhlContactUsWebhook();
  const initialValues = {
    fullName: "",
    message: "",
    inquiryType: "",
    email: "",
  };

  const onSubmit = (values, { resetForm }) => {
    sendToWebhook(values);
    resetForm();
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Grid>
      <Grid
        margin={"0 auto"}
        component={"form"}
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{
          "& .MuiOutlinedInput-root.MuiInputBase-multiline": {
            padding: 0,
          },
          "& .MuiOutlinedInput-input": {
            color: "black !important",
            background: theme.palette.background.paper,
            padding: 2,
          },
        }}
      >
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12}>
            <Typography for="fullName" component={"Label"} variant="subtitle1" color="text.primary" fontWeight={700} gutterBottom>
              Full name
            </Typography>
            <TextField
              id="fullName"
              name="fullName"
              disabled={loading || status === "success"}
              placeholder="Your full name"
              variant="outlined"
              size="medium"
              fullWidth
              type="text"
              value={formik.values.fullName}
              onChange={formik.handleChange}
              error={formik.touched.fullName && Boolean(formik.errors.fullName)}
              helperText={formik.touched.fullName && formik.errors.fullName}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography for="email" component={"Label"} variant="subtitle1" color="text.primary" fontWeight={700} gutterBottom>
              E-mail
            </Typography>
            <TextField
              id="email"
              disabled={loading || status === "success"}
              placeholder="Your e-mail address"
              variant="outlined"
              size="medium"
              name="email"
              fullWidth
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography for="inquiryType" component={"Label"} variant="subtitle1" color="text.primary" fontWeight={700} gutterBottom>
              Inquiry Type
            </Typography>
            <FormControl fullWidth error={Boolean(formik.touched.inquiryType) && Boolean(formik.errors.inquiryType)}>
              <Select
                id="inquiryType"
                variant="outlined"
                name="inquiryType"
                value={formik.values.inquiryType}
                onChange={formik.handleChange}
                displayEmpty
                sx={{ height: "52px" }}
              >
                <MenuItem value="" disabled sx={{}}>
                  <MKTypography component="p" sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.55)" }}>
                    Select your inquiry type
                  </MKTypography>
                </MenuItem>
                {inquiryType.map((type, index) => (
                  <MenuItem key={index} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.inquiryType && <FormHelperText>{formik.errors.inquiryType}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography for="message" component={"Label"} variant="subtitle1" color="text.primary" fontWeight={700} gutterBottom>
              Message
            </Typography>
            <TextField
              id="message"
              disabled={loading || status === "success"}
              placeholder="Your question about our services"
              variant="outlined"
              name="message"
              fullWidth
              multiline
              rows={4}
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />
          </Grid>
          <Grid item container justifyContent="center" xs={12}>
            <MKButton
              aria-label={loading ? "Sending..." : error ? "Error..." : status === "success" ? "Success!" : "Send your question"}
              disabled={loading || status === "success"}
              variant="contained"
              type="submit"
              color="secondary"
              size="large"
            >
              {loading ? "Sending..." : error ? "Error..." : status === "success" ? "Success!" : "Send your question"}
            </MKButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Form;
