// Components
import WhyUs from "./components/whyChooseUsForYourMentalHealthJourney";
import WhatSetsUsApart from "./components/whatSetsUsApart";
import YourJourneyStarts from "./components/yourJourneyStarts";
import SchemaComponent from "components/CBSchema";
import SEO from "components/CBSEO";
// Styled Components
import { StyledMainPageContainer } from "index.styles";
// Utils
import { constructSchemaData } from "utils/helpers/constructSchemaData";
// Data
import whyChooseUsSchemaData from "./schema.organization.json";
import seoData from "./seo.data.json";

const WhyChooseUs = () => {
  const schemaData = constructSchemaData(whyChooseUsSchemaData.url, whyChooseUsSchemaData.breadcrumbs);
  return (
    <StyledMainPageContainer id="why-choose-container">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <WhyUs />
      <WhatSetsUsApart />
      <YourJourneyStarts />
    </StyledMainPageContainer>
  );
};

export default WhyChooseUs;
