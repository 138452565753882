import React from "react";
import { Link } from "react-router-dom";
// Icons
import { Close, Menu } from "@mui/icons-material";
// Styles
import theme from "assets/theme";
// Components
import LoginAndLogOut from "components/CBLoginLogOut";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import { Stack } from "@mui/material";
// Utils
import { ghlLinkFreeConsultation } from "utils/helpers/ghlFunnelLinks";
import useMediaQueries from "utils/mediaQueries.utils";

const NavBarActions = ({ setMobileNavToggle, mobileNavToggle }) => {
  const toggleMobileNav = () => setMobileNavToggle(!mobileNavToggle);
  const { isSm, isNavMenu } = useMediaQueries();

  return (
    <Stack direction={"row"} alignItems={"center"}>
      <MKButton
        component={Link}
        to={ghlLinkFreeConsultation}
        variant={"contained"}
        size="small"
        target="_blank"
        rel="noopener noreferrer"
        color="secondary"
        sx={{ height: "1rem", width: { xs: "115px", md: "100%" }, marginInline: { md: "1rem" } }}
        aria-label="Get Started Today"
      >
        Get Started
      </MKButton>
      {!isSm && <LoginAndLogOut />}
      {isNavMenu && (
        <MKBox
          component={MKButton}
          display={{ xs: "inline-block", padding: "10px", minWidth: "40px !important" }}
          lineHeight={0}
          size="small"
          color={"#000"}
          sx={{ cursor: "pointer" }}
          onClick={toggleMobileNav}
          aria-label={mobileNavToggle ? "Close mobile navigation" : "Open mobile navigation"}
        >
          {mobileNavToggle ? (
            <Close sx={{ color: theme.palette.secondary.main, height: "24px", width: "24px" }} />
          ) : (
            <Menu sx={{ color: theme.palette.secondary.main, height: "24px", width: "24px" }} />
          )}
        </MKBox>
      )}
    </Stack>
  );
};

export default NavBarActions;
