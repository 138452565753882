// Major
import React from "react";

// Componets
import CandidateSurveyForm from "./components/candidateSurveryForm";
import SchemaComponent from "components/CBSchema";
import SEO from "components/CBSEO";

// Utils
import { constructSchemaData } from "utils/helpers/constructSchemaData";

// Styles
import { StyledMainPageContainer } from "index.styles";

// Data
import candidateSchemaData from "./schema.organization.json";
import seoData from "./seo.data.json";

const Candidate = () => {
  const schemaData = constructSchemaData(candidateSchemaData.url, candidateSchemaData.breadcrumbs);
  return (
    <StyledMainPageContainer id="candidate-survey-page">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <CandidateSurveyForm />
    </StyledMainPageContainer>
  );
};

export default Candidate;
