import React from "react";

// Components
import MKTypography from "components/MKTypography";
import { Grid } from "@mui/material";
import WhatSetsUsApartCard from "../whatSetsUsApartCard";
import CBDivider from "components/CBDivider";
// Styled Components
import { StyledSectionBackgroundWrapper } from "index.styles";
import { StyledTextBox, StyledWhatSetsUsApartSectionContainer, StyledWhyChooseUsGridItem } from "./index.styles";
import { StyledCheckImage } from "index.styles";

import theme from "assets/theme";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Data
import { WhatSetsUsApartData } from "./whatSetsUsApart.data.jsx";

const WhatSetsUsApart = () => {
  const { isLg } = useMediaQueries();

  return (
    <StyledSectionBackgroundWrapper id="benefits-of-ketamine-therapy-wrapper">
      <StyledWhatSetsUsApartSectionContainer component="section" id="what-sets-us-apart-section">
        <Grid container>
          <StyledWhyChooseUsGridItem sx={{ marginBottom: { xs: "4rem", xl: "0" } }} item xs={12} xl={6}>
            <MKTypography component="h3" variant="h3" sx={{ color: `${theme.palette.white.main} !important`, marginBottom: "2rem" }}>
              What Sets Us
              <span style={{ color: theme.palette.accent.main, fontSize: "inherit", fontWeight: "inherit" }}> Apart?</span>
            </MKTypography>
            <Grid container rowSpacing={isLg ? 4 : 2}>
              {WhatSetsUsApartData.slice(0, 3).map(({ title, content }, index) => {
                return <WhatSetsUsApartCard key={index} title={title} content={content} />;
              })}
            </Grid>
          </StyledWhyChooseUsGridItem>
          <StyledWhyChooseUsGridItem item xs={12} xl={6}>
            <MKTypography component="h3" variant="h3" sx={{ color: `${theme.palette.white.main} !important`, marginBottom: "2rem" }}>
              How We
              <span style={{ color: theme.palette.accent.main, fontSize: "inherit", fontWeight: "inherit" }}> Support You</span>
            </MKTypography>
            <Grid container rowSpacing={isLg ? 4 : 2}>
              {WhatSetsUsApartData.slice(3, 6).map(({ title, content }, index) => {
                return <WhatSetsUsApartCard key={index} title={title} content={content} />;
              })}
            </Grid>
          </StyledWhyChooseUsGridItem>
          <StyledWhyChooseUsGridItem item xs={12} sx={{ paddingBlock: { xs: "4rem", lg: "6rem" } }}>
            <CBDivider />
          </StyledWhyChooseUsGridItem>
          <StyledWhyChooseUsGridItem item xs={12} sx={{ gap: "2rem" }}>
            <StyledCheckImage
              height="69"
              width="69"
              alt={"A Jagged Circle with a Check Mark Inside"}
              src={"https://d3a0rs3velfrz.cloudfront.net/check.svg"}
            ></StyledCheckImage>
            <StyledTextBox>
              <MKTypography component="h3" variant="h3" sx={{ color: `${theme.palette.white.main} !important` }} gutterBottom>
                <span style={{ color: theme.palette.accent.main, fontSize: "inherit", fontWeight: "inherit" }}>Comfort </span>
                And
                <span style={{ color: theme.palette.accent.main, fontSize: "inherit", fontWeight: "inherit" }}> Trust</span>
              </MKTypography>
              <MKTypography component="p" variant={isLg ? "body1" : "body2"} sx={{ color: `${theme.palette.white.main} !important` }} gutterBottom>
                Your trained guide will help you create a serene and inviting treatment environment. We foster a trusting and non-judgmental
                atmosphere where you can be yourself and explore your inner world without fear. You're not alone on this journey.
              </MKTypography>
              <MKTypography component="p" variant={isLg ? "body1" : "body2"} sx={{ color: `${theme.palette.white.main} !important` }}>
                Almadelic is more than a provider of psychedelic therapy; we're your partners on a transformative path to better health. We invite you
                to embark on this journey with us, knowing that you'll be guided, supported, and empowered every step of the way. Your healing and
                personal growth are our collective mission, and we're dedicated to helping you achieve a brighter, healthier future.
              </MKTypography>
            </StyledTextBox>
          </StyledWhyChooseUsGridItem>
        </Grid>
      </StyledWhatSetsUsApartSectionContainer>
    </StyledSectionBackgroundWrapper>
  );
};

export default WhatSetsUsApart;
