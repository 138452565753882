/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useContext } from "react";

import { Routes, Route, useLocation } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "assets/theme";
import Home from "pages/Home";

import routes from "routes";
import footerRoutes from "./footer.routes";

import NavbarTransparent from "components/CBNavigation";
import Term from "pages/Terms";
import PrivacyPolicy from "pages/PrivacyPolicy";

import TherapistBio from "pages/TherapistBio";
import EducationalCourses from "pages/EducationalCourses";
import MetaPixel from "components/CBMetaPixel";
import pixelConfig from "./components/CBMetaPixel/metaPixel.config.data.json";
import usePageTracking from "./utils/hooks/usePageTracking.hooks";
import AuthPage from "pages/Auth";
import Shop from "pages/Shop";
import ProductPage from "pages/ProductPage";
import Cart from "pages/Cart";
import Checkout from "pages/Checkout";

import { AuthContext } from "utils/context/authentication.context";
import { UserContext } from "utils/context/user.context";
import CustomizeYourPackage from "pages/customizeYourPackage";
import OrderConfirmation from "pages/orderConfirmation";

import Page404 from "pages/404";
import Footer from "./components/CBFooter";
import Contact from "pages/ContactPage/ContactPage";
import Candidate from "pages/Candidate";
import Careers from "pages/Careers";
import Blog from "pages/Blog";
import RefundPage from "pages/RefundPage";
import PartnerProspectSurveyPage from "pages/PartnerProspectSurvey";
import ReadBlogPage from "pages/ReadBlog";
import Temp from "pages/temp";
import InsuranceOptions from "pages/InsuranceOptions";
import InformedConsentPage from "pages/InformedConsent";
import TAMetaOGTags from "components/TAMetaOGTags";
import ConsumerHealthDataPrivacyPolicy from "pages/ConsumerHealthDataPrivacyPolicy";
import ResetPassword from "pages/ResetPassword";

export default function App() {
  const { pathname } = useLocation();
  const { isAuthorized } = useContext(AuthContext);
  const { currentUserProfile } = useContext(UserContext);

  // GA4 - page tracking
  usePageTracking();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes, isAuthorized, role) => {
    return allRoutes.flatMap((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse, isAuthorized, role);
      }

      if (route.route) {
        // Check for authorization and role
        if (route.protected && !isAuthorized) {
          return [];
        }
        if (route.requiredRole && role !== route.requiredRole) {
          return [];
        }
        return [<Route exact path={route.route} element={route.component} key={route.key} />];
      }

      return [];
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NavbarTransparent />
      <MetaPixel pixelId={pixelConfig.pixelId} events={pixelConfig.events} />
      <TAMetaOGTags pathname={pathname} />
      {/* <Suspense fallback="Loading"> */}
      <Routes>
        {getRoutes(routes, isAuthorized, currentUserProfile?.role)}
        <Route exact path="/" element={<Home />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/terms" element={<Term />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/candidate" element={<Candidate />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/therapist-bio/:therapistInfo/:therapistID" element={<TherapistBio />} />
        <Route path="/refund-policy" element={<RefundPage />} />
        <Route path="/partner-prospect-survey" element={<PartnerProspectSurveyPage />} />
        <Route path="/educational-courses" element={<EducationalCourses />} />
        <Route path="/read-blog" element={<ReadBlogPage />}></Route>
        <Route path="/temp" element={<Temp />}></Route>
        <Route path="/therapist-auth/sign-up" element={<AuthPage />}></Route>
        <Route path="/therapist-auth/sign-in" element={<AuthPage />}></Route>
        <Route path="/shop" element={<Shop />}></Route>
        <Route path="/product" element={<ProductPage />}></Route>
        <Route path="/cart" element={<Cart />}></Route>
        <Route path="/checkout" element={<Checkout />}></Route>
        <Route path="/insurance-options" element={<InsuranceOptions />}></Route>
        {/* <Route path="/thank-you" element={<ThankYou />}></Route> */}
        <Route path="/customize-your-package" element={<CustomizeYourPackage />}></Route>
        <Route path="/order-confirmation" element={<OrderConfirmation />}></Route>
        <Route path="/informed-consent" element={<InformedConsentPage />}></Route>
        <Route path="/consumer-health-data-privacy-policy" element={<ConsumerHealthDataPrivacyPolicy />}></Route>
        <Route path="/reset-password" element={<ResetPassword />}></Route>

        <Route path="*" element={<Page404 />}></Route>

        {/* <Route path="/checkout-example" element={<CheckoutForm />}></Route> */}
      </Routes>
      <Footer footerRoutes={footerRoutes} />
    </ThemeProvider>
  );
}
