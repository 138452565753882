import styled from "@emotion/styled";
import {Container, Stack } from "@mui/material";


export const StyledStackContainer = styled(Stack)(({ theme }) => ({
    width: "100%",
}));

export const StyledProfessionalInfoContainer = styled(Container)(({ theme }) => ({
    padding: "0rem !important",
    fontWeight: "bold",
    margin: "0",
}));

