import React from "react";
// Components
import MKTypography from "components/MKTypography";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Styles
import { StyledOurTeamHeadeOuterGrid, StyledOurTeamHeaderSectionContainer, StyledOurTeamHeaderSpanTitle } from "./index.style";

const Header = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledOurTeamHeaderSectionContainer component={"section"} id={"our-team-header-section"}>
      <StyledOurTeamHeadeOuterGrid>
        <MKTypography gutterBottom component={"h1"} variant={"h1"}>
          <StyledOurTeamHeaderSpanTitle>
            <span>Meet Our </span>
            Team
          </StyledOurTeamHeaderSpanTitle>
        </MKTypography>
        <MKTypography variant={isLg ? "body1" : "body2"}>
          Our network of highly skilled, licensed mental health therapists can support  you in your journey to mental wellbeing.
        </MKTypography>
      </StyledOurTeamHeadeOuterGrid>
    </StyledOurTeamHeaderSectionContainer>
  );
};

export default Header;
