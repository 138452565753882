import { styled } from "@mui/system";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
const { Box, Typography } = require("@mui/material");

export const StyledNavBox = styled(Box)(({ theme }) => ({
  position: "relative",
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  cursor: "pointer",
}));

export const StyledNavLink = styled(Typography)({
  textDecoration: "none",
});

export const StyledDropdownBox = styled(Box)(({ theme, isVisible }) => ({
  borderRadius: theme.borders.borderRadius.md,
  display: isVisible ? "block" : "none",
  position: "absolute",
  top: "150%",
  left: 0,
  backgroundColor: "white",
  color: "black",
  padding: theme.spacing(2),
  zIndex: 1,
  boxShadow: theme.shadows[2],
  width: "300px",
  "&::before": {
    content: '""',
    position: "absolute",
    top: "-15px",
    left: 0,
    borderWidth: "10px 10px 0 10px",
    borderStyle: "solid",
    borderColor: "#75CDC5 transparent transparent transparent",
    width: 0,
    height: 0,
  },
}));
