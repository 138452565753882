import React, { useState } from "react";

// Components
import MKTypography from 'components/MKTypography';
import CBLoading from "components/CBLoading";
import EditButton from "../EditButton";

// Utils
import { findCustomFieldValue } from "utils/helpers/findCustomFieldValue";
import EditForm from '../EditForm';
import usePutTherapistByID from "utils/hooks/usePutTherapistByID";

// Styles
import { StyledStackContainer, StyledInsuranceContainer} from "./index.styles";


const CostAndInsuranceEdit = ({userData, handleUpdateUserData}) => {
    const { email, phone, state, city, name, customFields } = userData || [];
    const hourlyRate = findCustomFieldValue("Hourly Rate", customFields);
    const insurance = findCustomFieldValue("Insurance", customFields);
    const { updateContactInfo, data, isLoading, isError, error, } = usePutTherapistByID();
    const [showForm, setShowForm] = useState(false);

    

    const handleSubmit = async (values) => {
        const response = await updateContactInfo(values, userData.id);
        if(response){
            handleUpdateUserData(response);
        }
    }
    
    const HourlyRate = () => {
        if(!hourlyRate) return null;
        return (
            <StyledStackContainer sx={{ margin: "0.5rem" }}>
                <MKTypography component="p" variant="body2" gutterBottom fontWeight="light">
                    <b>Hourly Rate: </b>${hourlyRate}
                </MKTypography>
            </StyledStackContainer>
        )
    }

    const Insurance = () => {
        return (
            <StyledStackContainer sx={{ margin: "0.5rem" }}>
                <MKTypography component="p" variant="body2" gutterBottom fontWeight="light">
                    <b>Insurance: </b>{insurance === "Yes" ? "Yes, we accept insurance" : "No, we do not accept insurance"}
                </MKTypography>
            </StyledStackContainer>
        )
    }


    const questions = [
        {
            name: "Hourly Rate",
            answer: hourlyRate || [],
            placeholder: "Hourly Rate",
            type: "number",
            variant: "outlined",
            rows: 1,
        },
        {
            name: "Insurance",
            answer: insurance || [],
            placeholder: "Insurance",
            type: "select",
            variant: "outlined",
            rows: 1,
            options: [
                { value: "Yes", label: "Yes" },
                { value: "No", label: "No" },
            ]
        }
    ]

    const PageBody = () => {
        return (
            <>
                <MKTypography component="h3" variant="h5" gutterBottom>
                    Service Costs & Insurance <EditButton showForm={showForm} setShowForm={setShowForm}/>
                </MKTypography>
                <HourlyRate />
                <Insurance />
                
                {showForm && <EditForm handleSubmit={handleSubmit} questions={questions} />}
            </>
        );
    }

    return(
        <StyledInsuranceContainer id='therapist-bio-cost-insurance-edit'>
            {isLoading ? <CBLoading/> : <PageBody />}
        </StyledInsuranceContainer>
    )
}

export default CostAndInsuranceEdit;