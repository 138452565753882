import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MKTypography from "components/MKTypography";
import { StyledContentSectionContainer } from "./index.styles";
import MKBox from "components/MKBox";
import { Link } from "react-router-dom";
import { List, ListItem, Stack } from "@mui/material";
import useMediaQueries from "utils/mediaQueries.utils";
import CBBulletPoint from "components/CBBulletPoint";
const FaqGroupItem = ({ items }) => {
  const theme = useTheme();
  const { isLg } = useMediaQueries();
  return (
    <>
      {items?.map((item, i) => (
        <MKBox key={i} sx={{ marginTop: i !== 0 && "2rem" }}>
          <MKTypography variant={"h2"} component={"h2"} mb="1rem" gutterBottom>
            {item.category}
          </MKTypography>
          {item.FAQs.map((item, index) => {
            const cta = item?.cta;
            return (
              <Box
                component={Accordion}
                key={index}
                padding={1}
                marginBottom={index === item.length - 1 ? 0 : 2}
                borderRadius={`${theme.spacing(1)} !important`}
                sx={{
                  "&::before": {
                    display: "none",
                  },
                }}
              >
                <Box
                  component={AccordionSummary}
                  expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.secondary.main }} />}
                  aria-controls="panel1a-content"
                  id={`panel1a-header--${i}`}
                >
                  <MKTypography variant={"h6"} component={"h3"} sx={{ fontWeight: 500, fontSize: "90%" }}>
                    {item.question}
                  </MKTypography>
                </Box>
                <AccordionDetails>
                  {Array.isArray(item.answer) ? (
                    item.answer.map((answer, index) => {
                      return (
                        <MKTypography
                          component={"p"}
                          variant={isLg ? "body1" : "body2"}
                          key={index}
                          gutterBottom
                          sx={{ marginBottom: index === item.answer.length - 1 ? "2rem" : 0 }}
                        >
                          {answer}
                        </MKTypography>
                      );
                    })
                  ) : (
                    <MKTypography variant={isLg ? "body1" : "body2"} component={"p"} sx={{ marginBottom: "2rem" }}>
                      {item.answer}
                    </MKTypography>
                  )}
                  {item.answerList &&
                    item.answerList.map(({ answerTitle, answerContent }, indexs) => {
                      return (
                        <MKBox sx={{ marginBottom: "2rem" }} key={answerTitle}>
                          <MKTypography component="h6" variant="h6" gutterBottom>
                            {answerTitle}
                          </MKTypography>
                          <List>
                            {answerContent &&
                              answerContent?.map((content, idx) => (
                                <Stack direction={"row"}>
                                  <MKBox sx={{ marginTop: ".5rem" }}>
                                    <CBBulletPoint />
                                  </MKBox>
                                  <ListItem key={idx}>
                                    <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
                                      {content}
                                    </MKTypography>
                                  </ListItem>
                                </Stack>
                              ))}
                          </List>
                        </MKBox>
                      );
                    })}
                    {item.answerGroup && 
                      item.answerGroup.map(({ answerTitle, answerContent }, indexs) => {
                        return (
                          <MKBox sx={{ marginBottom: "2rem" }} key={answerTitle}>
                            <MKTypography component="h6" variant="h6" gutterBottom>
                              {answerTitle}
                            </MKTypography>
                            <List>
                              {answerContent &&
                                answerContent?.map((content, idx) => (
                                  <Stack direction={"row"} sx={{marginTop: "1rem"}}>
                                    <MKBox sx={{ marginTop: ".5rem" }}>
                                      <CBBulletPoint />
                                    </MKBox>
                                    <ListItem key={idx}>
                                      <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
                                        {Array.isArray(content) ? content.map((content, index) => (
                                            <MKTypography key={index} component="p" variant={isLg ? "body1" : "body2"} gutterBottom sx={{margin: "0 !important", padding: "0 !important"}}>
                                              {content}
                                            </MKTypography>
                                          )) : content
                                        }
                                      </MKTypography>
                                    </ListItem>
                                  </Stack>
                                ))}
                            </List>
                          </MKBox>
                        );
                      })
                    }
                  {Array.isArray(item.answerFooter) ? (
                    item.answerFooter.map((content, indexsz) => {
                      return (
                        <MKTypography key={indexsz} component="p" variant={isLg ? "body1" : "body2"} gutterBottom>
                          {content}
                        </MKTypography>
                      );
                    })
                  ) : (
                    <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
                      {item.answerFooter}
                    </MKTypography>
                  )}

                  {Array.isArray(cta)
                    ? cta.map(({ text, link }, index) => {
                        return (
                          <Link to={link} key={index}>
                            {text}
                          </Link>
                        );
                      })
                    : cta}
                  {item.list &&
                    item.list.map((listItem, index) => {
                      return <p key={index}>- {listItem}</p>;
                    })}
                </AccordionDetails>
              </Box>
            );
          })}
        </MKBox>
      ))}
    </>
  );
};

FaqGroupItem.propTypes = {
  // title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

const Content = ({ filteredData }) => {
  return (
    <StyledContentSectionContainer component="section" id="faq-content-section">
      {filteredData?.length === 0 ? <MKTypography>No results found</MKTypography> : <FaqGroupItem items={filteredData} />}
    </StyledContentSectionContainer>
  );
};

export default Content;
