import styled from "@emotion/styled";
import { Container } from "@mui/material";

export const StyledOurTeamHeaderSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
  minHeight: "285px",
  maxHeight: "285px",
  display: "flex",
  alignItems: "center",
}));
export const StyledOurTeamHeadeOuterGrid = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
}));
export const StyledOurTeamHeaderSpanTitle = styled(Container)(({ theme }) => ({
  '& span': {
    color: theme.palette.accent.main,
    fontSize: "inherit",
    fontWeight: "inherit",
  },
}));
