import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
export const StyledWhyUsSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));
export const StyledGridTextItem = styled(Grid)(({ theme }) => ({
  minHeight: { lg: 500 },
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));
export const StyledGridImageItem = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
