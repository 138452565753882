import React from "react";
// Components
import PageHeader from "./components/pageHeader";
import InsuranceCoverage from "./components/insuranceCoverage";
import FaqContent from "./components/faqContent";
import SchemaComponent from "components/CBSchema";
import SEO from "components/CBSEO";
// Styled Components
import { StyledMainPageContainer } from "index.styles";
// Utils
import { constructSchemaData } from "utils/helpers/constructSchemaData";
// Data
import insuranceOptionsSchemaData from "./schema.organization.json";
import seoData from "./seo.data.json";

const InsuranceOptions = () => {
  const schemaData = constructSchemaData(insuranceOptionsSchemaData.url, insuranceOptionsSchemaData.breadcrumbs);
  return (
    <StyledMainPageContainer id="insurance-options-page">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />

      <PageHeader />
      <InsuranceCoverage />
      <FaqContent />
    </StyledMainPageContainer>
  );
};

export default InsuranceOptions;
