import MKTypography from "components/MKTypography";
import clinicalResearchData from "./clinicalResearch.data.json";
import { Grid, Link } from "@mui/material";
import { useState } from "react";
import MKButton from "components/MKButton";
import theme from "assets/theme";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { EllipsisHeader6Typography, EllipsisHeaderTypography, EllipsisBodyTextTypography, StyledResearchSectionContainer } from "./index.styles";
import MKBox from "components/MKBox";
import { StyledResearchArticleCards } from "index.styles";
function ClinicalResearch() {
  const [viewMore, setViewMore] = useState(false);

  const handleViewMore = () => {
    setViewMore(!viewMore);
  };

  // const displayedClinicalResearchData = viewMore ? clinicalResearchData.slice(0, 12) : clinicalResearchData.slice(0, 8);
  const displayedClinicalResearchDataMobile = viewMore ? clinicalResearchData.slice(0, 12) : clinicalResearchData.slice(0, 4);

  return (
    <StyledResearchSectionContainer component={"section"} id="clinical-research-section">
      <MKBox marginBottom={"2rem"}>
        <MKTypography gutterBottom variant="h2">
          Clinical <span style={{ color: theme.palette.accent.main }}>Research</span>
        </MKTypography>
      </MKBox>
      <Grid container spacing={4} justifyContent={"center"} alignContent={"center"} alignItems={"center"}>
        {displayedClinicalResearchDataMobile.map(({ title, author, summary, sourceReference }, i) => (
          <Grid key={i} item xs={12} sm={12} md={6} lg={3} sx={{ margin: "auto" }}>
            <StyledResearchArticleCards>
              <EllipsisHeaderTypography variant={"h6"} component="h3" sx={{ fontSize: "90% !important", marginBottom: ".5rem", lineHeight: "1.3" }}>
                {title}
              </EllipsisHeaderTypography>
              <EllipsisHeader6Typography variant={"h4"} fontWeight={"light"} fontSize={"75%"} sx={{ minHeight: "20px", marginBottom: ".8rem" }}>
                By {author}
              </EllipsisHeader6Typography>
              <EllipsisBodyTextTypography variant={"body2"}>{summary}</EllipsisBodyTextTypography>
              <MKButton
                component={Link}
                href={sourceReference}
                endIcon={<ArrowRightAltIcon sx={{ color: "inherit", scale: "1.5" }} />}
                variant="outlined"
                color={"accent"}
                size={"small"}
                sx={{ marginTop: "1rem", maxWidth: "150px" }}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Learn more"
              >
                Learn More
              </MKButton>
            </StyledResearchArticleCards>
          </Grid>
        ))}
        <Grid item xs={12} textAlign={"center"}>
          <MKButton
            aria-label={viewMore ? "View Less Articles" : "View More Articles"}
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleViewMore}
          >
            {viewMore ? "View Less Articles" : "View More Articles"}
          </MKButton>
        </Grid>
      </Grid>
    </StyledResearchSectionContainer>
  );
}

export default ClinicalResearch;
