import React from "react";
// Components
import MusicAndTherapyPageHeader from "./components/musicAndTherapyPageHeader";
import Music from "./components/music";
import MusicAndMentalHealth from "./components/musicAndMentalHealth";
import BenefitsOfMusicAndMentalHealth from "./components/benefits";
// Styled Components
import { StyledMainPageContainer } from "index.styles";
import SchemaComponent from "components/CBSchema";
import SEO from "components/CBSEO";
// Utils
import { constructSchemaData } from "utils/helpers/constructSchemaData";
// Data
import whyChooseUsSchemaData from "./schema.organization.json";
import whyChooseUsArticleSchemaData from "./schema.article.json";
import seoData from "./seo.data.json";

const MusicAndTherapy = () => {
  const schemaData = constructSchemaData(whyChooseUsSchemaData.url, whyChooseUsSchemaData.breadcrumbs, whyChooseUsArticleSchemaData);
  return (
    <StyledMainPageContainer id="music-and-therapy-page-container">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <MusicAndTherapyPageHeader />
      <Music />
      <MusicAndMentalHealth />
      <BenefitsOfMusicAndMentalHealth />
    </StyledMainPageContainer>
  );
};

export default MusicAndTherapy;
