import { Grid, TextField, Box, MenuItem, Select, FormControl, InputLabel, FormHelperText } from "@mui/material";
import MKButton from "components/MKButton";

import { Field, useFormikContext } from "formik";
import React, {useContext} from "react";
import MKTypography from "components/MKTypography";
import countryList from "react-select-country-list";
import { CheckoutContext } from "utils/context/checkout.context";
import StateOptions from "utils/helpers/stateOptions"




const BillingAddress = () => {

  const { errors, touched, handleChange } = useFormikContext();
  const countries = countryList().getData();
  const {showBillingAddressFormFields, setShowBillingAddressFormFields} = useContext(CheckoutContext);

  

  const handleToggle = () => {
    setShowBillingAddressFormFields((prev) => !prev);
  };

  return (
    <>
      <Box sx={{display: 'flex', justifyContent: "center", mb: "2rem"}}>
        <MKButton variant="contained" color="secondary" onClick={handleToggle}>
          {showBillingAddressFormFields ? 'billing and shipping address are the same' : 'Do you have a separate billing address'}
        </MKButton>
      </Box>
      {showBillingAddressFormFields && (
        <Grid container sx={{ display: "flex", flexDirection: "column", justifyContent: "center", mb: "2rem" }}>
          <Grid container item> 
            {/* <Grid item xs={0.5}>
              2
            </Grid> */}
            <Grid item xs={11.5}>
              <MKTypography component="h4" variant="h4" sx={{ display: "block" }}>
                2. Billing address
              </MKTypography>
              <MKTypography variant="body2" sx={{ mb: 2 }}>
                Enter the billing address that matches your payment method.
              </MKTypography>
              <Field
                as={TextField}
                label="First name"
                name="billingFirstName"
                type="text"
                error={touched.billingFirstName && !!errors.billingFirstName}
                helperText={touched.billingFirstName && errors.billingFirstName}
                variant="outlined"
                sx={{
                  width: { xs: '100%', md: '50%' },
                  paddingRight: { xs: 0, md: '8px' }
                }}
                margin="normal"
              />
              <Field
                as={TextField}
                label="Last name"
                name="billingLastName"
                type="text"
                error={touched.billingLastName && !!errors.billingLastName}
                helperText={touched.billingLastName && errors.billingLastName}
                variant="outlined"
                sx={{width: { xs: '100%', md: '50%' }}}
                margin="normal"
              />
              <Field
                as={TextField}
                label="Address"
                name="billingAddress"
                type="text"
                error={touched.billingAddress && !!errors.billingAddress}
                helperText={touched.billingAddress && errors.billingAddress}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <Field
                as={TextField}
                label="Appartment, suite, etc. (optional)"
                name="billingAppartment"
                type="text"
                error={touched.billingAppartment && !!errors.billingAppartment}
                helperText={touched.billingAppartment && errors.billingAppartment}
                variant="outlined"
                fullWidth
                optional="true"
                margin="normal"
              />
              <Field name="billingCountry">
                {({ field }) => (
                  <FormControl fullWidth variant="outlined" margin="normal" error={Boolean(touched.country && !!errors.country)}>
                    <InputLabel id="country-label">Country</InputLabel>
                    <Select labelId="country-label" label="Country" {...field} sx={{ height: "45px" }} onChange={handleChange(field.name)}>
                      {countries.map((country) => (
                        <MenuItem key={country.value} value={country.value}>
                          {country.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.billingCountry && !!errors.billingCountry ? <FormHelperText>{errors.billingCountry}</FormHelperText> : null}
                  </FormControl>
                )}
              </Field>
              <Field
                as={TextField}
                label="City"
                name="billingCity"
                type="text"
                error={touched.billingCity && !!errors.billingCity}
                helperText={touched.billingCity && errors.billingCity}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <Field name="billingState">
                {({ field }) => (
                  <FormControl variant="outlined" margin="normal"
                  error={touched.billingState && !!errors.billingState} 
                  sx={{
                    width: { xs: '100%', md: '50%' },
                    paddingRight: { xs: 0, md: '8px' }
                  }}
                  >
                    <InputLabel id="state-label">State</InputLabel>
                    <Select sx={{ height: "45px" }} labelId="state-label" label="State" {...field} onChange={field.onChange}>
                      {StateOptions.map((state) => (
                        <MenuItem key={state.name} value={state.name}>
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.billingState && !!errors.billingState ? <FormHelperText>{errors.billingState}</FormHelperText> : null}
                  </FormControl>
                )}
              </Field>
              <Field
                as={TextField}
                label="Zip Code"
                name="billingZipCode"
                type="text"
                error={touched.billingZipCode && !!errors.billingZipCode}
                helperText={touched.billingZipCode && errors.billingZipCode}
                variant="outlined"
                sx={{width: { xs: '100%', md: '50%' }}}
                margin="normal"
              />
              <Field
                as={TextField}
                label="Phone"
                name="billingPhone"
                type="text"
                error={touched.billingPhone && !!errors.billingPhone}
                helperText={touched.billingPhone && errors.billingPhone}
                variant="outlined"
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default BillingAddress;
