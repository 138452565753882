import { Box, Container } from "@mui/material";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { StyledPageFooterContainer } from "index.styles";
import React from "react";
import { Link } from "react-router-dom";

const PageFooter = ({ content, to, buttonText, internalLink = false }, ...otherProps) => {
  return (
    <StyledPageFooterContainer>
      <Container>
        <Box marginBottom={4}>
          <MKTypography
            variant="h4"
            component="h3"
            align={"center"}
            sx={{
              fontWeight: "medium",
              textTransform: "capitalize",
            }}
          >
            {content || "Didn't find what you are looking for?"}
          </MKTypography>
        </Box>
        <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} justifyContent={"center"} alignItems={{ xs: "stretched", sm: "center" }}>
          <MKButton
            component={Link}
            to={`${to}`}
            target={internalLink ? undefined : "_blank"}
            rel={internalLink ? undefined : "noopener noreferrer"}
            variant="contained"
            color="secondary"
            sx={{ fontSize: "0.875rem" }}
            aria-label={buttonText ? buttonText : "Contact our team today!"}
          >
            {buttonText ? buttonText : "Contact our team today!"}
          </MKButton>
        </Box>
      </Container>
    </StyledPageFooterContainer>
  );
};

export default PageFooter;
