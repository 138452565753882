import { Grid, List, Stack } from "@mui/material";
import React, { Fragment } from "react";
import { StyledProductCards, StyledShopProductContainer, StyledShopWrapper } from "./index.styles";
import welcomePackageData from "./welcomePackage.config.json";
import renewalPackageData from "./renewalPackage.config.json";

import MKTypography from "components/MKTypography";
import theme from "assets/theme";
import useMediaQueries from "utils/mediaQueries.utils";
import MKBox from "components/MKBox";
import { useNavigate } from "react-router-dom";

const ProductSection = ({ reverse, darkTheme, productId }) => {
  const data = productId === "prod_QhjbTCZqe6jIXZ" ? welcomePackageData : renewalPackageData;
  const { isLg } = useMediaQueries();
  const navigate = useNavigate();

  const handleNavigateTo = (e) => {
    navigate(`/product?productId=${e.currentTarget.id}`);
  };
  return (
    <StyledShopWrapper darkTheme={darkTheme}>
      <StyledShopProductContainer darkTheme={darkTheme}>
        <Grid container columnSpacing={4} alignItems={"center"}>
          <Grid item xs={12} lg={5} order={!isLg ? "0" : reverse ? 1 : 0}>
            {data.map(({ packages }, index) => (
              <Fragment key={index}>
                <MKTypography component="h2" variant="h2" gutterBottom sx={{ color: darkTheme && `${theme.palette.white.main} !important` }}>
                  {packages.title}
                </MKTypography>
                <MKTypography component="h6" variant="h6" sx={{ color: `${theme.palette.accent.main} !important` }} gutterBottom>
                  {packages.subtitle}
                </MKTypography>
                <MKBox sx={{ marginBlock: "1rem !important" }}>
                  {packages.descriptions.map((item, index) => (
                    <MKTypography
                      key={index}
                      component="p"
                      sx={{ fontSize: "16px", color: darkTheme && `${theme.palette.white.main} !important` }}
                      gutterBottom
                    >
                      {item}
                    </MKTypography>
                  ))}
                </MKBox>
              </Fragment>
            ))}
          </Grid>
          <Grid item xs={12} lg={7} order={!isLg ? "1" : reverse ? 0 : 1}>
            <StyledProductCards onClick={handleNavigateTo} id={productId}>
              {data.map(({ details }, index) => (
                <Fragment key={index}>
                  <MKTypography component="h4" variant={isLg ? "h4" : "h6"} gutterBottom>
                    {details.title}
                  </MKTypography>
                  <List sx={{ marginTop: "1rem" }}>
                    {details.items.map((detail, indexz) => (
                      <Stack direction={"row"} alignItems={"start"} spacing={2} sx={{ marginBottom: 1 }}>
                        <MKBox
                          component="img"
                          sx={{ width: isLg ? "24px" : "18px", marginTop: ".5rem !important" }}
                          src="https://shop.almadelic.com/wp-content/uploads/2024/03/wbsite-icon.png"
                          alt="Almadelic butterfly bullet point icon"
                        />
                        <li key={indexz}>
                          <MKTypography component="p" sx={{ fontSize: "16px" }}>
                            {detail}
                          </MKTypography>
                        </li>
                      </Stack>
                    ))}
                  </List>
                </Fragment>
              ))}
            </StyledProductCards>
          </Grid>
        </Grid>
      </StyledShopProductContainer>
    </StyledShopWrapper>
  );
};

export default ProductSection;
