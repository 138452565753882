import React from "react";
// Components
import { CardContent, Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
// Styled components
import { StyledWhyChooseUsCards } from "index.styles";
// Theme
import theme from "assets/theme";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";

const WhatSetsUsApartCard = ({ title, content }) => {
  const { isLg } = useMediaQueries();
  return (
    <Grid item xs={12} lg={12}>
      <StyledWhyChooseUsCards variant={"outlined"}>
        <CardContent sx={{ padding: "0 !important" }}>
          <MKTypography component="h4" variant="h6" gutterBottom sx={{ fontSize: "20px" }}>
            <span style={{ color: theme.palette.accent.main, fontSize: "inherit", fontWeight: "inherit" }}>{title}</span>
          </MKTypography>
          <MKTypography component="p" variant={isLg ? "body2" : "body2"} sx={{ color: `${theme.palette.primary.main} !important` }}>
            {content}
          </MKTypography>
        </CardContent>
      </StyledWhyChooseUsCards>
    </Grid>
  );
};

export default WhatSetsUsApartCard;
