// Major
import React from "react";

// Components
import PageHeader from "components/CBPageHeader";
import MultiStepSurvey from "./components/newSurvey";
import SchemaComponent from "components/CBSchema";
import SEO from "components/CBSEO";

// Styles
import { StyledMainPageContainer } from "index.styles";

// Utils
import { constructSchemaData } from "utils/helpers/constructSchemaData";

// Data
import partnerProspectSurveySchemaData from "./schema.organization.json";
import seoData from "./seo.data.json";

// import PartnerSurvey from "./components/partnerProspectSurvey";
// import Survey from "./components/newSurvey";

const PartnerProspectSurvey = () => {
  const schemaData = constructSchemaData(partnerProspectSurveySchemaData.url, partnerProspectSurveySchemaData.breadcrumbs);

  return (
    <StyledMainPageContainer id="partner-prospect-survey-page">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />

      <PageHeader
        section="partner-prospect-survey"
        header="Start Your Journey Today!"
        headerAccent="Join Almadelic’s Professional Partner Program"
        br={true}
        // headerTagline="We offer a complimentary listing for therapists to help patients connect with mental health professionals.  Submit your information to get started."
      />
      {/* <PartnerSurvey /> */}
      <MultiStepSurvey />
      
    </StyledMainPageContainer>
  );
};

export default PartnerProspectSurvey;
