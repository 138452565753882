/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Faq from "pages/Faq";
import OurProgram from "pages/OurProgram";
import WhatIsKetamine from "pages/WhatIsKetamine";
import WhyChooseUs from "pages/WhyChooseUs";
import ScienceAndResearch from "pages/ScienceAndResearch";
import PlanningAhead from "pages/PlanningAhead";
import SessionPreparation from "pages/SessionPreparation";
import CreatingYourSpace from "pages/CreatingYourSpace";
import MusicAndTherapy from "pages/MusicAndTherapy";
import TherapistProgram from "pages/TherapistProgram";
import FindATherapist from "pages/FindATherapist";
import OurTeam from "pages/OurTeam";
import Shop from "pages/Shop";
import Chaperone from "pages/Chaperone";
import TherapistBioEditable from "pages/TherapistBioEditable";
import Blog from "pages/Blog";

const routes = [
  {
    name: "Our Program",
    collapse: [
      {
        name: "Learn About Our Program",
        route: "/our-program",
        component: <OurProgram />,
        key: "our-program",
      },
      {
        name: "Why Choose Us",
        route: "/why-choose-us",
        component: <WhyChooseUs />,
        key: "why-choose-us",
      },
      {
        name: "Meet Our Team",
        route: "/our-team",
        component: <OurTeam />,
        key: "our-team",
      },
    ],
  },
  {
    name: "Our Packages",
    // route: "/shop",
    route: "https://shop.almadelic.com/",
    component: <Shop />,
    key: "our-packages",
  },
  {
    name: "Science & Research",
    collapse: [
      {
        name: "What Is Ketamine",
        route: "/what-is-ketamine",
        component: <WhatIsKetamine />,
        key: "what-is-ketamine",
      },
      {
        name: "The Science Behind Ketamine",
        route: "/science-and-research",
        component: <ScienceAndResearch />,
        key: "science-and-research",
      },
      //! uncomment when we have figma design
      // { name: "Psychedelic Therapies", route: "/psychedelic-therapies", component: <PsychedelicTherapies />, key: "psychedelic-therapies" },
    ],
  },

  {
    name: "For Patients",
    collapse: [
      {
        name: "Find A Therapist",
        route: "/find-a-therapist",
        component: <FindATherapist />,
        key: "find-a-therapist",
      },
      {
        name: "Planning Ahead",
        route: "/planning-ahead",
        component: <PlanningAhead />,
        key: "planning-ahead",
      },
      {
        name: "Session Preparations",
        route: "/session-preparations",
        component: <SessionPreparation />,
        key: "session-preparations",
      },
      {
        name: "Music And Therapy",
        route: "/music-and-therapy",
        component: <MusicAndTherapy />,
        key: "music-and-therapy",
      },
      {
        name: "Creating Your Space",
        route: "/creating-your-space",
        component: <CreatingYourSpace />,
        key: "creating-your-space",
      },
      {
        name: "The Role Of Chaperone",
        route: "/adult-chaperone",
        component: <Chaperone />,
        key: "adult-chaperone",
      },
    ],
  },
  {
    name: "For Therapists",
    collapse: [
      {
        name: "Our Therapist Referral Program",
        route: "/therapist-program",
        component: <TherapistProgram />,
        key: "therapist-program",
      },
      {
        name: "Your Therapist Profile",
        route: "/therapist-bio-editable",
        component: <TherapistBioEditable />,
        key: "therapist-program",
        protected: true,
        requiredRole: "partnered-therapist",
      },
    ],
  },
  {
    name: "FAQ's",
    collapse: [
      {
        name: "Search FAQ's",
        route: "/faq",
        component: <Faq />,
        key: "faq",
      },
    ],
  },
  {
    name: "Blog",
    route: "/blog",
    component: <Blog />,
    key: "blog",
  },
];

export default routes;
