import React from "react";
// Components
import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import RefundPolicyDetails from "./RefundPolicyDetails";
// Styled components
import { StyledRefundPolicySectionContainer } from "./index.style";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";

const RefundPolicy = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledRefundPolicySectionContainer id={"refund-appointment-cancellation-section"} component={"section"}>
      <Grid sx={{ display: "flex", flexDirection: "column" }}>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "50px",
          }}
        >
          <MKTypography gutterBottom component={"h2"} variant={"h2"}>
            What is Almadelic’s appointment cancellation policy?
          </MKTypography>
          <MKTypography variant={isLg ? "body1" : "body2"}>
            We understand that circumstances can change and we want to do our best to accommodate the needs of customers while also being respectful
            of our clinicians' time. If you are unable to attend your scheduled appointment, please contact us at least 24 hours in advance to avoid a
            $150 clinical appointment fee.
          </MKTypography>
        </Grid>
        <MKTypography gutterBottom component={"h2"} variant={"h2"}>
          What is Almadelic’s refund policy?
        </MKTypography>
        <RefundPolicyDetails />
      </Grid>
    </StyledRefundPolicySectionContainer>
  );
};

export default RefundPolicy;
