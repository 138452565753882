import { Grid } from "@mui/material";
import theme from "assets/theme";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { StyledImage } from "index.styles";
import { StyledSectionBackgroundWrapper } from "index.styles";
import React from "react";
import useMediaQueries from "utils/mediaQueries.utils";
import {
  StyledHeroImage,
  StyledHeroImageGrid,
  StyledHeroSectionContainer,
} from "./index.style";

export default function Hero() {
  const { isMd, isLg, isXl } = useMediaQueries();

  return (
    <StyledSectionBackgroundWrapper id={"educational-hero-section-wrapper"}>
      <StyledHeroSectionContainer
        component={"section"}
        id={"educational-hero-section"}
      >
        <Grid
          container
          columnSpacing={isLg ? 6 : 3}
          rowSpacing={isLg ? 0 : 6}
          alignItems={"center"}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={12} xl={7} order={isXl ? 0 : 1}>
            <MKBox>
              <MKTypography
                variant="h1"
                component="h1"
                gutterBottom
                sx={{
                  color: `${theme.palette.accent.main} !important`,
                }}
              >
                Psychedelic Therapy Educational Courses{" "}
                <span style={{ color: `${theme.palette.white.main}` }}>
                  by Dr. Erika Zelfand
                </span>
              </MKTypography>
              <MKTypography
                variant={isMd ? "body1" : "body2"}
                gutterBottom
                sx={{ color: theme.palette.white.main }}
              >
                Dr Erika Zelfand is licensed as a family medicine doctor – also
                known as a primary care provider (PCP) or a general practitioner
                (GP). Training in primary care entails knowing about a variety
                of different health conditions affecting different parts of the
                body and mind.
              </MKTypography>
              <br />

              <MKTypography
                variant={isMd ? "body1" : "body2"}
                gutterBottom
                sx={{ color: theme.palette.white.main }}
              >
                While the medical industry expects specialization, Dr. Erika
                Zelfand continues to stay multi-passionate and versatile,
                because she believes that the body is inter-connected; not
                compartmentalized. Dr. Zelfand’s healing philosophy seeks to
                treat the whole person.
              </MKTypography>
              <br />

              <MKTypography
                variant={isMd ? "body1" : "body2"}
                gutterBottom
                sx={{ color: theme.palette.white.main }}
              >
                As a graduate of one of the only few accredited naturopathic
                medical schools in the world, furthermore, Dr. Zelfand knows
                much more about nutrition, supplements, botanicals (herbs),
                functional medicine, orthomolecular medicine, lifestyle
                counseling, bodywork, and mental health than your average doc.
              </MKTypography>
              <br />

              <MKTypography
                variant={isMd ? "body1" : "body2"}
                gutterBottom
                sx={{ color: theme.palette.white.main }}
              >
                Dr. Zelfand is also an expert in psychedelic medicine. She
                teaches internationally on the topic and advocates for the
                normalization and legalization of entheogens. In fact, she is so
                committed to the cause of making medicines like psilocybin,
                MDMA, and ketamine accessible to others that she is the
                president of the nonprofit organization Right to Heal.
              </MKTypography>
            </MKBox>
          </Grid>
          <StyledHeroImageGrid item xs={12} xl={4} order={isXl ? 1 : 0}>
            <Grid order={isXl ? 0 : 1}>
              <StyledHeroImage
                component={"img"}
                src={
                  "https://d3a0rs3velfrz.cloudfront.net/educational-courses/educational-courses-1.png"
                }
                alt="Dr. Erika Zelfand headshot."
                width={isXl ? "340px" : "150px"}
              />

              <MKTypography
                sx={{
                  marginTop: isXl ? "25px" : "0px",
                  marginBottom: "15px",
                  textAlign: "center",
                }}
              >
                <span
                  style={{
                    color: `${theme.palette.white.main}`,
                  }}
                >
                  Dr. Erika Zelfand
                </span>
              </MKTypography>
            </Grid>
            <StyledHeroImage
              component={"img"}
              src={
                "https://d3a0rs3velfrz.cloudfront.net/educational-courses/educational-courses-2.png"
              }
              alt="The Right to Heal logo."
              width={isXl ? "472px" : "250px"}
              order={isXl ? 1 : 0}
            />
          </StyledHeroImageGrid>
        </Grid>
      </StyledHeroSectionContainer>
    </StyledSectionBackgroundWrapper>
  );
}
