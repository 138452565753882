import React, { useContext } from "react";
// Styled Components
import { StyledMainPageContainer } from "index.styles";
// Components
import PageHeader from "./components/pageHeader";
import ProfileCardContainer from "./components/profileCardContainer";
import AreYouATherapist from "./components/areYouATherapist";
import CBDivider from "components/CBDivider";
import SchemaComponent from "components/CBSchema";
import SEO from "components/CBSEO";
// Utils
import { SearchFor } from "utils/context/searchFilter.context";
import { GHLUsersContext } from "utils/context/ghlUsers.context";
import useSortedTherapists from "./hooks/useSortedTherapist.hook";
import { constructSchemaData } from "utils/helpers/constructSchemaData";
// Data
import findATherapistSchemaData from "./schema.organization.json";
import seoData from "./seo.data.json";

import CBLoading from "components/CBLoading";
import { useLocation } from "react-router-dom";


const FindATherapist = () => {
  const schemaData = constructSchemaData(findATherapistSchemaData.url, findATherapistSchemaData.breadcrumbs);
  const { isLoading, isError, error } = useContext(GHLUsersContext);
  const { filteredData, pathName } = useContext(SearchFor);
  const therapists = useSortedTherapists(filteredData);
  const location = useLocation();


  return (
    <StyledMainPageContainer>
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <PageHeader therapists={therapists} />
      {location.pathname === pathName ? <ProfileCardContainer filteredResults={therapists} isLoading={isLoading} isError={isError} error={error} /> : <CBLoading />}
      <CBDivider />
      <AreYouATherapist />
    </StyledMainPageContainer>
  );
};

export default FindATherapist;
