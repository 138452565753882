const data = [
  {
    title: "Choose a Clean and Decluttered Space:",
    description:
      "Opt for a space that is clean, decluttered, and devoid of distractions, including loud noises. Schedule your treatment when household members are away or when external disruptions are minimal.",
  },
  {
    title: "Arrange Devices and Notepad:",
    description:
      "Set up devices, including a computer for video conferencing and a music-playing device, within easy reach. Keep a notepad handy for recording session intentions and post-session reflections.",
  },
  {
    title: "Put Smartphones in Do Not Disturb Mode:",
    description:
      "Eliminate distractions by activating Do Not Disturb mode on smartphones and silencing other devices.",
  },
  {
    title: "Adjust Lighting:",
    description:
      "Dim or use ambient lighting to create a relaxed atmosphere. Consider using an eye mask to block out light, promoting a more comfortable experience.",
  },
  {
    title: "Soothing Background Music:",
    description:
      "    Play relaxing music or use a provided playlist during the session. Headphones may enhance the relaxation experience for some individuals.",
  },
  {
    title: "Create a Comfortable Seating Area:",
    description:
      "Designate a seating area where you can transition from sitting to lying down comfortably. Use pillows and blankets as needed. Have a nearby cup or trash bin for spitting out the solution after it dissolves.",
  },
];

export default data;
