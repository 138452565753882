

import { useEffect, useMemo } from "react";




// const useSortFilterOptionsForTherapist = (data, filterOptions) => {
//     const sortedData = useMemo(() => {
//         if (Object.keys(filterOptions).length === 0) {
//             return data;
//         }

//         return data.filter((therapist) => {
//             return Object.keys(filterOptions).every((key) => {
//                 const filterValue = filterOptions[key].toLowerCase();
//                 const therapistValue = therapist[key]?.toLowerCase();
//                 // Check direct properties
//                 if (therapistValue && therapistValue === filterValue) {
//                     return true;
//                 }

//                 // Check custom fields
//                 if (therapist.customFields) {
//                     return therapist.customFields.some((field) => {
//                         const fieldName = field.name.toLowerCase();
//                         if (fieldName === key.toLowerCase()) {
//                             if (Array.isArray(field.value)) {
//                                 const fieldValue = field.value.map((value) => value.toLowerCase());
                                
//                                 return fieldValue.includes(filterValue);
//                             }
//                             return field.value === filterValue;
//                         }
//                         return false;
//                     });
//                 }

//                 return false;
//             });
//         });
//     }, [data, filterOptions]);

//     return sortedData;
// };

// export default useSortFilterOptionsForTherapist;

const useSortFilterOptionsForTherapist = (data, filterOptions) => {
    const sortedData = useMemo(() => {
        if (Object.keys(filterOptions).length === 0) {
            return data;
        }

        return data.filter((therapist) => {
            return Object.keys(filterOptions).every((key) => {
                const filterValue = filterOptions[key];
                const therapistValue = therapist[key]?.toLowerCase();

                // Check direct properties
                if (therapistValue) {
                    if (Array.isArray(filterValue)) {
                        const filterValues = filterValue.map(value => value.toLowerCase());
                        if (filterValues.includes(therapistValue)) {
                            return true;
                        }
                    } else if (typeof filterValue === 'string' && therapistValue === filterValue.toLowerCase()) {
                        return true;
                    }
                }

                // Check custom fields
                if (therapist.customFields) {
                    return therapist.customFields.some((field) => {
                        const fieldName = field.name.toLowerCase();
                        if (fieldName === key.toLowerCase()) {
                            if (Array.isArray(field.value)) {
                                const fieldValues = field.value.map(value => value.toLowerCase());
                                if (Array.isArray(filterValue)) {
                                    const filterValues = filterValue.map(value => value.toLowerCase());
                                    return filterValues.some(value => fieldValues.includes(value));
                                }
                                return fieldValues.includes(filterValue.toLowerCase());
                            }
                            return typeof filterValue === 'string' && field.value.toLowerCase() === filterValue.toLowerCase();
                        }
                        return false;
                    });
                }

                return false;
            });
        });
    }, [data, filterOptions]);

    return sortedData;
};



export default useSortFilterOptionsForTherapist;