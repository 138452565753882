import React from "react";
import { StyledIdealSpaceSectionContainer, StyledIdealSpaceHeader, StyledIdealSpaceGrid } from "./index.style";
import IdealSpaceList from "./IdealSpaceList";

export default function IdealSpace() {
  return (
    <StyledIdealSpaceSectionContainer component={"section"} id="ideal-space-section">
      <StyledIdealSpaceGrid container>
        <StyledIdealSpaceHeader component="h2" variant="h2">
          Tips For An Ideal Space
        </StyledIdealSpaceHeader>
        <IdealSpaceList />
      </StyledIdealSpaceGrid>
    </StyledIdealSpaceSectionContainer>
  );
}
