//! instead of creating all these different state values, why not just have the states in a single formData value, and then access each value using dot notation ex. formData.billingFirstName
// import { createContext, useState, useEffect } from "react";

// export const CheckoutContext = createContext({
//     showShippingAddress: false,
//     setShowShippingAddress: () => {},
//     billingFirstName: "",
//     setBillingFirstName: () => {},
//     billingLastName: "",
//     setBillingLastName: () => {},
//     billingAddress: "",
//     setBillingAddress: () => {},
//     billingAppartment: "",
//     setBillingAppartment: () => {},
//     billingCountry: "",
//     setBillingCountry: () => {},
//     billingCity: "",
//     setBillingCity: () => {},
//     billingState: "",
//     setBillingState: () => {},
//     billingZipCode: "",
//     setBillingZipCode: () => {},
//     billingPhone: "",
//     setBillingPhone: () => {},
//     shippingFirstName: "",
//     setShippingFirstName: () => {},
//     shippingLastName: "",
//     setShippingLastName: () => {},
//     shippingAddress: "",
//     setShippingAddress: () => {},
//     shippingAppartment: "",
//     setShippingAppartment: () => {},
//     shippingCountry: "",
//     setShippingCountry: () => {},
//     shippingCity: "",
//     setShippingCity: () => {},
//     shippingState: "",
//     setShippingState: () => {},
//     shippingZipCode: "",
//     setShippingZipCode: () => {},
//     shippingPhone: "",
//     setShippingPhone: () => {},
// });

// export const CheckoutProvider = ({ children }) => {
//   const [showShippingAddress, setShowShippingAddress] = useState(false);
//   const [billingFirstName, setBillingFirstName] = useState("");
//   const [billingLastName, setBillingLastName] = useState("");
//   const [billingAddress, setBillingAddress] = useState("");
//   const [billingAppartment, setBillingAppartment] = useState("");
//   const [billingCountry, setBillingCountry] = useState("");
//   const [billingCity, setBillingCity] = useState("");
//   const [billingState, setBillingState] = useState("");
//   const [billingZipCode, setBillingZipCode] = useState("");
//   const [billingPhone, setBillingPhone] = useState("");

//   const [shippingFirstName, setShippingFirstName] = useState("");
//   const [shippingLastName, setShippingLastName] = useState("");
//   const [shippingAddress, setShippingAddress] = useState("");
//   const [shippingAppartment, setShippingAppartment] = useState("");
//   const [shippingCountry, setShippingCountry] = useState("");
//   const [shippingCity, setShippingCity] = useState("");
//   const [shippingState, setShippingState] = useState("");
//   const [shippingZipCode, setShippingZipCode] = useState("");
//   const [shippingPhone, setShippingPhone] = useState("");

//   const value = {
//     showShippingAddress, setShowShippingAddress,
//     billingFirstName, setBillingFirstName,
//     billingLastName, setBillingLastName,
//     billingAddress, setBillingAddress,
//     billingAppartment, setBillingAppartment,
//     billingCountry, setBillingCountry,
//     billingCity, setBillingCity,
//     billingState, setBillingState,
//     billingZipCode, setBillingZipCode,
//     billingPhone, setBillingPhone,
//     shippingFirstName, setShippingFirstName,
//     shippingLastName, setShippingLastName,
//     shippingAddress, setShippingAddress,
//     shippingAppartment, setShippingAppartment,
//     shippingCountry, setShippingCountry,
//     shippingCity, setShippingCity,
//     shippingState, setShippingState,
//     shippingZipCode, setShippingZipCode,
//     shippingPhone, setShippingPhone,
//   };

//   return <CheckoutContext.Provider value={value}>{children}</CheckoutContext.Provider>;
// };

import React, { createContext, useState } from "react";

const CheckoutContext = createContext({});

const CheckoutProvider = ({ children }) => {
  const [showBillingAddressFormFields, setShowBillingAddressFormFields] = useState(false);
  const [formData, setFormData] = useState({
    billingAddress: "",
    billingCity: "",
    billingCountry: "US",
    billingFirstName: "",
    billingLastName: "",
    billingPhone: "",
    billingState: "",
    billingZipCode: "",
    email: "",
    shippingAddress: "",
    shippingCity: "",
    shippingCountry: "US",
    shippingFirstName: "",
    shippingLastName: "",
    shippingPhone: "",
    shippingState: "",
    shippingZipCode: "",
  });

  const updateFormData = (values, showShippingAddress) => {
    const updatedValues = {
      ...values,
      shippingFirstName: showShippingAddress ? values.shippingFirstName : values.billingFirstName,
      shippingLastName: showShippingAddress ? values.shippingLastName : values.billingLastName,
      shippingAddress: showShippingAddress ? values.shippingAddress : values.billingAddress,
      shippingCity: showShippingAddress ? values.shippingCity : values.billingCity,
      shippingCountry: showShippingAddress ? values.shippingCountry : values.billingCountry,
      shippingState: showShippingAddress ? values.shippingState : values.billingState,
      shippingZipCode: showShippingAddress ? values.shippingZipCode : values.billingZipCode,
      shippingPhone: showShippingAddress ? values.shippingPhone : values.billingPhone,
    };

    setFormData(updatedValues);
  };

  const values = {
    formData,
    updateFormData,
    showBillingAddressFormFields,
    setShowBillingAddressFormFields,
  };

  return <CheckoutContext.Provider value={values}>{children}</CheckoutContext.Provider>;
};

export { CheckoutContext, CheckoutProvider };
