import styled from "@emotion/styled";
import { Container } from "@mui/material";

export const StyledImpactOfEnvironmentSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  display: "flex",
  flexDirection: "column",
  gap: "4rem",

  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));
