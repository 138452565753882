import styled from "@emotion/styled";
import { Accordion, AccordionDetails, AccordionSummary, Link, Stack, Container } from "@mui/material";

export const StyledYTVideoContainer = styled(Container)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: "0.5rem !important",
    margin: "0rem 0rem 1rem 0rem",
    "& iframe": {
        width: "100%",
        maxWidth: "45rem",
        aspectRatio: "16 / 9 !important",
        border: "none",
        borderRadius: "1rem",
    }
}));