import React from "react";
// Components
import { About, Jobs } from "./components";
import SubmitJobApplication from "./components/submitJobApplication";
import SchemaComponent from "components/CBSchema";
import PageFooter from "components/CBPageFooter";
import SEO from "components/CBSEO";
// Styled Components
import { StyledMainPageContainer } from "index.styles";
// Utils
import { constructSchemaData } from "utils/helpers/constructSchemaData";
// Data
import careersSchemaData from "./schema.organization.json";
import seoData from "./seo.data.json";

const Careers = () => {
  const schemaData = constructSchemaData(careersSchemaData.url, careersSchemaData.breadcrumbs);
  return (
    <StyledMainPageContainer>
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <About />
      <SubmitJobApplication />
      <Jobs />
      <PageFooter
        content="Start Transforming Lives Today With Almadelic"
        to="/contact-us"
        buttonText={"Contact Our Team Today!"}
        internalLink={true}
      />
    </StyledMainPageContainer>
  );
};

export default Careers;
