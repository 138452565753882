import React from "react";
import data from "./index.data";
import {
  StyledIdealSpaceList,
  StyledIdealSpaceListItem,
  StyledIdealSpaceListTypography,
  StyledIdealSpaceListMKBox,
} from "./index.style";
import MKBox from "components/MKBox";
import CBBulletPoint from "components/CBBulletPoint";
import { Stack } from "@mui/system";
import useMediaQueries from "utils/mediaQueries.utils";

export default function IdealSpaceList(
  {
    // seeMoreList
  }
) {
  // const filteredData = seeMoreList ? data : data.slice(0, 3);
  const { isLg } = useMediaQueries();
  return (
    <StyledIdealSpaceList>
      {data.map(({ title, description }, index) => {
        return (
          <StyledIdealSpaceListItem key={index}>
            <StyledIdealSpaceListMKBox>
              <CBBulletPoint />
            </StyledIdealSpaceListMKBox>
            <Stack ml={1}>
              <MKBox>
                <StyledIdealSpaceListTypography
                  component="h3"
                  variant="h6"
                  mb={"6px"}
                  // gutterBottom
                >
                  {title}
                </StyledIdealSpaceListTypography>
                <StyledIdealSpaceListTypography
                  component="p"
                  variant={isLg ? "body1" : "body2"}
                >
                  {description}
                </StyledIdealSpaceListTypography>
              </MKBox>
            </Stack>
          </StyledIdealSpaceListItem>
        );
      })}
    </StyledIdealSpaceList>
  );
}
