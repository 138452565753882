import React from "react";
// Components
import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
// Styled Components
import { StyledGridTextItem, StyledGridImageItem, StyledBenefitsOfKetamieTherapySpan, StyledWhatIsKetamineSectionContainer } from "./index.style";
import { StyledImage } from "index.styles";
// Theme
import theme from "assets/theme";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";

const WhatIsKetamineTherapy = () => {
  const { isLg, isXl } = useMediaQueries();

  return (
    <StyledWhatIsKetamineSectionContainer component={"section"} id={"what-is-ketamine-section"}>
      {/* 1st Section */}
      <Grid columnSpacing={isLg ? 6 : 0} container sx={{ marginBottom: { xs: 4, md: 8 } }}>
        <StyledGridImageItem item xs={12} xl={5} order={isXl ? 1 : 0}>
          <StyledImage
            height="611"
            width="427"
            whatisketamine={true}
            src={"https://d3a0rs3velfrz.cloudfront.net/whatIsKetamine/whatIsKetamine1.webp"}
            alt="Image of a woman putting her sleeping mask getting ready for here ketamine session."
          />
        </StyledGridImageItem>
        <StyledGridTextItem item xs={12} xl={7} order={isXl ? 0 : 1}>
          <MKTypography gutterBottom component={"h1"} variant={"h1"}>
            <span style={StyledBenefitsOfKetamieTherapySpan}>What is </span>
            <span>Ketamine Assisted Therapy (KAT)?</span>
          </MKTypography>
          <MKTypography component="p" variant={isLg ? "body1" : "body2"} mb={2}>
            Ketamine Assisted Therapy (KAT), also referred to as Ketamine Psychedelic Therapy (KPT), is a form of psychotherapy that incorporates the
            controlled and supervised use of ketamine, a dissociative anesthetic, to facilitate therapeutic breakthroughs. Unlike traditional talk
            therapy alone, KAT or KPT combines psychological support with the administration of ketamine to enhance the therapeutic process.
          </MKTypography>
          <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
            Ketamine, when administered in a controlled and therapeutic setting, can induce altered states of consciousness that may lead to profound
            psychological insights and experiences. During a ketamine psychedelic therapy session, individuals typically receive a predetermined
            dosage of ketamine, often guided by a trained guide, therapist or healthcare professional. The therapist or guide then provides support
            and guidance as the individual explores their thoughts, emotions, and memories.
          </MKTypography>
        </StyledGridTextItem>
      </Grid>
      {/* 2nd Section */}
      <Grid columnSpacing={isLg ? 6 : 0} container>
        <StyledGridImageItem item xs={12} lg={5}>
          <StyledImage
            sx={{ marginBottom: { lg: "0 !important" } }}
            height="644"
            width="427"
            whatisketamine={true}
            src={"https://d3a0rs3velfrz.cloudfront.net/whatIsKetamine/whatIsKetamineImage2.webp"}
            alt="Man having a converstation with his professional"
          />
        </StyledGridImageItem>
        <StyledGridTextItem item xs={12} lg={7}>
          <MKTypography gutterBottom component={"h2"} variant={"h2"}>
            How Ketamine Assisted Therapy <br />
            <span
              style={{
                color: theme.palette.accent.main,
                fontSize: "inherit",
                fontWeight: "inherit",
              }}
            >
              {" "}
              Improves Mental Health
            </span>
          </MKTypography>
          <MKTypography component="p" variant={isLg ? "body1" : "body2"} mb={2}>
            Ketamine psychedelic therapy has shown promise in treating various mental health conditions, including depression, anxiety, post-traumatic
            stress disorder (PTSD), and chronic pain. Research suggests that ketamine may impact certain neurotransmitter systems in the brain,
            leading to rapid and robust therapeutic effects.
          </MKTypography>
          <MKTypography component="p" variant={isLg ? "body1" : "body2"} mb={2}>
            As explained by John Krystal, MD, chair of the Yale School of Medicine Department of Psychiatry, when someone has depression, there are
            changes in the brain’s circuitry, including how neurons communicate with one another allowing for a regrowth of synapses (connections
            between neurons).
          </MKTypography>
          <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
            It's important to note that ketamine-assisted therapy can only be administered by qualified and licensed professionals to qualified
            patients. Patients should be monitored to ensure safety and efficacy. The approach is still evolving, and ongoing research aims to further
            understand its mechanisms and potential applications in mental health treatment.
          </MKTypography>
        </StyledGridTextItem>
      </Grid>
    </StyledWhatIsKetamineSectionContainer>
  );
};

export default WhatIsKetamineTherapy;
