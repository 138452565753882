import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

export const StyledWhatToExpectSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));
export const StyledWhatToExpectTextBox = styled(MKBox)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  paddingBottom: "4rem",
  [theme.breakpoints.up("lg")]: {
    textAlign: "center",
    paddingBottom: "6rem",
  },
}));

export const StyledGridContainer = styled(Grid)(({ theme }) => ({
  paddingTop: "4rem",
  [theme.breakpoints.up("lg")]: {
    paddingTop: "6rem",
  },
}));
export const StyledWhatToExpectStepsTypography = styled(MKTypography)(({ theme }) => ({
  fontSize: "32px",
  position: "absolute",
  inset: "-45px 0 0 -5px",
  display: "none",
  background: "-webkit-linear-gradient(110deg, #ffffff52 55%, #305B83 90%)",
  WebkitBackgroundClip: "text ",
  color: "transparent ",
  [theme.breakpoints.up("md")]: {
    fontSize: "70px",
    inset: "-55px 0 0 -50px",
  },
  [theme.breakpoints.up("lg")]: {
    display: "flex",
    fontSize: "95px",
    inset: "-90px 0 0 -60px",
  },
  [theme.breakpoints.up("xxl")]: {
    fontSize: "114px",
    inset: "-115px 0 0 -75px",
  },
}));
