import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import therapistData from "../../pages/FindATherapist/therapist.data.json";
import faqData from "../../pages/Faq/components/Content/AlmadelicFaqData.json";
import blogData from "../../pages/Blog/components/Result/blogsData.config.json";
import { useSearch } from "utils/hooks/useSearch.hook";
import { GHLUsersContext } from "./ghlUsers.context";

export const SearchFor = createContext({
  setSearchBarTerms: () => {},
  setSearchTerm: () => {},
  selectedCheckboxes: [],
  setSelectedCheckboxes: () => {},
  filteredData: {},
  resetSearchTerms: () => {},
  pathName: "",
  filterOptions: [], // added
  setFilterOptions: () => {}, // added
});

export const SearchForProvider = ({ children }) => {
  const location = useLocation();
  const [initialData, setInitialData] = useState();
  const [pathName, setPathname] = useState();
  const { users } = useContext(GHLUsersContext);

  useEffect(() => {
    if (location.pathname === "/faq") {
      setInitialData(faqData);
      setPathname("/faq");
    } else if (location.pathname === "/find-a-therapist") {
      setInitialData(users);
      setPathname("/find-a-therapist");
    } else if (location.pathname === "/blog") {
      setInitialData(blogData);
      setPathname("/blog");
    }
  }, [location.pathname, users]);
  const { searchTerms, filteredData, resetSearchTerms, setSearchBarTerms, setSelectedCheckboxes, selectedCheckboxes, filterOptions, setFilterOptions } = useSearch(
    initialData,
    pathName
  );

  const value = {
    setSearchBarTerms,
    searchTerms,
    selectedCheckboxes,
    setSelectedCheckboxes,
    filteredData,
    resetSearchTerms,
    pathName,
    filterOptions, // added
    setFilterOptions, // added
  };

  return <SearchFor.Provider value={value}>{children}</SearchFor.Provider>;
};
