import React from "react";
// Components
import MKTypography from "components/MKTypography";
// Styled Components
import { StyledPlanningAheadPageHeaderSectionContainer } from "./index.styles";
import { StyledSectionBackgroundWrapper } from "index.styles";
// Theme
import theme from "assets/theme";

const PlanningAheadPageHeader = () => {
  return (
    <StyledSectionBackgroundWrapper id="planning-ahead-page-header-wrapper">
      <StyledPlanningAheadPageHeaderSectionContainer component="section" id="planning-ahead-page-header-section">
        <MKTypography variant={"h1"} sx={{ color: `${theme.palette.white.main} !important` }} gutterBottom>
          What to Know & When to
          <span style={{ color: theme.palette.accent.main }}> Start Preparing</span>
        </MKTypography>
      </StyledPlanningAheadPageHeaderSectionContainer>
    </StyledSectionBackgroundWrapper>
  );
};

export default PlanningAheadPageHeader;
