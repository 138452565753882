import React from "react";
// Styled Components
import { StyledChaperoneScenariosSectionContainer } from "./index.styles";
import { StyledAboutKetamineCards } from "index.styles";
import { StyledImage } from "index.styles";
// Components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// Data
import chaperoneScenariosData from "./chaperoneScenarios.data.config.json";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Theme
import theme from "assets/theme";
const ChaperoneScenarios = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledChaperoneScenariosSectionContainer id="chaperone-scenarios-section">
      <MKBox sx={{ maxWidth: "600px", margin: "0 auto 2rem auto" }}>
        <StyledImage
          width="600"
          height="392"
          src="https://d3a0rs3velfrz.cloudfront.net/chaperone/chaperone3.webp"
          alt="Two women sitting next to each other having a conversation"
        ></StyledImage>
      </MKBox>
      <MKTypography component="h2" variant="h2" textAlign="center" mb="2rem">
        <span style={{ color: theme.palette.accent.main }}>Potential Scenarios</span> Requiring Chaperone Assistance
      </MKTypography>
      <MKBox sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
        {chaperoneScenariosData.map(({ title, content }, index) => {
          return (
            <StyledAboutKetamineCards>
              <MKTypography component="h3" variant="h6" gutterBottom>
                {title}
              </MKTypography>
              <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
                {content}
              </MKTypography>
            </StyledAboutKetamineCards>
          );
        })}
      </MKBox>
    </StyledChaperoneScenariosSectionContainer>
  );
};

export default ChaperoneScenarios;
