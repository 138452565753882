import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
// Components
import { Grid, Stack, Container } from "@mui/material";
import SearchBar from "components/CBSearchBar";
import MKTypography from "components/MKTypography";
import TAFilterMenu from "components/TAFilterMenu";
import MKButton from "components/MKButton";
// Styled Components
import { StyledPageHeaderSectionContainer } from "./index.styles";
import { StyledSectionBackgroundWrapper } from "index.styles";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Theme
import theme from "assets/theme";
// Utils
import { SearchFor } from "utils/context/searchFilter.context";
import useResetSearchTerms from "pages/FindATherapist/hooks/useResetSearchTerms.hook";



const PageHeader = ({therapists}) => {
  const { isMd, isLg, isXl } = useMediaQueries();
  const { searchTerms, resetSearchTerms, setSearchBarTerms, pathName, filterOptions } = useContext(SearchFor);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  useResetSearchTerms(resetSearchTerms);

  const handleOnSearchChange = (event) => {
    const searchField = event.target.value.toLocaleLowerCase();
    setSearchBarTerms(searchField);
  };

  const handleNumberOfFilters = () => {
    const keys = Object.keys(filterOptions);
    let count = 0;
    keys.forEach((key) => {
      if (filterOptions[key] !== "All" && filterOptions[key].length > 0) {
        count++;
      }
    });
    return count;
  }

  const handleNumberOfTherapists = () => {
    return therapists.length;
  }

  const HandleResultsText = () => {
    if(handleNumberOfFilters() === 0 && (searchTerms.size === 0 || searchTerms[0].length === 0)) {
      return null
    }
    return(
      <MKTypography variant="p" component="p" gutterBottom sx={{color: theme.palette.white.main, padding: "0.5rem 0 0rem", fontSize: "1rem" }} >
        {handleNumberOfTherapists()} Results found
      </MKTypography>
    )
    
  }

  const filterMenuOptions = [
    {
        name: "state",
        label: "Sort by State",
        type: "select",
        options: [
          { value: "All", label: "All" },
          { value: "Alabama", label: "Alabama" },
          { value: "Alaska", label: "Alaska" },
          { value: "Arizona", label: "Arizona" },
          { value: "Arkansas", label: "Arkansas" },
          { value: "California", label: "California" },
          { value: "Colorado", label: "Colorado" },
          { value: "Connecticut", label: "Connecticut" },
          { value: "Delaware", label: "Delaware" },
          { value: "Florida", label: "Florida" },
          { value: "Georgia", label: "Georgia" },
          { value: "Hawaii", label: "Hawaii" },
          { value: "Idaho", label: "Idaho" },
          { value: "Illinois", label: "Illinois" },
          { value: "Indiana", label: "Indiana" },
          { value: "Iowa", label: "Iowa" },
          { value: "Kansas", label: "Kansas" },
          { value: "Kentucky", label: "Kentucky" },
          { value: "Louisiana", label: "Louisiana" },
          { value: "Maine", label: "Maine" },
          { value: "Maryland", label: "Maryland" },
          { value: "Massachusetts", label: "Massachusetts" },
          { value: "Michigan", label: "Michigan" },
          { value: "Minnesota", label: "Minnesota" },
          { value: "Mississippi", label: "Mississippi" },
          { value: "Missouri", label: "Missouri" },
          { value: "Montana", label: "Montana" },
          { value: "Nebraska", label: "Nebraska" },
          { value: "Nevada", label: "Nevada" },
          { value: "New Hampshire", label: "New Hampshire" },
          { value: "New Jersey", label: "New Jersey" },
          { value: "New Mexico", label: "New Mexico" },
          { value: "New York", label: "New York" },
          { value: "North Carolina", label: "North Carolina" },
          { value: "North Dakota", label: "North Dakota" },
          { value: "Ohio", label: "Ohio" },
          { value: "Oklahoma", label: "Oklahoma" },
          { value: "Oregon", label: "Oregon" },
          { value: "Pennsylvania", label: "Pennsylvania" },
          { value: "Rhode Island", label: "Rhode Island" },
          { value: "South Carolina", label: "South Carolina" },
          { value: "South Dakota", label: "South Dakota" },
          { value: "Tennessee", label: "Tennessee" },
          { value: "Texas", label: "Texas" },
          { value: "Utah", label: "Utah" },
          { value: "Vermont", label: "Vermont" },
          { value: "Virginia", label: "Virginia" },
          { value: "Washington", label: "Washington" },
          { value: "West Virginia", label: "West Virginia" },
          { value: "Wisconsin", label: "Wisconsin" },
          { value: "Wyoming", label: "Wyoming" },
        ],
        multi: true,
    },
    {
        name: "Specialties",
        label: "Sort by Specialty",
        type: "select",
        options: [
            { value: "All", label: "All" },
            { value: "Anxiety", label: "Anxiety" },
            { value: "PTSD", label: "PTSD" },
            { value: "Depression", label: "Depression" },
            { value: "OCD", label: "OCD" },
            { value: "Bi-polar", label: "Bi-polar" },
            { value: "Phobias", label: "Phobias" },
            { value: "Panic Disorders", label: "Panic Disorders" },
            { value: "Addictions", label: "Addictions" },
            { value: "Eating Disorders", label: "Eating Disorders" },
            { value: "Personality Disorders", label: "Personality Disorders" },
            { value: "Sexual Disorders", label: "Sexual Disorders" },
        ],
        multi: true,
    },
    {
      name: "Types Of Therapy",
      label: "Sort by Therapy Options",
      type: "select",
      multi: true,
      options: [
        { value: "All", label: "All" },
        { value: "Humanistic", label: "Humanistic" },
        { value: "Psychodynamic", label: "Psychodynamic" },
        { value: "Cognitive", label: "Cognitive" },
        { value: "Integrative/Holistic", label: "Integrative/Holistic" },
        { value: "Group", label: "Group" },
        { value: "Mindfulness", label: "Mindfulness" },
        { value: "Existential", label: "Existential" },
        { value: "Christian-Based", label: "Christian-Based" },
        { value: "Adlerian", label: "Adlerian" },
        { value: "Biodynamic", label: "Biodynamic" },
        { value: "Mentalization", label: "Mentalization" },
        { value: "Interpersonal", label: "Interpersonal" },
        { value: "Acceptance and Commitment", label: "Acceptance and Commitment" },
        { value: "Family", label: "Family" },
        { value: "Couples", label: "Couples" },
        { value: "Attachment", label: "Attachment" },
        { value: "Exposure", label: "Exposure" },
        { value: "Coherence", label: "Coherence" },
        { value: "Eye Movement Desensitization", label: "Eye Movement Desensitization" },
        { value: "Art", label: "Art" },
        { value: "Behavioral", label: "Behavioral" },
        { value: "Brain Stimulation", label: "Brain Stimulation" },
        { value: "Psychoanalysis", label: "Psychoanalysis" },
        { value: "Bibliotherapy", label: "Bibliotherapy" },
        { value: "Play", label: "Play" },
        { value: "Culturally Sensitive", label: "Culturally Sensitive" },
      ]
    },
    
    {
      name: "Treatment Methods",
      label: "Sort by Treatment Methods",
      type: "select",
      multi: true,
      options: [
        { value: "All", label: "All" },
        { value: "ACT", label: "ACT" },
        { value: "CBT", label: "CBT" },
        { value: "DBT", label: "DBT" },
        { value: "Ego State/Parts Work", label: "Ego State/Parts Work" },
        { value: "Supportive Psychotherapy", label: "Supportive Psychotherapy" },
        { value: "Psychodynamic Psychotherapy", label: "Psychodynamic Psychotherapy" },
        { value: "IFS", label: "IFS" },
        { value: "Other", label: "Other" },
      ]
    },
    {
      name: "languages",
      label: "Sort by Language",
      type: "select",
      options: [
          { value: "All", label: "All" },
          { value: "English", label: "English" },
          { value: "Spanish", label: "Spanish" },
          { value: "French", label: "French" },
      ],
      multi: true,
  },
  {
    name: "Visit Types",
    label: "Sort by Visit Types",
    type: "select",
    multi: true,
    options: [
      { value: "All", label: "All" },
      { value: "In-Person", label: "In-Person" },
      { value: "Virtual", label: "Virtual" },
    ]
  },
    // {
    //     name: "verified",
    //     label: "Verified Therapists",
    //     type: "select",
    //     options: [
    //         { value: "All", label: "All" },
    //         { value: "active directory therapist", label: "Verified" },
    //     ],
    //     multi: false,

    // },
]

  const AdvancedSearchButton = () => {
    return (
      <Container sx={{width: "auto !important", margin: "0 !important", padding: "0 !important"}}>
      <MKButton
        variant="outlined"
        color="primary"
        onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
        sx={{ color: theme.palette.white.main, backgroundColor: theme.palette.secondary.main, height: "3rem", padding: "0.4rem", margin: "12px 0 0 0 !important" }}
      >
        {showAdvancedSearch ? "Hide Advanced Search" : "Advanced Search"}
      </MKButton>
      </Container>
    )
  }

  return (
    <StyledSectionBackgroundWrapper id="find-a-therapist-wrapper">
      <StyledPageHeaderSectionContainer id="find-a-therapist-header-section" component="section">
        <Grid container columnSpacing={isLg ? 6 : 3} rowSpacing={isXl ? 0 : 6}>
          <Grid item xs={12} textAlign={"center"}>
            <MKTypography gutterBottom component={"h1"} variant={"h1"} sx={{ color: `${theme.palette.white.main} !important` }}>
              Find the Right Therapist for Your <br />
              <span style={{ color: theme.palette.accent.main, fontSize: "inherit", fontWeight: "inherit" }}> Mental Wellness Journey</span>
            </MKTypography>
            <MKTypography component="p" variant={isLg ? "body1" : "body2"} sx={{ color: `${theme.palette.white.main} !important` }} gutterBottom>
              At Almadelic, we understand the importance of mental health support and the transformative power of therapy. That's why we have created
              our "Find a Therapist" directory, a resource designed to help you connect with licensed therapists who can support you on your journey
              to improved mental wellness.
            </MKTypography>
            <Stack spacing={2} direction={isMd || isLg ? "row" : "column"} justifyContent="center">
              <SearchBar
                minusPadding="true"
                pathName={pathName}
                searchTerms={searchTerms}
                onChangeHandler={handleOnSearchChange}
                placeholder="Search by name, location, preferred language, or job title. "
              />
              
              <AdvancedSearchButton />
            </Stack>

            <Link
              aria-label="Are you are therapist? Join our directory"
              to="/partner-prospect-survey"
              style={{ color: theme.palette.accent.main, fontSize: "18px" }}
            >
              <>
                <br />
              </>
              Are You A Therapist? Join Our Directory!
            </Link>


            <HandleResultsText />
            {handleNumberOfFilters() > 0 ? (
            <MKTypography variant="p" component="p" gutterBottom sx={{color: theme.palette.white.main, padding: "0.5rem", fontSize: "1rem" }}>
              You have {handleNumberOfFilters()} filters applied
            </MKTypography>
            ) : null} 

            
            
            
            
            {showAdvancedSearch ? <TAFilterMenu filterMenuOptions={filterMenuOptions} /> : null}
          </Grid>
        </Grid>
      </StyledPageHeaderSectionContainer>
    </StyledSectionBackgroundWrapper>
  );
};

export default PageHeader;
