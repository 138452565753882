import React from "react";
// Theme
import { useTheme } from "@mui/material/styles";
// Components
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
// Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// Data
import RefundPolicyData from "./RefundPolicyDetails.data.json";

const FaqGroupItem = ({ items }) => {
  const theme = useTheme();
  return (
    <>
      {items.map((item, i) => (
        <MKBox key={i} sx={{ marginTop: i !== 0 && "2rem" }}>
          <MKTypography variant={"h3"} component={"h3"} mb="1rem" gutterBottom>
            {item.name}
          </MKTypography>
          {item.policies.map((item, index) => {
            return (
              <Box
                component={Accordion}
                key={index}
                padding={1}
                marginBottom={index === item.length - 1 ? 0 : 2}
                borderRadius={`${theme.spacing(1)} !important`}
                sx={{
                  "&::before": {
                    display: "none",
                  },
                }}
              >
                <Box
                  component={AccordionSummary}
                  expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.secondary.main }} />}
                  aria-controls="panel1a-content"
                  id={`panel1a-header--${i}`}
                >
                  <MKTypography component="h4" variant={"h6"} sx={{ fontWeight: 500, fontSize: "90%" }}>
                    {item.refundName}
                  </MKTypography>
                </Box>
                <AccordionDetails>
                  {item.refundText.map((item, index) => {
                    return (
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          margin: "8px 0 !important",
                        }}
                      >
                        <Grid
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            minWidth: "50px",
                            marginTop: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              height: "6px",
                              width: "6px",
                              backgroundColor: "black !important",
                              marginRight: ".5rem",
                              borderRadius: "50%",
                            }}
                          ></Box>
                        </Grid>
                        <MKTypography component={"p"}>{item}</MKTypography>
                      </Grid>
                    );
                  })}
                </AccordionDetails>
              </Box>
            );
          })}
        </MKBox>
      ))}
    </>
  );
};

const RefundPolicyDetails = () => {
  return <FaqGroupItem items={RefundPolicyData} />;
};

export default RefundPolicyDetails;
