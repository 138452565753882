import styled from "@emotion/styled";
import { Container, Box, Divider } from "@mui/material";

export const StyledKetamineAssistedTherapySectionContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflow: "visible",
  paddingBlock: "4rem",
  [theme.breakpoints.up("md")]: {
    minHeight: "625px",
  },
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
    minHeight: "650px",
    alignItems: "center",
  },
  [theme.breakpoints.up("xl")]: {
    minHeight: "650px",
  },
  [theme.breakpoints.up("xxl")]: {
    minHeight: "850px",
  },
}));

export const StyledKeyMechanismsOfKetamineAssistedTherapyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}));

export const StyledKeyMechanismsOfKetamineAssistedTherapyInnerBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
}));

export const StyledKeyMechanismsOfKetamineAssistedTherapyDivider = styled(Divider)(({ theme }) => ({
  height: "3px",
  backgroundColor: "rgba(117, 205, 197, 1)",
  opacity: "1",
}));

export const StyledKeyMechanismsOfKetamineAssistedTherapyButtonBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "75px",
}));
