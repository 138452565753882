import { useMemo } from "react";

export const useFilterDataForBlogs = (initialData = [], searchTerms) => {
  const filteredData = useMemo(() => {
    if (searchTerms.size === 0) {
      return initialData;
    }
    const normalizeAndLowerCase = (value) => String(value).trim().toLowerCase();
    const normalizedSearchTerms = [...searchTerms].map(normalizeAndLowerCase);

    return initialData.filter((blog) => {
      const blogTitle = normalizeAndLowerCase(blog.title);
      const blogDescription = normalizeAndLowerCase(blog.description);
      return normalizedSearchTerms.some((term) => blogTitle.includes(term) || blogDescription.includes(term));
    });
  }, [initialData, searchTerms]);
  return filteredData;
};
