
import React, { useContext, useState } from "react";
import { Select, FormControl, MenuItem, OutlinedInput, Stack, Container, Grid } from "@mui/material";

import MKTypography from "components/MKTypography";
import theme from "assets/theme";
import { SearchFor } from "utils/context/searchFilter.context";
import { StyledMenuContainer, StyledOptionStack } from "./index.styles";
import MKButton from "components/MKButton";



const TAFilterMenu = ({filterMenuOptions}) => {
    const { filterOptions, setFilterOptions } = useContext(SearchFor);
    
    const menuProps = {
        PaperProps: {
          style: {
            maxHeight: 200,
            marginTop: "1rem",
          },
        },
      };

    const SortOptionSelect = ( option ) => {

    
        return (
           
            <FormControl variant="outline" fullWidth sx={{maxHeight: "200px"}}>
                <Select
                    label={option.label}
                    
                    onChange={(e) => handleMultiSelectChange(e, option)}
                    input={<OutlinedInput label="State" />}
                    value={filterOptions[option.name] || []}
                    sx={{height: "44px", justifyContent: "center", }}
                    displayEmpty
                    multiple={option.multi}
                    renderValue={(selected) => {
                        if (!selected.length) {
                            return (
                                <MKTypography component="p" sx={{ fontSize: "14px", color: theme.palette.primary.main,}}>
                                    Select your {option.name}
                                </MKTypography>
                            );
                        }
                        
                        return (<MKTypography sx={{color: theme.palette.primary.main, fontSize: "1.0rem", }}>
                                    {Array.isArray(selected) ? selected.join(", ") : selected}
                                </MKTypography>
                            );
                    }}
                    MenuProps={menuProps}
                >
                    
                    {option.options.map((selectOption, index) => {
                        return (
                            <MenuItem key={index} value={selectOption.value} name={selectOption.label} sx={{color: theme.palette.primary.main }}>
                                {selectOption.label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        );
    }

    
    const handleCleatFilter = () => {
        setFilterOptions({});
    }
    
    

    const handleSelectChange = (event, option) => {
        const { value } = event.target;
        const { name } = option;
        
        if(value === "All" || value.length === 0) {
            const updatedFilterOption = filterOptions;
            delete updatedFilterOption[name];
            setFilterOptions({...updatedFilterOption});
        }
        else if(!filterOptions[name]) {
            setFilterOptions({...filterOptions, [name]: value});
        } else if(filterOptions[name] !== value) {
            const updatedFilterOption = filterOptions
            updatedFilterOption[name] = value;
            setFilterOptions({...updatedFilterOption});
        } 
    }

    const handleMultiSelectChange = (event, option) => {
        const { value } = event.target;
        const { name } = option;
        const updatedFilterOption = filterOptions;
        
        if(value.includes("All") || value.length === 0) {
            delete updatedFilterOption[name];
            setFilterOptions({...updatedFilterOption});
        } else if(!filterOptions[name]) {
            setFilterOptions({...filterOptions, [name]: value});
        } else if(filterOptions[name] !== value) {
            updatedFilterOption[name] = value;
            setFilterOptions({...updatedFilterOption});
        }
    }


    return (
        <StyledMenuContainer id="advance-search-filter-menu">
            <MKTypography variant="h5" component="h5" gutterBottom>
                Filter Options 
            </MKTypography>
            <Grid container spacing={2} sx={{padding: "1rem"}}>
                {filterMenuOptions.map((option, index) => {
                    return (
                        <Grid item xs={12} md={6} lg={4} xl={4} key={index}>
                        <StyledOptionStack >
                            <MKTypography variant="p" component="p" gutterBottom sx={{textAlign: "left", color: theme.palette.primary.main}}>
                                {option.label}
                            </MKTypography>
                            {option.type === "select" ? SortOptionSelect(option) : null}
                        </StyledOptionStack>
                        </Grid>
                    );
                })}
            </Grid>
            <MKButton onClick={handleCleatFilter} variant="outlined" color="primary" sx={{color: theme.palette.secondary.main, borderColor: theme.palette.secondary.main, margin: "2rem 0rem"}}>
                Clear Filters
            </MKButton>
        </StyledMenuContainer>
    );
} 

export default TAFilterMenu;
