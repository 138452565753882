import React from "react";
import MKTypography from "components/MKTypography";
import theme from "assets/theme";
import { StyledHeadlineSectionContainer } from "./index.styles";
import { StyledSectionBackgroundWrapper } from "index.styles";

const Headline = () => {
  return (
    <StyledSectionBackgroundWrapper>
      <StyledHeadlineSectionContainer>
        <MKTypography gutterBottom align={"center"} variant={"h1"} component={"h1"} sx={{ color: "#fff !important" }}>
          Have a <span style={{ color: theme.palette.accent.main }}>question?</span>
        </MKTypography>
        <MKTypography variant="body1" align={"center"} sx={{ color: "#fff" }}>
          <em>Search our FAQ for answers to anything you might ask.</em>
        </MKTypography>
      </StyledHeadlineSectionContainer>
    </StyledSectionBackgroundWrapper>
  );
};

export default Headline;
