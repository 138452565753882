/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { SearchForProvider } from "utils/context/searchFilter.context";
import ReactGA from "react-ga4";
import { GHLUsersProvider } from "utils/context/ghlUsers.context";
import QueryProvider from "./utils/setup/queryClient";
import { AuthProvider } from "utils/context/authentication.context";
import { UserProvider } from "utils/context/user.context";
import { ProfileModalProvider } from "utils/context/profileModal.context";
import { CartProvider } from "utils/context/cart.context";
import StripeProvider from "utils/context/stripe.context";
import { CheckoutProvider } from "utils/context/checkout.context";
// import { CardProvider } from "utils/context/card.context";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);
ReactGA.initialize("G-1W04078ESG");
root.render(
  <BrowserRouter>
    <QueryProvider>
      <AuthProvider>
        <UserProvider>
          <GHLUsersProvider>
            <SearchForProvider>
              <ProfileModalProvider>
                <CartProvider>
                  <CheckoutProvider>
                    <StripeProvider>
                      {/* <CardProvider> */}
                      <App />
                      {/* </CardProvider> */}
                    </StripeProvider>
                  </CheckoutProvider>
                </CartProvider>
              </ProfileModalProvider>
            </SearchForProvider>
          </GHLUsersProvider>
        </UserProvider>
      </AuthProvider>
      {/* <ReactQueryDevtools /> */}
    </QueryProvider>
  </BrowserRouter>
);
