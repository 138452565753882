import styled from "@emotion/styled";
import { Container } from "@mui/material";
import MKBox from "components/MKBox";

export const StyledPreparationsPageHeaderSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",

  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));

export const StyledPreparationTextBox = styled(MKBox)(({ theme }) => ({
  //! ended up not needing at the moment delete if so.
}));

// export const StyledPreparationImage = styled(MKBox)(({ theme }) => ({
//   height: "300px",
//   width: "100%",
// }));
