import React from "react";
import { StyledCreatingYourSpaceMainPageContainer } from "index.styles";
import ImportanceOfSetting from "./components/ImportanceOfSetting";
import ImpactOfEnvironment from "./components/ImpactOfEnvironment";
import IdealSpace from "./components/IdealSpace";
import PageFooter from "components/CBPageFooter";
import { constructSchemaData } from "utils/helpers/constructSchemaData";
import creatingYourSpaceSchemaData from "./schema.organization.json";
import creatingYourSpaceArticleSchemaData from "./schema.article.json";
import seoData from "./seo.data.json";

import SchemaComponent from "components/CBSchema";
import SEO from "components/CBSEO";
const CreatingYourSpace = () => {
  const schemaData = constructSchemaData(
    creatingYourSpaceSchemaData.url,
    creatingYourSpaceSchemaData.breadcrumbs,
    creatingYourSpaceArticleSchemaData
  );
  return (
    <StyledCreatingYourSpaceMainPageContainer>
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <ImportanceOfSetting />
      <IdealSpace />
      <ImpactOfEnvironment />
      <PageFooter
        content="By attending to these details, you can significantly improve the overall
        environment for your at-home ketamine therapy, maximizing the potential
        benefits of each session."
        to={"https://shop.almadelic.com/"}
        buttonText={"Get Started Today!"}
        internalLink="false"
      />
    </StyledCreatingYourSpaceMainPageContainer>
  );
};

export default CreatingYourSpace;
