import React from "react";
import styled from "@emotion/styled";
// Components
import { Box, Card, Container, Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
// Theme
import theme from "assets/theme";
// Utils
import rgba from "assets/theme/functions/rgba";

export const StyledBenefitsOfKetamineTherapyWrapper = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${"https://d3a0rs3velfrz.cloudfront.net/bg-gradient.svg"})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
}));
export const StyledBenefitsOfKetamineTherapySectionContainer = styled(Container)(({ theme }) => ({
  backgroundImage: `url(${"https://d3a0rs3velfrz.cloudfront.net/bg-gradient.svg"})`,
  paddingBlock: "4rem",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",

  [theme.breakpoints.up("md")]: {
    minHeight: "625px",
  },
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
    minHeight: "650px",
    alignItems: "center",
  },
  [theme.breakpoints.up("xl")]: {
    minHeight: "650px",
  },
  [theme.breakpoints.up("xxl")]: {
    minHeight: "850px",
  },
  flexWrap: "wrap",
}));

export const StyledBenefitsOfKetamineTherapyGrid = styled(Grid)(({ theme }) => ({
  minHeight: "300px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const StyledKetamineMentalHealthGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export const StyledBenefitsOfKetamieTherapyMKTypography = styled(MKTypography)(({ theme }) => ({
  color: `${theme.palette.accent.main} !important`,
  maxWidth: "500px",
  textAlign: "center",
}));

export const StyledBenefitsOfKetamieTherapySpan = {
  color: theme.palette.white.main,
  fontSize: "inherit",
  fontWeight: "inherit",
};

export const StyledBenefitsOfKetamineTherapyBenefitsCardBox = styled(Box)(({ theme }) => ({
  borderRadius: "12px",
  width: "100%",
  minHeight: "263px",
  maxWidth: "485px",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "1rem",
  padding: "1.5rem",
  marginBottom: { xs: "2rem", lg: 0 },
  transition: "all .2s ease-in-out",

  boxShadow: `0 0 10px rgba(128, 128, 128, .5)`,
  borderBottom: `10px solid ${rgba(theme.palette.accent.main, 1)}`,
  background: "white",

  "&:hover": {
    cursor: "pointer",
    transform: "translateY(-4px)",
    transition: "all 0.3s ease-in-out",
    boxShadow: `${rgba(theme.palette.accent.main, 0.6)} 0px 6px 24px`,
  },
}));

export const BenefitsCard = ({ title, content }) => {
  return (
    <Grid item xs={12} lg={12}>
      <StyledWhyChooseUsCards>
        <MKTypography
          gutterBottom
          variant={"h6"}
          component="h4"
          sx={{
            color: `${theme.palette.accent.main} !important`,
            fontSize: "20px",
          }}
        >
          {title}
        </MKTypography>
        <MKTypography variant={"body2"} component="p" maxWidth={900}>
          {content}
        </MKTypography>
      </StyledWhyChooseUsCards>
    </Grid>
  );
};

export const StyledWhyChooseUsCards = styled(Card)(({ theme }) => ({
  padding: "3rem 2rem",
  width: "100%",
  minHeight: "263px",
  minWidth: "300px",
  margin: "auto",
  transition: "all .2s ease-in-out",
  background: `#fff`,
  backdropFilter: "blur(15px)",
  borderRadius: "12px",
  borderBottom: `10px solid ${theme.palette.accent.main}`,
  "&:hover": {
    cursor: "pointer",
    transition: "all .3s ease-in-out",
    transform: "translateY(-4px)",
    boxShadow: `${rgba(theme.palette.accent.main, 0.6)} 0px 6px 24px`,
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: "500px",
  },
}));
