import styled from "@emotion/styled";
import { Card } from "@mui/material";
import rgba from "assets/theme/functions/rgba";

export const StyledProfileCards = styled(Card)(({ theme }) => ({
  borderRadius: "12px",
  width: "100%",
  textAlign: "center",
  maxHeight: "275px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
  padding: "1.5rem",
  borderLeft: `10px solid ${theme.palette.accent.main}`,
  [theme.breakpoints.up("md")]: {
    maxWidth: "320px",
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: "430px",
  },
  "@media screen and (min-width: 1200px)": {
    maxWidth: "340px",
  },

  "@media screen and (min-width: 1400px)": {
    maxWidth: "400px",
  },

  "&:hover": {
    cursor: "pointer",
    transition: "all .3s ease-in-out",
    transform: "translateY(-4px)",
    boxShadow: `${rgba(theme.palette.accent.main, 0.6)} 0px 6px 24px`,
  },
}));
