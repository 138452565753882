import { useState, useMemo, useEffect } from "react";
import { useFilterDataForTherapist } from "./useFilterDataForTherapists";
import { useFilterDataForFaqs } from "./useFilterDataForFaqs.hooks";
import { useFilterDataForBlogs } from "./useFilterDataForBlogs.hooks";
import useSortFilterOptionsForTherapist from "./useSortFilterOptionsForTherapists.hooks";

export const useSearch = (initialData = [], pathName) => {
  const [searchTerms, setSearchTerms] = useState(new Set());
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [searchBarTerms, setSearchBarTerms] = useState("");

  const [filterOptions, setFilterOptions] = useState([]); // added

  const filteredTherapistData = useFilterDataForTherapist(initialData, searchTerms);
  const filteredFaqsData = useFilterDataForFaqs(initialData, searchTerms);
  const filteredBlogData = useFilterDataForBlogs(initialData, searchTerms);

  const sortFilteredData = useSortFilterOptionsForTherapist(filteredTherapistData, filterOptions);

  const filteredData = useMemo(() => {
    if (pathName === "/find-a-therapist") {
      return sortFilteredData;
    } else if (pathName === "/faq") {
      return filteredFaqsData;
    } else if (pathName === "/blog") {
      return filteredBlogData;
    } else {
      return initialData;
    }
  }, [initialData, pathName, filteredTherapistData, filteredFaqsData, filteredBlogData, sortFilteredData]);

  useEffect(() => {
    console.log("filterOptions in useSearch", filterOptions);
  }, [filterOptions]);

  useEffect(() => {
    const selectedCheckBoxesArray = selectedCheckboxes;
    const searchBarTermsArray = searchBarTerms.trim().toLowerCase().split(" ");
    const combinedSearchTerms = selectedCheckBoxesArray.concat(searchBarTermsArray);
    setSearchTerms(combinedSearchTerms);
  }, [selectedCheckboxes, searchBarTerms]);

  

  const resetSearchTerms = () => {
    setSearchTerms(new Set());
  };

  return {
    setSearchBarTerms,
    setSelectedCheckboxes,
    searchTerms,
    filteredData,
    resetSearchTerms,
    filterOptions,
    setFilterOptions,
  };
};
