import { Grid, TextField, MenuItem, Select, FormControl, InputLabel, FormHelperText } from "@mui/material";
import { Field, useFormikContext } from "formik";
import React from "react";
import MKTypography from "components/MKTypography";
import countryList from "react-select-country-list";
import StateOptions from "utils/helpers/stateOptions";

const ContactInformation = () => {
  const { errors, touched, handleChange } = useFormikContext();
  const countries = countryList().getData();
  return (
    <Grid container sx={{ display: "flex", flexDirection: "column", justifyContent: "center", pb: "2rem" }}>
      <Grid container item>
        {/* <Grid item xs={0.5}>
          1
        </Grid> */}
        <Grid item xs={11.5}>
          <MKTypography component="h4" variant="h4" sx={{ display: "block" }}>
            1. Contact Information
          </MKTypography>
          <MKTypography variant="body2" sx={{ mb: 2 }}>
            Already have an account?
          </MKTypography>
          <MKTypography variant="body2" sx={{ mb: 2 }}>
            &nbsp;Log in
          </MKTypography>
          <MKTypography variant="body2" sx={{ mb: 2, display: "block" }}>
            We'll use this email to send you details and updates about your order.
          </MKTypography>
          <Field
            as={TextField}
            label="Email address"
            name="email"
            type="email"
            error={touched.email && !!errors.email}
            // error={touched.email && !!errors.email}
            helperText={touched.email && errors.email}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <Field
            as={TextField}
            label="First name"
            name="shippingFirstName"
            type="text"
            error={touched.shippingFirstName && !!errors.shippingFirstName}
            helperText={touched.shippingFirstName && errors.shippingFirstName}
            variant="outlined"
            sx={{
              width: { xs: "100%", md: "50%" },
              paddingRight: { xs: 0, md: "8px" },
            }}
            margin="normal"
          />
          <Field
            as={TextField}
            label="Last name"
            name="shippingLastName"
            type="text"
            error={touched.shippingLastName && !!errors.shippingLastName}
            helperText={touched.shippingLastName && errors.shippingLastName}
            variant="outlined"
            sx={{ width: { xs: "100%", md: "50%" } }}
            margin="normal"
          />
          <Field
            as={TextField}
            label="Address"
            name="shippingAddress"
            type="text"
            error={touched.shippingAddress && !!errors.shippingAddress}
            helperText={touched.shippingAddress && errors.shippingAddress}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <Field
            as={TextField}
            label="Appartment, suite, etc. (optional)"
            name="shippingAppartment"
            type="text"
            error={touched.shippingAppartment && !!errors.shippingAppartment}
            helperText={touched.shippingAppartment && errors.shippingAppartment}
            variant="outlined"
            fullWidth
            optional="true"
            margin="normal"
          />
          <Field name="shippingCountry">
            {({ field }) => (
              <FormControl fullWidth variant="outlined" margin="normal" error={touched.shippingCountry && !!errors.shippingCountry}>
                <InputLabel id="country-label">Country</InputLabel>
                <Select labelId="country-label" label="Country" {...field} sx={{ height: "45px" }} onChange={handleChange(field.name)}>
                  {countries.map((country) => (
                    <MenuItem key={country.value} value={country.value}>
                      {country.label}
                    </MenuItem>
                  ))}
                </Select>
                {/* {touched.shippingCountry && errors.shippingCountry ? (
                  <FormHelperText>{errors.shippingCountry}</FormHelperText>
                ) : null} */}
              </FormControl>
            )}
          </Field>
          <Field
            as={TextField}
            label="City"
            name="shippingCity"
            type="text"
            error={touched.shippingCity && !!errors.shippingCity}
            helperText={touched.shippingCity && errors.shippingCity}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <Field
            name="shippingState"
            sx={{
              width: { xs: "100%", md: "50%" }, // Conditional width based on screen size
              paddingRight: { xs: 0, md: "8px" }, // Conditional padding-right based on screen size
            }}
          >
            {({ field }) => (
              <FormControl
                variant="outlined"
                margin="normal"
                error={touched.shippingState && !!errors.shippingState}
                sx={{ width: { sm: "100%", md: "50%" }, paddingRight: 1 }}
              >
                <InputLabel id="state-label">State</InputLabel>
                <Select sx={{ height: "45px" }} labelId="state-label" label="State" {...field} onChange={field.onChange}>
                  {StateOptions.map((state) => (
                    <MenuItem key={state.name} value={state.name}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
                {touched.shippingState && errors.shippingState && <FormHelperText>{errors.shippingState}</FormHelperText>}
              </FormControl>
            )}
          </Field>
          <Field
            as={TextField}
            label="Zip Code"
            name="shippingZipCode"
            type="text"
            error={touched.shippingZipCode && !!errors.shippingZipCode}
            helperText={touched.shippingZipCode && errors.shippingZipCode}
            variant="outlined"
            sx={{ width: { xs: "100%", md: "50%" } }}
            margin="normal"
          />
          <Field
            as={TextField}
            label="Phone"
            name="shippingPhone"
            type="text"
            error={touched.shippingPhone && !!errors.shippingPhone}
            helperText={touched.shippingPhone && errors.shippingPhone}
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactInformation;
