import styled from "@emotion/styled";
import { Container } from "@mui/material";
import MKBox from "components/MKBox";

export const StyledOurServicesSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));

export const StyledOurServicesTextBox = styled(MKBox)(({ theme }) => ({
  marginBottom: "4rem",
  textAlign: "center",
  [theme.breakpoints.up("lg")]: {
    marginBottom: "6rem",
  },
}));

export const StyledOurServicesCard = styled(MKBox)(({ theme }) => ({
  marginBottom: "4rem",
  textAlign: "center",
  minHeight: "200px",
  padding: "1rem",
  display: "flex",
  flexDirection: "column",
  gap: ".5rem",
  [theme.breakpoints.up("md")]: {
    maxWidth: "400px",
  },
  [theme.breakpoints.up("lg")]: {
    marginBottom: "6rem",
  },
}));
