import { useState } from "react";
import axios from "axios";

const useImageUpload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const uploadImage = async (file, fileName) => {
    setIsLoading(true);
    setError(null);

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        try {
          const base64 = reader.result.split(",")[1];
          const response = await axios.post(
            process.env.REACT_APP_AWS_UPLOAD_IMAGE_ENDPOINT,
            {
              fileContent: base64,
              fileName: fileName || file.name,
              bucketName: process.env.REACT_APP_AWS_S3_ASSETS_BUCKET,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (response.status !== 200) throw new Error("Failed to upload image");

          const responseData = response.data;
          const parsedData = JSON.parse(responseData.body);
          setImageUrl(parsedData.url);
          resolve(parsedData.url);
        } catch (error) {
          console.error("Upload error:", error);
          setError(error.message);
          reject(error);
        } finally {
          setIsLoading(false);
        }
      };
      reader.onerror = (error) => {
        console.error("File read error:", error);
        setError(error.message);
        setIsLoading(false);
        reject(error);
      };
    });
  };

  return {
    uploadImage,
    isLoading,
    error,
    imageUrl,
  };
};

export default useImageUpload;
