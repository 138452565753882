import React from "react";
// Components
import MeetTheTeamCard from "./MeetTheTeamCards";
import CBDivider from "components/CBDivider";
import { Grid } from "@mui/material";
// Data
import meetTheTeam from "./MeetTheTeam.data.json";
// Styled Components
import { StyledSectionBackgroundWrapper } from "index.styles";
import {
  StyledOurTeamCardCardContainerGrid,
  StyledOurTeamCardInnerGrid,
  StyledOurTeamCardOuterGrid,
  StyledOurTeamCardSectionContainer,
  StyledOurTeamSectionDividerContainer,
} from "./index.style";
// Theme
import theme from "assets/theme";

const MeetTheTeam = () => {
  const techData = meetTheTeam.filter((data) => data.tech === "True");
  const nonTechData = meetTheTeam.filter((data) => data.tech === "False");

  return (
    <StyledSectionBackgroundWrapper id="meet-the-team-wrapper" rotate="true">
      <StyledOurTeamCardSectionContainer id="meet-the-team-wrapper" component={"section"}>
        <StyledOurTeamCardOuterGrid>
          <Grid>
            <img alt="Almadelic Logo" src={"https://d3a0rs3velfrz.cloudfront.net/ourTeam/almadelic_logo_white.png"} width={"300px"} />
          </Grid>
          <StyledOurTeamCardInnerGrid>
            <StyledOurTeamCardCardContainerGrid container rowSpacing={3}>
              {nonTechData.map(({ image, name, title }, index) => {
                return <MeetTheTeamCard key={index} image={image} name={name} title={title} />;
              })}
            </StyledOurTeamCardCardContainerGrid>
          </StyledOurTeamCardInnerGrid>

          <StyledOurTeamSectionDividerContainer>
            {/* <CBDivider sections="meet-the-team" color={theme.palette.grey[400]} /> */}
          </StyledOurTeamSectionDividerContainer>

          <StyledOurTeamCardInnerGrid>
            <StyledOurTeamCardCardContainerGrid container rowSpacing={3}>
              {techData.map(({ image, name, title }, index) => {
                return <MeetTheTeamCard key={index} image={image} name={name} title={title} />;
              })}
            </StyledOurTeamCardCardContainerGrid>
          </StyledOurTeamCardInnerGrid>
        </StyledOurTeamCardOuterGrid>
      </StyledOurTeamCardSectionContainer>
    </StyledSectionBackgroundWrapper>
  );
};

export default MeetTheTeam;
