import React, { Fragment } from "react";
import { Stack } from "@mui/material";
import theme from "assets/theme";
import MKTypography from "components/MKTypography";
const OrderSummary = ({ discount, originalSubtotal, cartTotal, textColor }) => {
  return (
    <Fragment>
      <Stack direction={"row"} justifyContent={"space-between"} alignItems="center" sx={{ marginBottom: "1rem" }}>
        <MKTypography
          component="p"
          variant="h6"
          sx={{ fontSize: "16px !important", color: textColor === "accent" && `${theme.palette.accent.main} !important` }}
        >
          Subtotal
        </MKTypography>
        <MKTypography sx={{ color: textColor === "accent" && `${theme.palette.white.main} !important` }} component="small" variant="body2">
          ${(originalSubtotal / 100).toFixed(2)}
        </MKTypography>
      </Stack>
      {discount !== null && discount !== 0 && (
        <Stack direction={"row"} justifyContent={"space-between"} alignItems="center" sx={{ marginBottom: "1rem" }}>
          <MKTypography
            component="p"
            variant="h6"
            sx={{ fontSize: "16px !important", color: textColor === "accent" && `${theme.palette.accent.main} !important` }}
          >
            Discount
          </MKTypography>
          <MKTypography component="small" variant="body2" sx={{ color: theme.palette.success.main }}>
            -${(discount / 100)?.toFixed(2)}
          </MKTypography>
        </Stack>
      )}

      <Stack direction={"row"} justifyContent={"space-between"} alignItems="center" sx={{ marginBottom: "1rem" }}>
        <MKTypography component="p" variant="h6" sx={{ color: textColor === "accent" && `${theme.palette.accent.main} !important` }}>
          Total
        </MKTypography>
        <MKTypography sx={{ color: textColor === "accent" && `${theme.palette.white.main} !important` }} component="small" variant="body2">
          ${(cartTotal / 100).toFixed(2)}
        </MKTypography>
      </Stack>
    </Fragment>
  );
};

export default OrderSummary;
