import React from "react";
// components
import MKTypography from "components/MKTypography";
import { Grid } from "@mui/material";
// styled components
import { StyledRefundHeaderSectionContainer } from "./index.style";
import { StyledSectionBackgroundWrapper } from "index.styles";
// theme
import theme from "assets/theme";

const RefundHeader = () => {
  return (
    <StyledSectionBackgroundWrapper>
      <StyledRefundHeaderSectionContainer id={"refund-header-section"} component={"section"}>
        <Grid sx={{ display: "flex", justifyContent: "center" }}>
          <MKTypography
            component={"h1"}
            variant={"h1"}
            sx={{
              color: `${theme.palette.white.main} !important`,
            }}
          >
            Cancellation and <span style={{ color: theme.palette.accent.main }}>Refund Policy</span>
          </MKTypography>
        </Grid>
      </StyledRefundHeaderSectionContainer>
    </StyledSectionBackgroundWrapper>
  );
};

export default RefundHeader;
