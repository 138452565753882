import styled from "@emotion/styled";
import { Container } from "@mui/material";

export const StyledTextContainer = styled(Container)(({ theme }) => ({
  minHeight: "285px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
}));
