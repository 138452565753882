import { Card } from "@mui/material";
import { styled } from "@mui/material/styles";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

export const StyledProfileCard = styled(Card)(({ theme, isBusinessListing }) => ({
  width: "100%",
  justifyContent: "space-between",
  maxWidth: "1100px",
  margin: "auto",
  backgroundColor: "#fff",
  overflow: "hidden",
  padding: "1rem",
  borderLeft: `6px solid ${theme.palette.accent.main}`,
  [theme.breakpoints.up("lg")]: {
    padding: "2rem",
  },
  cursor: !isBusinessListing && "pointer",
}));

export const StyledProfileCardImage = styled("img")(({ theme }) => ({
  borderRadius: "50%",
  minWidth: "125px",
  height: "125px",
  padding: ".3rem",
  objectFit: "cover",
  border: `2px solid ${theme.palette.accent.main}`,
  boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
}));

export const StyledEllipsisTypography = styled(MKTypography)(({ theme, webkitLineClamp, width }) => ({
  fontSize: "14px",
  overflow: "hidden",
  display: "-webkit-box",
  WebkitLineClamp: webkitLineClamp,
  WebkitBoxOrient: "vertical",
  textOverflow: "ellipsis",
  width: width ? width : "150px",
}));
