import styled from "@emotion/styled";
import { Box, Container, Grid } from "@mui/material";
export const StyledBlogResultSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));

export const StyledBlogResultInnerGrid = styled(Grid)(({ theme }) => ({
  display: "block",
  textDecoration: "none",
  transition: "all .2s ease-in-out",
  "&:hover": {
    transform: `translateY(-${theme.spacing(1 / 2)})`,
  },
}));

export const StyledBlogResultOuterCardBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundImage: "none",
  boxShadow: "0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);",
  [theme.breakpoints.up("md")]: {
    minHeight: "650px",
  },
  [theme.breakpoints.up("lg")]: {
    minHeight: "675px",
  },
}));
export const StyledBlogResultBottomOuterCardBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "1rem",
}));

export const StyledBlogResultBottomInnerCardBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
export const StyledBlogResultAvatarBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));
