import React from "react";
// Components
import MKTypography from "components/MKTypography";
// Styled Components
import { StyledSubmitJobApplicationSectionContainer } from "./index.styles";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// theme
import theme from "assets/theme";
const SubmitJobApplication = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledSubmitJobApplicationSectionContainer component={"section"} id="submit-job-application-section">
      <MKTypography component="p" variant={isLg ? "body1" : "body2"} textAlign="center">
        Don’t see your exact position but feel you have the skill set we need to grow and succeed?
      </MKTypography>
      <MKTypography component="p" variant={isLg ? "body1" : "body2"} textAlign="center">
        Submit your resume and cover letter to{" "}
        <a
          aria-label="Send an email to careers at almadelic dot com"
          href="mailto:careers@almadelic.com"
          style={{ textDecoration: "underline !important", color: `${theme.palette.secondary.main}` }}
        >
          careers@almadelic.com
        </a>
      </MKTypography>
    </StyledSubmitJobApplicationSectionContainer>
  );
};

export default SubmitJobApplication;
