import theme from "assets/theme";
import MKTypography from "components/MKTypography";
import React from "react";
import { StyledPageHeaderSectionContainer } from "./index.styles";
import PolicyHeader from "./components/policyHeader";

const PageHeader = ({ headerType, section, header, headerAccent, headerTagline, lightText, br, dateModified }) => {
  return (
    <StyledPageHeaderSectionContainer component="section" id={`${section}-page-header-section `}>
      {headerType === "policy" ? (
        <PolicyHeader section={section} header={header} headerAccent={headerAccent} dateModified={dateModified} />
      ) : (
        <>
          <MKTypography variant={"h1"} sx={{ color: lightText ? "#fff !important" : `${theme.palette.primary.main} !important` }} gutterBottom>
            {header}
            {br && <br />}
            <span style={{ color: theme.palette.accent.main }}> {headerAccent}</span> {""}
          </MKTypography>
          <MKTypography sx={{ maxWidth: "800px", margin: "auto" }}>
            <em>{headerTagline}</em>
          </MKTypography>
        </>
      )}
    </StyledPageHeaderSectionContainer>
  );
};

export default PageHeader;
