import { Grid, Link, Stack } from "@mui/material";
import React, { useContext, useState } from "react";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import theme from "assets/theme";
import { CartContext } from "utils/context/cart.context";
import EllipsisText from "components/CBEllipsisText";
import useMediaQueries from "utils/mediaQueries.utils";
import { useNavigate } from "react-router-dom";

const CartItem = ({ cartItems }) => {
  const { removeItemFromCart } = useContext(CartContext);
  const [expandedItems, setExpandedItems] = useState([]);
  const navigate = useNavigate();
  const { isSm, isLg, isSmUp } = useMediaQueries();

  const handleRemoveProduct = (index, e) => {
    e.stopPropagation();
    removeItemFromCart(cartItems[index]);
  };

  const handleReadMoreClick = (index, e) => {
    e.stopPropagation();
    setExpandedItems((prev) => {
      const newExpandedItems = [...prev];
      newExpandedItems[index] = !newExpandedItems[index];
      return newExpandedItems;
    });
  };

  const handleNavigateTo = (e) => {
    navigate(`/product?productId=${e.currentTarget.id}`);
  };

  return (
    <Grid item xs={12} lg={8}>
      {cartItems.map(({ id, name, price, images, metadata }, index) => (
        <MKBox
          onClick={handleNavigateTo}
          id={id}
          key={id}
          sx={{
            border: `1px solid ${theme.palette.grey[400]}`,
            display: "flex",
            gap: "1rem",
            padding: "1rem",
            marginBottom: "1rem",
            flexDirection: isSmUp ? "row" : "column",
            cursor: "pointer",
          }}
        >
          <MKBox
            component="img"
            src={images && images[0]}
            sx={{
              maxWidth: isLg ? "180px" : isSmUp ? "190px" : "200px",
              maxHeight: isLg ? "180px" : isSmUp ? "190px" : "200px",
              margin: isSm && "auto",
            }}
          ></MKBox>
          <Stack>
            <MKTypography component="h2" variant="h4" gutterBottom>
              {name}
            </MKTypography>
            <MKTypography component="p" variant="body2" gutterBottom>
              ${(price / 100).toFixed(2)} / Month
            </MKTypography>
            <EllipsisText
              text={metadata.long_description}
              product={"true"}
              onReadMore={(e) => handleReadMoreClick(index, e)}
              isExpanded={expandedItems[index]}
            ></EllipsisText>
            <Link
              onClick={(e) => handleRemoveProduct(index, e)}
              sx={{ fontSize: "14px", marginTop: "16px", textDecoration: "underline", maxWidth: "100px", color: theme.palette.accent.main }}
            >
              Remove Item
            </Link>
          </Stack>
        </MKBox>
      ))}
    </Grid>
  );
};

export default CartItem;
