export const WhatSetsUsApartData = [
  {
    title: "Safety First",
    content:
      "Your well-being is our top priority. We support you in creating a safe environment for each treatment. Our commitment to safety is of the utmost importance so that you can explore your inner landscape with confidence.",
  },
  {
    title: "Expert Guidance",
    content:
      "Our team of experienced certified guides and medical professionals are well-versed in the safe and responsible use of ketamine for therapeutic purposes. We serve as your knowledgeable and compassionate guides, ensuring you feel comfortable and prepared every step of the way.",
  },
  {
    title: "Personalized Approach",
    content:
      "We recognize that every individual's journey is unique. Before your therapy begins, we take the time to understand your specific needs, concerns, and goals. Your treatment plan can be tailored by you and your clinician, ensuring that your experience is both meaningful and effective.",
  },
  {
    title: "Emotional Support",
    content:
      "The psychedelic experience can be deeply introspective and emotionally profound. Our guides are skilled in providing emotional support during your journey, helping you process insights and emotions that arise, and facilitating a therapeutic dialogue that promotes healing.",
  },
  {
    title: "Integration",
    content:
      "We don't just guide you during your psychedelic ketamine sessions; we're here to support your integration afterward. We help you translate the insights gained during your journey into practical, positive changes in your daily life. Your journey with us extends beyond the session room.",
  },
  {
    title: "Education",
    content:
      "We believe that knowledge empowers. We provide comprehensive educational resources about ketamine therapy, the science behind it, and what you can expect during your sessions. We ensure you have all the information you need to make informed decisions.",
  },
];
