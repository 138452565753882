export const formatPhoneNumber = (phoneNumber) => {
  let cleaned = ("" + phoneNumber).replace(/\D/g, "");

  if (cleaned.startsWith("1") && cleaned.length === 11) {
    cleaned = cleaned.substring(1);
  }

  if (cleaned.length !== 10) {
    return "Invalid number";
  }

  const part1 = cleaned.slice(0, 3);
  const part2 = cleaned.slice(3, 6);
  const part3 = cleaned.slice(6);

  return `(${part1}) ${part2}-${part3}`;
};
