import styled from "@emotion/styled";
import { Container, Stack } from "@mui/material";

export const StyledFormContainer = styled(Container)(({ theme }) => ({
    padding: "1rem !important",
    margin: "0rem",
    fontWeight: "bold",
    backgroundColor: theme.palette.grey[200],
    borderRadius: "1rem",
}));

export const StyledInputContainer = styled(Container)(({ theme }) => ({
    marginTop: "1rem",
}));