// Major
import React from "react";
// Styled Components
import { StyledMainPageContainer } from "index.styles";
// Components
import SchemaComponent from "components/CBSchema";
import PageHeader from "components/CBPageHeader";
import SEO from "components/CBSEO";
import PolicyContent from "components/CBPolicyContent";
// Data
import seoData from "./seo.data.json";
import termsSchemaData from "./schema.organization.json";
import termsOfUseData from "./terms.data.json";
// Utils
import { constructSchemaData } from "utils/helpers/constructSchemaData";

const Term = () => {
  const schemaData = constructSchemaData(termsSchemaData.url, termsSchemaData.breadcrumbs);

  return (
    <StyledMainPageContainer id="terms-and-conditions-page">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <PageHeader
        headerType="policy"
        section="terms-of-use-agreement-policy"
        header="Terms Of"
        headerAccent="Use Agreement"
        dateModified="12 Jan, 2024"
      />
      <PolicyContent data={termsOfUseData} section={"terms-of-use-agreement-policy"} />
    </StyledMainPageContainer>
  );
};

export default Term;
