import styled from "@emotion/styled";
import { List, ListItem } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

export const StyledIdealSpaceListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  marginBottom: "1rem",
}));

export const StyledIdealSpaceList = styled(List)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  // gap: "1rem",
}));
export const StyledIdealSpaceListTypography = styled(MKTypography)(
  ({ theme }) => ({
    // color: `${theme.palette.white.main} !important`,
  })
);

export const StyledIdealSpaceListMKBox = styled(MKBox)(({ theme }) => ({
  marginTop: ".5rem",
}));
