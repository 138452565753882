import styled from "@emotion/styled";
import { Container } from "@mui/material";
import MKBox from "components/MKBox";

export const StyledPrecautionsSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));

export const StyledCardsContainer = styled(MKBox)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  [theme.breakpoints.up("lg")]: {
    flexDirection: "row",
    gap: "2rem",
  },
}));

export const StyledPrecautionsCardImage = styled("img")(({ theme }) => ({
  width: "55px",
  height: "55px",
  [theme.breakpoints.up("lg")]: {
    width: "85px",
    height: "85px",
  },
}));
