import { styled } from "@mui/material/styles";
import MKTypography from "components/MKTypography";

export const StyledEllipsisTypography = styled(MKTypography)(({ theme, webkitLineClamp, width, productDetails }) => ({
  fontSize: productDetails ? "16px" : "14px",
  overflow: "hidden",
  display: "-webkit-box",
  WebkitLineClamp: webkitLineClamp,
  WebkitBoxOrient: "vertical",
  textOverflow: "ellipsis",
  width: width ? width : "150px",
}));
