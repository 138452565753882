import React from "react";
// Components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKTypography from "components/MKTypography";
import CardContent from "@mui/material/CardContent";
// Styled Components
import {
  StyledCareersJobSectionContainer,
  StyledCareersJobOuterCardBox,
  StyledCareersJobOuterCardGrid,
  StyledCareersJobInnerCardGrid,
} from "./index.style";
// Theme
import theme from "assets/theme";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Data
import jobsData from "./jobs.data.json";

const Jobs = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledCareersJobSectionContainer component={"section"} id={"careers-job-section"}>
      <Box marginBottom={4}>
        <MKTypography variant={"h2"} gutterBottom align={"center"}>
          Current <span style={{ color: theme.palette.accent.main }}>Open Positions</span>
        </MKTypography>
      </Box>
      <Grid container spacing={2}>
        {jobsData.map((item, i) => (
          <Grid item xs={12} key={i} sx={{ paddingTop: "30px" }}>
            <StyledCareersJobOuterCardBox component={Card} variant={"outlined"} bgColor={"transparent"}>
              <StyledCareersJobOuterCardGrid component={CardContent}>
                <StyledCareersJobInnerCardGrid>
                  <MKTypography variant={"h6"} component={"h3"}>
                    {item.title}
                  </MKTypography>
                  <MKTypography variant={isLg ? "body1" : "body2"} color={"text.secondary"} textAlign={"right"}>
                    {`${item.location} / ${item.type}`}
                  </MKTypography>
                </StyledCareersJobInnerCardGrid>
              </StyledCareersJobOuterCardGrid>
            </StyledCareersJobOuterCardBox>
          </Grid>
        ))}
      </Grid>
    </StyledCareersJobSectionContainer>
  );
};

export default Jobs;
