import styled from "@emotion/styled";
import { Container } from "@mui/material";

export const StyledProfileCardsSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  display: "flex",
  flexWrap: "wrap",
  gap: "2rem",

  justifyContent: "center",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));

export const StyledPaginationSectionContainer = styled(Container)(({ theme }) => ({
  paddingBottom: "4rem",

  justifyContent: "center",
  [theme.breakpoints.up("lg")]: {
    paddingBottom: "6rem",
  },
}));
