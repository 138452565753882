// export const findCustomFieldValue = (name, customFields) => {
//   let field;
//   if (customFields) {
//     field = customFields.find((field) => field.name === name);
//   }
//   return field ? field.value : null;
// };
export const findCustomFieldValue = (name, customFields) => {
  if (Array.isArray(customFields)) {
    const field = customFields.find((field) => field.name === name);
    return field ? field.value : null;
  }
  return null;
};
