import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

export const StyledRefundHeaderSectionContainer = styled(Container)(
  ({ theme }) => ({
    paddingBlock: "4rem",
    [theme.breakpoints.up("lg")]: {
      paddingBlock: "6rem",
    },
  })
);
