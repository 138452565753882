import React from "react";
import { Link } from "react-router-dom";
import { Grid, List, ListItem, Stack } from "@mui/material";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import CBBulletPoint from "components/CBBulletPoint";
import { StyledButtonContainer } from "index.styles";
import { StyledServicesSectionContainer } from "./index.styles";
import useMediaQueries from "utils/mediaQueries.utils";
import theme from "assets/theme";
import ourServicesBulletPointData from "./ourServicesBulletPoint.data.json";
import { StyledImage } from "index.styles";

const OurServices = () => {
  const { isLg } = useMediaQueries();

  const RightSide = () => {
    return (
      <Stack direction="row" alignContent={"center"} spacing={isLg ? 2 : 0} justifyContent={"space-between"}>
        <StyledImage
          ourservices={"true"}
          src={"https://d3a0rs3velfrz.cloudfront.net/home/services/services3.webp"}
          alt="Man at a table using his laptop to view Almadelic services."
          width="238"
          height="355"
        ></StyledImage>
        <StyledImage
          ourservices={"true"}
          src={"https://d3a0rs3velfrz.cloudfront.net/home/services/servicesImg1.webp"}
          alt="Woman at a table using her laptop to view Almadelic services."
          width="238"
          height="355"
        ></StyledImage>
      </Stack>
    );
  };
  const LeftSide = () => (
    <Grid container>
      <Grid item xs={12}>
        <MKTypography gutterBottom variant="h2" component="h2">
          Explore Our <span style={{ color: theme.palette.accent.main }}>Services</span>
        </MKTypography>
        <MKTypography component="p" variant={isLg ? "body1" : "body2"} gutterBottom>
          Whether you're seeking relief from depression, anxiety, or a desire for personal growth, our team is here to guide you every step of the
          way.
        </MKTypography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          {ourServicesBulletPointData.map((item, i) => (
            <Grid item xs={12} key={i}>
              <List>
                <MKBox component={ListItem} disableGutters width={"auto"} padding={0}>
                  <CBBulletPoint />
                  <MKTypography component="p" variant={"body2"}>
                    {item}
                  </MKTypography>
                </MKBox>
              </List>
            </Grid>
          ))}
        </Grid>
        <StyledButtonContainer>
          <MKButton
            aria-label="Learn more about our services"
            component={Link}
            to={"/our-program"}
            variant="contained"
            color="secondary"
            size="large"
          >
            Learn more about our Services
          </MKButton>
        </StyledButtonContainer>
      </Grid>
    </Grid>
  );

  return (
    <MKBox id="our-services-wrapper">
      <StyledServicesSectionContainer component="section" id="our-services-section">
        <Grid container spacing={4}>
          {isLg ? (
            <>
              <Grid item xs={12} lg={6} justifyContent={"space-between"}>
                <LeftSide />
              </Grid>
              <Grid item sx={{ display: "Flex" }} alignItems={"center"} justifyContent={"center"} xs={12} lg={6}>
                <RightSide />
              </Grid>
            </>
          ) : (
            <>
              <Grid item sx={{ display: "Flex" }} alignItems={"center"} justifyContent={"center"} xs={12} lg={6}>
                <RightSide />
              </Grid>
              <Grid item xs={12} lg={6}>
                <LeftSide />
              </Grid>
            </>
          )}
        </Grid>
      </StyledServicesSectionContainer>
    </MKBox>
  );
};

export default OurServices;
