export const CourseCardsData = [
  {
    title: "Psychedelic Harm Reduction",
    content:
      "This is a FREE crash course on psychedelic harm reduction, how to prepare for the most meaningful psychedelic experience possible, and how to troubleshoot when things don’t go as planned. Whether you’ll be around psychedelics in recreational, ceremonial, or clinical settings, our introduction to tripping and trip-sitting is for you.",
  },

  {
    title: "Psilocybin & SSRIs",
    content: `SSRIs (selective serotonin reuptake inhibitors) are commonly-prescribed to treat depression. Because SSRIs and psilocybin both work on serotonin receptors, patients are often instructed to wean off their SSRIs before beginning psilocybin-assisted therapy. This interruption in medication therapy is considered standard practice, but is it truly necessary?

  FREE course.`,
  },

  {
    title: "MDMA for PTSD (and Beyond)",
    content: `After reviewing the history of this unique molecule, we will dissect its pharmacology to understand just why it pairs so well with counseling. The results of recent studies of MDMA-assisted psychotherapy in the treatment of post-traumatic stress disorder (PTSD) will be presented, along with a video clip from an actual session recorded during one of the studies.
    MDMA’s side effect profile will be reviewed, along with its drug-drug interactions and other notes on safety.`,
  },

  {
    title: "Microdosing Psychedelics - What We Know So Far",
    content: `What happens when we take extremely diluted doses of LSD and/or psilocybin? Everything – or nothing, depending on who you ask.
  Peppered with real cases of people who have tried microdosing, this module outlines the dosage protocols, critiques the studies currently available on this new way of taking psychedelics, and summarizes their findings.
  The proposed mechanisms of action of microdosing will be explained, along with safety considerations and drug-drug interactions.
  (This course was updated, improved, and overhauled April 2023.)
  CE (continuing education) credit is available.`,
  },

  {
    title: "Psilocybin, LSD, & the Classic Psychedelics: Mechanisms of Action",
    content: `In this module we examine the pharmacology of the “classic psychedelics,” namely psilocybin (the active constituent in “magic mushrooms”) and LSD-25 (also known as “acid”).
    The effects of these medicines on brain function, chemistry, and neuronal firing will be reviewed, and the concepts of the default mode network (DMN), brain entropy, and child consciousness will be introduced.
    We will also take a closer look at the role of mystical experiences, and what spirituality has to do with psychological and physiological healing.`,
  },

  {
    title: "Polyvagal Theory & Using the Body to Heal the Mind",
    content: `What you think and feel isn’t just “in your head” – it’s influenced by tangible structures and observable chemicals coursing through your physical body. After explaining the “wiring” of the central nervous system, Dr. Zelfand will introduce concepts like neuroception and the polyvagal theory. She also offers simple, do-it-yourself hacks for stimulating the vagus nerve and explains the gut-brain axis.
  
  CE available`,
  },
  {
    title: "The Psy Science Course Bundle",
    content: `This course include all of the below modules bundled together – plus a bonus experiential meditation – for a discounted price.
  Created for healthcare providers and empowered individuals alike, this 8-hour psychedelic webinar series is rooted in evidence-based medicine and first-hand experiences in psychedelic facilitation.
  We explain the scientific mechanisms of medicines like psilocybin, ayahuasca, MDMA, and ketamine in the treatment of depression, addiction, PTSD, and other conditions, and explore when these medicines are best indicated.
  
  CE (continuing education) & CME (medical) credit available.
  `,
  },
  {
    title: "Ketamine for Depression & Mood Disorders",
    content: `How on earth did ketamine, an anesthesia drug, come to be used for psychiatric purposes? We will review the history of this unique molecule, examine its known mechanisms of action, and explore the theories of how and why it treats depression.
  After juxtaposing SSRIs with ketamine, we will learn about the several different paradigms for administering the drug, from small microdoses to large psychedelic doses and everything in between.`,
  },
  {
    title: "The Psychedelic Treatment of Addiction",
    content: `We will examine the use of psychedelic medicines in the treatment of alcohol use disorder (alcoholism), smoking, and other substance use disorders (including crack, methamphetamine, cocaine, and opiates).
    Detail will be offered on psilocybin, LSD, ketamine, ayahuasca, and ibogaine. The safety, risks, and contraindications for these medicines will also be reviewed, and basic psychedelic “troubleshooting” tips will be offered.`,
  },
  {
    title: "The Foundations of Psychedelic Counseling",
    content: `Supporting somebody on psychedelics is quite different than facilitating a regular therapy session.
    Tips for handling common situations during psychedelic sessions will be offered, along with suggestions on how to maximize the potential for healing and minimize the risk of a “bad trip.”
    Guidelines for designing a healing space, using directive and non-directive interventions, and supporting clients in difficult moments will be explained.
    We will consider the importance of integration work and offer recommendations for how often people should take psychedelics to achieve their health goals.
    CE (continuing education) credit available.`,
  },
];
