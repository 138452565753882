import { Container, Divider } from "@mui/material";
import theme from "assets/theme";
import React from "react";

const CBDivider = ({ sections, marginZero, color, isProfile }) => {
  return (
    <Container
      id={`${sections}-section-divider`}
      sx={{ paddingInline: "0 !important", maxWidth: isProfile && "none !important", margin: "none !important" }}
    >
      <Divider
        sx={{
          width: "100% !important",
          height: "3px",
          backgroundColor: color ? color : theme.palette.grey[400],
          margin: marginZero && 0,
        }}
      />
    </Container>
  );
};

export default CBDivider;
