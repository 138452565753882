import styled from "@emotion/styled";
import { Box, Container, Grid } from "@mui/material";
export const StyledCareersJobSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));

export const StyledCareersJobOuterCardBox = styled(Box)(({ theme }) => ({}));

export const StyledCareersJobOuterCardGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  paddingBottom: "16px !important",
  padding: "16px",

  [theme.breakpoints.up("sm")]: {
    padding: "24px",
  },
}));

export const StyledCareersJobInnerCardGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: "1 1 100%",

  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
