import React from "react";
import { StyledEmptyCartSectionContainer } from "./index.styles";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import useMediaQueries from "utils/mediaQueries.utils";
import { Stack } from "@mui/material";
import { StyledButtonContainer } from "index.styles";
import theme from "assets/theme";
import { Link } from "react-router-dom";

const EmptyCart = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledEmptyCartSectionContainer>
      <MKBox
        component="img"
        alt="https://www.freepik.com/free-vector/supermarket-shopping-cart-concept-illustration_82642488.htm#query=empty%20cart&position=0&from_view=keyword&track=ais_user&uuid=563f070e-a593-40bb-8578-30160fb72c4c - image by storyset"
        src="https://d3a0rs3velfrz.cloudfront.net/cart/9960436.jpg"
        sx={{ maxWidth: "300px" }}
      ></MKBox>
      <Stack justifyContent={"center"} textAlign={!isLg && "center"}>
        <MKTypography component="h1" variant="h1" gutterBottom>
          Your Cart Is
          <span style={{ color: theme.palette.accent.main }}> Currently Empty!</span>
        </MKTypography>
        <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
          Looks like you have not added anything to your cart. {isLg && <br></br>}Check out our packages below to get started!
        </MKTypography>
        <StyledButtonContainer>
          <MKButton component={Link} to="/shop" color="secondary">
            View Our Packages
          </MKButton>
        </StyledButtonContainer>
      </Stack>
    </StyledEmptyCartSectionContainer>
  );
};

/* <a href="https://www.freepik.com/free-vector/supermarket-shopping-cart-concept-illustration_82642488.htm#query=empty%20cart&position=0&from_view=keyword&track=ais_user&uuid=563f070e-a593-40bb-8578-30160fb72c4c">Image by storyset</a> on Freepik */

export default EmptyCart;
