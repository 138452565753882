import { Grid, Link } from "@mui/material";
import theme from "assets/theme";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { StyledButtonContainer } from "index.styles";
import React from "react";
import useMediaQueries from "utils/mediaQueries.utils";

const TwoColumnGrid = ({ src, title, titleAccent, content, cta, ctaUrl, imgHeight, titleVariant, titleComponent }) => {
  const { isLg } = useMediaQueries();
  return (
    <Grid container columnSpacing={isLg ? 6 : 3} rowSpacing={isLg ? 0 : 6} alignItems={"center"}>
      <Grid item xs={12} lg={8}>
        <MKTypography component={titleComponent ? titleComponent : "h1"} variant={titleVariant ? titleVariant : "h1"} gutterBottom>
          <span style={{ color: theme.palette.accent.main }}>{titleAccent}</span> {title}
        </MKTypography>
        {content.map((content, index) => (
          <MKTypography key={index} component="p" variant={"body2"} gutterBottom>
            {content}
          </MKTypography>
        ))}
        {cta && (
          <StyledButtonContainer>
            <MKButton component={Link} href={ctaUrl} color="secondary">
              {cta}
            </MKButton>
          </StyledButtonContainer>
        )}
      </Grid>
      <Grid item xs={12} lg={4}>
        <MKBox component="img" src={src} sx={{ width: "100%", height: imgHeight ? imgHeight : "100%" }}></MKBox>
      </Grid>
    </Grid>
  );
};

export default TwoColumnGrid;
