import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
export const StyledContactUsHeroSectionContainer = styled(Container)(
  ({ theme }) => ({
    paddingBlock: "4rem",
    [theme.breakpoints.up("lg")]: {
      paddingBlock: "6rem",
    },
  })
);

export const StyledContactUsHeroOuterGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

export const StyledContactUsHeroImageOuterGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
