import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import React from "react";
import { StyledImportanceOfSettingSectionContainer } from "./index.style";
import theme from "assets/theme";
import useMediaQueries from "utils/mediaQueries.utils";
import MKBox from "components/MKBox";
import { StyledSectionBackgroundWrapper } from "index.styles";
import { StyledImage } from "index.styles";

export default function ImportanceOfSetting() {
  const { isMd, isLg } = useMediaQueries();
  return (
    <StyledSectionBackgroundWrapper id={"importance-of-setting-section-wrapper"}>
      <StyledImportanceOfSettingSectionContainer component={"section"} id={"importance-of-setting-section"}>
        <Grid container columnSpacing={isLg ? 6 : 3} rowSpacing={isLg ? 0 : 6} alignItems={"center"}>
          <Grid item xs={12} lg={6}>
            <StyledImage
              large={true}
              height="500"
              width="636"
              src={"https://d3a0rs3velfrz.cloudfront.net/creatingYourSpace/creating-your-space-1.webp"}
              alt="A cozy and well-lit living room featuring a mid-century modern aesthetic."
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox>
              <MKTypography variant="h1" gutterBottom sx={{ color: `${theme.palette.accent.main} !important` }}>
                <span style={{ color: `${theme.palette.white.main}` }}>Creating </span>Your Space
              </MKTypography>
              <MKTypography variant={isMd ? "body1" : "body2"} gutterBottom sx={{ color: theme.palette.white.main }}>
                The landscape of ketamine therapy has broadened with emerging research indicating the safety of supervised ketamine use outside
                clinical settings.
              </MKTypography>
              <MKTypography variant={isMd ? "body1" : "body2"} gutterBottom sx={{ color: theme.palette.white.main }}>
                Some studies even suggest that a home environment might be more conducive to ketamine therapy. Consequently, at-home ketamine
                treatments have become a viable option, offering a safe and comfortable setting that can positively impact outcomes. Despite the
                inherent comfort of one's home, there are ways to enhance the environment for an optimal ketamine therapy experience.
              </MKTypography>
            </MKBox>
          </Grid>
        </Grid>
      </StyledImportanceOfSettingSectionContainer>
    </StyledSectionBackgroundWrapper>
  );
}
