import React from "react";
// Components
import Header from "./components/Header";
import MeetTheTeam from "./components/MeetTheTeam";
import SchemaComponent from "components/CBSchema";
import SEO from "components/CBSEO";
// Styles
import { StyledMainPageContainer } from "index.styles";
// Utils
import { constructSchemaData } from "utils/helpers/constructSchemaData";
// Data
import ourTeamSchemaData from "./schema.organization.json";
import seoData from "./seo.data.json";

const OurTeam = () => {
  const schemaData = constructSchemaData(ourTeamSchemaData.url, ourTeamSchemaData.breadcrumbs);
  return (
    <StyledMainPageContainer id="our-team-page-container">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />

      <Header />
      <MeetTheTeam />
    </StyledMainPageContainer>
  );
};

export default OurTeam;
