// Major
import React, {useEffect, useState, useContext} from "react";
import { useParams } from 'react-router-dom';

// Components
import Header from "./components/Header";
import AboutMe from "./components/AboutMe";
import TherapyServices from "./components/TherapyServices";
import ProfessionalInfo from "./components/ProfessionalInfo";
import CostAndInsurance from "./components/CostAndInsurance";
import MetaTags from "./components/MetaTags";
import therapistSchemaData from "./schema.therapistBio.json";
import SchemaComponent from "components/CBSchema";
import CBDivider from "components/CBDivider";
import MKTypography from "components/MKTypography";
import SEO from "components/CBSEO";


// Utils
import { ProfileModalContext } from "utils/context/profileModal.context";
import { constructSchemaData } from "utils/helpers/constructSchemaData";
import  useFetchTherapistByID  from "utils/hooks/useFetchTherapistByID";

// Styles
import theme from "assets/theme";
import { StyledBioBodyContainer  } from "./index.styles";
import  CBLoading  from "components/CBLoading";
import { Container } from "@mui/material";
import { StyledMainPageContainer } from "index.styles";


const TherapistBio = () => {
  const { therapistID } = useParams();
  const [userData, setUserData] = useState(null);
  const [storedContactState, setStoredContactState] = useState(false);
  const { currentUserData } = useContext(ProfileModalContext);
  const {data: therapistBio, isLoading, isError, error} = useFetchTherapistByID(therapistID)
  const schemaData = userData ? constructSchemaData(therapistSchemaData.url, therapistSchemaData.breadcrumbs, null, userData) : constructSchemaData(therapistSchemaData.url, therapistSchemaData.breadcrumbs);
  
  

  // setting user data states
  useEffect(() => {
    if(therapistID === currentUserData?.id) {
      setStoredContactState(true);
      setUserData(currentUserData);
    } else if (therapistBio) {
      setUserData(therapistBio.contact);
    }
  }, [therapistBio, currentUserData]);


  const DividerBody = () => {
    return (
      <Container sx={{ padding: "0 !important", margin: "2rem 0rem !important"}}>
        <CBDivider isProfile={true} color={theme.palette.accent.main} /> 
      </Container>
    )
  }
  
  // main page body
  const PageBody = () => {
    return(
      <>
        <MetaTags currentUserData={userData} />
        <SEO title={userData?.name} description={`Learn more about ${userData?.name} on their Bio page Here!`}  />
        <SchemaComponent schemas={schemaData} />
        <Header currentUserData={userData} />
        <StyledBioBodyContainer>
            <AboutMe currentUserData={userData} />
              <DividerBody />
            <TherapyServices currentUserData={userData} />
              <DividerBody />
            <ProfessionalInfo currentUserData={userData} />
              <DividerBody />
            <CostAndInsurance currentUserData={userData} />
        </StyledBioBodyContainer>
      </>
    )
  }


  
  return (
    <StyledMainPageContainer id="therapist-bio-main">
      
      {storedContactState ? (
        <PageBody />
      ) : isLoading ? (
        <Container sx={{ marginBlock: "4rem" }}>
          <CBLoading loadingItem={"therapist profile"} />
        </Container>
      ) : isError ? (
        <Container sx={{ marginBlock: "4rem" }}>
          <MKTypography component="h3" variant="h3" sx={{ color: `${theme.palette.error.main} !important`, textAlign: "center" }}>
            There was an error fetching the data: {error?.message}. Please try again later
          </MKTypography>
        </Container>
      ) : (
        <PageBody />
      )}
    </StyledMainPageContainer>
  );
}

export default TherapistBio;