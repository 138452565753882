import styled from "@emotion/styled";
import { Container } from "@mui/material";
import MKTypography from "components/MKTypography";

export const StyledIdealSpaceSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",

  [theme.breakpoints.up("md")]: {
    minHeight: "625px",
  },
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
    minHeight: "650px",
    alignItems: "center",
  },
  [theme.breakpoints.up("xl")]: {
    minHeight: "650px",
  },
  [theme.breakpoints.up("xxl")]: {
    minHeight: "850px",
  },
  flexWrap: "wrap",
}));
export const StyledIdealSpaceHeader = styled(MKTypography)(({ theme }) => ({
  borderBottom: `3px solid ${theme.palette.accent.main}`,
  marginBottom: "2rem",
  paddingBottom: "1.5rem",
}));
export const StyledIdealSpaceGrid = styled(MKTypography)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));
