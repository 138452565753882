// Major
import React, {useEffect, useState} from "react";
import { Helmet } from "react-helmet";

// Data
import tagData from "./OGTags.data.json";


// This component is used to set the Open Graph tags for each page
const TAMetaOGTags = ({pathname}) => {

    
    // Default Tag Info if pathname doesnt match
    const defaultTag = {
        title: "Almadelic",
        description: "Explore the transformative possibilities of psychedelic therapies as your pathway to renewed mental vitality.",
        type: "website",
        image: "https://msgsndr-private.storage.googleapis.com/calendar-widget-logo/26bb979f-32a9-4ec6-948c-d4cb9b9cc62f.png"
    }
    const [tagInfo, setTagInfo] = useState(defaultTag);
    

    // Finding correct tag data based on pathname
    useEffect(() => {
        const tag = tagData.find((tag) => tag.path === pathname);
        tag ? setTagInfo(tag) : setTagInfo(defaultTag);
    }, [pathname]);
    


    // Returning Hemlet component with Open Graph tags
    return (
        <Helmet>
            <meta property="og:type" content={"website"} />
            <meta property="og:title" content={tagInfo.title} />
            <meta property="og:description" content={tagInfo.description} />
            <meta property="og:url" content={`https://www.almadelic.com${pathname}`} />
            <meta property="og:image" content={"https://d3a0rs3velfrz.cloudfront.net/metaTags/almadelic_logo_h.webp"} />
            <meta name="og:image:height" content={"600px"} />
            <meta name="og:image:width" content={"600px"} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={tagInfo.title} />
            <meta name="twitter:image" content={"https://d3a0rs3velfrz.cloudfront.net/metaTags/almadelic_logo_11.webp"} />
            <meta name="description" content={tagInfo.description} />
        </Helmet>
    )
}



export default TAMetaOGTags;