import styled from "@emotion/styled";
import { Container, ListItem } from "@mui/material";
import MKTypography from "components/MKTypography";

export const StyledPatientBenefitsSectionContainer = styled(Container)(
  ({ theme }) => ({
    paddingBlock: "4rem",
    [theme.breakpoints.up("lg")]: {
      paddingBlock: "6rem",
    },
  })
);

export const StyledPatientBenefitsHeader = styled(MKTypography)(
  ({ theme }) => ({
    borderBottom: `2px solid ${theme.palette.accent.main}`,
    paddingBottom: ".5rem",
    marginBottom: "2rem",
  })
);
export const StyledPatientBenefitsListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  borderBottom: `2px solid ${theme.palette.accent.main}`,
  paddingBottom: ".5rem",
  marginBottom: "2rem",
}));
