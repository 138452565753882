import * as React from "react";
import Modal from "@mui/material/Modal";
import { ProfileModalContext } from "utils/context/profileModal.context";
import theme from "assets/theme";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import { Stack, Container, List, ListItem, IconButton } from "@mui/material";
import { StyledProfileCardImage } from "../profileCard/index.styles";
import MKTypography from "components/MKTypography";
import CBDivider from "components/CBDivider";
// Icons
import LanguageIcon from "@mui/icons-material/Language";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import WorkIcon from "@mui/icons-material/Work";
import useMediaQueries from "utils/mediaQueries.utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import CloseIcon from "@mui/icons-material/Close";
import {
  StyledEllipsisTypographyLink,
  StyledProfileAccordion,
  StyledProfileAccordionDetails,
  StyledProfileAccordionSummary,
  StyledStackContainer,
} from "./index.styles";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { findCustomFieldValue } from "utils/helpers/findCustomFieldValue";
import { convertState } from "utils/helpers/stateConverter";
import CBBulletPoint from "components/CBBulletPoint";
import { formatPhoneNumber } from "utils/helpers/reformatPhone";
import { useEffect } from "react";
import { StyledVerticalDivider } from "styles/components";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  maxWidth: "900px",
  backgroundColor: "#fff",
  maxHeight: "90%",
  boxShadow: 4,
  p: 2,
  borderBottom: `10px solid ${theme.palette.accent.main}`,
  borderRadius: "12px",
  overflow: "scroll",
  [theme.breakpoints.up("lg")]: {
    width: "80%",
  },
};

export default function ProfileModal() {
  const { handleClose, open, currentUserData } = React.useContext(ProfileModalContext);
  const { isLg, isSmUp, isMd } = useMediaQueries();
  const [showMore, setShowMore] = React.useState(false);

  // Profile Data
  const { email, phone, state, city, name, customFields } = currentUserData || [];
  const websiteURL = findCustomFieldValue("Website", customFields);
  const licenses = findCustomFieldValue("Licenses", customFields);
  const profileURL = findCustomFieldValue("Profile URL", customFields);
  const longBio = findCustomFieldValue("Long Bio", customFields);
  const languages = findCustomFieldValue("Languages", customFields);
  const yearsPracticed = findCustomFieldValue("Years Practiced", customFields);
  const hourlyRate = findCustomFieldValue("Hourly Rate", customFields);
  const visitTypes = findCustomFieldValue("Visit Types", customFields);
  const insurance = findCustomFieldValue("Insurance", customFields);
  const linkedIn = findCustomFieldValue("LinkedIn", customFields);
  const licensedToPracticeIn = findCustomFieldValue("Licensed To Practice In", customFields);
  const treatmentMethods = findCustomFieldValue("Treatment Methods", customFields);
  const specialties = findCustomFieldValue("Specialties", customFields);
  const psychedelicsYouWorkWith = findCustomFieldValue("Psychedelics You Work With", customFields);
  const typesOfTherapy = findCustomFieldValue("Types Of Therapy", customFields);
  const handleEmailClick = () => {
    if (email) {
      window.location.href = `mailto:${email}`;
    }
  };
  const handlePhoneClick = () => {
    if (phone) {
      window.location.href = `tel:+${phone}`;
    }
  };

  // Read more for bio
  const handleReadMore = () => {
    setShowMore(!showMore);
  };

  const maxChars = 400;
  const isLongText = longBio?.length > maxChars;
  const displayText = showMore ? longBio : `${longBio?.slice(0, maxChars)}${isLongText ? "..." : ""}`;

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        handleClose();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [handleClose]);

  return (
    <Modal
      sx={{
        "& .css-919eu4": {
          backgroundColor: "rgba(255, 255, 255, .1) !important",
        },
        "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
          backgroundColor: "rgba(255, 255, 255, .1) !important",
          backdropFilter: "blur(1px)",
        },

        "& :focus-visible": {
          outline: "none !important",
        },
      }}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MKBox sx={style}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <MKBox component="img" src={`https://d3a0rs3velfrz.cloudfront.net/logos/mainLogo.svg`} sx={{ width: "200px", height: "auto" }}></MKBox>
          <IconButton onClick={handleClose} aria-label="close" size="large">
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Stack>
        <MKBox
          sx={{
            display: "flex",
            flexDirection: isSmUp ? "row" : "column",
            flexWrap: isMd ? "no-wrap" : "wrap",
            color: "#fff",
            gap: "2rem",
            alignItems: "center",
            padding: "2rem",
            borderRadius: "12px",
            position: "relative",
            overflow: "hidden",
            "&:before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "url(https://d3a0rs3velfrz.cloudfront.net/bg-gradient.svg) 0 0 repeat",
              zIndex: -1,
              transform: "rotate(180deg)",
              borderRadius: "12px",
            },
          }}
        >
          <StyledProfileCardImage
            component="img"
            src={profileURL || "https://d3a0rs3velfrz.cloudfront.net/logos/almadelicButterFlyTransparent.webp"}
            alt={`${name} profile image`}
          />
          <Stack sx={{ flexGrow: 1, maxWidth: isMd ? "50%" : "100%" }}>
            <MKTypography sx={{ color: `${theme.palette.light.main} !important` }} component="h3" variant="h6" gutterBottom>
              {name} &nbsp;
            </MKTypography>
            <MKTypography component="p" variant="body2" sx={{ color: `${theme.palette.light.main} !important` }} gutterBottom>
              {specialties?.join(", ")}
            </MKTypography>
            <Stack direction={"row"} alignItems={"center"} spacing={0.4}>
              <WorkIcon
                sx={{
                  // marginLeft: { sm: "1rem" },
                  width: { xs: "18px" },
                  height: { xs: "18px" },
                  color: theme.palette.accent.main,
                }}
              />
              <MKTypography component="p" variant="body2" sx={{ color: `${theme.palette.light.main} !important` }}>
                {yearsPracticed} Years Exp
              </MKTypography>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} spacing={0.4}>
              <FmdGoodIcon
                sx={{
                  width: { xs: "18px" },
                  height: { xs: "18px" },
                  color: theme.palette.accent.main,
                }}
              />
              <MKTypography component="p" variant="body2" sx={{ color: `${theme.palette.light.main} !important` }}>
                {city && `${city}, `}
                {convertState(state)}
              </MKTypography>
            </Stack>
            {websiteURL && (
              <Stack direction={"row"} alignItems={"center"} spacing={0.4}>
                <LanguageIcon style={{ width: "18px", height: "18px", fontSize: "18px", color: theme.palette.accent.main }} />
                <StyledEllipsisTypographyLink target="_blank" href={websiteURL} sx={{ fontSize: "14px", color: theme.palette.accent.main }}>
                  {websiteURL}
                </StyledEllipsisTypographyLink>
              </Stack>
            )}
            {linkedIn && (
              <Stack direction={"row"} alignItems={"center"} spacing={0.4}>
                <LinkedInIcon style={{ width: "18px", height: "18px", fontSize: "18px", color: theme.palette.accent.main }} />
                <StyledEllipsisTypographyLink target="_blank" href={linkedIn}>
                  {linkedIn}
                </StyledEllipsisTypographyLink>
              </Stack>
            )}
          </Stack>
          <Stack direction={isMd ? "column" : isSmUp ? "row" : "column"} spacing={2} sx={{ width: "100%", maxWidth: isLg ? "200px" : "100%" }}>
            <MKButton
              startIcon={<PhoneIcon />}
              onClick={handlePhoneClick}
              disabled={!phone}
              color="secondary"
              variant="outlined"
              sx={{ color: "#fff !important", width: isMd ? "100%" : isSmUp ? "50%" : "100%" }}
            >
              {formatPhoneNumber(phone) || "Not Available"}
            </MKButton>
            <MKButton
              startIcon={<EmailIcon />}
              onClick={handleEmailClick}
              disabled={!email}
              color="secondary"
              variant="outlined"
              sx={{ color: "#fff !important", width: isMd ? "100%" : isSmUp ? "50%" : "100%" }}
            >
              Email Me
            </MKButton>
          </Stack>
        </MKBox>
        <Container
          sx={{
            padding: "1rem 0 !important",
            maxWidth: "none !important",
            margin: "none !important",
          }}
        >
          <CBDivider isProfile={true} color={theme.palette.accent.main}></CBDivider>
        </Container>
        {/* About Me Section */}
        <MKBox>
          <MKTypography component="h4" variant="h5" gutterBottom>
            About Me
          </MKTypography>
          <MKTypography component="p" variant={"body2"}>
            <MKTypography component="p" variant={"body2"} gutterBottom>
              {displayText}
            </MKTypography>
            {longBio?.length > maxChars && (
              <MKButton color="secondary" size="small" sx={{ width: "120px" }} onClick={handleReadMore}>
                {showMore ? "Read Less" : "Read More"}
              </MKButton>
            )}
          </MKTypography>
        </MKBox>
        <Container
          sx={{
            padding: "1rem 0 !important",
            maxWidth: "none !important",
            margin: "none !important",
          }}
        >
          <CBDivider isProfile={true} color={theme.palette.accent.main}></CBDivider>
        </Container>
        {/* Professional Information */}
        <MKBox>
          <StyledProfileAccordion>
            <StyledProfileAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
              <MKTypography component="h4" variant="h5" gutterBottom>
                Professional Information
              </MKTypography>
            </StyledProfileAccordionSummary>
            <StyledProfileAccordionDetails>
              <Stack direction={isLg ? "row" : "column"} justifyContent={"flex-start"} sx={{ gap: "2rem" }}>
                {licensedToPracticeIn && (
                  <>
                    <StyledStackContainer>
                      <MKTypography component="h5" variant="h6" fontWeight="light">
                        Licensed States
                      </MKTypography>
                      <List>
                        {licensedToPracticeIn?.map((item, index) => (
                          <ListItem key={index} sx={{ alignItems: "center" }}>
                            <MKBox>
                              <CBBulletPoint small={true} />
                            </MKBox>
                            <MKTypography key={index} component="p" variant={"body2"}>
                              {item}
                            </MKTypography>
                          </ListItem>
                        ))}
                      </List>
                    </StyledStackContainer>
                    <StyledVerticalDivider color={theme.palette.accent.main}></StyledVerticalDivider>
                  </>
                )}
                {licenses?.length > 0 && (
                  <>
                    <StyledStackContainer>
                      <MKTypography component="h5" variant="h6" fontWeight="light">
                        Licenses
                      </MKTypography>
                      <List>
                        {licenses?.map((item, index) => (
                          <ListItem key={index} sx={{ alignItems: "center" }}>
                            <MKBox>
                              <CBBulletPoint small={true} />
                            </MKBox>
                            <MKTypography
                              key={index}
                              component="p"
                              variant={"body2"}
                              sx={{
                                display: "-webkit-box",
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: "vertical",
                                textOverflow: "ellipsis",
                                width: "100%",
                                overflow: "hidden",
                              }}
                            >
                              {item}
                            </MKTypography>
                          </ListItem>
                        ))}
                      </List>
                    </StyledStackContainer>
                    <StyledVerticalDivider color={theme.palette.accent.main}></StyledVerticalDivider>
                  </>
                )}
                {languages && (
                  <StyledStackContainer sx={{ alignContent: "flex-start" }}>
                    <MKTypography component="h5" variant="h6" fontWeight="light">
                      Languages
                    </MKTypography>
                    <List>
                      {languages?.map((item, index) => (
                        <ListItem key={index} sx={{ alignItems: "center" }}>
                          <MKBox>
                            <CBBulletPoint small={true} />
                          </MKBox>
                          <MKTypography key={index} component="p" variant={"body2"}>
                            {item}
                          </MKTypography>
                        </ListItem>
                      ))}
                    </List>
                  </StyledStackContainer>
                )}
              </Stack>
            </StyledProfileAccordionDetails>
          </StyledProfileAccordion>
        </MKBox>
        <Container
          sx={{
            padding: "1rem 0 !important",
            maxWidth: "none !important",
            margin: "none !important",
          }}
        >
          <CBDivider isProfile={true} color={theme.palette.accent.main}></CBDivider>
        </Container>
        {/* Psychedelic services & Details */}
        <MKBox>
          <StyledProfileAccordion>
            <StyledProfileAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
              <MKTypography component="h4" variant="h5" gutterBottom>
                Psychedelic Services & Details
              </MKTypography>
            </StyledProfileAccordionSummary>
            <StyledProfileAccordionDetails>
              <Stack
                direction={isLg ? "row" : "column"}
                sx={{ justifyContent: "space-around", gap: "2rem", flexWrap: psychedelicsYouWorkWith && "wrap" }}
              >
                {treatmentMethods && (
                  <>
                    <StyledStackContainer sx={{ flexGrow: 2 }}>
                      <MKTypography
                        component="h5"
                        variant="h6"
                        gutterBottom
                        fontWeight="light"
                        sx={{
                          overflow: "hidden",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          textOverflow: "ellipsis",
                          width: "105%",
                        }}
                      >
                        Treatment Methods
                      </MKTypography>
                      <List>
                        {treatmentMethods?.map((item, index) => (
                          <ListItem key={index} sx={{ alignItems: "center" }}>
                            <MKBox>
                              <CBBulletPoint small={true} />
                            </MKBox>
                            <MKTypography
                              key={index}
                              component="p"
                              variant={"body2"}
                              sx={{
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: "vertical",
                                textOverflow: "ellipsis",
                                width: "105%",
                              }}
                            >
                              {item}
                            </MKTypography>
                          </ListItem>
                        ))}
                      </List>
                    </StyledStackContainer>
                    <StyledVerticalDivider color={theme.palette.accent.main}></StyledVerticalDivider>
                  </>
                )}

                {visitTypes && (
                  <>
                    <StyledStackContainer sx={{ flexGrow: 1 }}>
                      <MKTypography component="h5" variant="h6" gutterBottom fontWeight="light">
                        Visit Types
                      </MKTypography>
                      <List>
                        {visitTypes?.map((type, index) => (
                          <ListItem key={index} sx={{ alignItems: "center" }}>
                            <MKBox>
                              <CBBulletPoint small={true} />
                            </MKBox>
                            <MKTypography key={index} component="p" variant={"body2"}>
                              {type}
                            </MKTypography>
                          </ListItem>
                        ))}
                      </List>
                    </StyledStackContainer>
                    <StyledVerticalDivider color={theme.palette.accent.main}></StyledVerticalDivider>
                  </>
                )}

                {specialties && (
                  <>
                    <StyledStackContainer sx={{ flexGrow: 1 }}>
                      <MKTypography component="h5" variant="h6" gutterBottom fontWeight="light">
                        Specialties
                      </MKTypography>
                      <List>
                        {specialties?.map((item, index) => (
                          <ListItem key={index} sx={{ alignItems: "center" }}>
                            <MKBox>
                              <CBBulletPoint small={true} />
                            </MKBox>
                            <MKTypography key={index} component="p" variant={"body2"}>
                              {item}
                            </MKTypography>
                          </ListItem>
                        ))}
                      </List>
                    </StyledStackContainer>
                    <StyledVerticalDivider color={theme.palette.accent.main}></StyledVerticalDivider>
                  </>
                )}

                {typesOfTherapy && (
                  <StyledStackContainer sx={{ flexGrow: 2 }}>
                    <MKTypography component="h5" variant="h6" gutterBottom fontWeight="light">
                      Therapy services
                    </MKTypography>
                    <List>
                      {typesOfTherapy?.map((item, index) => (
                        <ListItem key={index} sx={{ alignItems: "center" }}>
                          <MKBox>
                            <CBBulletPoint small={true} />
                          </MKBox>
                          <MKTypography
                            key={index}
                            component="p"
                            variant={"body2"}
                            sx={{
                              overflow: "hidden",
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              textOverflow: "ellipsis",
                              width: "105%",
                            }}
                          >
                            {item}
                          </MKTypography>
                        </ListItem>
                      ))}
                    </List>
                  </StyledStackContainer>
                )}
                {psychedelicsYouWorkWith && <StyledVerticalDivider color={theme.palette.accent.main}></StyledVerticalDivider>}

                {psychedelicsYouWorkWith && (
                  <StyledStackContainer sx={{ flexGrow: 2 }}>
                    <MKTypography component="h5" variant="h6" gutterBottom fontWeight="light">
                      Types Of psychedelics I Work With
                    </MKTypography>
                    <List>
                      {psychedelicsYouWorkWith?.map((item, index) => (
                        <ListItem key={index} sx={{ alignItems: "center" }}>
                          <MKBox>
                            <CBBulletPoint small={true} />
                          </MKBox>
                          <MKTypography key={index} component="p" variant={"body2"}>
                            {item}
                          </MKTypography>
                        </ListItem>
                      ))}
                    </List>
                  </StyledStackContainer>
                )}
              </Stack>
            </StyledProfileAccordionDetails>
          </StyledProfileAccordion>
        </MKBox>
        <Container
          sx={{
            padding: "1rem 0 !important",
            maxWidth: "none !important",
            margin: "none !important",
          }}
        >
          <CBDivider isProfile={true} color={theme.palette.accent.main}></CBDivider>
        </Container>
        {/* Service cost and insurance */}
        <MKBox>
          <StyledProfileAccordion>
            <StyledProfileAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
              <MKTypography component="h4" variant="h5" gutterBottom>
                Service Costs & Insurance
              </MKTypography>
            </StyledProfileAccordionSummary>
            <StyledProfileAccordionDetails>
              <Stack direction={isLg ? "row" : "column"} justifyContent={"space-around"} spacing="2rem">
                {hourlyRate && (
                  <>
                    <StyledStackContainer>
                      <MKTypography component="h5" variant="h6" gutterBottom fontWeight="light">
                        Hourly Rate
                      </MKTypography>
                      <MKTypography component="p" variant={"body2"} gutterBottom>
                        ${hourlyRate}
                      </MKTypography>
                    </StyledStackContainer>
                    <StyledVerticalDivider color={theme.palette.accent.main}></StyledVerticalDivider>
                  </>
                )}
                {insurance && (
                  <StyledStackContainer>
                    <MKTypography component="h5" variant="h6" gutterBottom fontWeight="light">
                      Insurance
                    </MKTypography>
                    <MKTypography component="p" variant={"body2"} gutterBottom>
                      {insurance ? "Yes, we accept insurance" : "No, we do not accept insurance"}
                    </MKTypography>
                  </StyledStackContainer>
                )}
              </Stack>
            </StyledProfileAccordionDetails>
          </StyledProfileAccordion>
        </MKBox>
      </MKBox>
    </Modal>
  );
}

//! Values From Contacts May need later
// const shortBio = findCustomFieldValue("Short Bio", customFields);
// const website = findCustomFieldValue("Website", customFields);
// const modalities = findCustomFieldValue("Modalities - (AKA - Primary Practice)", customFields);
// const education = findCustomFieldValue("Education", customFields);
// const typesOfSettings = findCustomFieldValue("Types Of Settings", customFields);
// const position = findCustomFieldValue("Professional Title", customFields);
