import styled from "@emotion/styled";
import { Grid, Container } from "@mui/material";
import theme from "assets/theme";

export const StyledWhatIsKetamineSectionContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "3rem",
  paddingBlock: "4rem",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));

export const StyledGridTextItem = styled(Grid)(({ theme }) => ({
  minHeight: { lg: 500 },
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));
export const StyledGridImageItem = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const StyledBenefitsOfKetamieTherapySpan = {
  color: theme.palette.accent.main,
  fontSize: "inherit",
  fontWeight: "inherit",
};
