import React from "react";
// Styled components
import { StyledMeditationSectionContainer, StyledMeditationVideo, StyledMeditationVideoContainer } from "./index.styles";
import { StyledSectionBackgroundWrapper } from "index.styles";
// Components
import MKTypography from "components/MKTypography";
// Theme
import theme from "assets/theme";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
const Meditation = () => {
  const { isLg } = useMediaQueries();

  return (
    <StyledSectionBackgroundWrapper id="meditation-wrapper">
      <StyledMeditationSectionContainer id="meditation-section" component="section">
        <StyledMeditationVideoContainer>
          <StyledMeditationVideo>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/Hzi3PDz1AWU?si=jKO6DtGetRQ-7fvp"
              title="How to meditate - Sadhguru"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </StyledMeditationVideo>
        </StyledMeditationVideoContainer>
        <MKTypography
          gutterBottom
          component="h2"
          variant="h2"
          sx={{ color: `${theme.palette.white.main} !important`, borderBottom: `2px solid ${theme.palette.accent.main}`, paddingBottom: ".5rem" }}
        >
          Meditation
        </MKTypography>
        <MKTypography component="p" variant={isLg ? "body1" : "body2"} sx={{ color: `${theme.palette.white.main} !important` }}>
          Meditation is a practice that can help you focus and quiet your mind, both of which can be helpful before ketamine-assisted psychotherapy.
          By taking some time to meditate before your session, you can help clear your mind and prepare yourself to receive the benefits of ketamine
          treatment.There are many different ways to meditate, so know that there is no wrong way to do this. If you’re new to meditation practice,
          there are plenty of helpful resources online, or you can even download a meditation app on your phone.
        </MKTypography>
      </StyledMeditationSectionContainer>
    </StyledSectionBackgroundWrapper>
  );
};

export default Meditation;
