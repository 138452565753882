import styled from "@emotion/styled";
import { Container } from "@mui/material";

export const StyledPageHeaderSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  textAlign: "center",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));
