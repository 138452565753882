import styled from "@emotion/styled";
import { Accordion, AccordionDetails, AccordionSummary, Link, Stack } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
export const StyledProfileAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: "none",
  padding: "0 !important",
}));
export const StyledProfileAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: "0 !important",
}));
export const StyledProfileAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: "0 !important",
}));
export const StyledEllipsisTypographyLink = styled(Link)(({ theme }) => ({
  overflow: "hidden",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  textOverflow: "ellipsis",
  width: "100%",
  color: theme.palette.accent.main,
  fontSize: "14px",
}));

export const StyledStackContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
}));
