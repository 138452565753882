import styled from "@emotion/styled";
import { Container } from "@mui/material";
import MKBox from "components/MKBox";

export const StyledHowToSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));

export const StyledHowToCardsContainer = styled(MKBox)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
}));
