// utils/context/GHLUsers.context.js
import useFetchGHLContacts from "pages/FindATherapist/hooks/useFetchContacts.hooks";
import React, { createContext } from "react";

export const GHLUsersContext = createContext({
  users: [],
  isLoading: true,
});

export const GHLUsersProvider = ({ children }) => {
  const { users, isLoading, isError, error } = useFetchGHLContacts();
  const value = { users, isLoading, isError, error };
  return <GHLUsersContext.Provider value={value}>{children}</GHLUsersContext.Provider>;
};
