// Major
import React from "react";
import { useLocation } from "react-router-dom";

// Components
import SignUpForm from "./components/signUpForm";
import SignInForm from "./components/signInForm";

// Styles
import { StyledMainPageContainer } from "index.styles";


const AuthPage = () => {
  const location = useLocation();
  // const handleUserSignUp = () => {
  //   createAuthUserWithEmailAndPassword("cbega112127@gmail.com", "1234567");
  // };

  // console.log(isAuthorized);
  // console.log(currentUserProfile);

  return (
    <StyledMainPageContainer id="therapist-auth-page">
      {location.pathname === "/therapist-auth/sign-in" && <SignInForm></SignInForm>}
      {location.pathname === "/therapist-auth/sign-up" && <SignUpForm></SignUpForm>}
    </StyledMainPageContainer>
  );
};

export default AuthPage;
