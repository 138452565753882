import React from "react";
// Components
import PreparationPageHeader from "./components/planningAheadPageHeader";
import QuickTips from "./components/quickTips";
import PlanningContent from "./components/planningContent";
import Precautions from "./components/precautions";
import Technology from "./components/technology";
import CBDivider from "components/CBDivider";
import SchemaComponent from "components/CBSchema";
import SEO from "components/CBSEO";
// Styled Components
import { StyledMainPageContainer } from "index.styles";
// Utils
import { constructSchemaData } from "utils/helpers/constructSchemaData";
// Data
import planningAheadSchemaData from "./schema.organization.json";
import planningAheadArticleSchemaData from "./schema.article.json";
import seoData from "./seo.data.json";

const PlanningAhead = () => {
  const schemaData = constructSchemaData(planningAheadSchemaData.url, planningAheadSchemaData.breadcrumbs, planningAheadArticleSchemaData);
  return (
    <StyledMainPageContainer id="planning-ahead-page-container">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <PreparationPageHeader />
      <PlanningContent />
      <QuickTips />
      <Precautions />
      <CBDivider sections={"precautions-and-technology"} />
      <Technology />
    </StyledMainPageContainer>
  );
};

export default PlanningAhead;
