import { useMemo } from "react";

export const useFilterDataForFaqs = (initialData = [], searchTerms) => {
  const filteredData = useMemo(() => {
    if (searchTerms.size === 0) {
      return initialData;
    }
    const normalizeAndLowerCase = (value) => String(value).trim().toLowerCase();
    const searchTermString = normalizeAndLowerCase([...searchTerms].join(" "));
    return initialData
      .map((category) => {
        const categoryMatch = normalizeAndLowerCase(category.category).includes(searchTermString);
        const faqsMatch = category?.FAQs?.filter((faq) => normalizeAndLowerCase(faq.question).includes(searchTermString));
        if (categoryMatch || faqsMatch?.length > 0) {
          return {
            ...category,
            FAQs: faqsMatch,
          };
        }
        return null;
      })
      .filter(Boolean);
  }, [initialData, searchTerms]);
  return filteredData;
};
