import { Grid, Stack } from "@mui/material";
import theme from "assets/theme";
import rgba from "assets/theme/functions/rgba";
import CBDivider from "components/CBDivider";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React from "react";
import { StyledSectionContainer } from "styles/components";
import { StyledVerticalDivider } from "styles/components";
import useMediaQueries from "utils/mediaQueries.utils";

const OrderDetails = ({ firstName, lastName, billingAddress, shippingAddress, email, orderId }) => {
  const { isMd } = useMediaQueries();
  return (
    <Grid item xs={12} lg={7}>
      <StyledSectionContainer>
        <MKTypography component="h1" variant="h2" gutterBottom sx={{ color: `${theme.palette.white.main} !important` }}>
          Your order is
          <span style={{ color: theme.palette.accent.main }}> confirmed</span>
        </MKTypography>
        <MKTypography component="p" variant={"body2"} sx={{ color: `${theme.palette.white.main} !important` }} gutterBottom>
          Thank you {firstName} {lastName} for choosing Almadelic for your journey towards wellness! We are honored that you have entrusted us with
          your care and are committed to supporting you every step of the way. Your order has been successfully processed, and you are one step closer
          to experiencing the transformative potential of at-home ketamine therapy.
        </MKTypography>
        <CBDivider />
        <Stack direction={isMd ? "row" : "column"} mt={4} spacing="2rem">
          <MKBox sx={{ width: "100%" }}>
            <MKTypography component="h4" variant="h4" gutterBottom sx={{ color: `${theme.palette.accent.main} !important` }}>
              Shipping Address
            </MKTypography>
            <MKTypography component="p" variant={"body2"} sx={{ color: `${theme.palette.white.main} !important` }}>
              {JSON.parse(shippingAddress).firstName} {JSON.parse(shippingAddress).lastName}
            </MKTypography>
            <MKTypography component="p" variant={"body2"} sx={{ color: `${theme.palette.white.main} !important` }}>
              {JSON.parse(shippingAddress).addressLine}
            </MKTypography>
            <MKTypography component="p" variant={"body2"} sx={{ color: `${theme.palette.white.main} !important` }}>
              {JSON.parse(shippingAddress).city.charAt(0).toUpperCase() + JSON.parse(shippingAddress).city.slice(1).toLowerCase()},{" "}
              {JSON.parse(shippingAddress).state} {JSON.parse(shippingAddress).postalCode}
            </MKTypography>
            <MKTypography component="p" variant={"body2"} sx={{ color: `${theme.palette.white.main} !important` }}>
              {JSON.parse(shippingAddress).phone}
            </MKTypography>
            <MKTypography component="p" variant={"body2"} sx={{ color: `${theme.palette.white.main} !important` }}>
              {email}
            </MKTypography>
          </MKBox>
          <StyledVerticalDivider color={rgba(theme.palette.grey[300], 0.5)} marginInline={"true"} />
          <MKBox sx={{ width: "100%" }}>
            <MKTypography component="h4" variant="h4" gutterBottom sx={{ color: `${theme.palette.accent.main} !important` }}>
              Billing Address
            </MKTypography>
            <MKTypography component="p" variant={"body2"} sx={{ color: `${theme.palette.white.main} !important` }}>
              {JSON.parse(billingAddress).firstName} {JSON.parse(billingAddress).lastName}
            </MKTypography>
            <MKTypography component="p" variant={"body2"} sx={{ color: `${theme.palette.white.main} !important` }}>
              {JSON.parse(billingAddress).addressLine}
            </MKTypography>
            <MKTypography component="p" variant={"body2"} sx={{ color: `${theme.palette.white.main} !important` }}>
              {JSON.parse(billingAddress).city.charAt(0).toUpperCase() + JSON.parse(billingAddress).city.slice(1).toLowerCase()},{" "}
              {JSON.parse(billingAddress).state} {JSON.parse(billingAddress).postalCode}
            </MKTypography>
            <MKTypography component="p" variant={"body2"} sx={{ color: `${theme.palette.white.main} !important` }}>
              {JSON.parse(billingAddress).phone}
            </MKTypography>
            <MKTypography component="p" variant={"body2"} sx={{ color: `${theme.palette.white.main} !important` }}>
              {email}
            </MKTypography>
          </MKBox>
        </Stack>
      </StyledSectionContainer>
    </Grid>
  );
};

export default OrderDetails;
