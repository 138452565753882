import styled from "@emotion/styled";
import { Container, Stack } from "@mui/material";


export const StyledBodyContainer = styled(Container)(({ theme }) => ({
    padding: "0rem !important",
    margin: "0rem",
    fontWeight: "bold",
}));

export const StyledBodyStackSection = styled(Stack)(({ theme }) => ({
    padding: "0rem !important",
    margin: "0rem",
}));