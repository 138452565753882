import { styled } from "@mui/system";
import rgba from "assets/theme/functions/rgba";
const { Toolbar, AppBar } = require("@mui/material");

export const StyledNavigationAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: rgba("#fff", 0.8),
  boxShadow: "rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backdropFilter: "blur(30px)",
    zIndex: -1,
  },
}));
export const StyledNavigationToolBar = styled(Toolbar)(({ theme }) => ({
  position: "relative",
  padding: "1rem",
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    padding: "1rem 2rem",
  },
}));
