import styled from "@emotion/styled";
import { Container } from "@mui/material";
import MKBox from "components/MKBox";

export const StyledAboutKetamineSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));
export const StyledAboutKetamineImageBox = styled(MKBox)(({ theme }) => ({
  position: "relative",
  height: "400px",
  width: "100%",
  overflow: "hidden",
  "::before": {
    zIndex: "2",
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    boxShadow: " 0px 4px 30px 6px #FFF inset",
    pointerEvents: "none",
  },
  img: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: "1",
  },
}));

export const StyledAboutKetamineIframe = styled("iframe")(({ theme }) => ({
  position: "absolute",
  inset: "0",
}));
