// Major
import React from "react";

// Styles
import { StyledMainPageContainer } from "index.styles";
import ResetPasswordForm from "./resetPasswordForm";

const ResetPassword = () => {
  return (
    <StyledMainPageContainer id="reset-password-page">
      <ResetPasswordForm />
    </StyledMainPageContainer>
  );
};

export default ResetPassword;
