import { Box } from "@mui/material";
import theme from "assets/theme";
import React from "react";

const CBBulletPoint = ({ small, med }) => {
  return (
    <Box
      sx={{
        height: small ? "8px !important" : med ? "12px" : "16px",
        width: small ? "8px !important" : med ? "12px" : "16px",
        backgroundColor: theme.palette.secondary.main,
        marginRight: ".5rem",
        borderRadius: "50%",
      }}
    ></Box>
  );
};

export default CBBulletPoint;
