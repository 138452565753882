import { unixToDateConversion } from "./unixToDateConversion";

export const constructPurchaseMadeData = (values, cartItems, cartTotal, originalSubtotal, paymentResults, subscription) => {
  // console.log("subscription", subscription);
  // console.log("payment result", paymentResults);
  const {
    email,
    shippingAddress,
    shippingFirstName,
    shippingLastName,
    shippingCity,
    shippingCountry,
    shippingZipCode,
    shippingState,
    shippingPhone,
    billingAddress,
    billingFirstName,
    billingLastName,
    billingCity,
    billingCountry,
    billingZipCode,
    billingState,
    billingPhone,
  } = values;

  const cartItemsData = cartItems.map((item) => {
    const { default_price, id, name, price, quantity, images } = item;
    return {
      default_price,
      id,
      name,
      price,
      quantity,
      image: images[0],
    };
  });
  const finalBillingAddress = billingAddress || shippingAddress;
  const finalBillingFirstName = billingFirstName || shippingFirstName;
  const finalBillingLastName = billingLastName || shippingLastName;
  const finalBillingCity = billingCity || shippingCity;
  const finalBillingCountry = billingCountry || shippingCountry;
  const finalBillingZipCode = billingZipCode || shippingZipCode;
  const finalBillingState = billingState || shippingState;
  const finalBillingPhone = billingPhone || shippingPhone;

  const totalDetails = {
    subtotal: (originalSubtotal / 100).toFixed(2),
    total: (cartTotal / 100).toFixed(2),
  };

  let automationDetails;

  if (subscription && !paymentResults) {
    automationDetails = {
      promoCodeId: subscription.discount.coupon.id,
      promoCodeName: subscription.discount.name,
      datePurchased: unixToDateConversion(subscription.created),
      numberOfPayments: subscription.plan.interval_count + 1,
      initialPaymentAmount: (subscription.latest_invoice.amount_paid / 100).toFixed(2),
      initialPaymentDate: unixToDateConversion(subscription.latest_invoice.created),
      // recurringPaymentAmount: subscription.plan.amount,
      recurringPaymentAmount: (subscription.latest_invoice.amount_due / 100).toFixed(2),
      recurringPaymentDates: `${unixToDateConversion(subscription.latest_invoice.created)}, ${unixToDateConversion(
        subscription.latest_invoice.period_end
      )}`,
      // recurringPaymentDates: [
      //   unixToDateConversion(subscription.latest_invoice.created),
      //   unixToDateConversion(subscription.latest_invoice.period_end),
      // ], //! need to address 3 month programs
    };
  }

  if (paymentResults && !subscription) {
    automationDetails = {
      // promoCodeId: subscription.discount.coupon.id, //!grab from meta data
      // promoCodeName: subscription.discount.name, //!may need to pass discount name in metadata to payment intent
      datePurchased: unixToDateConversion(paymentResults.created),
      numberOfPayments: 1,
      initialPaymentAmount: (paymentResults.amount / 100).toFixed(2),
      initialPaymentDate: unixToDateConversion(paymentResults.created),
      recurringPaymentAmount: "N/A",
      recurringPaymentDates: "N/A",
    };
  }

  if (paymentResults && subscription) {
    automationDetails = {
      promoCodeId: subscription.discount.coupon.id,
      promoCodeName: subscription.discount.coupon.name,
      datePurchased: unixToDateConversion(subscription.created) || unixToDateConversion(paymentResults.created),
      numberOfPayments: subscription.plan.interval_count + 1,
      initialPaymentAmount: ((subscription.latest_invoice.amount_paid + paymentResults.amount) / 100).toFixed(2),
      initialPaymentDate: unixToDateConversion(subscription.latest_invoice.created) || unixToDateConversion(paymentResults.created),
      // recurringPaymentAmount: (subscription.plan.amount / 100).toFixed(2), // add coupon discount to subscription plan
      recurringPaymentAmount: (subscription.latest_invoice.amount_due / 100).toFixed(2),
      recurringPaymentDates: `${unixToDateConversion(subscription.current_period_start)}, ${unixToDateConversion(subscription.current_period_end)}`, //! need to address 3 month programs
    };
  }

  // Aug 28th --> Sept 28th

  const purchaseData = {
    line_items: cartItemsData,
    billing: {
      addressLine: finalBillingAddress,
      firstName: finalBillingFirstName,
      lastName: finalBillingLastName,
      city: finalBillingCity,
      country: finalBillingCountry,
      postalCode: finalBillingZipCode,
      state: finalBillingState,
      phone: finalBillingPhone,
    },
    shipping: {
      addressLine: shippingAddress,
      firstName: shippingFirstName,
      lastName: shippingLastName,
      city: shippingCity,
      country: shippingCountry,
      postalCode: shippingZipCode,
      state: shippingState,
      phone: shippingPhone,
    },
    total: totalDetails,
    email: email,
    phone: shippingPhone,
    ...automationDetails,
  };

  return { purchaseData };
};
