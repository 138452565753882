// Major
import styled from "@emotion/styled";
import { Container, Divider, Grid } from "@mui/material";

// Components
import MKTypography from "components/MKTypography";


export const StyledOurTeamCardSectionContainer = styled(Container)(
  ({ theme }) => ({
    paddingBlock: "4rem",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("lg")]: {
      paddingBlock: "6rem",
    },
    transform: "rotate(-180deg)",
  })
);

export const StyledOurTeamCardOuterGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.up("md")]: {
  },
  [theme.breakpoints.up("lg")]: {
  },
}));

export const StyledOurTeamCardInnerGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  width: "100%",
}));

export const StyledOurTeamCardInnerGridHeader = styled(MKTypography)(
  ({ theme }) => ({
    color: `${theme.palette.white.main} !important`,
    textAlign: "center",
    [theme.breakpoints.up("md")]: { textAlign: "start" },
    marginBottom: "15px",
  })
);

export const StyledOurTeamCardCardContainerGrid = styled(Grid)(({ theme }) => ({
  marginTop: "0px",
  display: "flex",
  flexWrap: "wrap",
  gap: "2rem",

  justifyContent: "center",
  [theme.breakpoints.up("md")]: {
    justifyContent: "flex-start",
  },
}));

export const StyledOurTeamCardDivider = styled(Divider)(({ theme }) => ({
  height: "3px",
  backgroundColor: theme.palette.grey[400],
}));

export const StyledOurTeamSectionDividerContainer = styled(Container)(({ theme }) => ({
  paddingInline: "0rem, !important",
  marginBlock: "2rem !important",
}));