// Pass in a string, bad words are filtered out and replaced with **** and then return filtered string
import React from 'react';
import {Filter} from 'bad-words';

const additionalBadWords = [
    'phuk', 
    'biatch', 
    'tranny', 
    'milf', 
    'shemale', 
    'fag', 
    'dyke', 
    'gook', 
    'kike', 
    'retard', 
    'cripple', 
    'junkie', 
    'slut', 
    'bimbo', 
    'kill myself', 
    'cutting',
]

const filterBadWords = (text) => {
    const filter = new Filter();
    filter.addWords(...additionalBadWords)
    return filter.clean(text)
};

export default filterBadWords;
