import React from "react";
import { Step, StepLabel, Stepper, StepConnector, stepConnectorClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import MKTypography from "components/MKTypography";
import useMediaQueries from "utils/mediaQueries.utils";
import theme from "assets/theme";

const icons = {
  1: <PersonOutlineIcon />,
  2: <MedicalInformationIcon />,
  3: <AssignmentIcon />,
};

// Styled Step Connector
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: `linear-gradient(90deg, rgba(10,40,96,.4) 0%, rgba(117,205,197,1) 50%, rgba(10,40,96,.4) 100%)`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: `linear-gradient(90deg, rgba(10,40,96,.4) 0%, rgba(117,205,197,1) 50%, rgba(10,40,96,.4) 100%)`,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

// Custom Step Icon Component
const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage: `radial-gradient(circle, rgba(117,205,197,1) 0%, rgba(10,40,96,.7) 100%)`,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage: `radial-gradient(circle, rgba(117,205,197,1) 0%, rgba(10,40,96,.7) 100%)`,
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot height="50" width="50" ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const ProgressBar = ({ activeStep, steps }) => {
  const { isLg } = useMediaQueries();
  return (
    <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
      {steps.map((step, index) => (
        <Step key={index}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>
            <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
              <small style={{ fontSize: "12px", fontWeight: "800", color: index === activeStep && `${theme.palette.accent.main}` }}>
                Step {index + 1} <br />
              </small>
              {step.sectionName}
            </MKTypography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default ProgressBar;
