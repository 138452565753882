import React from "react";
// Components
import SchemaComponent from "components/CBSchema";
import SEO from "components/CBSEO";
// Styled Components
import { StyledMainPageContainer } from "index.styles";
// Data
import seoData from "./seo.data.json";
import consumerHealthDataPrivacyPolicySchemaData from "./schema.organization.json";
import consumerHealthPolicyData from "./consumerHealthPolicy.data.json";
// Utils
import { constructSchemaData } from "utils/helpers/constructSchemaData";
// Components
import PageHeader from "components/CBPageHeader";
import PolicyContent from "components/CBPolicyContent";

const ConsumerHealthDataPrivacyPolicy = () => {
  const schemaData = constructSchemaData(consumerHealthDataPrivacyPolicySchemaData.url, consumerHealthDataPrivacyPolicySchemaData.breadcrumbs);
  return (
    <StyledMainPageContainer id="consumer-health-data-privacy-policy">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <PageHeader
        headerType="policy"
        section="consumer-health-data-privacy-policy"
        header="Health Data"
        headerAccent="Privacy Policy"
        dateModified="07 Aug, 2024"
      />
      <PolicyContent data={consumerHealthPolicyData} section={"consumer-health-data-privacy-policy"} />
    </StyledMainPageContainer>
  );
};

export default ConsumerHealthDataPrivacyPolicy;
