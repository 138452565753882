import React from "react";
// Styled Components
import { StyledPolicyContentSectionContainer } from "./index.styles";
// Components
import MKTypography from "components/MKTypography";
import { Link, List, ListItem } from "@mui/material";
import MKBox from "components/MKBox";
import CBBulletPoint from "components/CBBulletPoint";
import theme from "assets/theme";
import { replaceKeywordWithLink } from "utils/helpers/replaceKeywordWithLink";

const renderFooterDescription = (footerDescription) => {
  if (typeof footerDescription === "string") {
    return (
      <MKTypography component={"p"} variant={"body2"} sx={{ mt: "1rem" }}>
        {footerDescription}
      </MKTypography>
    );
  } else if (typeof footerDescription === "object" && footerDescription !== null) {
    const { description, keywords } = footerDescription;
    const updateDescription = replaceKeywordWithLink(description, keywords);
    return (
      <div>
        <MKTypography component={"p"} variant={"body2"} sx={{ mt: "1rem" }}>
          {updateDescription}
        </MKTypography>
      </div>
    );
  } else {
    return null;
  }
};

const PolicyContent = ({ data, section }) => {
  return (
    <StyledPolicyContentSectionContainer id={`${section}-content`}>
      {data.map(({ title, sections, footerDescription, cta }, index) => (
        <div key={index}>
          <MKTypography
            variant="h3"
            component="h2"
            gutterBottom
            sx={{
              fontWeight: "medium",
              mt: "2rem",
            }}
          >
            {title}
          </MKTypography>
          {sections.map(({ description, keywords, list }, sectionIndex) => {
            const updateDescription = replaceKeywordWithLink(description, keywords);

            return (
              <div key={sectionIndex}>
                <MKTypography component="p" variant="body2" my="1rem">
                  {updateDescription}
                </MKTypography>
                <List>
                  {list?.map((listItem, listIndex) => (
                    <MKBox
                      component={ListItem}
                      disableGutters
                      width="auto"
                      padding={0}
                      style={{ display: "flex", alignItems: "flex-start" }}
                      key={listIndex}
                    >
                      <MKBox style={{ marginTop: "0.3em" }}>
                        <CBBulletPoint med="true" />
                      </MKBox>
                      <MKTypography component="p" variant="body2">
                        {listItem}
                      </MKTypography>
                    </MKBox>
                  ))}
                </List>
              </div>
            );
          })}
          {cta && (
            <Link sx={{ color: theme.palette.secondary.main }} rel="noopener" target="_blank" href={cta.link}>
              {cta.label}
            </Link>
          )}
          {renderFooterDescription(footerDescription)}
        </div>
      ))}
    </StyledPolicyContentSectionContainer>
  );
};

export default PolicyContent;
