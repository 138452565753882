import { Grid } from "@mui/material";
import React, { useContext } from "react";
import theme from "assets/theme";
import { CardElement } from "@stripe/react-stripe-js";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { CheckoutContext } from "utils/context/checkout.context";

const PaymentOptions = () => {
  const { showBillingAddressFormFields } = useContext(CheckoutContext);

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#303238",
        fontFamily: 'Nunito Sans, "Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        lineHeight: "44px",
        "::placeholder": {
          color: "#a0a0a0",
        },
        ":-webkit-autofill": {
          color: "#e39f48",
        },
        ":-webkit-autofill::first-line": {
          color: "#000",
        },
      },
      complete: {
        color: theme.palette.success.main,
        iconColor: theme.palette.success.main,
      },
      invalid: {
        color: theme.palette.error.main,
        iconColor: theme.palette.error.main,
      },
    },
  };

  return (
    <Grid container sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
      <MKTypography component="h4" variant="h4" sx={{ display: "block" }} gutterBottom>
        {showBillingAddressFormFields ? "3." : "2."} Payment Options
      </MKTypography>
      <MKBox sx={{ border: `1px solid ${theme.palette.grey[400]}`, borderRadius: "8px", paddingInline: "10px", backgroundColor: "#fff" }}>
        <CardElement options={CARD_ELEMENT_OPTIONS} disableLink={true} />
      </MKBox>
    </Grid>
  );
};

export default PaymentOptions;
//! if we want to separate the card elements like card number, expire data, cvc we can, but may require use to refactor how we send data to confirmPayment stripe method.
// import { Grid, Box, Typography } from "@mui/material";
// import React from "react";
// import theme from "assets/theme";
// import { CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
// import MKBox from "components/MKBox";
// import CreditCardIcon from "@mui/icons-material/CreditCard";
// import { StyledPaymentContainer } from "./index.styles";

// const CARD_ELEMENT_OPTIONS = {
//   style: {
//     base: {
//       color: "#303238",
//       fontFamily: 'Nunito Sans, "Helvetica Neue", Helvetica, sans-serif',
//       fontSmoothing: "antialiased",
//       fontSize: "16px",
//       "::placeholder": {
//         color: "#a0a0a0",
//       },
//       ":-webkit-autofill": {
//         color: "#e39f48",
//       },
//       ":-webkit-autofill::first-line": {
//         color: "#000",
//       },
//     },
//     complete: {
//       color: theme.palette.success.main,
//       iconColor: theme.palette.success.main,
//     },
//     invalid: {
//       color: theme.palette.error.main,
//       iconColor: theme.palette.error.main,
//     },
//   },
// };

// const PaymentOptions = () => {
//   return (
//     <Grid container sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
//       <Typography variant="h6" sx={{ mb: 2 }}>
//         Credit/Debit Cards
//       </Typography>
//       <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
//         <StyledPaymentContainer>
//           <CreditCardIcon />
//           <CardNumberElement options={CARD_ELEMENT_OPTIONS} style={{ paddingLeft: "30px" }} />
//         </StyledPaymentContainer>
//         <Box sx={{ display: "flex", gap: 2 }}>
//           <StyledPaymentContainer>
//             <CardExpiryElement options={CARD_ELEMENT_OPTIONS} style={{ paddingLeft: "30px" }} />
//           </StyledPaymentContainer>
//           <StyledPaymentContainer>
//             <CardCvcElement options={CARD_ELEMENT_OPTIONS} style={{ paddingLeft: "30px" }} />
//           </StyledPaymentContainer>
//         </Box>
//       </Box>
//     </Grid>
//   );
// };

// export default PaymentOptions;
