// Major
import React from "react";
// Styled Components
import { StyledMainPageContainer } from "index.styles";
// Components
import SEO from "components/CBSEO";
import SchemaComponent from "components/CBSchema";
import PageHeader from "components/CBPageHeader";
import PolicyContent from "components/CBPolicyContent";
// Data
import privacyPolicyData from "./privacyPolicy.data.json";
import privacyPolicySchemaData from "./schema.organization.json";
import seoData from "./seo.data.json";
// Utils
import { constructSchemaData } from "utils/helpers/constructSchemaData";
const PrivacyPolicy = () => {
  const schemaData = constructSchemaData(privacyPolicySchemaData.url, privacyPolicySchemaData.breadcrumbs);

  return (
    <StyledMainPageContainer id="privacy-policy-page">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <PageHeader headerType="policy" section="privacy-policy" header="Privacy" headerAccent=" Policy" dateModified="21 Feb, 2024" />
      <PolicyContent data={privacyPolicyData} section={"privacy-policy"} />
    </StyledMainPageContainer>
  );
};

export default PrivacyPolicy;
