import React, { useState } from "react";

// Components
import NavList from "../NavList";
import NavLogo from "../NavLogo";
import NavBarActions from "../NavBarActions";

// Utils
import useMediaQueries from "utils/mediaQueries.utils";

// Styles
import { StyledNavigationAppBar, StyledNavigationToolBar } from "./index.styles";

const CBNavBar = ({ routes }) => {
  const { isLg } = useMediaQueries();
  const [mobileNavToggle, setMobileNavToggle] = useState(false);

  return (
    <StyledNavigationAppBar position="sticky">
      <StyledNavigationToolBar>
        <NavLogo paddingLeft={isLg && "2rem"} setMobileNavToggle={setMobileNavToggle} mobileNavToggle={mobileNavToggle} />
        <NavList routes={routes} mobileNavToggle={mobileNavToggle} setMobileNavToggle={setMobileNavToggle} />
        <NavBarActions setMobileNavToggle={setMobileNavToggle} mobileNavToggle={mobileNavToggle} />
      </StyledNavigationToolBar>
    </StyledNavigationAppBar>
  );
};

export default CBNavBar;
