import React from "react";
import { scrollToTop } from "utils/helpers/scrollToTop";
import { StyledLogo } from "./index.styles";

const CBLogo = ({ footerLogo }) => {
  return (
    <StyledLogo
      onClick={scrollToTop}
      // component="img"
      alt="Ketamine psychedelic therapy from Almadelic"
      src={footerLogo ? "https://d3a0rs3velfrz.cloudfront.net/logos/footerLogoNew.svg" : "https://d3a0rs3velfrz.cloudfront.net/logos/mainLogo.svg"}
      height="60"
      footerLogo={footerLogo}
      width="120"
    ></StyledLogo>
  );
};

export default CBLogo;
