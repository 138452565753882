/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import MKTypography from "components/MKTypography";
import theme from "assets/theme";
import { Link } from "react-router-dom";

const date = new Date().getFullYear();
const footerRoutes = {
  brand: {
    name: "Almadelic",
    description: "Find your happiness at Almadelic",
  },
  socials: [
    {
      icon: (
        <img
          src={"https://d3a0rs3velfrz.cloudfront.net/footer/facebook-Icon.webp"}
          alt="Facebook Social Media Icon For Almadelic"
          style={{ height: "24px", width: "24px" }}
        />
      ),
      link: "https://www.facebook.com/people/Almadelic/61557003442928/",
    },
    {
      icon: (
        <img
          src={"https://d3a0rs3velfrz.cloudfront.net/footer/linkedIn-Icon.webp"}
          alt="Linked In Social Media Icon For Almadelic"
          style={{ height: "24px", width: "24px" }}
        />
      ),
      link: "https://www.linkedin.com/company/almadelic/about/",
    },
    {
      icon: (
        <img
          src={"https://d3a0rs3velfrz.cloudfront.net/footer/instagram-icon.webp"}
          alt="Instagram Social Media Icon For Almadelic"
          style={{ height: "24px", width: "24px" }}
        />
      ),
      link: "https://www.instagram.com/almadelicjourney/?igsh=MWo5MG96amN3N2hzNQ%3D%3D",
    },
    {
      icon: (
        <img
          src={"https://d3a0rs3velfrz.cloudfront.net/footer/youtube-Icon.webp"}
          alt="Youtube Social Media Icon For Almadelic By - https://www.iconfinder.com/thepinkgroup "
          style={{ height: "24px", width: "24px" }}
        />
      ),
      link: "https://www.youtube.com/channel/UCFUPRjKAfTPTpZDDz3rMEAA",
    },
  ],
  menus: [
    {
      name: "Company",
      items: [
        { name: "Careers", route: "/careers" },
        { name: "Contact Us", route: "/contact-us" },
        { name: "Insurance", route: "/insurance-options" },
        { name: "Refund Policy", route: "/refund-policy" },
      ],
    },
    {
      name: "Other",
      items: [
        { name: "Privacy Policy", route: "/privacy-policy" },
        { name: "Terms Of Use", route: "/terms" },
        { name: "Informed Consent", route: "/informed-consent" },
        { name: "Health Data Privacy Policy", route: "/consumer-health-data-privacy-policy" },
      ],
    },
  ],
  disclaimer: (
    <MKTypography variant="button" sx={{ color: `${theme.palette.white.main} !important` }}>
      Almadelic Management Services, LLC provides services to affiliated medical practices which are independently owned and operated, and in no way
      owns, directs, or controls the mental healthcare clinicians providing care. This website has been reviewed by Almadelic Medical Group, P.C. and
      should not be used as medical advice in place of a licensed psychiatric clinician. If you are in a life-threatening situation, don’t use this
      site. Call, text, or chat 988 or 1-800-273-TALK (8255) to get immediate help.
    </MKTypography>
  ),
  copyright: (
    <MKTypography variant="button" sx={{ color: `${theme.palette.white.main} !important` }}>
      Copyright &copy; {date}{" "}
      <MKTypography component={Link} to="/" variant="button" sx={{ color: `${theme.palette.white.main} !important` }}>
        Almadelic Management Services LLC
      </MKTypography>
      .
    </MKTypography>
  ),
};
export default footerRoutes;
