import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Styled components
import { StyledEllipsisTypography, StyledProfileCard, StyledProfileCardImage } from "./index.styles";
// Components
import MKTypography from "components/MKTypography";
import { Stack, Link as MuiLink } from "@mui/material";
import MKBox from "components/MKBox";
import CBDivider from "components/CBDivider";
import EllipsisText from "components/CBEllipsisText";
import ProfileModal from "../profileModal";
// Icons
import LanguageIcon from "@mui/icons-material/Language";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import WorkIcon from "@mui/icons-material/Work";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
import { findCustomFieldValue } from "utils/helpers/findCustomFieldValue";
import { containsTag } from "utils/helpers/checkTag";
import { convertState } from "utils/helpers/stateConverter";
import { ProfileModalContext } from "utils/context/profileModal.context";
import { ensureHttps } from "utils/helpers/ensureHTTPS";
// Theme
import theme from "assets/theme";

const ProfileCard = ({ profileData }) => {
  const { isLg, isMd, isSm } = useMediaQueries();
  const { handleOpen, setCurrentUserData, open } = useContext(ProfileModalContext);

  const { state, name, customFields, tags, city } = profileData || [];
  const [isBusinessListing, setIsBusinessListing] = useState();

  const longBio = findCustomFieldValue("Long Bio", customFields);

  const specialties = findCustomFieldValue("Specialties", customFields);
  const websiteURL = findCustomFieldValue("Website", customFields);
  const profileURL = findCustomFieldValue("Profile URL", customFields);
  const experience = findCustomFieldValue("Years Practiced", customFields);

  const navigate = useNavigate();

  const handleReadMoreClick = () => {
    setCurrentUserData(profileData);
    // handleOpen();
  };

  const handleCardClick = (event) => {
    if (open || isBusinessListing) return;
    // event.stopPropagation();
    setCurrentUserData(profileData);
    // handleOpen();
    const url = `/therapist-bio/${name?.replace(/\s+/g, "_")}_${city?.replace(/\s+/g, "_")}_${state?.replace(/\s+/g, "_")}/${profileData.id}`;

    navigate(url);
  };

  useEffect(() => {
    if (containsTag(tags, "active directory business listing")) {
      setIsBusinessListing(true);
    } else {
      setIsBusinessListing(false);
    }
  }, [tags]);
  return (
    <StyledProfileCard aria-label={`${name}'s profile card`} onClick={handleCardClick} isBusinessListing={isBusinessListing}>
      <Stack direction={"row"} alignItems={"center"} spacing={2}>
        {/* Profile Image */}
        {!isBusinessListing && (
          <StyledProfileCardImage
            height="125"
            width="125"
            src={profileURL || "https://d3a0rs3velfrz.cloudfront.net/logos/almadelicButterFlyTransparentNew.webp"}
            alt={`${name} profile image`}
          />
        )}
        <Stack>
          {/* Card Title */}
          <MKTypography
            component="h2"
            variant="h6"
            gutterBottom
            sx={{
              alignItems: "center",
              gap: "1rem",
              overflow: "hidden",
              display: isSm ? "-webkit-box" : "flex",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              textOverflow: "ellipsis",
              width: "100%",
            }}
          >
            {name}
            {isMd && !isBusinessListing && (
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={1}
                sx={{ marginBlock: ".5rem", borderBottom: `1px solid ${theme.palette.accent.marginBlock}` }}
              >
                <img
                  src={"https://d3a0rs3velfrz.cloudfront.net/logos/almadelicButterFlyTransparent.webp"}
                  style={{ height: "20px", width: "30px" }}
                  height="20"
                  width="30"
                  alt="Almadelic butterfly logo"
                ></img>
                <MKTypography component="small" sx={{ fontSize: isLg ? "14px" : "12px", color: theme.palette.accent.main }}>
                  Verified Almadelic Partner
                </MKTypography>
              </Stack>
            )}
          </MKTypography>
          <Stack spacing={1}>
            {/* Specialties */}
            <StyledEllipsisTypography component="p" webkitLineClamp={2} width={"100%"}>
              {specialties?.join(", ")}
              {/* {Array.isArray(licenses) ? licenses.map(extractAbbreviation).join(", ") : extractAbbreviation(licenses)} */}
            </StyledEllipsisTypography>
            {/* State and website */}
            {isMd && (
              <Stack direction={"row"} spacing={isSm ? 2 : 2}>
                <MKBox sx={{ maxWidth: "50%" }}>
                  <Stack direction={"row"} alignItems={"center"} spacing={0.4}>
                    <FmdGoodIcon
                      sx={{
                        width: { xs: "18px" },
                        height: { xs: "18px" },
                        color: theme.palette.accent.main,
                      }}
                    />
                    <StyledEllipsisTypography component="p" webkitLineClamp={1} width="200px">
                      {city && `${city}, `}
                      {convertState(state)}
                    </StyledEllipsisTypography>
                  </Stack>
                  {websiteURL && (
                    <Stack direction={"row"} alignItems={"center"} spacing={0.4}>
                      <LanguageIcon style={{ width: "18px", height: "18px", fontSize: "18px", color: theme.palette.accent.main }} />
                      <MuiLink target="_blank" href={websiteURL} sx={{ fontSize: "14px", color: theme.palette.accent.main }}>
                        <StyledEllipsisTypography
                          component="p"
                          webkitLineClamp={1}
                          width="200px"
                          sx={{ color: theme.palette.accent.main, fontWeight: "bold" }}
                        >
                          {ensureHttps(websiteURL)}
                          {/* {websiteURL || website} */}
                        </StyledEllipsisTypography>
                      </MuiLink>
                    </Stack>
                  )}
                </MKBox>
                {/* column 2 */}
                {!isBusinessListing && (
                  <MKBox sx={{ maxWidth: "50%" }}>
                    {/* accepting new patients */}
                    <Stack direction={"row"} alignItems={"center"} spacing={0.4}>
                      <CheckCircleOutlineIcon
                        sx={{
                          // marginLeft: { sm: "1rem" },
                          width: { xs: "18px" },
                          height: { xs: "18px" },
                          color: theme.palette.accent.main,
                        }}
                      />
                      <StyledEllipsisTypography component="p" webkitLineClamp={1} width="200px">
                        Accepting New Patients
                      </StyledEllipsisTypography>
                    </Stack>
                    {/* experience */}
                    <Stack direction={"row"} alignItems={"center"} spacing={0.4}>
                      <WorkIcon
                        sx={{
                          // marginLeft: { sm: "1rem" },
                          width: { xs: "18px" },
                          height: { xs: "18px" },
                          color: theme.palette.accent.main,
                        }}
                      />
                      <StyledEllipsisTypography component="p" webkitLineClamp={1} width="200px">
                        {experience} Years Exp
                      </StyledEllipsisTypography>
                    </Stack>
                  </MKBox>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
      {!isMd && (
        <Stack direction={"row"} spacing={isSm ? 2 : 2} sx={{ marginTop: "1rem" }}>
          <MKBox sx={{ maxWidth: "50%" }}>
            <Stack direction={"row"} alignItems={"center"} spacing={0.4}>
              <FmdGoodIcon
                sx={{
                  // marginLeft: { sm: "1rem" },
                  width: { xs: "18px" },
                  height: { xs: "18px" },
                  color: theme.palette.accent.main,
                }}
              />
              <StyledEllipsisTypography component="p" webkitLineClamp={1} width="100%" sx={{ color: theme.palette.accent.main }}>
                {city && `${city}, `}
                {convertState(state)}
              </StyledEllipsisTypography>
            </Stack>
            {websiteURL && (
              <Stack direction={"row"} alignItems={"center"} spacing={0.4}>
                <LanguageIcon style={{ width: "18px", height: "18px", fontSize: "18px", color: theme.palette.accent.main }} />
                <MuiLink target="_blank" href={websiteURL} sx={{ fontSize: "14px", color: theme.palette.accent.main }}>
                  <StyledEllipsisTypography
                    component="p"
                    webkitLineClamp={1}
                    width="100px"
                    sx={{ color: theme.palette.accent.main, fontWeight: "bold" }}
                  >
                    {websiteURL}
                  </StyledEllipsisTypography>
                </MuiLink>
              </Stack>
            )}
          </MKBox>
          {/*  */}
          {!isBusinessListing && (
            <MKBox sx={{ maxWidth: "50%" }}>
              <Stack direction={"row"} alignItems={"center"} spacing={0.4}>
                <CheckCircleOutlineIcon
                  sx={{
                    // marginLeft: { sm: "1rem" },
                    width: { xs: "18px" },
                    height: { xs: "18px" },
                    color: theme.palette.accent.main,
                  }}
                />
                <StyledEllipsisTypography component="p" webkitLineClamp={1} width="100%" sx={{ color: theme.palette.accent.main }}>
                  Accepting New Patients
                </StyledEllipsisTypography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} spacing={0.4}>
                <WorkIcon
                  sx={{
                    // marginLeft: { sm: "1rem" },
                    width: { xs: "18px" },
                    height: { xs: "18px" },
                    color: theme.palette.accent.main,
                  }}
                />
                <StyledEllipsisTypography component="p" webkitLineClamp={1} width="100%" sx={{ color: theme.palette.accent.main }}>
                  {experience} Years Exp
                </StyledEllipsisTypography>
              </Stack>
            </MKBox>
          )}
        </Stack>
      )}

      <CBDivider></CBDivider>
      <EllipsisText
        text={
          longBio ||
          "Provider of psychological treatment and support to individuals facing emotional, behavioral, or mental health challenges. Professional therapists that offer various forms of talk therapy and other specialties to help clients work through their concerns and improve their well-being."
        }
        onReadMore={handleReadMoreClick}
        webkitLineClamp={2}
        isBusinessListing={isBusinessListing}
      ></EllipsisText>
      {/* <ProfileModal /> */}
    </StyledProfileCard>
  );
};

export default ProfileCard;
