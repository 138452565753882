// // Major
// import React, { useRef, useState, memo } from "react";
// import { Field, ErrorMessage, useFormikContext } from "formik";

// // Components
// import MKTypography from "components/MKTypography";
// import MKBox from "components/MKBox";
// import CBLoading from "components/CBLoading";
// import MKButton from "components/MKButton";

// // Styles
// import { TextField, Stack, InputAdornment, IconButton, MenuItem, Select, FormControl, OutlinedInput } from "@mui/material";
// import ErrorIcon from "@mui/icons-material/Error";
// import { Visibility, VisibilityOff } from "@mui/icons-material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import theme from "assets/theme";

// // Utils
// import useMediaQueries from "utils/mediaQueries.utils";
// import { convertCamelCaseToWords } from "utils/helpers/convertCamelCase";

// // Data
// import { steps } from "pages/PartnerProspectSurvey/surveySchema";

// const menuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: 200,
//       marginTop: "1rem",
//     },
//   },
// };
// const commonInputStyles = {
//   "& .MuiInputBase-input": {
//     color: "black !important",
//   },
//   "& .MuiFormLabel-root": {
//     color: "black !important",
//   },
// };
// const safeJSONParse = (str) => {
//   try {
//     return JSON.parse(str);
//   } catch (e) {
//     return str;
//   }
// };

// const StepComponent = ({ questions, currentStep, submissionMessage, isLoading }) => {
//   const [showPassword, setShowPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//   const { isLg } = useMediaQueries();
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [fileName, setFileName] = useState("");
//   const { setFieldValue } = useFormikContext(); // Get Formik context to set field value
//   const fileInputRef = useRef(null);

//   const handleClickShowPassword = () => {
//     setShowPassword(!showPassword);
//   };

//   const handleClickShowConfirmPassword = () => {
//     setShowConfirmPassword(!showConfirmPassword);
//   };

//   const handleFileChange = (e, name) => {
//     const file = e.target.files[0];
//     setSelectedFile(file);
//     setFileName(file.name);
//     setFieldValue(name, file);
//   };

//   const handleButtonClick = () => {
//     fileInputRef.current.click();
//   };

  
  
    

//   // Loading Page
//   const LoadingPage = () => {
//     return(
//       <Stack>
//         <MKTypography component="h3" variant="h3" textAlign="center" gutterBottom>
//           Creating Your Profile Now!
//         </MKTypography>
//         <CBLoading />
//       </Stack>
//     )
//   }

//   // Successful Form Submission
//   const SuccessfulFormSubmission = () => {
//     return(
//       <Stack
//         direction={"row"}
//         alignItems={"center"}
//         spacing={2}
//         sx={{
//           backgroundColor: submissionMessage === "Form submitted successfully!" ? theme.palette.success.main : theme.palette.error.main,
//           color: "#fff",
//           padding: "1rem",
//           marginBottom: "1rem",
//         }}

//       >
//         {submissionMessage === "Form submitted successfully!" ? <CheckCircleIcon></CheckCircleIcon> : <ErrorIcon></ErrorIcon>}
//         <MKTypography sx={{ color: "#fff" }}>{submissionMessage}</MKTypography>
//       </Stack>
//     )
//   }

// // Form Step Header
//   const StepHeader = () => {
//     return(
//       <>
//           <MKTypography component="h2" variant="h6" gutterBottom sx={{ color: `${theme.palette.accent.main} !important` }}>
//             Step {currentStep + 1}:
//           </MKTypography>
//           <MKTypography component="h3" variant="h5" gutterBottom>
//             {steps[currentStep].sectionTitle}
//           </MKTypography>
//           <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
//             <em>{steps[currentStep].sectionSubTitle}</em>
//           </MKTypography>
//       </>
//     )
//   }
//   const FormBody = () => {

//     const handleClickShowPassword = () => {
//       setShowPassword(!showPassword);
//     };

//     const handleClickShowConfirmPassword = () => {
//       setShowConfirmPassword(!showConfirmPassword);
//     };

//     const handleFileChange = (e, name) => {
//       const file = e.target.files[0];
//       setSelectedFile(file);
//       setFileName(file.name);
//       setFieldValue(name, file);
//     };

//     const handleButtonClick = () => {
//       fileInputRef.current.click();
//     };

  
  
    

  

//   // Question Password
//   const QuestionPassword = ({ question }) => {
//     return(
//       <Field
//         id={question.name}
//         variant={"outlined"}
//         placeholder={`Enter your ${convertCamelCaseToWords(question.name)}`}
//         name={question.name}
//         as={TextField}
//         type={showPassword ? "text" : "password"}
//         InputProps={{
//           style: {
//             color: "black !important"

//           },
//           endAdornment: (
//             <InputAdornment position="end">
//               <IconButton onClick={handleClickShowPassword} edge="end" aria-label="show or hide hidden password text">
//                 {showPassword ? <VisibilityOff alt={"Visibility off icon"}/> : <Visibility alt={"Visibility on icon"}/>}
//               </IconButton>
//             </InputAdornment>
//           ),
//         }}
//         sx={{
//           ...commonInputStyles,
//           '& input[type="password"]::-ms-reveal': {
//             display: 'none',
//           },
//         }}
//       />
//     )
//   }

//   const QuestionPasswordConfirm = ({ question }) => {
//     return(
//       <Field
//         id={question.name}
//         variant={"outlined"}
//         name={question.name}
//         placeholder={`Enter your ${convertCamelCaseToWords(question.name)}`}
//         as={TextField}
//         type={showConfirmPassword ? "text" : "password"}
//         InputProps={{
//           style: {
//             color: "black !important",
//           },
//           endAdornment: (
//             <InputAdornment position="end">
//               <IconButton onClick={handleClickShowConfirmPassword} edge="end" aria-label="show or hide hidden confirm password text">
//                 {showConfirmPassword ? <VisibilityOff alt={"Visibility off icon"}/> : <Visibility alt={"Visibility on icon"}/>}
//               </IconButton>
//             </InputAdornment>
//           ),
//         }}
//         sx={{
//           ...commonInputStyles,
//           '& input[type="password"]::-ms-reveal': {
//             display: 'none',
//           },
//         }}
//       />
//     )
//   }

//   // Question Select
//   const QuestionSelect = ({ question }) => {
//     return(
//       <Field name={question.name} id={question.name}>
//         {({ field, form }) => (
//           <FormControl variant="outlined" fullWidth>
//             <Select
//               {...field}
//               multiple={question.multi}
//               value={safeJSONParse(field.value) || []}
//               onChange={(event) => {
//                 const value = event.target.value;
//                 const formattedValue = question.multi ? JSON.stringify(value) : value;
//                 form.setFieldValue(question.name, formattedValue);
//               }}
//               input={
//                 <OutlinedInput
//                   endAdornment={
//                     <InputAdornment  position="end">
//                       {/* <IconButton edge="end" aria-label={`Expand to show more ${convertCamelCaseToWords(question.name)} options`}> */}
//                         <ExpandMoreIcon alt={"Expand more Icon"} sx={{zIndex: "-1"}} />
//                       {/* </IconButton> */}
//                     </InputAdornment>
//                   }
//                 />
//               }
//               label={convertCamelCaseToWords(question.name)}
//               displayEmpty
//               renderValue={(selected) => {
//                 if (!selected.length) {
//                   return (
//                     <MKTypography component="p" sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.55)" }}>
//                       Select your {convertCamelCaseToWords(question.name)}
//                     </MKTypography>
//                   );
//                 }
//                 return Array.isArray(selected) ? selected.join(", ") : selected;
//               }}
//               MenuProps={menuProps}
//               sx={{
//                 "& .MuiInputBase-input": {
//                   color: "black !important",
//                   height: "44px",
//                   display: "flex",
//                   alignItems: "center",
//                   padding: "10px 14px",
//                   marginRight: "-40px",
//                 },
//                 "& .MuiFormLabel-root": {
//                   color: "black !important",
//                 },
//                 height: "44px",
//                 width: "100%",
//               }}
//             >
//               {question.options.map((option, optionIndex) => (
//                 <MenuItem key={optionIndex} value={option.value}>
//                   {option.label}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         )}
//       </Field>
//     )
//   }

//   // Question Number
//   const QuestionNumber = ({ question }) => {
//     return(
//       <Field
//         id={question.name}
//         name={question.name}
//         as={TextField}
//         type="number"
//         variant={"outlined"}
//         placeholder={`Enter your ${convertCamelCaseToWords(question.name)}`}
//         InputProps={{
//           style: {
//             color: "black !important",
//           },
//         }}
//         sx={commonInputStyles}
//       />
//     )
//   }

//   // Question Tel
//   const QuestionTel = ({ question }) => {
//     return(
//       <Field
//         id={question.name}
//         name={question.name}
//         as={TextField}
//         type="tel"
//         variant={"outlined"}
//         placeholder={`Enter your ${convertCamelCaseToWords(question.name)}`}
//         InputProps={{
//           style: {
//             color: "black !important",
//           },
//         }}
//         sx={commonInputStyles}
//       />
//     )
//   }

//   // Question File
//   const Questionfile = ({ question }) => {
//     return(
//       <>
//         <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={(e) => handleFileChange(e, question.name)} />
//         <TextField
//           variant="outlined"
//           name={question.name}
//           value={fileName}
//           placeholder="Choose a file"
//           InputProps={{
//             readOnly: true,
//             endAdornment: (
//               <MKButton variant="contained" onClick={handleButtonClick}>
//                 Browse
//               </MKButton>
//             ),
//             style: {
//               color: "black !important",
//             },
//           }}
//           sx={commonInputStyles}
//         />
//       </>
//     )
//   }

// // Question Outline
//   const QuestionOutline = ({ question }) => {
//     return(
//       <Field
//         id={question.name}
//         variant={"outlined"}
//         name={question.name}
//         as={TextField}
//         placeholder={`Enter your ${convertCamelCaseToWords(question.name)}`}
//         InputProps={{
//           style: {
//             color: "black !important",
//           },
//         }}
//         sx={commonInputStyles}
//       />
//     )
//   }

//   // Form Body
  
//     // creating a map of question components
//     const questionComponents = {
//       password: QuestionPassword,
//       confirmPassword: QuestionPasswordConfirm,
//       select: QuestionSelect,
//       number: QuestionNumber,
//       tel: QuestionTel,
//       file: Questionfile,
//     };

//     return (
//       <MKBox sx={{ display: "flex", flexWrap: "wrap", gap: "1rem", marginTop: "4rem" }}>
//         {questions.map((question, index) => {
//           const QuestionComponent = questionComponents[question.type] || QuestionOutline; // if question type is not found, default to QuestionOutline
//           return (
//             <Stack
//               key={index}
//               mb={2}
//               sx={{
//                 width: "100%",
//                 [theme.breakpoints.up("lg")]: {
//                   width: "49% !important",
//                 },
//               }}
//             >
//               <MKTypography
//                 component="label"
//                 htmlFor={`${question.name}`}
//                 variant="h6"
//                 color="text.primary"
//                 sx={{ fontWeight: "500" }}
//                 gutterBottom
//               >
//                 {question.label || ""}
//               </MKTypography>
//               <QuestionComponent question={question} />
//               <ErrorMessage
//                 name={question.name || ""}
//                 component="div"
//                 style={{ color: "red", fontSize: "12px" }}
//               />
//             </Stack>
//           );
//         })}
//       </MKBox>
//     );
//   };

//   return (
//     <>
//       {isLoading ? (
//           <LoadingPage />
//         ) : (
//           <>
//             {submissionMessage && (<SuccessfulFormSubmission />)}
//             <StepHeader />
//             <FormBody />
//           </>
//       )}
//     </>
//   );
// };

// export default StepComponent;
// Major
import React, { useRef, useState } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";

// Components
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import CBLoading from "components/CBLoading";
import MKButton from "components/MKButton";

import TAImageCrop from "components/TAImageCrop";

// Styles
import { TextField, Stack, InputAdornment, IconButton, MenuItem, Select, FormControl, OutlinedInput, Container } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import theme from "assets/theme";
import WarningIcon from "@mui/icons-material/Warning";

import 'react-image-crop/dist/ReactCrop.css';

// Utils
import useMediaQueries from "utils/mediaQueries.utils";
import { convertCamelCaseToWords } from "utils/helpers/convertCamelCase";
import getCroppedImg from "utils/helpers/getCroppedImg";

// Data
import { steps } from "pages/PartnerProspectSurvey/surveySchema";

const menuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
      marginTop: "1rem",
    },
  },
};
const commonInputStyles = {
  "& .MuiInputBase-input": {
    color: "black !important",
  },
  "& .MuiFormLabel-root": {
    color: "black !important",
  },
};
const safeJSONParse = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
};

const StepComponent = ({ questions, currentStep, submissionMessage, isLoading, isUpdating,}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { isLg } = useMediaQueries();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const { setFieldValue } = useFormikContext(); // Get Formik context to set field value
  const fileInputRef = useRef(null);


///////////////////////////////////////

  const [src, setSrc] = useState(null); // To store the original image
  const [crop, setCrop] = useState({ aspect: 1 }); // Crop settings
  const [croppedImageUrl, setCroppedImageUrl] = useState(null); // URL of the cropped image
  const [croppedFile, setCroppedFile] = useState(null); // File of the cropped image
  const imgRef = useRef(null); // Reference to the image element

  const onImageLoaded = (event) => {
    const image = event.target;
    imgRef.current = image;
    setCroppedImageUrl(null);
  };

  const onCropComplete = (crop) => {
    if (imgRef.current && crop.width && crop.height) {
      const {croppedUrl, blob} = getCroppedImg(imgRef.current, crop);
      if(croppedUrl){
        setSrc(null)
        setCroppedImageUrl(croppedUrl);
      }
      if(blob){
        setCroppedFile(blob);
      }
      setSelectedFile(blob);
      setFileName("Cropped_Image");
      setFieldValue("profile cropped image", blob);
    }
  };


///////////////////////////////////////




  

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleFileChange = (e, name) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setFileName(file.name);
    setFieldValue(name, file);
    
    const reader = new FileReader();
    reader.onload = () => setSrc(reader.result);
    reader.readAsDataURL(file);
    };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      {isLoading ? (
        <Stack>
          <MKTypography component="h3" variant="h3" textAlign="center" gutterBottom>
            Creating Your Profile Now!
          </MKTypography>
          <CBLoading />
        </Stack>
      ) : isUpdating ? (
        <Stack>
          <MKTypography component="h3" variant="h3" textAlign="center" gutterBottom>
            Updating Your Profile Now!
          </MKTypography>
          <CBLoading />
        </Stack>
      ) : (
        <>
          {submissionMessage && (
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={2}
              sx={{
                backgroundColor:
                  submissionMessage === "Form submitted successfully!"
                    ? theme.palette.success.main
                    : submissionMessage === "Existing Account Found! Updating Now..."
                    ? theme.palette.warning.focus
                    : theme.palette.error.main,
                color: "#fff",
                padding: "1rem",
                marginBottom: "1rem",
              }}
              s
            >
              {submissionMessage === "Form submitted successfully!" ? (
                <CheckCircleIcon></CheckCircleIcon>
              ) : submissionMessage === "Existing Account Found! Updating Now..." ? (
                <WarningIcon></WarningIcon>
              ) : (
                <ErrorIcon></ErrorIcon>
              )}
              <MKTypography sx={{ color: "#fff" }}>{submissionMessage}</MKTypography>
            </Stack>
          )}
          <MKTypography component="h2" variant="h6" gutterBottom sx={{ color: `${theme.palette.accent.main} !important` }}>
            Step {currentStep + 1}:
          </MKTypography>
          <MKTypography component="h3" variant="h5" gutterBottom>
            {steps[currentStep].sectionTitle}
          </MKTypography>
          <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
            <em>{steps[currentStep].sectionSubTitle}</em>
          </MKTypography>
          <MKBox sx={{ display: "flex", flexWrap: "wrap", gap: "1rem", marginTop: "4rem" }}>
            {questions.map((question, index) => (
              <Stack
                key={index}
                mb={2}
                sx={{
                  width: "100%",
                  [theme.breakpoints.up("lg")]: {
                    width: "49% !important",
                  },
                }}
              >
                <MKTypography component="label" for={`${question.name}`} variant="h6" color="text.primary" sx={{ fontWeight: "500" }} gutterBottom>
                  {question.label || ""}
                </MKTypography>
                {question.type === "password" ? (
                  <Field
                    id={question.name}
                    variant={"outlined"}
                    placeholder={`Enter your ${convertCamelCaseToWords(question.name)}`}
                    name={question.name}
                    as={TextField}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      style: {
                        color: "black !important",
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickShowPassword} edge="end" aria-label="show or hide hidden password text">
                            {showPassword ? <VisibilityOff alt={"Visibility off icon"} /> : <Visibility alt={"Visibility on icon"} />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      ...commonInputStyles,
                      '& input[type="password"]::-ms-reveal': {
                        display: "none",
                      },
                    }}
                  />
                ) : question.type === "confirmPassword" ? (
                  <Field
                    id={question.name}
                    variant={"outlined"}
                    name={question.name}
                    placeholder={`Enter your ${convertCamelCaseToWords(question.name)}`}
                    as={TextField}
                    type={showConfirmPassword ? "text" : "password"}
                    InputProps={{
                      style: {
                        color: "black !important",
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickShowConfirmPassword} edge="end" aria-label="show or hide hidden confirm password text">
                            {showConfirmPassword ? <VisibilityOff alt={"Visibility off icon"} /> : <Visibility alt={"Visibility on icon"} />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      ...commonInputStyles,
                      '& input[type="password"]::-ms-reveal': {
                        display: "none",
                      },
                    }}
                  />
                ) : question.type === "select" ? (
                  <Field name={question.name} id={question.name}>
                    {({ field, form }) => (
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          {...field}
                          multiple={question.multi}
                          value={safeJSONParse(field.value) || []}
                          onChange={(event) => {
                            const value = event.target.value;
                            const formattedValue = question.multi ? JSON.stringify(value) : value;
                            form.setFieldValue(question.name, formattedValue);
                            console.log("form", form);
                          }}
                          input={
                            <OutlinedInput
                              endAdornment={
                                <InputAdornment position="end">
                                  {/* <IconButton edge="end" aria-label={`Expand to show more ${convertCamelCaseToWords(question.name)} options`}> */}
                                  <ExpandMoreIcon alt={"Expand more Icon"} sx={{ zIndex: "-1" }} />
                                  {/* </IconButton> */}
                                </InputAdornment>
                              }
                            />
                          }
                          label={convertCamelCaseToWords(question.name)}
                          displayEmpty
                          renderValue={(selected) => {
                            if (!selected.length) {
                              return (
                                <MKTypography component="p" sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.55)" }}>
                                  Select your {convertCamelCaseToWords(question.name)}
                                </MKTypography>
                              );
                            }
                            return Array.isArray(selected) ? selected.join(", ") : selected;
                          }}
                          MenuProps={menuProps}
                          sx={{
                            "& .MuiInputBase-input": {
                              color: "black !important",
                              height: "44px",
                              display: "flex",
                              alignItems: "center",
                              padding: "10px 14px",
                              marginRight: "-40px",
                            },
                            "& .MuiFormLabel-root": {
                              color: "black !important",
                            },
                            height: "44px",
                            width: "100%",
                          }}
                        >
                          {question.options.map((option, optionIndex) => (
                            <MenuItem key={optionIndex} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Field>
                ) : question.type === "number" ? (
                  <Field
                    id={question.name}
                    name={question.name}
                    as={TextField}
                    type="number"
                    variant={"outlined"}
                    placeholder={`Enter your ${convertCamelCaseToWords(question.name)}`}
                    InputProps={{
                      style: {
                        color: "black !important",
                      },
                    }}
                    sx={commonInputStyles}
                  />
                ) : question.type === "tel" ? (
                  <Field
                    id={question.name}
                    name={question.name}
                    as={TextField}
                    type="tel"
                    variant={"outlined"}
                    placeholder={`Enter your ${convertCamelCaseToWords(question.name)}`}
                    InputProps={{
                      style: {
                        color: "black !important",
                      },
                    }}
                    sx={commonInputStyles}
                  />
                ) : question.type === "file" ? (
                  <>
                    <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={(e) => handleFileChange(e, question.name)} />
                    <TextField
                      variant="outlined"
                      name={question.name}
                      value={fileName}
                      placeholder="Choose a file"
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <MKButton variant="contained" onClick={handleButtonClick}>
                            Browse
                          </MKButton>
                        ),
                        style: {
                          color: "black !important",
                        },
                      }}
                      sx={commonInputStyles}
                    />
                    {src ? (
                        <Stack sx={{width: "80% !important", padding: "1rem", margin: "auto !important", backgroundColor: theme.palette.grey[100]}}>
                        <TAImageCrop 
                          src={src} 
                          crop={crop}
                          setCrop={setCrop}
                          onCropComplete={onCropComplete}
                          locked={false} 
                          circularCrop={false} 
                          aspect={1} o
                          onImageLoaded={onImageLoaded} 
                        /> 
                        <MKButton onClick={() => {onCropComplete(crop)}}>Crop</MKButton>
                        </Stack>
                        ) : null}
                        {croppedImageUrl && (
                          <Container sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <img src={croppedImageUrl} alt="Cropped_Image" />
                        </Container>
                        )}
                    
                  </>
                ) : (
                  <Field
                    id={question.name}
                    variant={"outlined"}
                    name={question.name}
                    as={TextField}
                    placeholder={`Enter your ${convertCamelCaseToWords(question.name)}`}
                    InputProps={{
                      style: {
                        color: "black !important",
                      },
                    }}
                    sx={commonInputStyles}
                  />
                )}
                <ErrorMessage name={question.name || ""} component="div" style={{ color: "red", fontSize: "12px" }} />
              </Stack>
            ))}
          </MKBox>
        </>
      )}
    </>
  );
};

export default StepComponent;
