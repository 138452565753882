import React from "react";
import { StyledPersonalizedJourneySectionContainer } from "./index.styles";
import { Grid } from "@mui/material";
import MKBox from "components/MKBox";
import useMediaQueries from "utils/mediaQueries.utils";
import MKTypography from "components/MKTypography";
import theme from "assets/theme";
const PersonalizedJourney = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledPersonalizedJourneySectionContainer>
      <Grid container columnSpacing={isLg ? 6 : 3} rowSpacing={isLg ? 0 : 6} alignItems={"center"}>
        <Grid item xs={12} lg={4}>
          <MKBox
            component="img"
            src="https://d3a0rs3velfrz.cloudfront.net/customizePackage/customizeYourJourney3.webp"
            sx={{ width: "100%", height: "100%" }}
          ></MKBox>
        </Grid>
        <Grid item xs={12} lg={8}>
          <MKTypography component="h1" variant="h1" gutterBottom>
            <span style={{ color: theme.palette.accent.main }}>Personalize</span> Your Healing Journey
          </MKTypography>
          <MKTypography component="p" variant={isLg ? "body1" : "body2"} gutterBottom>
            At Almadelic, we believe that your mental wellness journey is as unique as you are. That's why we offer a range of additional services to
            complement your at-home ketamine treatment, allowing you to create a personalized experience that best supports your healing process.
          </MKTypography>
          <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
            Customize your Almadelic experience by selecting the services that resonate with you, and our team will be there to support you every step
            of the way. Start your personalized healing journey today.
          </MKTypography>
        </Grid>
      </Grid>
    </StyledPersonalizedJourneySectionContainer>
  );
};

export default PersonalizedJourney;
