import { Box, Container } from "@mui/material";
import theme from "assets/theme";
import MKTypography from "components/MKTypography";
import React from "react";
import useMediaQueries from "utils/mediaQueries.utils";
const PageHeader = () => {
  const { isLg } = useMediaQueries();
  return (
    <Box
      id="candidate-header-wrapper"
      sx={{
        backgroundImage: `url(${"https://d3a0rs3velfrz.cloudfront.net/bg-gradient.svg"})`,
        backgroundSize: "cover",
        minHeight: "320px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Container sx={{ textAlign: "center", justifyContent: "center" }}>
        <MKTypography variant={"h1"} sx={{ color: `${theme.palette.white.main} !important` }} gutterBottom>
          Interested in <span style={{ color: theme.palette.accent.main }}> better health?</span>
        </MKTypography>
        <MKTypography component="p" variant={isLg ? "body1" : "body2"} sx={{ color: "#fff" }}>
          Please take a moment to answer a few questions to see if our program might be right for you.
        </MKTypography>
      </Container>
    </Box>
  );
};

export default PageHeader;
