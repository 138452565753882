import styled from "@emotion/styled";
import { Container } from "@mui/material";
import theme from "assets/theme";
import MKBox from "components/MKBox";

export const StyledVerticalDivider = styled(MKBox)(({ color, marginInline }) => ({
  border: color ? `1px solid ${color}` : `1px solid ${theme.palette.accent.main}`,
  marginInline: marginInline && "2rem",
}));

export const StyledSectionContainer = styled(Container)(({ theme, paddingBlock }) => ({
  paddingBlock: paddingBlock ? paddingBlock : "4rem",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: paddingBlock ? paddingBlock : "6rem",
  },
}));
