import React from "react";
import { useTheme } from "@mui/material/styles";

// Components
import MKTypography from "components/MKTypography";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import MKButton from "components/MKButton";

// Styles
import { StyledMainPageContainer } from "index.styles";

// SEO
import SEO from "components/CBSEO";
import seoData from "./seo.data.json";
import { Styled404SectionContainer } from "./index.styles";

// Utils
import useMediaQueries from "utils/mediaQueries.utils";

const Page404 = () => {
  const theme = useTheme();
  const { isMd } = useMediaQueries();

  return (
    <StyledMainPageContainer id={"404-page"}>
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} prerenderStatusCode={"404"} />
      <Box
        position={"relative"}
        minHeight={"calc(100vh - 247px)"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        height={1}
        marginTop={-12}
        paddingTop={12}
      >
        <Styled404SectionContainer>
          <Grid container sx={{ overflow: "visible !important" }} spacing="2rem">
            <Grid item container alignItems={"center"} justifyContent={"center"} xs={12} md={5} order={isMd ? 0 : 1}>
              <Box>
                <MKTypography variant="h1" component={"h1"} align={isMd ? "left" : "center"} sx={{ fontWeight: 700 }}>
                  404
                </MKTypography>
                <MKTypography component="p" color="text.secondary" align={isMd ? "left" : "center"}>
                  Oops! Looks like you followed a bad link.
                  <br />
                  If you think this is a problem with us,{" "}
                  <Link to={"/contact-us"} style={{ color: theme.palette.accent.main }}>
                    contact us
                  </Link>
                </MKTypography>
                <Box marginTop={4} display={"flex"} justifyContent={{ xs: "center", md: "flex-start" }}>
                  <MKButton variant="contained" color="secondary" size="large" href="/">
                    Back home
                  </MKButton>
                </Box>
              </Box>
            </Grid>
            <Grid item container justifyContent={"center"} xs={12} md={7} order={isMd ? 1 : 0} sx={{ overflow: "visible !important" }}>
              <img
                height={isMd ? "516" : "300"}
                width={isMd ? "800" : "500"}
                alt={"Drawing of a Person looking for something that is not there."}
                src={"https://d3a0rs3velfrz.cloudfront.net/home/hero/HeroImgSm.webp"}
                style={{
                  filter: theme.palette.mode === "dark" ? "brightness(0.8)" : "none",
                  objectFit: "cover",
                  transform: " scaleX(-1)",
                }}
              />
            </Grid>
          </Grid>
        </Styled404SectionContainer>
      </Box>
    </StyledMainPageContainer>
  );
};

export default Page404;
