import styled from "@emotion/styled";
import { Container } from "@mui/material";
import MKBox from "components/MKBox";

export const StyledDayOfSessionSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));

export const StyledDayOfSessionCardContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
  padding: 0,
}));

// export const StyledDayOfSessionImage = styled(MKBox)(({ theme }) => ({
//   backgroundPosition: "center center",
//   backgroundSize: "cover",
//   height: "300px",
//   width: "100%",
//   borderRadius: "12px 12px 0 0",
//   [theme.breakpoints.up("lg")]: {
//     borderRadius: "0 0 0 0",
//     minWidth: "300px",
//     minHeight: "300px",
//     maxWidth: "300px",
//   },
// }));

export const StyledPreparationText = styled(MKBox)(({ theme }) => ({
  padding: "1.5rem",
}));
