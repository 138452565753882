import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
// Themes/Styles
import theme from "assets/theme";
// Components
import { Stack } from "@mui/material";
import MKTypography from "components/MKTypography";
import { StyledDropdownBox, StyledNavBox, StyledNavLink } from "./index.styles";

const DesktopNavList = ({ routes }) => {
  const [hovered, setHovered] = useState(null);
  const timerRef = useRef(null);

  const handleMouseEnter = (index) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setHovered(index);
  };

  const handleMouseLeave = () => {
    timerRef.current = setTimeout(() => {
      setHovered(null);
    }, 250);
  };

  return (
    <Stack direction={"row"}>
      {routes.map((item, index) => (
        <StyledNavBox key={item.name} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave}>
          <StyledNavLink variant="button" color="inherit" component={item.route ? Link : "span"} to={item.route || null}>
            <MKTypography
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              sx={{ fontSize: { xs: "18px", lg: "16px" }, color: theme.palette.primary.main, fontWeight: "100%" }}
            >
              {item.name}
            </MKTypography>
          </StyledNavLink>
          {item.collapse && (
            <StyledDropdownBox isVisible={hovered === index}>
              {item.collapse.map(({ key, route, name }, indexz) => (
                <MKTypography
                  key={indexz}
                  onClick={() => {
                    setHovered(null);
                  }}
                  variant="button"
                  py={0.625}
                  px={2}
                  textTransform="capitalize"
                  sx={{
                    width: "100%",
                    display: "block",
                    borderRadius: theme.borders.borderRadius.md,
                    "&:hover": {
                      backgroundColor: theme.palette.grey[200],
                      color: theme.palette.dark.main,
                      "& *": {
                        color: theme.palette.dark.main,
                      },
                    },
                  }}
                >
                  <MKTypography variant="body2" component={Link} to={route} key={key}>
                    {name}
                  </MKTypography>
                </MKTypography>
              ))}
            </StyledDropdownBox>
          )}
        </StyledNavBox>
      ))}
    </Stack>
  );
};

export default DesktopNavList;
