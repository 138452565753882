import { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "utils/firebase/index.firebase";

const usePasswordReset = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [error, setError] = useState("");

  const sendPasswordReset = async (email) => {
    setIsLoading(true);
    setError("");
    setStatus("sending");

    try {
      await sendPasswordResetEmail(auth, email);
      setStatus("success");
      alert("Please check your email to reset your password.");
    } catch (error) {
      console.error("Error sending password reset email:", error);
      setError("Failed to send password reset email. Please try again.");
      setStatus("error");
      alert(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { sendPasswordReset, isLoading, status, error };
};

export default usePasswordReset;
