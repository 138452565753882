// Major
import React, {useState} from "react";

// Components
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import TAEmbeddedYT from "components/TAEmbeddedYT";

// Utils
import { findCustomFieldValue } from "utils/helpers/findCustomFieldValue";
import useMediaQueries from "utils/mediaQueries.utils";

// Styles
import { StyledBodyContainer, StyledBodyStackSection } from "./index.styles";
import { Container, Stack  } from "@mui/material";
import theme from "assets/theme";


const AboutMe = ({currentUserData}) => {
    const { email, phone, state, city, name, customFields } = currentUserData || [];
    const longBio = findCustomFieldValue("Long Bio", customFields);
    const youtubeURL = findCustomFieldValue("YouTube Link", customFields);
    const [showMore, setShowMore] = useState(false);
    
    const handleReadMore = () => {
        setShowMore(!showMore);
    };

    const { isLg, isMd } = useMediaQueries();
    
    const maxChars = isLg ? 1000 : 400;
    const isLongText = longBio?.length > maxChars;
    const displayText = showMore ? longBio : `${longBio?.slice(0, maxChars)}${isLongText ? "..." : ""}`;


    const ReadMoreButton = () => {
        if (longBio?.length < maxChars) return null;
        return (
            <MKButton color="secondary" size="small" sx={{ width: "120px", marginTop: "1.0rem" }} onClick={handleReadMore}>
                {showMore ? "Read Less" : "Read More"}
            </MKButton>
        )
    }

    const AboutMeBody = () => {
        if(isLg){
            return (
                <StyledBodyStackSection direction={isLg ? "row" : "column"} spacing={ 2 } >
                    <Container sx={{padding: isLg ? "1rem 0rem !important" : "0rem !important"}}>
                        <MKTypography component="h2" variant="h5" gutterBottom>
                            About Me
                        </MKTypography>
                        <MKTypography component="p" variant={"body2"} sx={{padding: "0rem 0.5rem"}}>
                                {displayText}
                                <span
                                        role="button"
                                        tabIndex={0}
                                        onClick={handleReadMore}
                                        onKeyDown={handleReadMore}
                                        style={{ color: theme.palette.info.main, cursor: "pointer" }}
                                    >
                                        {showMore ? "Read Less" : "Read More"}
                                    </span>
                        </MKTypography> 
                    </Container>
                    {youtubeURL ? <TAEmbeddedYT youtubeURL={youtubeURL} /> : null}
                </StyledBodyStackSection>)   
        } else{
            return(
                <StyledBodyStackSection direction={isLg ? "row" : "column"} spacing={ 2 } >
                    {youtubeURL ? <TAEmbeddedYT youtubeURL={youtubeURL} /> : null}
                    <Container sx={{padding: isLg ? "1rem" : "0rem !important"}}>
                        <MKTypography component="h2" variant="h5" gutterBottom>
                            About Me
                        </MKTypography>
                        <MKTypography component="p" variant={"body2"} sx={{padding: "0rem 0.5rem"}}>
                                {displayText}
                                <span
                                        role="button"
                                        tabIndex={0}
                                        onClick={handleReadMore}
                                        onKeyDown={handleReadMore}
                                        style={{ color: theme.palette.info.main, cursor: "pointer" }}
                                    >
                                        {showMore ? "Read Less" : "Read More"}
                                    </span>
                        </MKTypography> 
                    </Container>
                </StyledBodyStackSection>
            )
        }
    }

    

    return (
        <StyledBodyContainer id="therapist-bio-about-me">
            <AboutMeBody />
        </StyledBodyContainer>
    );
}

export default AboutMe;

