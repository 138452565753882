import styled from "@emotion/styled";
import { Box, Container, Grid } from "@mui/material";

export const StyledAboutKetamineSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  position: "relative",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));

export const StyledWhyChooseKetamineWrapper = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${"https://d3a0rs3velfrz.cloudfront.net/bg-gradient.svg"})`,
  backgroundSize: "cover",
}));

export const StyledWhyChooseKetamineTextGridItem = styled(Grid)(({ theme }) => ({
  marginBottom: "2rem",
}));
