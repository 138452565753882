import React from "react";
import { StyledCreatingYourSpaceMainPageContainer } from "index.styles";
import Hero from "./components/Hero";
import AllCourses from "./components/AllCourses";
import PageFooter from "./components/PageFooter";

const EducationalCourses = () => {
  return (
    <StyledCreatingYourSpaceMainPageContainer>
      <Hero />
      <AllCourses />
      <PageFooter />
    </StyledCreatingYourSpaceMainPageContainer>
  );
};

export default EducationalCourses;
