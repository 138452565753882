import theme from "assets/theme";
import MKTypography from "components/MKTypography";
import React from "react";

const PolicyHeader = ({ header, headerAccent, dateModified }) => {
  return (
    <>
      <MKTypography component="h1" variant="h1" textAlign="center" gutterBottom>
        {header} <span style={{ color: `${theme.palette.accent.main}` }}> {headerAccent}</span>
      </MKTypography>
      <MKTypography
        align="center"
        component="p"
        gutterBottom
        sx={{
          fontSize: 14,
          color: theme.palette.common.black,
        }}
      >
        Last modified on <strong>{dateModified}</strong>
      </MKTypography>
    </>
  );
};

export default PolicyHeader;
