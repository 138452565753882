import MKBox from "components/MKBox";
import React from "react";
import { useNavigate } from "react-router-dom";

const TAIconImage = ({ iconURL, alt, size, link, route }) => {
    const navigate = useNavigate();
    const iconSize = size.toLowerCase() === "sm" ? "10px" : size.toLowerCase() === "md" ? "20px" : size.toLowerCase() === "lg" ? "30px" : null ;
    
    const handleClick = () => {
        if (route) {
            navigate(route);
        } else if (link) {
            window.open(link, "_blank");
        } else {
            return;
        }
    }
    
    
    return (
        <MKBox
            component="img"
            onClick={handleClick}
            alt={alt ? alt : "image icon"}
            src={iconURL}
            sx={{
                width: iconSize ? iconSize : "20px",
                maxHeight: iconSize ? iconSize : "20px",
                transform: iconSize ? { xs: "scale(1.2)", md: "scale(1.2)" } : { xs: "scale(1.2)", md: "scale(1.3)", lg: "scale(1.5)" },
            }}
        ></MKBox>
    );
};

export default TAIconImage;