import React, { useContext, useEffect } from "react";
import { Content, Headline } from "./components";
import PageFooter from "components/CBPageFooter";
import { StyledMainPageContainer } from "index.styles";
import SearchBar from "components/CBSearchBar";
import { SearchFor } from "utils/context/searchFilter.context";
import SchemaComponent from "components/CBSchema";
import faqSchemaData from "./schema.organization.json";
import { constructSchemaData } from "utils/helpers/constructSchemaData";
import SEO from "components/CBSEO";
import seoData from "./seo.data.json";

import CBLoading from "components/CBLoading";
import { useLocation } from "react-router-dom";

const Faq = () => {
  const schemaData = constructSchemaData(faqSchemaData.url, faqSchemaData.breadcrumbs);
  const location = useLocation();
  const { searchTerms, resetSearchTerms, setSearchBarTerms, filteredData, pathName } = useContext(SearchFor);

  const handleOnSearchChange = (event) => {
    const searchField = event.target.value.toLocaleLowerCase();
    setSearchBarTerms(searchField);
  };
  useEffect(() => {
    resetSearchTerms();
  }, []);

  


  return (
    <StyledMainPageContainer id="faq-page-container">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />

      <Headline />
      <SearchBar
        pathName={pathName}
        searchTerms={searchTerms}
        onChangeHandler={handleOnSearchChange}
        placeholder="Search by billing, refunds, safety, treatments..."
      />

      {location.pathname === pathName ? <Content filteredData={filteredData} /> : <CBLoading />}
      <PageFooter content="Didn't Find What You Are Looking For?" to="/contact-us" buttonText={"Contact Our Team Today!"} internalLink="true" />
    </StyledMainPageContainer>
  );
};

export default Faq;
