import React from "react";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import { StyledHeroImage, StyledHeroImageGridItem, StyledHeroSectionContainer, StyledHeroTextGridItem } from "./index.styles";
import { StyledGridContainer, StyledButtonContainer } from "index.styles";
import useMediaQueries from "utils/mediaQueries.utils";
import { ghlLinkFreeConsultation } from "utils/helpers/ghlFunnelLinks";

const Hero = () => {
  const theme = useTheme();
  const { isMd, isLg } = useMediaQueries();

  return (
    <StyledHeroSectionContainer component="section" id="hero-section">
      <StyledGridContainer container justifyContent={"space-between"}>
        <StyledHeroTextGridItem item order={isMd ? "2" : "2"} xs={12} md={8} lg={6}>
          <MKTypography variant="h1" component="h1" gutterBottom>
            Journey Of The<span style={{ color: theme.palette.accent.main }}> Mind</span>
            <br />
            <MKTypography component={"span"} color={"inherit"} variant={"inherit"}>
              Healing Of The
              <span style={{ color: `${theme.palette.secondary.main}` }}> Soul</span>
            </MKTypography>
          </MKTypography>
          <MKTypography variant={isLg ? "body1" : "body2"} component="p" sx={{ maxWidth: "500px" }} gutterBottom>
            Explore the transformative possibilities of psychedelic therapies as your pathway to renewed mental vitality. Connect with a healthcare
            professional now to embark on your journey towards lasting happiness and well-being.
          </MKTypography>
          <MKTypography component="em" variant={isMd ? "body1" : "body2"} sx={{ color: theme.palette.accent.main }}>
            Have Questions? Schedule your complementary consultation!
          </MKTypography>
          <StyledButtonContainer>
            <MKButton
              component={Link}
              to={ghlLinkFreeConsultation}
              target="_blank"
              rel="noopener noreferrer"
              variant="contained"
              color="secondary"
              size="large"
              aria-label="Schedule your consultation today"
              fullWidth={isMd ? false : true}
            >
              Schedule Your consultation today
            </MKButton>
          </StyledButtonContainer>
        </StyledHeroTextGridItem>
        <StyledHeroImageGridItem item order={isMd ? "1" : "1"} xs={12} md={1} lg={5}>
          <StyledHeroImage
            component={"img"}
            height={1}
            width={1}
            src={"https://d3a0rs3velfrz.cloudfront.net/home/hero/HeroImgSm.webp"}
            alt="Almadelic Home Page Image - an colorful abstract portrait of a womans face depicted as a tree."
          />
        </StyledHeroImageGridItem>
      </StyledGridContainer>
    </StyledHeroSectionContainer>
  );
};
export default Hero;
