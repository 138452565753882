import { Box, Grid, useMediaQuery } from "@mui/material";
import theme from "assets/theme";
import rgba from "assets/theme/functions/rgba";
import MKTypography from "components/MKTypography";
import React from "react";
import useMediaQueries from "utils/mediaQueries.utils";
import { StyledAllCoursesCardDivider } from "../index.style";
import { StyledCourseCardBox } from "./index.style";

const CourseCard = ({ title, content }) => {
  return (
    <Grid item xs={12} lg={12}>
      <StyledCourseCardBox
        sx={{
          boxShadow: `0 0 10px rgba(128, 128, 128, .5), inset 10px 0px 0px ${rgba(
            theme.palette.accent.main,
            1
          )}`,
        }}
      >
        <MKTypography variant={"h3"} component="h3">
          {title}
        </MKTypography>
        <StyledAllCoursesCardDivider />

        <MKTypography variant={"body2"} component="h6" maxWidth={900}>
          {content}
        </MKTypography>
      </StyledCourseCardBox>
    </Grid>
  );
};

export default CourseCard;
