import { useQuery } from "@tanstack/react-query";
import axios from "axios";

// Function to fetch products
const fetchProducts = async () => {
  const response = await axios.get("https://lbealjhg80.execute-api.us-east-1.amazonaws.com/dev/products", {
    headers: {
      "Content-Type": "application/json",
    },
  });
  const fetchedProducts = JSON.parse(response.data.body);

  const packageItems = fetchedProducts.filter((product) => product.metadata?.type === "package");
  const addonItems = fetchedProducts.filter((product) => product.metadata?.type === "addon");

  return {
    products: fetchedProducts,
    packages: packageItems,
    addons: addonItems,
  };
};

const useFetchProducts = () => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["products"],
    queryFn: fetchProducts,
    staleTime: 30 * 60 * 1000, // 30 minutes
    cacheTime: 60 * 60 * 1000, // 1 hour
    refetchOnWindowFocus: false, // Do not refetch on window focus
  });

  return {
    products: data?.products || [],
    packages: data?.packages || [],
    addons: data?.addons || [],
    loading: isLoading,
    error: isError ? error : null,
  };
};

export default useFetchProducts;
