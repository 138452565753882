import React from "react";
import { Helmet } from "react-helmet";

const MetaTags = ({currentUserData}) => {
    const { email, phone, state, city, name, customFields } = currentUserData || [];
    const url = `/therapist-bio/${name?.replace(/\s+/g, "_")}_${city?.replace(/\s+/g, "_")}_${state?.replace(/\s+/g, "_")}/${currentUserData?.id}`;
    return (
        <Helmet>
            <meta property="og:type" content={"website"} />
            <meta property="og:title" content={`Almadelic | ${name}`} />
            <meta property="og:description" content={`Learn more about ${name} on their Bio page Here!`} />
            <meta property="og:url" content={`${url}`} />
            <meta property="og:image" content={"https://d3a0rs3velfrz.cloudfront.net/metaTags/almadelic_logo_h.webp"} />
            <meta name="og:image:height" content={"600px"} />
            <meta name="og:image:width" content={"600px"} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={`Almadelic | ${name}`} />
            <meta name="twitter:image" content={"https://d3a0rs3velfrz.cloudfront.net/metaTags/almadelic_logo_11.webp"} />
        </Helmet>
    )
};

export default MetaTags;