import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
export const StyledWhatIsNeuroPlasticityInnerGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

export const StyledWhatIsNeuroPlasticityButtonGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
}));

export const StyledWhatIsNeuroplasticitySectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  display: "flex",
  flexDirection: "column",
  gap: "4rem",

  [theme.breakpoints.up("lg")]: {
    paddingBlock: "4rem",
  },
}));
