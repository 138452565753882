import { Grid } from "@mui/material";
import TwoColumnGrid from "components/CBTwoColumnGrid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React from "react";
import { StyledSectionContainer } from "styles/components";

const NextSteps = ({ shippingAddress }) => {
  const address = JSON.parse(shippingAddress).state.toLowerCase();
  return (
    <Grid item xs={12}>
      <StyledSectionContainer>
        <TwoColumnGrid
          src="https://d3a0rs3velfrz.cloudfront.net/orderConfirmation/next-steps.png"
          imgHeight="300px"
          title="Next Steps"
          titleAccent="Your"
          titleVariant="h2"
          titleComponent="h2"
          content={[
            "To ensure a smooth and effective start to your therapy, the next critical step is to schedule your Initial Clinical Evaluation. This 45-minute appointment with a licensed mental health specialist is key to tailoring your therapy to your unique needs.",
          ]}
          cta="Schedule Your Clinical Evaluation Today!"
          ctaUrl={
            address === "colorado"
              ? "https://clinical.almadelic.com/get-started-clinical-evaluation-colorado"
              : address === "ohio"
              ? "https://clinical.almadelic.com/get-started-clinical-evaluation-ohio"
              : address === "florida"
              ? "https://clinical.almadelic.com/get-started-clinical-evaluation-florida"
              : "https://clinical.almadelic.com/get-started-clinical-evaluation"
          }
        />
        <MKBox mt={"2rem"}>
          {[
            "This link will take you to our scheduling platform, where you can choose a time and date for your evaluation that best fits your schedule. The evaluation is an opportunity to discuss your needs, any concerns you might have, and ensure that at-home ketamine therapy is the right path for you.",
            "Should you have any questions or need assistance at any point, please do not hesitate to reach out to our support team. We are here to help make your journey as comfortable and effective as possible.",
            "Thank you once again for choosing Almadelic. We look forward to being a part of your journey to healing and growth.",
          ].map((text, index) => (
            <MKTypography key={index} component="p" variant={"body2"} gutterBottom>
              {text}
            </MKTypography>
          ))}
        </MKBox>
        <MKTypography component="p" variant={"body2"} gutterBottom>
          Warm regards, <br />
          The Almadelic Team
        </MKTypography>
      </StyledSectionContainer>
    </Grid>
  );
};

export default NextSteps;
