import React from "react";
import { Link } from "react-router-dom";
// Components
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
// Styled components
import { StyledTextBox, StyledYourJourneySectionContainer } from "./index.styles";
import { StyledButtonContainer } from "index.styles";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Theme
import theme from "assets/theme";

const YourJourneyStarts = () => {
  const { isMd, isLg } = useMediaQueries();

  return (
    <StyledYourJourneySectionContainer>
      <StyledTextBox>
        {isMd || isLg ? (
          <MKTypography gutterBottom component={"h4"} variant={"h2"}>
            Your Journey to
            <span style={{ color: theme.palette.accent.main, fontSize: "inherit", fontWeight: "inherit" }}> Better Health</span>
            <br />
            <span style={{ color: theme.palette.accent.main, fontSize: "inherit", fontWeight: "inherit" }}>Begins at </span>
            Almadelic.
          </MKTypography>
        ) : (
          <MKTypography gutterBottom component={"h4"} variant={"h2"}>
            Your Journey to
            <span style={{ color: theme.palette.accent.main, fontSize: "inherit", fontWeight: "inherit" }}> Better Health Begins at </span>
            Almadelic.
          </MKTypography>
        )}
        <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
          At Almadelic, we understand that the prospect of a psychedelic experience can be both exciting and daunting. We're here to assure you that
          your journey with us will be safe, supported, and transformative. Here's how we assist you in navigating this unique path towards better
          health.
        </MKTypography>
        <StyledButtonContainer>
          <MKButton
            component={Link}
            to={"https://shop.almadelic.com/"}
            target="_blank"
            rel="noopener noreferrer"
            variant="contained"
            color="secondary"
            size="large"
            fullWidth={isMd ? false : true}
            aria-label="Get Started Today"
          >
            Get Started Today!
          </MKButton>
        </StyledButtonContainer>
      </StyledTextBox>
    </StyledYourJourneySectionContainer>
  );
};

export default YourJourneyStarts;
