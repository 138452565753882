import React from "react";

import MobileNavList from "./MobileNavList";
import DesktopNavList from "./DeskTopNavList";
import useMediaQueries from "utils/mediaQueries.utils";

const NavList = ({ routes, mobileNavToggle, setMobileNavToggle }) => {
  const { isNavMenu } = useMediaQueries();

  return (
    <>
      {isNavMenu ? (
        <MobileNavList routes={routes} mobileNavToggle={mobileNavToggle} setMobileNavToggle={setMobileNavToggle} />
      ) : (
        <DesktopNavList routes={routes} />
      )}
    </>
  );
};

export default NavList;
