import React from "react";
// Components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
// Theme
import { useTheme } from "@mui/material/styles";
import { StyledImage } from "index.styles";
import { StyledContactUsHeroSectionContainer, StyledContactUsHeroOuterGrid, StyledContactUsHeroImageOuterGrid } from "./index.style";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
const Hero = () => {
  const theme = useTheme();
  const { isLg } = useMediaQueries();
  return (
    <StyledContactUsHeroSectionContainer component={"section"} id={"contact-us-hero-section"}>
      <StyledContactUsHeroOuterGrid container columnSpacing={isLg ? 6 : 3} rowSpacing={isLg ? 0 : 6}>
        <Grid item alignItems={"center"} xs={12} lg={6} order={isLg ? 0 : 1}>
          <Box>
            <MKTypography variant="h1" component="h1" gutterBottom>
              Contact <span style={{ color: theme.palette.accent.main }}>Us</span>
            </MKTypography>
          </Box>
          <Box>
            <MKTypography variant={isLg ? "body1" : "body2"} component="p">
              We are here to answer your questions and provide you with the support and information you need. Whether you're interested in our
              ketamine therapy services or have general inquiries, we are just a message away. Feel free to reach out to us!
            </MKTypography>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <StyledContactUsHeroImageOuterGrid>
            <Grid>
              <StyledImage
                component={"img"}
                src={"https://d3a0rs3velfrz.cloudfront.net/contactUs/contact_us_new.webp"}
                alt="Person on their laptop contacting us."
              />
            </Grid>
          </StyledContactUsHeroImageOuterGrid>
        </Grid>
      </StyledContactUsHeroOuterGrid>
    </StyledContactUsHeroSectionContainer>
  );
};

export default Hero;
