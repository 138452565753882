import styled from "@emotion/styled";
import { Container } from "@mui/material";

export const StyledImportanceOfSettingSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  flexWrap: "wrap",

  [theme.breakpoints.up("md")]: {
    minHeight: "625px",
  },
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
    minHeight: "650px",
    alignItems: "center",
    paddingInline: "0 !important",
  },
  [theme.breakpoints.up("xl")]: {
    minHeight: "650px",
  },
  [theme.breakpoints.up("xxl")]: {
    // minHeight: "850px",
  },
}));
