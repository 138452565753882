import MKTypography from "components/MKTypography";
import OrderSummary from "components/Ecom/components/orderSummary";
import React from "react";
import ProductList from "components/Ecom/components/productList";
import { StyledVerticalDivider } from "styles/components";
import theme from "assets/theme";
import MKBox from "components/MKBox";
import { StyledSectionContainer } from "styles/components";
import { Grid, Stack } from "@mui/material";
import useMediaQueries from "utils/mediaQueries.utils";
import rgba from "assets/theme/functions/rgba";

const PaymentSummary = ({ discount, originalSubtotal, cartTotal, createdAt, paymentId, paymentMethod, cartItems }) => {
  const { isMd } = useMediaQueries();

  // console.log("discount", discount);
  // console.log("subtotal", originalSubtotal);
  // console.log("cart total", cartTotal);
  return (
    <Grid item xs={12} lg={5}>
      <StyledSectionContainer>
        <MKBox sx={{ border: `2px solid ${rgba(theme.palette.grey[300], 0.5)}`, padding: "1rem" }}>
          <MKTypography component="h4" variant="h4" mb={"1rem"} sx={{ color: `${theme.palette.white.main} !important` }}>
            Order Summary
          </MKTypography>
          <Stack
            direction={isMd ? "row" : "column"}
            spacing={2}
            mb={"2rem"}
            sx={{ backgroundColor: theme.palette.white.main, padding: ".5rem 1rem", borderRadius: "8px" }}
          >
            <Stack>
              <MKTypography component="small" sx={{ fontSize: "14px", fontWeight: "bold", color: theme.palette.primary.main }}>
                Date
              </MKTypography>
              <MKTypography component="p" variant="body2">
                {createdAt}
              </MKTypography>
            </Stack>
            <StyledVerticalDivider color={theme.palette.grey[400]} />
            <Stack>
              <MKTypography component="small" sx={{ fontSize: "14px", fontWeight: "bold", color: theme.palette.primary.main }}>
                Order Number
              </MKTypography>
              <MKTypography component="p" variant="body2">
                {Array.isArray(paymentId) && paymentMethod.length > 0 ? paymentId[0].slice(0, 15) : paymentId.slice(0, 15)}
              </MKTypography>
            </Stack>
            <StyledVerticalDivider color={theme.palette.grey[400]} />
            <Stack>
              <MKTypography component="small" sx={{ fontSize: "14px", fontWeight: "bold", color: theme.palette.primary.main }}>
                Payment
              </MKTypography>
              <MKTypography component="p" variant="body2">
                {Array.isArray(paymentMethod) && paymentMethod.length > 0
                  ? paymentMethod[0].charAt(0).toUpperCase() + paymentMethod[0].slice(1)
                  : paymentMethod.charAt(0).toUpperCase() + paymentMethod.slice(1)}
              </MKTypography>
            </Stack>
          </Stack>
          {/* <ProductList textColor="light" cartItems={JSON.parse(cartItems)} marginBottom={"true"} /> */}
          <OrderSummary textColor="accent" cartTotal={cartTotal} discount={discount} originalSubtotal={originalSubtotal} />
        </MKBox>
      </StyledSectionContainer>
    </Grid>
  );
};

export default PaymentSummary;
