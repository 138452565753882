import styled from "@emotion/styled";
import { Container, Divider, Grid } from "@mui/material";
import MKBox from "components/MKBox";

export const StyledAllCoursesSectionContainer = styled(Container)(
  ({ theme }) => ({
    paddingBlock: "4rem",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  })
);

export const StyledAllCoursesDivider = styled(Divider)(({ theme }) => ({
  height: "3px",
  backgroundColor: "rgba(117, 205, 197, 1)",
  opacity: "1",
  width: "100%",
  marginTop: "6rem",
  marginBottom: "4rem",
}));

export const StyledAllCoursesCardDivider = styled(Divider)(({ theme }) => ({
  height: "3px",
  backgroundColor: "rgba(117, 205, 197, 1)",
  opacity: "1",
  width: "100%",
  margin: "0",
}));

export const StyledAllCoursesCardGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
  [theme.breakpoints.up("lg")]: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));
