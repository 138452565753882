import styled from "@emotion/styled";
import { Container } from "@mui/material";

export const StyledSearchBarSectionContainer = styled(Container)(({ theme, minusPadding }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  paddingTop: minusPadding ? "1rem" : "4rem",
  [theme.breakpoints.up("lg")]: {
    paddingTop: minusPadding ? "1rem" : "6rem",
  },
}));
