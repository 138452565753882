import React from "react";
// Components
import { Grid, useMediaQuery } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
// Styled Components
import { StyledHowItWorksSectionContainer, StyledHowItWorksVideoBox } from "./index.styles";
// Theme
import theme from "assets/theme";
const HowItWorks = () => {
  const isLg = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });

  return (
    <StyledHowItWorksSectionContainer component="section" id="how-it-works-section">
      <Grid container spacing={isLg ? 8 : 4} alignItems={"center"}>
        <Grid item xs={12} lg={6} order={isLg ? 0 : 1}>
          <MKBox>
            <MKTypography gutterBottom variant="h2" component="h2">
              How Does <span style={{ color: theme.palette.accent.main }}>Ketamine Work?</span>
            </MKTypography>
            <MKTypography variant={isLg ? "body1" : "body2"} component="p">
              The workings of ketamine in the brain are intricate and continue to be a subject of ongoing research. Ketamine interacts with
              neurotransmitters such as Glutamate and GABA, modifying neural activity within the prefrontal cortex.
            </MKTypography>
          </MKBox>
        </Grid>
        <Grid item xs={12} lg={6} order={isLg ? 1 : 0}>
          <StyledHowItWorksVideoBox>
            <iframe
              title="Ketamine And Depression: How It Works - By Yale"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/nW21-AYY_fs"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </StyledHowItWorksVideoBox>
        </Grid>
      </Grid>
    </StyledHowItWorksSectionContainer>
  );
};

export default HowItWorks;
