import React, { useContext, useEffect } from "react";
import { Result } from "./components";
import { StyledMainPageContainer } from "index.styles";
import SearchBar from "components/CBSearchBar";
import { SearchFor } from "utils/context/searchFilter.context";
import { constructSchemaData } from "utils/helpers/constructSchemaData";
import blogSchemaData from "./schema.organization.json";
import SchemaComponent from "components/CBSchema";
import SEO from "components/CBSEO";
import seoData from "./seo.data.json";
import CBLoading from "components/CBLoading";
import { useLocation } from "react-router-dom";

const Blog = () => {
  const schemaData = constructSchemaData(blogSchemaData.url, blogSchemaData.breadcrumbs);
  
  const location = useLocation();

  const { searchTerms, resetSearchTerms, setSearchBarTerms, filteredData, pathName } = useContext(SearchFor);
  
  const handleOnSearchChange = (event) => {
    const searchField = event.target.value.toLocaleLowerCase();
    setSearchBarTerms(searchField);
  };

  useEffect(() => {
    resetSearchTerms();
  }, []);


  
  return (
    <StyledMainPageContainer>
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <SearchBar placeholder="Search for blogs here..." pathName={pathName} searchTerms={searchTerms} onChangeHandler={handleOnSearchChange} />
      {location.pathname === pathName ? <Result filteredData={filteredData} /> : <CBLoading />}
    </StyledMainPageContainer>
  );
};

export default Blog;
