import styled from "@emotion/styled";
import { Container } from "@mui/material";
import MKBox from "components/MKBox";

export const StyledMusicAndMentalHealthSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",

  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));

export const StyledMusicAndTherapyImage = styled(MKBox)(({ theme }) => ({
  width: "100%",
  height: "100%",
}));
