import React from "react";
import { Link } from "react-router-dom";
// Styled Components
import { StyledTechnologySectionContainer } from "./index.styles";
import { StyledButtonContainer } from "index.styles";
import { StyledImage } from "index.styles";
// Components
import { Grid } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
// Theme
import theme from "assets/theme";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Icons
import HeadphonesIcon from "@mui/icons-material/Headphones";
const Technology = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledTechnologySectionContainer component="section" id="technology-section">
      <MKTypography component="h2" variant="h2" mb="2rem">
        Testing Your
        <span style={{ color: theme.palette.accent.main }}> Technology</span>
      </MKTypography>

      <Grid container spacing={isLg ? 8 : 4}>
        <Grid item xs={12} lg={12} xl={6}>
          <MKBox>
            <StyledImage
              technology={"true"}
              height="400"
              width="514"
              src={"https://d3a0rs3velfrz.cloudfront.net/planningAhead/technology-section/technology1.webp"}
              alt="Person on there mobile device listening and setting up there music playlist before a ketamine session."
            ></StyledImage>
            <MKTypography component="h3" variant="h3" gutterBottom>
              Music
            </MKTypography>
            <MKTypography component="p" variant={isLg ? "body1" : "body2"} gutterBottom>
              If you plan to use music, take time ahead of your session to test your equipment- if you are using a speaker or headphones, ensure they
              are charged and ready so that the session day goes as planned. We recommend reviewing different playlists ahead of your session. Find
              the sounds that speak to you so that you have one less thing to prepare on the day of your session.
            </MKTypography>
            <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
              Click below to read more about music and ketamine, as well as to find great resources for music to enhance your session.
            </MKTypography>
            <StyledButtonContainer>
              <MKButton
                startIcon={<HeadphonesIcon />}
                component={Link}
                to={"/music-and-therapy"}
                variant="contained"
                color="secondary"
                fontWeight="regular"
                sx={{ fontSize: "0.875rem" }}
                aria-label="Music and Ketamine"
              >
                Music & Ketamine
              </MKButton>
            </StyledButtonContainer>
          </MKBox>
        </Grid>
        <Grid item xs={12} lg={12} xl={6}>
          <StyledImage
            technology={"true"}
            height="400"
            width="514"
            src={"https://d3a0rs3velfrz.cloudfront.net/planningAhead/technology-section/technology2.webp"}
            alt="Person testing out and setting up their blood pressure cuff."
          ></StyledImage>
          <MKTypography component="h3" variant="h3" gutterBottom>
            Blood Pressure Cuff
          </MKTypography>
          <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
            You will need to check and monitor your blood pressure before and during the session. Ahead of your session day, familiarize yourself with
            your blood pressure monitor. Check to ensure there are batteries installed and/or the unit is fully charged, depending on your model.
            Ensure that you can successfully read your blood pressure monitor and follow the instructions.
          </MKTypography>
        </Grid>
      </Grid>
    </StyledTechnologySectionContainer>
  );
};

export default Technology;
