import React, { Fragment } from "react";
// Components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import CBDivider from "components/CBDivider";
import { StyledImage } from "index.styles";
// Styled Components
import {
  StyledWhatToExpectSectionContainer,
  StyledWhatToExpectStepsTypography,
  StyledWhatToExpectTextBox,
  StyledGridContainer,
} from "./index.styles";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Theme
import theme from "assets/theme";

// Data
import whatToExpectData from "./whatToExpect.data.json";

const WhatToExpect = () => {
  const { isSm, isMd, isLg } = useMediaQueries();

  return (
    <StyledWhatToExpectSectionContainer component={"section"} id="what-to-expect-section">
      <StyledWhatToExpectTextBox>
        <MKTypography variant="h2" gutterBottom>
          What to <span style={{ color: `${theme.palette.accent.main}` }}>Expect</span>
        </MKTypography>
        <MKTypography variant={isMd ? "body1" : "body2"} maxWidth={900} margin={"auto"}>
          Our program is carefully crafted to offer personalized support, expert guidance, and a sense of community, ensuring you receive the highest
          quality care every step of the way. Discover the power of Psychedelic Ketamine Therapy (KPT) with us and embark on the path to a happier,
          healthier you. Your healing journey could begin today- here is what to expect from our program.
        </MKTypography>
      </StyledWhatToExpectTextBox>
      <CBDivider />
      <StyledGridContainer container rowSpacing={isLg ? 12 : 6} columnSpacing={6}>
        {whatToExpectData.map((item, i) => (
          <Grid
            key={i}
            item
            container
            xs={12}
            columnSpacing={isLg ? 8 : 4}
            direction={i % 2 === 1 ? "row-reverse" : "row"}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item container alignItems={"center"} xs={12} md={6} mb={isLg ? 0 : 4}>
              <Box>
                <MKTypography variant={"h3"} sx={{ fontWeight: 500, position: "relative" }} gutterBottom>
                  <StyledWhatToExpectStepsTypography component="span">{i + 1}</StyledWhatToExpectStepsTypography>
                  {item.title}
                </MKTypography>
                {Array.isArray(item.description) ? (
                  <>
                    {item.description.map((desc, index) => {
                      return (
                        <Fragment key={index}>
                          <MKTypography maxWidth={"600px"} component="p" variant={isLg ? "body1" : "body2"}>
                            {desc}
                          </MKTypography>
                        </Fragment>
                      );
                    })}
                  </>
                ) : (
                  <MKTypography maxWidth={"600px"} component="p" variant={isLg ? "body1" : "body2"}>
                    {item.description}
                  </MKTypography>
                )}
              </Box>
            </Grid>
            <Grid item container xs={12} md={6} sx={{ display: "flex", justifyContent: isSm ? "center" : i % 2 === 0 ? "flex-end" : "flex-start" }}>
              <StyledImage height="334" width="500" whattoexpect={"true"} src={`${item.illustration}`} alt={item.alt} />
            </Grid>
          </Grid>
        ))}
      </StyledGridContainer>
    </StyledWhatToExpectSectionContainer>
  );
};

export default WhatToExpect;
