import styled from "@emotion/styled";
import { Container } from "@mui/material";
export const StyledRefundPolicySectionContainer = styled(Container)(
  ({ theme }) => ({
    paddingBlock: "4rem",
    [theme.breakpoints.up("lg")]: {
      paddingBlock: "6rem",
    },
  })
);
