// Major
import React from "react";

// Components
import MKTypography from "components/MKTypography";
import { findCustomFieldValue } from "utils/helpers/findCustomFieldValue";

// Utils

// Styles
import { StyledStackContainer, StyledProfessionalInfoContainer} from "./index.styles"; 


const ProfessionalInfo = ({currentUserData}) => {
    const { email, phone, state, city, name, customFields } = currentUserData || [];
    const licenses = findCustomFieldValue("Licenses", customFields);
    const languages = findCustomFieldValue("Languages", customFields);
    const licensedToPracticeIn = findCustomFieldValue("Licensed To Practice In", customFields);
    
    

    // Mapping through list items
    const ListItems = () => {
        const list = []
        const listTitle = ["Licensed States", "Licenses", "Languages"]
        if(licensedToPracticeIn){list.push(licensedToPracticeIn)}
        if(licenses){list.push(licenses)}
        if(languages){list.push(languages)}
        return (
            <>
            {list.map((item, index) => (
                <StyledStackContainer key={index} sx={{ margin: "0.5rem" }}>
                    <MKTypography component="p" variant="body2" fontWeight="light">
                        <b>{listTitle[index]}: </b>{item.join(", ")} 
                    </MKTypography>
                </StyledStackContainer>
            ))}
            </>
        )
    }


    
    return (
        <StyledProfessionalInfoContainer id="therapist-bio-prof-info">
            <MKTypography component="h3" variant="h5" gutterBottom>
                Professional Information
            </MKTypography>
            <ListItems />
        </StyledProfessionalInfoContainer>
    );
}

export default ProfessionalInfo;