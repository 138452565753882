import React from "react";
// Components
import WhatIsKetamineTherapy from "./components/whatIsKetamineTherapy";
import BenefitsOfKetamineTherapy from "./components/benefitsOfKetamineTherapy";
import OurGoal from "./components/ourGoal";
import WhatIsNeuroplasticity from "./components/whatIsNeuroplasticity";
import KetamineAssistedTherapy from "./components/ketamineAssistedTherapy";
import CBDivider from "components/CBDivider";
import SchemaComponent from "components/CBSchema";
import SEO from "components/CBSEO";
// Styled Components
import { StyledWhatIsKetamineMainPageContainer } from "index.styles";
// Utils
import { constructSchemaData } from "utils/helpers/constructSchemaData";
// Data
import whatIsKetamineOrganizationSchemaData from "./schema.organization.json";
import whatIsKetamineArticleSchemaData from "./schema.article.json";
import seoData from "./seo.data.json";

const WhatIsKetamine = () => {
  const schemaData = constructSchemaData(
    whatIsKetamineOrganizationSchemaData.url,
    whatIsKetamineOrganizationSchemaData.breadcrumbs,
    whatIsKetamineArticleSchemaData
  );
  return (
    <StyledWhatIsKetamineMainPageContainer>
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />

      <WhatIsKetamineTherapy />
      <BenefitsOfKetamineTherapy />
      <KetamineAssistedTherapy />
      <CBDivider />
      <WhatIsNeuroplasticity />
      <CBDivider />
      <OurGoal />
    </StyledWhatIsKetamineMainPageContainer>
  );
};

export default WhatIsKetamine;
