import React from "react";
// Components
import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
// Styled Components
import { StyledImage } from "index.styles";
import { StyledChaperoneSectionContainer } from "./index.styles";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Theme
import theme from "assets/theme";

const ChaperoneContent = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledChaperoneSectionContainer>
      <Grid container spacing={isLg ? 8 : 4}>
        <Grid item xs={12} lg={5}>
          <StyledImage
            width="493"
            height="414"
            src="https://d3a0rs3velfrz.cloudfront.net/chaperone/chaperone1.webp"
            alt="Two women sitting on a coach laughing and talking."
          />
        </Grid>
        <Grid item xs={12} lg={7} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <MKTypography component="h2" variant="h2" gutterBottom>
            <span style={{ color: theme.palette.accent.main }}>Chaperoning</span> for At-Home Ketamine Experiences
          </MKTypography>
          <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
            At Almadelic, ensuring patient safety and comfort during ketamine therapy is our top priority. For patients undergoing ketamine treatment
            from the comfort of their own home, we require the presence of a chaperone. This trusted individual plays a vital role in providing
            support and assistance throughout the experience.
          </MKTypography>
        </Grid>
        <Grid item xs={12} lg={5} order={isLg ? 4 : 3}>
          <StyledImage
            width="493"
            height="429"
            src="https://d3a0rs3velfrz.cloudfront.net/chaperone/chaperone2.webp"
            alt="Group of friends laughing and talking"
          />
        </Grid>
        <Grid item xs={12} lg={7} order={isLg ? 3 : 4} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <MKTypography component="h2" variant="h2" gutterBottom>
            <span style={{ color: theme.palette.accent.main }}>What </span>
            is a Chaperone?
          </MKTypography>
          <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
            A chaperone is a responsible adult, at least 18 years old, who the patient trusts and feels at ease with. This could be a spouse, partner,
            close friend, family member, or roommate. It should be someone with whom the patient has a positive, uncomplicated relationship. The
            chaperone will be present during the at-home ketamine session to offer support and assistance as needed.
          </MKTypography>
        </Grid>
      </Grid>
    </StyledChaperoneSectionContainer>
  );
};

export default ChaperoneContent;
