import React, { useState } from "react";
import { Link } from "react-router-dom";

// Styles
import theme from "assets/theme";
import { CustomCollapse, DropdownBox, NavBox, NavLink, StyledMenuBox, StyledMKTypography } from "./index.styles";

// components
import { Collapse, Stack } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import CBDivider from "components/CBDivider";
import LoginAndLogOut from "components/CBLoginLogOut";

const MobileNavList = ({ routes, mobileNavToggle, setMobileNavToggle }) => {
  const [open, setOpen] = useState(null);

  const handleItemClick = (index) => {
    setOpen(open === index ? null : index);
  };

  return (
    <CustomCollapse in={Boolean(mobileNavToggle)} timeout={"auto"} unmountOnExit>
      <StyledMenuBox>
        {routes.map((item, index) => (
          <NavBox key={item.name} onClick={() => handleItemClick(index)}>
            <NavLink variant="button" color="inherit" component={item.route ? Link : "span"} to={item.route || null}>
              <MKTypography
                variant="button"
                fontWeight="regular"
                textTransform="capitalize"
                sx={{ fontSize: { xs: "18px", lg: "16px" }, color: theme.palette.primary.main, fontWeight: "100%", textDecoration: "none" }}
              >
                {item.name}
              </MKTypography>
            </NavLink>
            {item.collapse && (
              <Collapse in={Boolean(open === index)} timeout={"auto"} unmountOnExit>
                <DropdownBox>
                  {item.collapse.map(({ key, route, name }) => (
                    <StyledMKTypography
                      key={key}
                      onClick={() => {
                        setOpen(null);
                      }}
                      variant="button"
                      py={0.625}
                      px={2}
                      textTransform="capitalize"
                    >
                      <MKTypography onClick={() => setMobileNavToggle(!mobileNavToggle)} variant="body2" component={Link} to={route}>
                        {name}
                      </MKTypography>
                    </StyledMKTypography>
                  ))}
                </DropdownBox>
              </Collapse>
            )}
          </NavBox>
        ))}
        <MKBox sx={{ maxWidth: "90%", margin: "auto" }}>
          <CBDivider />
        </MKBox>
        <Stack direction={"row"} justifyContent={"center"}>
          <LoginAndLogOut setMobileNavToggle={setMobileNavToggle} mobileNavToggle={mobileNavToggle} />
        </Stack>
      </StyledMenuBox>
    </CustomCollapse>
  );
};

export default MobileNavList;
