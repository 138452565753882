import { Grid, Stack, TextField, Alert } from "@mui/material";
import theme from "assets/theme";
import CBDivider from "components/CBDivider";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { StyledButtonContainer } from "index.styles";
import CloseIcon from "@mui/icons-material/Close";
import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "utils/context/cart.context";
import OrderSummary from "components/Ecom/components/orderSummary";
import ProductList from "components/Ecom/components/productList";
import useFetchCoupons from "pages/Shop/hooks/useFetchCoupons";
import { useLocation, useNavigate } from "react-router-dom";

const CartSidebar = () => {
  const location = useLocation();
  const [isCheckoutPage, setIsCheckoutPage] = useState(false);

  const [enterPromoCode, setOpenPromoCode] = useState(false);
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const { cartItems, cartTotal, originalSubtotal, applyPromoCode, promoCode, couponNotValid, discount, removePromoCode } = useContext(CartContext);
  const { coupons, loading, error } = useFetchCoupons();

  const handleApplyPromoCode = () => {
    applyPromoCode(code, coupons);
  };

  const handleRemoveCoupon = () => {
    removePromoCode();
  };

  const handleNavigate = () => {
    navigate("/checkout");
  };

  useEffect(() => {
    if (location.pathname === "/checkout") {
      setIsCheckoutPage(true);
    }
  }, [location]);

  return (
    <Grid item xs={12} lg={4}>
      <MKBox sx={{ border: `1px solid ${theme.palette.grey[400]}`, padding: "1rem" }}>
        <Stack alignItems={"center"} sx={{ marginBottom: "2rem", backgroundColor: theme.palette.primary.main, padding: "1rem", borderRadius: "8px" }}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={enterPromoCode ? "space-between" : "center"}
            sx={{ width: "100%", marginBottom: enterPromoCode && "1rem" }}
          >
            <MKTypography component="h3" variant="h6" sx={{ color: "#fff !important" }}>
              Cart Totals
            </MKTypography>
            {enterPromoCode && (
              <MKButton
                sx={{ minWidth: "0 !important", padding: ".5rem 1rem !important" }}
                color="white"
                variant="outlined"
                onClick={() => setOpenPromoCode(!enterPromoCode)}
              >
                <CloseIcon />
              </MKButton>
            )}
          </Stack>
          {enterPromoCode ? (
            <Stack direction={"row"} sx={{ marginTop: "1rem" }} spacing={2}>
              <TextField
                sx={{
                  "& .css-p1mc1-MuiInputBase-input-MuiOutlinedInput-input": { color: "#fff !important" },
                  "& .css-1t1jbqy-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": { color: "#fff !important" },
                }}
                placeholder="Enter your promo code..."
                variant="outlined"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              {enterPromoCode && (
                <MKButton onClick={handleApplyPromoCode} sx={{ marginTop: "1rem" }} color="white" variant="contained">
                  Apply
                </MKButton>
              )}
            </Stack>
          ) : (
            <MKButton onClick={() => setOpenPromoCode(!enterPromoCode)} sx={{ marginBlock: "1rem" }} color="white" variant="outlined">
              Enter Promo Code
            </MKButton>
          )}

          {promoCode && (
            <Stack>
              <MKTypography
                component="p"
                variant="body2"
                sx={{ color: theme.palette.white.main, marginTop: "1rem", textAlign: "center", fontWeight: "700 !important", marginBottom: "1rem" }}
              >
                Promo code applied:
              </MKTypography>
              <MKTypography
                sx={{
                  color: theme.palette.white.main,
                  border: "1px solid grey",
                  padding: ".5rem",
                  borderRadius: "12px",
                  display: "flex",
                  gap: "1rem",
                }}
                component="span"
                variant="body2"
              >
                {promoCode}
                <button
                  onClick={handleRemoveCoupon}
                  style={{
                    padding: "0 !important",
                    minWidth: "15px !important",
                    border: "none",
                    background: "transparent",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                >
                  X
                </button>
              </MKTypography>
            </Stack>
          )}
          {couponNotValid && (
            <Alert severity="error" sx={{ marginTop: "1rem" }}>
              The promo code is not valid.
            </Alert>
          )}
        </Stack>

        <CBDivider />
        <ProductList cartItems={cartItems} />
        <OrderSummary cartTotal={cartTotal} discount={discount} originalSubtotal={originalSubtotal} />
        {!isCheckoutPage && (
          <StyledButtonContainer>
            <MKButton onClick={handleNavigate} color="secondary" fullWidth>
              Proceed To Checkout
            </MKButton>
          </StyledButtonContainer>
        )}
      </MKBox>
    </Grid>
  );
};

export default CartSidebar;
