// Major
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";

// Components
import StepComponent from "../surveyStepComponent";

// Styles
import { Container, Box } from "@mui/material";
import MKButton from "components/MKButton";
import ProgressBar from "../surveyProgressBar";
import { StyledAdditionalButterfly, StyledSurveyContainer, StyledSurveyWrapperContainer } from "./index.styles";
import theme from "assets/theme";

// Utils
import filterBadWords from "utils/helpers/filterBadWords";
// Hooks
import usePostGHLContacts from "./hooks/usePostContacts.hook";
import useImageUpload from "./hooks/useUploadImage.hook";

// Data
import { steps, validationSchemas, initialValues } from "pages/PartnerProspectSurvey/surveySchema";
import useUpdateGHLContacts from "./hooks/useUpdateContacts.hook";

const renderStepContent = (step, submissionMessage, isLoading) => {
  return <StepComponent questions={steps[step].questions} currentStep={step} submissionMessage={submissionMessage} isLoading={isLoading} />;
};

const MultiStepSurvey = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [submissionMessage, setSubmissionMessage] = useState("");
  const isLastStep = activeStep === steps.length - 1;
  // data, isError, error,
  const { isLoading, postGHLContacts } = usePostGHLContacts();
  const { isUpdating, updateGHLContacts } = useUpdateGHLContacts();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const { uploadImage } = useImageUpload();

  

  useEffect(() => {
    if (activeStep === 0) return;
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [activeStep]);

  const handleSubmit = async (values, actions) => {
        
        let imageUrl = "";
        let fileName = values["firstName"] +"_"+ values["lastName"];
        let modifiedFileName = fileName.replace(/ /g, "_").toLowerCase()+"profile_image.jpg";

    if (isLastStep) {
      actions.setSubmitting(true);
      try {
        // ^ - upload image
        let imageUrl = "";
        let fileName = values["firstName"] +"_"+ values["lastName"];
        let modifiedFileName = fileName.replace(/ /g, "_").toLowerCase()+"profile_image.jpg";
        if (values["profile cropped image"]) {
          const uploadResponse = await uploadImage(values["profile cropped image"], modifiedFileName);
          if (!uploadResponse) {
            setSubmissionMessage("Failed to upload image. Please try again.");
            throw new Error("Image upload failed");
          }
          imageUrl = uploadResponse;
        }

        const payload = {
          ...values,
          profileImage: imageUrl,
        };

        // filter bad words
        const filterPayload = (payload) => {
            const filteredPayload = { ...payload };
            const keysToFilter = ["city", "name", "firstName", "lastName"];

            Object.keys(filteredPayload).forEach((key) => {
                if (typeof filteredPayload[key] === "string" && keysToFilter.includes(key)) {
                    filteredPayload[key] = filterBadWords(filteredPayload[key]);
                }
            });

            return filteredPayload;
        };
        const filteredPayload = filterPayload(payload);


        // ^ - create contact
        const response = await postGHLContacts(filteredPayload);

        if (response.statusCode === 400 && response.message === "This location does not allow duplicated contacts.") {
          setSubmissionMessage("Existing Account Found! Updating Now...");
          // ^ - update exisitng contact
          const updateContactResponse = await updateGHLContacts(payload, response.meta.contactId);

          if (updateContactResponse.statusCode === 200) {
            setActiveStep(0);
            setSubmissionMessage("Form submitted successfully!");
            actions.resetForm();
            setTimeout(() => {
              navigate("/therapist-auth/sign-in");
            }, 3000);
          } else {
            setSubmissionMessage("Failed to submit form. Please contact support@almadelic.com.");
            throw new Error("Submission failed");
          }
        } else {
          setActiveStep(0);
          setSubmissionMessage("Form submitted successfully!");
          actions.resetForm();
          setTimeout(() => {
            navigate("/therapist-auth/sign-in");
          }, 3000);
        }
      } catch (error) {
        setSubmissionMessage("Failed to submit form. Please try again.");
      } finally {
        actions.setSubmitting(false);
      }
    } else {
      setActiveStep((prevStep) => prevStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  return (
    <Container
      ref={formRef}
      sx={{
        padding: "0 0 4rem 0",
        [theme.breakpoints.up("lg")]: {
          padding: "0 0 6rem 0",
        },
        overflow: "visible",
      }}
    >
      <ProgressBar activeStep={activeStep} steps={steps} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemas[activeStep]}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({ isSubmitting, isValid, touched, errors }) => (
          <Form>
            <StyledSurveyWrapperContainer>
              <StyledAdditionalButterfly />
              <StyledSurveyContainer>
                {renderStepContent(activeStep, submissionMessage, isLoading, isUpdating)}
                <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 2 }}>
                  {activeStep > 0 && (
                    <MKButton variant="outlined" aria-label="Back button" color="secondary" type="button" onClick={handleBack}>
                      Back
                    </MKButton>
                  )}
                  <MKButton
                    type="submit"
                    variant="contained"
                    aria-label={isLastStep ? "Submit button" : "Next button"}
                    color="secondary"
                    disabled={isSubmitting || !isValid}
                  >
                    {isLastStep ? "Submit" : "Next"}
                  </MKButton>
                </Box>
              </StyledSurveyContainer>
            </StyledSurveyWrapperContainer>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default MultiStepSurvey;
