import styled from "@emotion/styled";
import { Accordion, AccordionDetails, AccordionSummary, Link, Stack, Container } from "@mui/material";


export const StyledBioBodyContainer = styled(Container)(({ theme }) => ({
    width: "100%",
    paddingBottom: "7rem",
    paddingTop: "2rem",
    gap: "2rem",
    backgroundColor: theme.palette.background.default,
}));