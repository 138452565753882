// Components
import { Stack } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import CBBulletPoint from "components/CBBulletPoint";
// Styled Components
import { StyledBenefitsOfKetamineList, StyledBenefitsOfKetamineListItem } from "./index.styles";
// Data
import BenefitsData from "./benefits.data.json";
// Theme
import theme from "assets/theme";
// Utils
import { replaceKeywordWithLink } from "utils/helpers/replaceKeywordWithLink";

const BenefitsList = () => {
  return (
    <StyledBenefitsOfKetamineList>
      {BenefitsData.map(({ title, description, embeddedLink }, index) => {
        let updatedDescription = description;
        if (embeddedLink) {
          const { url, keyWord } = embeddedLink;
          updatedDescription = replaceKeywordWithLink(description, keyWord, url);
        }
        return (
          <StyledBenefitsOfKetamineListItem key={index}>
            <MKBox sx={{ marginTop: ".1rem" }}>
              <CBBulletPoint med="true" />
            </MKBox>
            <Stack ml={1} sx={{ flexDirection: "row" }}>
              <MKBox>
                <MKTypography variant={"h6"} component={"h3"} gutterBottom sx={{ color: `${theme.palette.accent.main} !important` }}>
                  {title}
                </MKTypography>
                <MKTypography component="p" variant="body2" sx={{ color: `${theme.palette.white.main}` }}>
                  {updatedDescription}
                </MKTypography>
              </MKBox>
            </Stack>
          </StyledBenefitsOfKetamineListItem>
        );
      })}
    </StyledBenefitsOfKetamineList>
  );
};
export default BenefitsList;
