import React from "react";
// Styled Components
import { StyledSectionBackgroundWrapper } from "index.styles";
import { StyledChaperoneResponsibilitiesSectionContainer } from "./index.styles";

// Components
import { List, ListItem } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import CBBulletPoint from "components/CBBulletPoint";
import CBDivider from "components/CBDivider";
// theme
import theme from "assets/theme";
// Data
import chaperoneResponsibilitiesData from "./chaperoneResponsibilities.data.config.json";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
const ChaperoneResponsibilities = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledSectionBackgroundWrapper rotate={"true"}>
      <StyledChaperoneResponsibilitiesSectionContainer sx={{ transform: "rotate(180deg)" }} id="chaperone-responsibilities-section">
        <MKTypography component="h2" variant="h2" sx={{ color: "#fff !important", marginBottom: "2rem" }}>
          <span style={{ color: theme.palette.accent.main }}>Responsibilities</span> of the Chaperone
        </MKTypography>
        {chaperoneResponsibilitiesData.map(({ title, bullets }, index) => {
          return (
            <MKBox key={index}>
              <MKTypography component="h3" variant="h5" sx={{ color: "#fff !important", marginBlock: "1.5rem" }} gutterBottom>
                {title}
              </MKTypography>
              <List sx={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
                {bullets.map((item, indexz) => {
                  return (
                    <ListItem key={indexz} sx={{ alignItems: item.length > 150 && "flex-start" }}>
                      <MKBox sx={{ marginTop: item.length > 150 && ".5rem" }}>
                        <CBBulletPoint med={"true"} />
                      </MKBox>
                      <MKTypography component="p" variant={isLg ? "body1" : "body2"} sx={{ color: "#fff !important" }}>
                        {item}
                      </MKTypography>
                    </ListItem>
                  );
                })}
              </List>
              {index !== chaperoneResponsibilitiesData.length - 1 && (
                <MKBox sx={{ paddingBlock: "2rem" }}>
                  <CBDivider color={theme.palette.accent.main} />
                </MKBox>
              )}
            </MKBox>
          );
        })}
      </StyledChaperoneResponsibilitiesSectionContainer>
    </StyledSectionBackgroundWrapper>
  );
};

export default ChaperoneResponsibilities;
