import styled from "@emotion/styled";
import { Container } from "@mui/material";
import MKButton from "components/MKButton";
import { ghlLinkFreeConsultation } from "utils/helpers/ghlFunnelLinks";

export const StyledOurGoalOuterBox = styled(Container)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  maxWidth: "800px",
  textAlign: "center",
  paddingBlock: "4rem",
}));

export const StyledOurGoalInnerBox = styled("img")(({ theme }) => ({
  width: "125px",
  height: "125px",
  marginBottom: "1rem",
}));

export function StyledGetStartedButton({ component, content }) {
  return (
    <MKButton
      component={component}
      to={ghlLinkFreeConsultation}
      target="_blank"
      rel="noopener noreferrer"
      variant="contained"
      color="secondary"
      aria-label="Get Started Today"
      sx={{
        width: "250px",
        mb: 4,
        fontSize: "0.875rem",
      }}
    >
      {content}
    </MKButton>
  );
}
