import styled from "@emotion/styled";
import { Box } from "@mui/material";
import MKBox from "components/MKBox";

export const StyledShopSliderContainer = styled(Box)(({ theme, currentStep }) => ({
  position: "relative",
  minHeight: "350px",
  overflow: "hidden",
  background:
    currentStep === 0
      ? "radial-gradient(circle, #325d90 0%, #2b4d70 50%, #1f3b54 100%)"
      : currentStep === 1
      ? "radial-gradient(circle, #30c9c6dd 0%, #2baba9 50%, #137c78 100%)"
      : "radial-gradient(circle, #f79f11d8 0%, #d88c16 50%, #c17a09 100%)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "1rem",
  [theme.breakpoints.up("lg")]: {
    height: "275px",
  },
}));

export const StyledShopSliderContent = styled(Box)(({ theme }) => ({
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "1rem",
  [theme.breakpoints.up("lg")]: {
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    gap: "2rem",
    paddingInline: "6rem",
  },
}));

export const StyledShopSliderContentImage = styled(MKBox)(({ theme }) => ({
  borderRadius: "50%",
  height: "150px",
  width: "150px",
  objectFit: "cover",
  margin: "auto",
  [theme.breakpoints.up("lg")]: {
    position: "absolute",
    top: "50%",
    right: "25px",
    transform: "translateY(-50%)",
    height: "200px",
    width: "200px",
  },
}));

export const StyledPaginationDots = styled(Box)(({ theme, index, currentindex }) => ({
  width: "12px",
  height: "12px",
  borderRadius: "50%",
  margin: "0 5px",
  cursor: "pointer",
  backgroundColor: index === currentindex ? "grey" : theme.palette.grey[400],
}));
