import { useEffect, useState } from "react";

const useCombinedPaymentIntent = (subscriptionPaymentIntent, addonPaymentIntent, subscriptionError, addonError) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [paymentIntent, setPaymentIntent] = useState(null);

  useEffect(() => {
    if (subscriptionPaymentIntent && addonPaymentIntent) {
      // Combine the two payment intents into one object
      const combinedPaymentIntent = {
        amount: subscriptionPaymentIntent.amount + addonPaymentIntent.amount,
        id: [subscriptionPaymentIntent.id, addonPaymentIntent.id],
        createdAt: subscriptionPaymentIntent.created || addonPaymentIntent.created,
        payment_method_types: subscriptionPaymentIntent.payment_method_types[0] || addonPaymentIntent.payment_method_types[0],
        metadata: {
          firstName: subscriptionPaymentIntent.metadata.firstName || addonPaymentIntent.metadata.firstName,
          lastName: subscriptionPaymentIntent.metadata.lastName || addonPaymentIntent.metadata.lastName,
          billingAddress: subscriptionPaymentIntent.metadata.billingAddress || addonPaymentIntent.metadata.billingAddress,
          shippingAddress: subscriptionPaymentIntent.metadata.shippingAddress || addonPaymentIntent.metadata.shippingAddress,
          couponId: subscriptionPaymentIntent.metadata.couponId || addonPaymentIntent.metadata.couponId,
          email: subscriptionPaymentIntent.metadata.email || addonPaymentIntent.metadata.email,
          combinedCartItems: [subscriptionPaymentIntent.metadata.cartItems, addonPaymentIntent.metadata.cartItems],
          couponAmount: Number(subscriptionPaymentIntent.metadata.couponAmount) + Number(addonPaymentIntent.metadata.couponAmount),
          subtotal: Number(subscriptionPaymentIntent.metadata.subtotal) + Number(addonPaymentIntent.metadata.subtotal),
        },
      };
      setPaymentIntent(combinedPaymentIntent);
      setLoading(false);
    } else if (subscriptionPaymentIntent || addonPaymentIntent) {
      // If only one of the payment intents exists, use it directly
      setPaymentIntent(subscriptionPaymentIntent || addonPaymentIntent);
      setLoading(false);
    }

    if (subscriptionError || addonError) {
      setError(subscriptionError || addonError);
      setLoading(false);
    }
  }, [subscriptionPaymentIntent, addonPaymentIntent, subscriptionError, addonError]);

  return { loading, error, paymentIntent };
};

export default useCombinedPaymentIntent;
