import styled from "@emotion/styled";
import { List, ListItem } from "@mui/material";

export const StyledBenefitsOfKetamineList = styled(List)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  gap: "1rem",
}));

export const StyledBenefitsOfKetamineListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
}));
