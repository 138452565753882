import styled from "@emotion/styled";
import { Container } from "@mui/material";

export const StyledBenefitsSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",

  [theme.breakpoints.up("lg")]: {
    minHeight: "525px",
    paddingBlock: "6rem",
    display: "flex",
    alignItems: "center",
  },
}));
