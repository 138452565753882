import styled from "@emotion/styled";
import { Container } from "@mui/material";
import MKBox from "components/MKBox";
export const StyledSurveyContainer = styled(Container)(({ theme }) => ({
  marginTop: "4rem",
  padding: "32px 16px",
  background: "rgba(255 ,255, 255, .3)",
  boxShadow: `rgba(0,0,0, .1) 0px 6px 12px`,
  backdropFilter: "blur(8px)",
  borderRadius: "8px",
  [theme.breakpoints.up("lg")]: {
    padding: "60px",
  },
}));
// export const StyledSurveyWrapperContainer = styled(MKBox)(({ theme }) => ({
//   "&::before": {
//     content: '""',
//     position: "absolute",
//     bottom: 0,
//     right: -25,
//     width: "30%",
//     height: "30%",
//     rotate: "-15deg",
//     backgroundImage: "url('https://d3a0rs3velfrz.cloudfront.net/logos/orange-butterfly.svg')",
//     backgroundSize: "cover",
//     backgroundPosition: "center",
//     backgroundRepeat: "no-repeat",
//     opacity: 0.5,
//     zIndex: -1,
//   },
// }));
export const StyledSurveyWrapperContainer = styled(MKBox)(({ theme }) => ({
  position: "relative",
  overflow: "visible",
  "&::before, &::after": {
    content: '""',
    position: "absolute",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    opacity: 0.5,
    zIndex: -1,
  },
  "&::before": {
    scale: "100%",
    height: "350px",
    width: "350px",
    bottom: -175,
    right: -125,
    rotate: "-20deg",
    backgroundImage: "url('https://d3a0rs3velfrz.cloudfront.net/logos/blue-butterfly.svg')",
  },
  "&::after": {
    width: "200px",
    height: "200px",
    top: "40%",
    left: -125,
    rotate: "40deg",
    backgroundImage: "url('https://d3a0rs3velfrz.cloudfront.net/logos/orange-butterfly.svg')",
  },
}));
export const StyledAdditionalButterfly = styled("div")({
  overflow: "visible",
  position: "absolute",
  rotate: "-75deg",
  top: -125,
  right: -140,
  width: "150px",
  height: "150px",
  backgroundImage: "url('https://d3a0rs3velfrz.cloudfront.net/logos/light-blue-butterfly.svg')",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  opacity: 0.5,
  zIndex: -1,
  transform: "translate(-50%, -50%) rotate(30deg)",
});
