
import { useState } from "react";
import axios from "axios";
import useImageUpload from "pages/PartnerProspectSurvey/components/newSurvey/hooks/useUploadImage.hook";

const usePutTherapistByID = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);
  const { uploadImage } = useImageUpload();
  

  const parseArrayFields = (data) => {
    const arrayFields = [
      "licensedStates",
      "languagesSpoken",
      "Licenses",
      "practiceSettings",
      "Specialties",
      "therapyTypes",
      "therapyTypesOffered",
      "treatmentMethods",
      "treatmentModalities",
      "visitTypes",
      "Treatment Methods",
      "Visit Types",
      "Types Of Therapy",
      "Licensed To Practice In",
      "Languages",
      "Hourly Rate",
    ];

    const parsedData = { ...data };
    if (!data) return parsedData;
    arrayFields.forEach((field) => {
      if (typeof data[field] === "string") {
        if (data[field].trim() === "") {
          parsedData[field] = [];
        } else {
          try {
            parsedData[field] = JSON.parse(data[field]);
          } catch (error) {
            console.error(`Error parsing field ${field}:`, error);
            console.error(`Data causing error: ${data[field]}`);
            parsedData[field] = [];
          }
        }
      }
    });
    return parsedData;
  };


  const updateContactInfo = async (postData, therapistID) => {
    setIsLoading(true);
    setIsError(false);
    setError(null);

    const parsedData = parseArrayFields(postData);
    const nonCustomFields = ["name", "phone", "state", "city"];
    
    const payload = {
        "contactID": therapistID,
        "details": {
            "customFields": {},
        }
    }
    

    Object.keys(parsedData).forEach(key => {
        if (nonCustomFields.includes(key)) {
          payload.details[key] = parsedData[key];
        } else {
          payload.details.customFields[key] = parsedData[key];
        }
      });
    try {
      const response = await axios.put("https://1br067lqe8.execute-api.us-east-1.amazonaws.com/corsEnabled/ghl-contact-by-id", payload);
      const responseObj = await JSON.parse(response.data.body);
      setData(responseObj);
      return responseObj;
    } catch (err) {
      setIsError(true);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, isLoading, isError, error, updateContactInfo };
};

export default usePutTherapistByID;