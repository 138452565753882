import ReactCrop from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css'


const TAImageCrop = ({ src, crop, setCrop, aspect=1, locked=false, circularCrop=false, ruleOfThirds=true, onImageLoaded }) => {
    
    return (
        <ReactCrop
            src={src}
            crop={crop}
            onChange={newCrop => setCrop(newCrop)}

            locked={locked}
            circularCrop={circularCrop}
            aspect={aspect}
            ruleOfThirds={ruleOfThirds}
        >
            <img src={src} alt="crop" onLoad={onImageLoaded} style={{objectFit: "cover", maxHeight: "600px"}} />
        </ReactCrop>
    )
}

export default TAImageCrop;