import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
export const GridItemStyles = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};
export const StyledOurProgramSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));

export const StyledGridContainer = styled(Grid)(({ theme }) => ({
  // minHeight: "425px",
  // justifyContent: "center",
}));
export const StyledGridItemLeft = styled(Grid)(({ theme }) => ({
  order: 1,
  [theme.breakpoints.up("lg")]: {
    order: 0,
  },
  ...GridItemStyles,
}));
export const StyledGridItemRight = styled(Grid)(({ theme }) => ({
  order: 0,
  [theme.breakpoints.up("lg")]: {
    order: 1,
  },
  ...GridItemStyles,
}));
