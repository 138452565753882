import React from "react";
// Styled Components
import { StyledPatientBenefitsHeader, StyledPatientBenefitsListItem, StyledPatientBenefitsSectionContainer } from "./index.styles";
// Components
import { List } from "@mui/material";
import MKTypography from "components/MKTypography";
// Data
import patientBenefitsData from "./patientBenefits.data";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";

const PatientBenefits = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledPatientBenefitsSectionContainer id="benefits-of-partnering-section" component="section">
      <StyledPatientBenefitsHeader component="h2" variant="h2">
        Benefits to Your Patients
      </StyledPatientBenefitsHeader>
      <List>
        {patientBenefitsData.map(({ title, description }, index) => {
          return (
            <StyledPatientBenefitsListItem key={index}>
              <MKTypography component="h3" variant="h6" gutterBottom>
                {title}
              </MKTypography>
              <MKTypography variant={isLg ? "body1" : "body2"} gutterBottom>
                {description}
              </MKTypography>
            </StyledPatientBenefitsListItem>
          );
        })}
      </List>
    </StyledPatientBenefitsSectionContainer>
  );
};

export default PatientBenefits;
