import CBLoading from "components/CBLoading";
import React from "react";

const Temp = () => {
  return (
    <>
      <CBLoading />
    </>
  );
};

export default Temp;
