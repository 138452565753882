import React, { useState } from "react";
// Components
import MKButton from "components/MKButton";
import { Button, Grid } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// Styled components
import { StyledButtonContainer } from "index.styles";
import {
  StyledWhatIsNeuroplasticitySectionContainer,
  StyledWhatIsNeuroPlasticityInnerGrid,
  StyledWhatIsNeuroPlasticityButtonGrid,
} from "./index.style";
import { StyledImage } from "index.styles";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Theme
import theme from "assets/theme";

const WhatIsNeuroplasticity = () => {
  const [seeMoreRole, setSeeMoreRole] = useState(false);

  const { isLg } = useMediaQueries();

  return (
    <StyledWhatIsNeuroplasticitySectionContainer component="section" id="what-is-neuroplasticity-section">
      <Grid container spacing={isLg ? 8 : 4}>
        {/*Neuroplasticity and Ketamine Therapy*/}
        <StyledWhatIsNeuroPlasticityInnerGrid item xs={12} lg={12} xl={6}>
          {/*Image 1*/}
          <StyledImage
            height="429"
            width="514"
            whatisneuroplasticity={"true"}
            src={"https://d3a0rs3velfrz.cloudfront.net/whatIsKetamine/whatIsKetamineImage3.webp"}
            alt="Image of a medical professional looking at brain scans to identify neuroplasticity."
          />
          {/*Content*/}
          <MKTypography component={"h2"} variant={"h2"} gutterBottom>
            Neuroplasticity and <span style={{ color: theme.palette.accent.main }}>Ketamine Therapy</span>
          </MKTypography>
          <MKTypography component={"p"} variant={isLg ? "body1" : "body2"} gutterBottom>
            Neuroplasticity refers to the brain's ability to reorganize itself by forming new neural connections throughout life. This dynamic process
            allows the brain to adapt to experiences, learn new information, and recover from injuries. Neuroplasticity involves changes in the
            strength and structure of existing synapses and the formation of entirely new synapses.
          </MKTypography>
        </StyledWhatIsNeuroPlasticityInnerGrid>

        {/*The Role of Ketamine in Neuroplasticity */}
        <StyledWhatIsNeuroPlasticityInnerGrid item xs={12} lg={12} xl={6}>
          {/*image 2*/}
          <StyledImage
            whatisneuroplasticity={"true"}
            height="429"
            width="514"
            src={"https://d3a0rs3velfrz.cloudfront.net/whatIsKetamine/whatIsKetamineImage4.webp"}
            alt="Image of a medical test tubes and equipment."
          />
          {/*Content*/}
          <MKBox>
            <MKTypography component={"h2"} variant={"h2"} gutterBottom>
              The Role of <span style={{ color: theme.palette.accent.main }}>Ketamine in Neuroplasticity</span>
            </MKTypography>
            <MKTypography component={"p"} variant={isLg ? "body1" : "body2"} gutterBottom>
              Ketamine's impact on neuroplasticity is a key area of interest. The drug's ability to enhance synaptic plasticity, as mentioned earlier,
              suggests that it may play a role in promoting adaptive changes in the brain. This could be particularly relevant in the context of
              mental health, where conditions like depression may be associated with alterations in neural circuitry.
            </MKTypography>
            {seeMoreRole ? (
              <MKTypography component={"p"} variant={isLg ? "body1" : "body2"} gutterBottom>
                Research is ongoing to understand the precise mechanisms through which ketamine influences neuroplasticity and how these changes
                correlate with therapeutic outcomes. The hope is that by harnessing the brain's plasticity, ketamine therapy can contribute to
                long-lasting improvements in mental health conditions. It's important to note that while promising, the field is still evolving, and
                further research is needed to fully elucidate the relationship between ketamine therapy, neuroplasticity, and mental health outcomes.
              </MKTypography>
            ) : null}
            <StyledWhatIsNeuroPlasticityButtonGrid>
              {seeMoreRole ? (
                <StyledButtonContainer>
                  <MKButton
                    component={Button}
                    variant="outlined"
                    color="secondary"
                    sx={{ fontSize: ".875rem" }}
                    aria-label="See less"
                    onClick={() => {
                      setSeeMoreRole(!seeMoreRole);
                    }}
                  >
                    See Less
                  </MKButton>
                </StyledButtonContainer>
              ) : (
                <StyledButtonContainer>
                  <MKButton
                    component={Button}
                    variant="contained"
                    color="secondary"
                    sx={{ fontSize: ".875rem" }}
                    aria-label="See more"
                    onClick={() => {
                      setSeeMoreRole(!seeMoreRole);
                    }}
                  >
                    See More
                  </MKButton>
                </StyledButtonContainer>
              )}
            </StyledWhatIsNeuroPlasticityButtonGrid>
          </MKBox>
        </StyledWhatIsNeuroPlasticityInnerGrid>
      </Grid>
    </StyledWhatIsNeuroplasticitySectionContainer>
  );
};

export default WhatIsNeuroplasticity;
