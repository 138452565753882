import React from "react";
// Components
import MKTypography from "components/MKTypography";
import { Grid } from "@mui/material";
// Theme
import theme from "assets/theme";
// Styled Components
import { StyledImage } from "../../../../index.styles";
import { StyledMusicAndTherapyPageHeaderSectionContainer, StyledMusicAndTherapyTextBox } from "./index.styles";
import { StyledSectionBackgroundWrapper } from "index.styles";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";

const MusicAndTherapyPageHeader = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledSectionBackgroundWrapper id="music-and-therapy-page-header-wrapper">
      <StyledMusicAndTherapyPageHeaderSectionContainer component="section" id="music-and-therapy-page-header-section">
        <Grid container columnSpacing={isLg ? 6 : 3} rowSpacing={isLg ? 0 : 6}>
          <Grid item xs={12} lg={5}>
            <StyledImage
              height="427"
              width="353"
              src={"https://d3a0rs3velfrz.cloudfront.net/musicTherapy/musicTherapy-One.webp"}
              alt="Lady with eyes closed and her headphones on relaxing."
            ></StyledImage>
          </Grid>
          <Grid item xs={12} lg={7}>
            <StyledMusicAndTherapyTextBox>
              <MKTypography variant={"h1"} sx={{ color: `${theme.palette.white.main} !important` }} gutterBottom>
                <span style={{ color: theme.palette.accent.main }}>Music & </span>
                Psychedelic Therapy
              </MKTypography>
              <MKTypography component="p" variant={isLg ? "body1" : "body2"} sx={{ color: "#fff" }}>
                The incorporation of music in ketamine therapy sessions by therapists serves as a significant influencer of their patients'
                experiences. Consequently, a thoughtful approach is crucial in selecting and introducing music to ensure its positive impact. Just as
                music has the potential to elicit positive emotions, it also has the capacity to act as a catalyst for negative experiences.
                Therefore, a thorough understanding of its implications is imperative before integrating music into ketamine therapy sessions. Music
                used during ketamine therapy can shape the patient’s experience.
              </MKTypography>
            </StyledMusicAndTherapyTextBox>
          </Grid>
        </Grid>
      </StyledMusicAndTherapyPageHeaderSectionContainer>
    </StyledSectionBackgroundWrapper>
  );
};

export default MusicAndTherapyPageHeader;
