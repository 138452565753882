import React from "react";
// Styled Components
import { StyledMainPageContainer } from "index.styles";
// Components
import PreparationPageHeader from "./components/preparationPageHeader";
import DayOfSession from "./components/dayOfSession";
import Meditation from "./components/meditation";
import HowTo from "./components/howTo";
import SchemaComponent from "components/CBSchema";
import SEO from "components/CBSEO";

// Utils
import { constructSchemaData } from "utils/helpers/constructSchemaData";
// Data
import sessionPreparationSchemaData from "./schema.organization.json";
import sessionPreparationArticleSchemaData from "./schema.article.json";
import seoData from "./seo.data.json";

const SessionPreparation = () => {
  const schemaData = constructSchemaData(
    sessionPreparationSchemaData.url,
    sessionPreparationSchemaData.breadcrumbs,
    sessionPreparationArticleSchemaData
  );
  return (
    <StyledMainPageContainer id="session-preparation-page-container">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <PreparationPageHeader />
      <DayOfSession />
      <Meditation />
      <HowTo />
    </StyledMainPageContainer>
  );
};

export default SessionPreparation;
