import styled from "@emotion/styled";
import { Container, Divider, Grid } from "@mui/material";
import MKBox from "components/MKBox";

export const StyledFooterSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));
