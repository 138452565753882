// import { useMemo } from "react";

// export const useFilterDataForTherapist = (initialData = [], searchTerms) => {
//   console.log(initialData);
//   const filteredData = useMemo(() => {
//     if (searchTerms.size === 0) {
//       return initialData;
//     }
//     const normalizeAndLowerCase = (value) => String(value).trim().toLowerCase();
//     return initialData.filter((profile) =>
//       Array.from(searchTerms).every((term) =>
//         Object.values(profile).some((value) => {
//           if (Array.isArray(value)) {
//             return value.some((item) => normalizeAndLowerCase(item).includes(term));
//           } else if (typeof value === "object") {
//             return Object.values(value).some((nestedValue) => normalizeAndLowerCase(nestedValue).includes(term));
//           } else {
//             return normalizeAndLowerCase(value).includes(term);
//           }
//         })
//       )
//     );
//   }, [initialData, searchTerms]);
//   return filteredData;
// };
// !
import { useMemo } from "react";

export const useFilterDataForTherapist = (initialData = [], searchTerms) => {
  const filteredData = useMemo(() => {
    if (searchTerms.size === 0) {
      return initialData;
    }

    const normalizeAndLowerCase = (value) => String(value).trim().toLowerCase();

    const matchesSearchTerms = (item) => {
      return Array.from(searchTerms).every((term) => normalizeAndLowerCase(item).includes(term));
    };

    const checkNestedValues = (value) => {
      if (Array.isArray(value)) {
        return value.some((item) => {
          if (typeof item === "object" && item !== null) {
            return Object.values(item).some((nestedValue) => checkNestedValues(nestedValue));
          }
          return matchesSearchTerms(item);
        });
      } else if (typeof value === "object" && value !== null) {
        return Object.values(value).some((nestedValue) => checkNestedValues(nestedValue));
      }
      return matchesSearchTerms(value);
    };

    return initialData.filter((profile) => {
      return Object.values(profile).some((value) => checkNestedValues(value));
    });
  }, [initialData, searchTerms]);

  return filteredData;
};
