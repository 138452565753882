// Major
import React from "react";

// Components
import MKTypography from "components/MKTypography";

// Utils
import { findCustomFieldValue } from "utils/helpers/findCustomFieldValue";

// Styles
import { 
        StyledStackContainer, 
        StyledSpecialistItem,
        StyledTherapyServicesContainer
    } from "./index.styles";


const TherapyServices = ({currentUserData}) => {
    const { email, phone, state, city, name, customFields } = currentUserData || [];
    const visitTypes = findCustomFieldValue("Visit Types", customFields);
    const treatmentMethods = findCustomFieldValue("Treatment Methods", customFields);
    const specialties = findCustomFieldValue("Specialties", customFields);
    const psychedelicsYouWorkWith = findCustomFieldValue("Psychedelics You Work With", customFields);
    const typesOfTherapy = findCustomFieldValue("Types Of Therapy", customFields);



    const Specialties = () => {
        if(!specialties) return null;
        return (
            <StyledStackContainer sx={{display: "center", flexWrap: "wrap"}}>
                    {specialties?.map((item, index) => (
                            <StyledSpecialistItem key={index}>
                                {item}
                            </StyledSpecialistItem>
                    ))}
            </StyledStackContainer>
        )
    }

    const PsychedelicsYouWorkWith = () => {
        if(!psychedelicsYouWorkWith) return null;
        return (
            <StyledStackContainer sx={{ margin: "0.5rem" }}>
                <MKTypography component="h6" variant="h6" gutterBottom fontWeight="light">
                    <b>Types Of Psychedelics I Work With:</b> 
                </MKTypography>
                    {psychedelicsYouWorkWith?.map((item, index) => (
                            
                            <MKTypography key={index} component="p" variant={"body2"}>
                                {item}
                            </MKTypography>
                    ))}
            </StyledStackContainer>
        )    
    }

    const ListItems = () => {
        let items = [];
        let itemTitle = ["Treatment Methods", "Visit Types", "Types Of Therapy"];
        if(treatmentMethods) {
            items.push(treatmentMethods);
        }
        if(visitTypes) {
            items.push(visitTypes);
        }
        if(typesOfTherapy) {
            items.push(typesOfTherapy);
        }
        return (
            <>
            {items?.map((item, index) => (
                <StyledStackContainer key={index} sx={{ margin: "0.5rem" }}>
                    <MKTypography component="p" variant="body2" gutterBottom fontWeight="light">
                        <b>{itemTitle[index]}:</b> {item?.join(", ")} 
                    </MKTypography>
                </StyledStackContainer>
            ))}
            </>
        )
    }


    return (
        <StyledTherapyServicesContainer id="therapist-bio-services">
            <MKTypography component="h3" variant="h5" gutterBottom>
                Therapy Services
            </MKTypography>
            <Specialties />
            <ListItems />
            <PsychedelicsYouWorkWith />
        </StyledTherapyServicesContainer>
    );
}

export default TherapyServices;