// Major
import React from 'react';

// Components
import MKTypography from 'components/MKTypography';

// Utils
import { findCustomFieldValue } from "utils/helpers/findCustomFieldValue";

// Styles
import { StyledStackContainer, StyledInsuranceContainer} from "./index.styles";


const CostAndInsurance = ({currentUserData}) => {
    const { email, phone, state, city, name, customFields } = currentUserData || [];
    const hourlyRate = findCustomFieldValue("Hourly Rate", customFields);
    const insurance = findCustomFieldValue("Insurance", customFields);
    
    
    const HourlyRate = () => {
        if(!hourlyRate) return null;
        return (
            <StyledStackContainer sx={{ margin: "0.5rem" }}>
                <MKTypography component="p" variant="body2" gutterBottom fontWeight="light">
                    <b>Hourly Rate: </b>${hourlyRate}
                </MKTypography>
            </StyledStackContainer>
        )
    }

    const Insurance = () => {
        return (
            <StyledStackContainer sx={{ margin: "0.5rem" }}>
                <MKTypography component="p" variant="body2" gutterBottom fontWeight="light">
                    <b>Insurance: </b>{insurance === 'Yes' ? "Yes, we accept insurance" : "No, we do not accept insurance"}
                </MKTypography>
            </StyledStackContainer>
        )
    }



    return (
        <StyledInsuranceContainer>
            <MKTypography component="h3" variant="h5" gutterBottom>
                Service Costs & Insurance
            </MKTypography>
            <HourlyRate />
            <Insurance />
        </StyledInsuranceContainer>
    );
}

export default CostAndInsurance;