import React from "react";
// Components
import { Grid } from "@mui/material";
// Styled Components
import {
  StyledBenefitsOfKetamineTherapySectionContainer,
  StyledBenefitsOfKetamineTherapyWrapper,
  StyledBenefitsOfKetamineTherapyGrid,
  StyledKetamineMentalHealthGrid,
  StyledBenefitsOfKetamieTherapyMKTypography,
  StyledBenefitsOfKetamieTherapySpan,
  BenefitsCard,
} from "./index.style";
// Data
import { BenefitsOfKetamineTherapyData } from "./benefitsOfKetamineTherapy.data";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";

const BenefitsOfKetamineTherapy = () => {
  const { isLg, isXl } = useMediaQueries();
  return (
    <StyledBenefitsOfKetamineTherapyWrapper id="benefits-of-ketamine-therapy-wrapper">
      <StyledBenefitsOfKetamineTherapySectionContainer component="section">
        <StyledBenefitsOfKetamineTherapyGrid container>
          {/* How Does Ketamine Assist in Mental Health */}
          <StyledKetamineMentalHealthGrid item xs={12} lg={12} xl={6} sx={{ marginBottom: { xs: "4rem", xl: "0" } }}>
            <StyledBenefitsOfKetamieTherapyMKTypography component="h3" variant="h3" mb={8}>
              How Does Ketamine
              <span style={StyledBenefitsOfKetamieTherapySpan}> Assist in Mental Health?</span>
            </StyledBenefitsOfKetamieTherapyMKTypography>
            {/* Content */}
            <Grid container rowSpacing={isLg ? 4 : 2}>
              {BenefitsOfKetamineTherapyData.slice(0, 1)[0].cardContent.map(({ subtitle, text }, index) => {
                return <BenefitsCard key={index} title={subtitle} content={text} />;
              })}
            </Grid>
          </StyledKetamineMentalHealthGrid>
          {/* The Mutual Benefits of Therapy & Ketamine */}
          <StyledKetamineMentalHealthGrid item xs={12} lg={12} xl={6} mt={isLg && !isXl ? 8 : null}>
            <StyledBenefitsOfKetamieTherapyMKTypography component="h3" variant="h3" mb={8}>
              The Mutual Benefits
              <span style={StyledBenefitsOfKetamieTherapySpan}> Of Therapy & Ketamine</span>
            </StyledBenefitsOfKetamieTherapyMKTypography>
            <Grid container rowSpacing={isLg ? 4 : 2}>
              {BenefitsOfKetamineTherapyData.slice(1, 2)[0].cardContent.map(({ subtitle, text }, index) => {
                return <BenefitsCard key={index} title={subtitle} content={text} />;
              })}
            </Grid>
          </StyledKetamineMentalHealthGrid>
        </StyledBenefitsOfKetamineTherapyGrid>
      </StyledBenefitsOfKetamineTherapySectionContainer>
    </StyledBenefitsOfKetamineTherapyWrapper>
  );
};

export default BenefitsOfKetamineTherapy;
