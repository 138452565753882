import styled from "@emotion/styled";
import { Container, Stack, Grid } from "@mui/material";

export const StyledMenuContainer = styled(Container)(({ theme }) => ({
    padding: "2rem",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "1rem",
    width: "80%",
    boxShadow: "0 10px 10px 0 rgba(0,0,0,0.1)",
    marginTop: "1rem",
    }));

export const StyledOptionStack = styled(Stack)(({ theme }) => ({
    padding: "1rem 0rem",
    borderRadius: "1rem",
    fontSize: "1.0rem",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
}));

