import React from "react";
import { Box } from "@mui/material";
import { StyledEllipsisTypography } from "./index.styles";
import styled from "@emotion/styled";
import useMediaQueries from "utils/mediaQueries.utils";
import theme from "assets/theme";

const ReadMore = styled(Box)`
  color: ${theme.palette.accent.main};
  cursor: pointer;
  display: inline;
`;

const EllipsisText = ({ text, onReadMore, isBusinessListing, product, productDetails, isExpanded, showMore, productCards, index, arrayLength }) => {
  const { isLg } = useMediaQueries();

  const truncateText = (text, limit) => {
    if (text?.length <= limit) {
      return text;
    }
    return `${text?.slice(0, limit)}...`;
  };

  const textLimit = isLg ? 280 : 150;
  const productItemTextLimit = isLg ? 150 : 80;
  const productItemDetailsTextLimit = isLg ? 125 : 50;
  const productCardTextLimit = isLg ? 125 : 50;

  return (
    <StyledEllipsisTypography productDetails={productDetails || productCards} width="90%" component="p" variant={"body2"}>
      <>
        {product || productDetails ? (
          <>
            {isExpanded || showMore ? text : truncateText(text, productDetails ? productItemDetailsTextLimit : productItemTextLimit)}
            <ReadMore onClick={onReadMore}> {isExpanded || showMore ? "Read Less" : "Read More"}</ReadMore>
          </>
        ) : productCards ? (
          <>
            {showMore ? text : truncateText(text, productCardTextLimit)}
            {!showMore && index === 0 && <ReadMore onClick={onReadMore}> Read More</ReadMore>}
            {showMore && index === arrayLength - 1 && <ReadMore onClick={onReadMore}> Read Less</ReadMore>}
          </>
        ) : (
          <>
            {isExpanded ? text : truncateText(text, textLimit)}
            {!isBusinessListing && text?.length > textLimit && <ReadMore onClick={onReadMore}> {isExpanded ? "Read Less" : "Read More"}</ReadMore>}
          </>
        )}
      </>
    </StyledEllipsisTypography>
  );
};

export default EllipsisText;
