import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// Styled Components
import { StyledSectionBackgroundWrapper } from "index.styles";
import { StyledInsuranceFaqSectionContainer } from "./index.styles";
import { StyledFaqCards } from "index.styles";
import { StyledDivider } from "index.styles";
// Components
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
// Theme
import { useTheme } from "@mui/material/styles";
import theme from "assets/theme";
// Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// Data
import InsuranceFaqData from "./faq-insurance.data.json";
const FaqGroupItem = () => {
  const theme = useTheme();
  return (
    <>
      {InsuranceFaqData.map(({ answer, question }, index) => {
        return (
          <StyledFaqCards
            component={Accordion}
            key={index}
            padding={1}
            marginBottom={index === InsuranceFaqData.length - 1 ? 0 : 2}
            borderRadius={`${theme.spacing(1)} !important`}
          >
            <MKBox
              component={AccordionSummary}
              expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.secondary.main }} />}
              aria-controls="panel1a-content"
              id={`panel1a-header--${index}`}
              sx={{ minWidth: "100%" }}
            >
              <MKTypography variant={"h6"} component={"h3"} sx={{ fontWeight: 500, fontSize: "90%" }}>
                {question}
              </MKTypography>
            </MKBox>
            <AccordionDetails>
              <MKTypography component={"p"}>{answer}</MKTypography>
            </AccordionDetails>
          </StyledFaqCards>
        );
      })}
    </>
  );
};

FaqGroupItem.propTypes = {
  items: PropTypes.array.isRequired,
};

const FaqContent = () => {
  return (
    <StyledSectionBackgroundWrapper sx={{ transform: "rotate(180deg)" }} id="insurance-faq-wrapper">
      <StyledInsuranceFaqSectionContainer sx={{ transform: "rotate(180deg)" }} component="section" id="insurance-faq-section">
        <MKTypography
          component={"h2"}
          variant={"h2"}
          sx={{
            color: `${theme.palette.white.main} !important`,
          }}
          gutterBottom
        >
          <span style={{ color: theme.palette.accent.main }}>FAQs</span> You May Find Helpful
        </MKTypography>
        <StyledDivider />

        <MKBox my={"2rem"}>
          <FaqGroupItem items={InsuranceFaqData} />
        </MKBox>
        <MKBox sx={{ display: "flex", justifyContent: "center" }}>
          <MKButton
            aria-label="See all Faqs"
            sx={{ minWidth: "175px", fontSize: "0.875rem" }}
            component={Link}
            to={`/faq`}
            variant="contained"
            color="secondary"
          >
            See all Faqs
          </MKButton>
        </MKBox>
      </StyledInsuranceFaqSectionContainer>
    </StyledSectionBackgroundWrapper>
  );
};

export default FaqContent;
