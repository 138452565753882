import { useState } from "react";
import axios from "axios";
import { constructAddressData } from "utils/helpers/constructAddressData";

const componentTypeMap = {
  street_number: "Street Number",
  route: "Street",
  locality: "City",
  administrative_area_level_1: "State",
  postal_code: "Zip Code",
  country: "Country",
};

const useGoogleAddressValidation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [addressError, setAddressError] = useState(null);

  const validateAddress = async (values) => {
    const addressData = constructAddressData(values);
    const endpoint = "https://x8e0ewqjdd.execute-api.us-east-1.amazonaws.com/default/validate_address";
    setIsLoading(true);
    setAddressError(null);

    try {
      const response = await axios.post(endpoint, { addressData });
      setIsLoading(false);

      const responseBody = JSON.parse(response.data.body);

      const confirmationLevels = responseBody.result.address.addressComponents.map((component) => component.confirmationLevel);

      const unconfirmedTypes = responseBody.result.address.unconfirmedComponentTypes || [];

      const isPlausibleButNotConfirmed = confirmationLevels.includes("UNCONFIRMED_BUT_PLAUSIBLE");

      const readableUnconfirmedComponents = unconfirmedTypes.map((type) => componentTypeMap[type] || type);

      if (isPlausibleButNotConfirmed) {
        const error = `The following address components are unconfirmed but plausible: ${readableUnconfirmedComponents.join(
          ", "
        )}. Please check the form fields.`;
        setAddressError(error);
        return { isValid: false, error };
      }

      return { isValid: true, error: null };
    } catch (error) {
      setIsLoading(false);
      const errorMessage = "Error calling Google API. Please check the console.";
      setAddressError(errorMessage);
      console.error("Error calling Google API:", error);
      return { isValid: false, error: errorMessage };
    }
  };

  // console.log("address error", addressError);

  return { validateAddress, isLoading, addressError };
};

export default useGoogleAddressValidation;
