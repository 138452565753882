import styled from "@emotion/styled";
import { Container } from "@mui/material";

export const Styled404SectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  position: "relative",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));
