import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const fetchGHLContacts = async () => {
  const response = await axios.get("https://1br067lqe8.execute-api.us-east-1.amazonaws.com/corsEnabled");
  const data = JSON.parse(response.data.body);
  return data.contacts;
};

const useFetchGHLContacts = () => {
  const {
    data: users,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["ghlContacts"],
    queryFn: fetchGHLContacts,
    staleTime: 30 * 60 * 1000, // 30 minutes
    cacheTime: 60 * 60 * 1000, // 1 hour
    refetchOnWindowFocus: false, // Do not refetch on window focus
  });

  return { users, isLoading, isError, error };
};

export default useFetchGHLContacts;
