import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import MKBox from "components/MKBox";

export const StyledHeroSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
}));

export const StyledHeroImage = styled(MKBox)(({ theme }) => ({}));

export const StyledHeroImageGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  // [theme.breakpoints.up("xl")]: {
  flexDirection: "column",
  // },
}));
