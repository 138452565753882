import styled from "@emotion/styled";
import { Container } from "@mui/material";
import MKBox from "components/MKBox";

export const StyledMeditationSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));

export const StyledDayOfSessionCardContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
  padding: 0,
}));

export const StyledMeditationVideoContainer = styled(MKBox)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}));

export const StyledMeditationVideo = styled(MKBox)(({ theme }) => ({
  width: "100%",
  maxWidth: "800px",
  minHeight: "300px",
  marginBottom: "2rem",
  [theme.breakpoints.up("md")]: {
    width: "100%",
    minHeight: "500px",
  },
}));

export const StyledPreparationText = styled(MKBox)(({ theme }) => ({
  padding: "1.5rem",
}));
