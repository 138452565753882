import { useEffect, useState } from "react";
import { shuffleArray } from "utils/helpers/shuffleArray";

const useSortedTherapists = (filteredData) => {
  const [therapists, setTherapists] = useState([]);

  useEffect(() => {
    const updateTherapists = () => {
      // Separate therapists by tag
      const featuredTherapists = filteredData.filter((profile) => profile.tags?.includes("active directory therapist"));
      const nonFeaturedTherapists = filteredData.filter((profile) => profile.tags?.includes("active directory business listing"));

      // Shuffle featured therapists and sort non-featured therapists
      const shuffledFeaturedTherapists = shuffleArray(featuredTherapists);
      const sortedNonFeaturedTherapists = nonFeaturedTherapists.sort((a, b) => a.name.localeCompare(b.name));

      // Combine lists
      const combinedList = [...shuffledFeaturedTherapists, ...sortedNonFeaturedTherapists];
      setTherapists(combinedList);
    };

    updateTherapists();
  }, [filteredData]);
  return therapists;
};

export default useSortedTherapists;
