import React from "react";
// Components
import MKTypography from "components/MKTypography";
import { List, ListItem, Stack } from "@mui/material";
import CBBulletPoint from "components/CBBulletPoint";
import MKBox from "components/MKBox";
// Styled Components
import { StyledSectionBackgroundWrapper } from "index.styles";
import { StyledQuickTipsSectionContainer } from "./index.styles";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Data
import tipsData from "./tips.data.json";
// theme
import theme from "assets/theme";
const QuickTips = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledSectionBackgroundWrapper id="quick-tips-wrapper">
      <StyledQuickTipsSectionContainer component="section" id="quick-tips-section">
        <MKTypography
          component={"h2"}
          variant={"h2"}
          sx={{ color: `${theme.palette.white.main} !important`, borderBottom: "1px solid #fff", minHeight: isLg ? "80px" : "60px" }}
          gutterBottom
        >
          Quick
          <span style={{ color: theme.palette.accent.main }}> Tips</span>
        </MKTypography>

        <List sx={{ marginTop: "2rem" }}>
          {tipsData.map(({ instruction }, index) => {
            return (
              <ListItem key={index} sx={{ marginBottom: "1rem" }}>
                <Stack direction={"row"} alignItems={"start"}>
                  <MKBox sx={{ marginTop: ".3rem" }}>
                    <CBBulletPoint />
                  </MKBox>
                  <MKTypography component="p" variant={isLg ? "body1" : "body2"} sx={{ color: `${theme.palette.white.main} !important` }}>
                    {instruction}
                  </MKTypography>
                </Stack>
              </ListItem>
            );
          })}
        </List>
      </StyledQuickTipsSectionContainer>
    </StyledSectionBackgroundWrapper>
  );
};

export default QuickTips;
