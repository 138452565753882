import { Grid } from "@mui/material";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import React from "react";
import { Link } from "react-router-dom";
import { StyledFooterSectionContainer } from "./index.style";

export default function PageFooter() {
  return (
    <StyledFooterSectionContainer>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <MKTypography variant={"h1"} gutterBottom>
          Learn More
        </MKTypography>
        <MKButton
          component={Link}
          to={"must-fill-in"}
          variant="contained"
          color="secondary"
          size="large"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click to Learn more about these courses
        </MKButton>
      </Grid>
    </StyledFooterSectionContainer>
  );
}
