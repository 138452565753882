import MKTypography from "components/MKTypography";
import React from "react";

// Styled Components
import { StyledOurServicesCard } from "../../index.styles";
import { StyledOurServicesParagraph } from "./index.styles";

const ServiceCard = ({ title, description, index }) => {
  return (
    <StyledOurServicesCard>
      <img
        height="76"
        width="76"
        src={
          index === 0
            ? "https://d3a0rs3velfrz.cloudfront.net/ourProgram/servicesIcon1.webp"
            : index === 1
            ? "https://d3a0rs3velfrz.cloudfront.net/ourProgram/servicesIcon2.webp"
            : index === 2
            ? "https://d3a0rs3velfrz.cloudfront.net/ourProgram/servicesIcon3.webp"
            : index === 3
            ? "https://d3a0rs3velfrz.cloudfront.net/ourProgram/servicesIcon4.webp"
            : index === 4
            ? "https://d3a0rs3velfrz.cloudfront.net/ourProgram/servicesIcon5.webp"
            : "https://d3a0rs3velfrz.cloudfront.net/ourProgram/servicesIcon6.webp"
        }
        style={{ height: "76px", width: "76px", margin: "auto" }}
        alt={`Services Icon for ${title}`}
      ></img>
      <MKTypography component="h3" variant="h6">
        {title}
      </MKTypography>
      <StyledOurServicesParagraph component="p" variant="body2">
        {description}
      </StyledOurServicesParagraph>
    </StyledOurServicesCard>
  );
};

export default ServiceCard;
