/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Link } from "react-router-dom";
// Components
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import BenefitsList from "./benefitsList";
// Styled Components
import { StyledSectionBackgroundWrapper, StyledButtonContainer } from "index.styles";
import { StyledBenefitsSectionContainer } from "./index.styles";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
import { ghlLinkFreeConsultation } from "utils/helpers/ghlFunnelLinks";
// Theme
import theme from "assets/theme";

function Benefits() {
  const { isLg } = useMediaQueries();

  return (
    <StyledSectionBackgroundWrapper id="benefits-of-ketamine-wrapper">
      <StyledBenefitsSectionContainer component="section" id="benefits-of-ketamine-section">
        <Grid container spacing={isLg ? 8 : 4}>
          <Grid item xs={12} lg={7}>
            <MKTypography gutterBottom variant="h2" component="h2" sx={{ color: `${theme.palette.white.main} !important` }}>
              Why Choose <span style={{ color: theme.palette.accent.main }}>Ketamine</span>
            </MKTypography>
            <MKTypography
              sx={{ color: `${theme.palette.white.main} !important` }}
              variant={isLg ? "body1" : "body2"}
              component="p"
              maxWidth={900}
              gutterBottom
            >
              Ketamine is an innovative therapy for mental health issues, providing rapid relief with visible effects in just hours or days. Its
              remarkable effectiveness extends beyond depression, making it a powerful and appealing non-traditional solution for individuals seeking
              relief. There is ongoing research that continues to show promise that ketamine therapy can be effective for treatment resistant
              depression and other symptoms of mental health disorders. You can learn more about the latest research below.
            </MKTypography>
            {isLg && (
              <>
                <StyledButtonContainer>
                  <MKButton
                    component={Link}
                    to={ghlLinkFreeConsultation}
                    variant="contained"
                    color="secondary"
                    fontWeight="regular"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ fontSize: ".875rem" }}
                    aria-label="Schedule a free consultation to learn more"
                  >
                    Schedule a free consultation to learn more
                  </MKButton>
                </StyledButtonContainer>
              </>
            )}
          </Grid>
          <Grid item xs={12} lg={5}>
            <BenefitsList />
            {!isLg && (
              <>
                <StyledButtonContainer>
                  <MKButton
                    component={Link}
                    to={ghlLinkFreeConsultation}
                    variant="contained"
                    color="secondary"
                    fontWeight="regular"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ fontSize: ".875rem" }}
                    aria-label="Schedule a free consultation to learn more"
                  >
                    Schedule a free consultation to learn more
                  </MKButton>
                </StyledButtonContainer>
              </>
            )}
          </Grid>
        </Grid>
      </StyledBenefitsSectionContainer>
    </StyledSectionBackgroundWrapper>
  );
}

export default Benefits;
