// Major
import React, { useEffect } from "react";

// Components
import MKBox from "components/MKBox";
import PageHeader from "../pageHeader";

const CandidateSurveyForm = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://link.msgsndr.com/js/form_embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <MKBox>
      <PageHeader />
      <iframe
        src="https://api.leadconnectorhq.com/widget/survey/MpxWT5ItLJCpXUfYyv3D"
        style={{ border: "none", width: "100%", minHeight: "600px" }}
        scrolling="no"
        id="MpxWT5ItLJCpXUfYyv3D"
        title="Online Self Assessment"
      ></iframe>
    </MKBox>
  );
};

export default CandidateSurveyForm;
